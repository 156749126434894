import React, { Component, Children } from "react";
import {
    Modal,
    Button,
    ButtonGroup,
    ModalBody,
    Row,
    Col,
    Container,
    ModalHeader,
    UncontrolledAlert,
    Jumbotron,
    Input,
    Label,
    FormGroup,
    InputGroupButtonDropdown,
    DropdownMenu,
    DropdownItem,
    Media,
    Collapse,
} from "reactstrap";
import "./HomeScreenBusiness.scss";
import {
    faCreditCard,
    faLock,
    faSave,
    faTimes,
    faExchangeAlt,
    faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BannerDetailsNormal from "../../NormalUser/BannerDetails/BannerDetailsNormal";
import MakeEventsBusiness from "../MakeEventsBusiness/MakeEventsBusiness.js";
import ContentEvents from "../../BothUsers/Events/ContentEvents.js";
import SearchBar from "../../BothUsers/SearchBar/SearchBar";
import BookKeeper from "../BookKeeper/BookKeeper";
import { fetchApi } from "../../../fetchApi.js";
import MakeOfferEvent from "../../BusinessUser/MakeEventsBusiness/MakeOfferEvent.js";
import MakeVideoEvent from "../../BusinessUser/MakeEventsBusiness/MakeVideoEvent.js";
import VoucherForm from "../Voucher/VoucherForm";
import { userCredentials } from "../../../globalVariables";
import FlatList from "flatlist-react";
import Voucher from "../Voucher/Voucher";
import swal from "sweetalert";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions.js";
import MakeDeal from "../Deals/MakeDeal";
import FindPeople from "../../BothUsers/FindPeople/FindPeople";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import AddCard from "../../BothUsers/CardsManagement/AddCard";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import MyTickets from "../../NormalUser/MyTickets/MyTickets";
import DatePicker from "react-modern-calendar-datepicker";
import UserProfile from "../../BothUsers/UserProfile/UserProfile";
import { configOptions } from "final-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ZingMap from "../../ZingMap/ZingMap";
import queryString from "query-string";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BusinessOffers from "../../FirstPage/Header/BusinessOffers";
import { fetchApiLocation } from "../../../fetchApiLocation";

import google from '../../../Assets/img/google.png';
import apple from '../../../Assets/img/appStoreNew.png';
import SelectLocation from "../../ZingMap/SelectLocation/SelectLocation";

const localizer = momentLocalizer(moment);

let profilePic = require("../../../Assets/img/profilePic.jpg");
let SLogo = require("../../../Assets/img/SLogo.png");

let countryID = 0;

export default class HomeScreenBusiness extends Component {
    constructor(props) {
        let today = moment().format();
        super(props);
        this.searchbarRef = React.createRef();
        this.ZingMapRef = React.createRef();
        this.state = {
            width: window.innerWidth,
            showContent: 1,
            todayDate: today,
            companyName: "",
            address: "",
            city: "",
            email: "",
            category: "",
            cui: "",
            nuInteleg: "234/dfsdf",
            isLogged: true,
            companyUrl: "",
            phoneNumber: "",
            prefix: "",
            modalMakeEvents: false,
            categories: [],
            categoryIdMyE: "",
            where: "",
            whereMyE: "",
            inputValue: "",
            inputValueMyE: "",
            dropdownOpen: false,
            dropdownOpenMyE: false,
            startDate: "",
            startDateMyE: "",
            createEventSelection: 1,
            currentEvent: "",
            modalShowDetails: false,
            categoryName: "",
            eventsList: [],
            eventsFromUserList: [],
            searchedEvents: [],
            showAlert: false,
            showEvents: false,
            showSettings: false,
            showVoucherInput: false,
            firstTime: true,
            modalMakeVoucher: false,
            showVoucherDetails: false,
            searchedEventsMyE: [],
            showEventInfoId: this.props.showEventInfoId,
            showDealInfoId: this.props.showDealInfoId,
            categoryText: "",
            categoryTextMyE: "",
            isTerms: true,
            dealsDateList: [],
            startDateMakeDeal: {
                day: new Date().getDate(),
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
            },
            endDateMakeDeal: null,
            showAddCard: false,
            cardsList: [],
            updateEvent: false,
            page: 0,
            pageUser: 0,
            hasEvents: false,
            hasEventsUser: false,
            pagedEvents: [],
            pagedEventsUser: [],
            userDeactivated: false,
            startDateH: "",
            endDateH: "",
            holidayDescription: "",
            holidayList: [],
            dateIS: [],
            dateIE: [],
            dateISCount: 0,
            dateIECount: 0,
            counter: [],
            descriptionList: [],
            businessUserId: null,
            openBusinessModal: false,
            userProfileModal: false,
            userProfileId: "",
            searchCheck: false,
            addressSV: "",
            latSV: "",
            lngSV: "",
            catIdSV: "",
            inHoliday: false,
            updatePeopleEvent: false,
            openDownload: false,
            appStoreLink: "",
            googlePlayLink: "",
            countryId: -1,
            countryList: [],
            country: "",
            openFirstEvent: false,
            selectedZone: "",
            cityLocation: "",
            loader: true,
            currentMonthList: [],
            curentDealCalendarId: null,
            showProfile: false,
            userIdforModal: "",
            eventModal: false,
            currentLogo: "",
            requestedEvent: "",
            businessEventList: [],
            a51List: [],
            a52List: [],
            currentMultiprice: [],
            updateVoucher: false,
            currentVoucher: '',
            showSearchBusiness: false,
            myBirthday: '',
            fromWrapper: false,
            minPriceLei: "",
            minPriceEuro: "",
            minPriceDolar: "",
            minPriceLire: "",


        };
    }

    updateDescription = (evt, id) => {
        let descriptionList = this.state.descriptionList;
        descriptionList[id] = evt.target.value;
        this.setState({ descriptionList });
    };

    handleChangeS = (date) => {
        this.setState({
            startDateH: date,
        });
    };

    handleChangeE = (date) => {
        this.setState({
            endDateH: date,
        });
    };

    handleChangeIS = (date, id) => {
        let dateIS = this.state.dateIS;
        dateIS[id].year = date.year;
        dateIS[id].month = date.month;
        dateIS[id].day = date.day;
        this.setState({ dateIS: dateIS });

    };

    handleChangeIE = (date, id) => {
        let dateIE = this.state.dateIE;
        dateIE[id].year = date.year;
        dateIE[id].month = date.month;
        dateIE[id].day = date.day;
        this.setState({ dateIE: dateIE });

    };

    eventStyleGetter = (event, start, end, isSelected) => {
        var style = {};
        let cd = new Date();
        cd.setHours(0, 0, 0, 0);
        let date = new Date(event.resource.deals.date);
        let day = new Date(start);

        event.resource.deals_calendar.forEach((element) => {
            let eDay = new Date(element.date);
            if (+day === +eDay) {
                this.state.holidayList.forEach((holiday) => {
                    let stdh = new Date(holiday.from_date);
                    let edh = new Date(holiday.to_date);
                    if (+edh >= +stdh && +stdh <= +eDay && +eDay <= +edh) {
                        style = {
                            backgroundColor: "orange",
                        };
                    }
                });
            }
        });
        return {
            style: style,
        };
    };

    onChangeHolidayDescription = (evt) => {
        this.setState({ holidayDescription: evt.target.value });
    };

    addHoliday = async () => {
        let authKey = userCredentials.authKey;
        const url = "addholidays";
        const data = new FormData();
        data.append("business_user_id", userCredentials.userId);
        data.append(
            "from_date",
            moment(
                new Date(
                    this.state.startDateH.year,
                    this.state.startDateH.month - 1,
                    this.state.startDateH.day
                )
            ).format("YYYY-MM-DD")
        );
        data.append(
            "to_date",
            moment(
                new Date(
                    this.state.endDateH.year,
                    this.state.endDateH.month - 1,
                    this.state.endDateH.day
                )
            ).format("YYYY-MM-DD")
        );
        data.append("description", this.state.holidayDescription);

        const response = await fetchApi(url, "POST", data, authKey);
        for (let item of data.entries()) {

        }

        if (response.success) {
            swal("Success!", "Holiday added!", "success");
            this.getMyDeals();
            this.setState({ startDateH: "", endDateH: "", holidayDescription: "" });
        }
    };

    deleteHoliday = async (id) => {
        let authKey = userCredentials.authKey;
        const url = "deleteholidays";
        const data = new FormData();
        data.append("business_user_id", userCredentials.userId);
        data.append("holiday_id", id);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            swal("Success!", "Holiday deleted!", "success");
            this.getMyDeals();
        }
    };

    updateHoliday = async (id, idS) => {
        let authKey = userCredentials.authKey;
        const url = "updateholidays";

        const data = new FormData();
        let startYear = this.state.dateIS[idS].year;
        let startMonth = this.state.dateIS[idS].month - 1;
        let startDay = this.state.dateIS[idS].day;

        let endYear = this.state.dateIE[idS].year;
        let endMonth = this.state.dateIE[idS].month - 1;
        let endDay = this.state.dateIE[idS].day;

        data.append("business_user_id", userCredentials.userId);
        data.append("holiday_id", id);
        data.append(
            "from_date",
            moment(new Date(startYear, startMonth, startDay)).format("YYYY-MM-DD")
        );
        data.append(
            "to_date",
            moment(new Date(endYear, endMonth, endDay)).format("YYYY-MM-DD")
        );
        data.append("description", this.state.descriptionList[idS]);
        const response = await fetchApi(url, "POST", data, authKey);


        if (response.success) {
            swal("Success!", "Holiday updated!", "success");
            this.getMyDeals();
        }
    };

    showTermsAndCondition = (isTermsWindow) => {
        const doesShow = this.state.showTerms;
        this.setState({ showTerms: !doesShow });
        this.setState({ isTerms: isTermsWindow });
    };

    getSearchedEventsFromUser = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofeventsperuser";
        const data = new FormData();
        data.append("location", this.state.whereMyE);
        data.append("latitude", this.state.latitude ? this.state.latitude : "");
        data.append("longitude", this.state.longitude ? this.state.longitude : "");
        data.append(
            "date",
            this.state.startDateMyE !== ""
                ? moment(
                    new Date(
                        this.state.startDateMyE.year +
                        "-" +
                        this.state.startDateMyE.month +
                        "-" +
                        this.state.startDateMyE.day
                    )
                ).format("YYYY-MM-DD")
                : ""
        );

        data.append("category", this.state.useAliasId ? 0 : this.state.categoryIdMyE);

        data.append("alias_category_id", this.state.useAliasId ? this.state.categoryIdMyE : 0);

        // data.append("category", this.state.categoryIdMyE);
        data.append("user_id", userCredentials.userId);
        data.append("searchWord", this.state.categoryTextMyE);

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            let allEvents = response.events.reverse();
            let normalEvents = [];
            let closedEvents = [];
            for (let i = 0; i < allEvents.length; i++) {

                if (allEvents[i].is_closed) {
                    closedEvents.push(allEvents[i]);
                } else {
                    normalEvents.push(allEvents[i]);
                }
            }


            normalEvents = [...normalEvents, ...closedEvents];

            for (let i = 0; i < normalEvents.length; i++) {
                normalEvents[i].event = { ...normalEvents[i].event, ...{ event_type: 2 } };

            }

            this.setState({
                searchedEventsMyE: normalEvents.reverse(),
                hasSearchedMyE: true,
                latitude: "",
                longitude: "",
            });
            if (normalEvents.length === 0) {
                this.setState({ showAlertMyE: true, showEventsMyE: false });
            }
        }

        if (
            this.state.searchedEventsMyE &&
            this.state.searchedEventsMyE.length == 0
        ) {
            this.setState({ showAlertMyE: true });
        } else {
            this.setState({ showAlertMyE: false });
        }

        if (this.state.searchedEventsMyE.length > 5) {
            this.addEventsUser(0);
        } else {
            this.setState({
                pagedEventsUser: this.state.searchedEventsMyE,
                hasEventsUser: false,
            });
        }
    };


    updateVoucher = (voucher,) => {

        this.setState({
            modalMakeVoucher: true,
            updateVoucher: true,
            currentVoucher: voucher
        });
    };

    toggleModalMakeVoucher = () => {
        this.setState({ modalMakeVoucher: !this.state.modalMakeVoucher });
        if (this.state.updateVoucher) {
            this.setState({ updateVoucher: false });
        }
    };

    onDismiss = () => {
        this.setState({ showAlert: false, showEvents: false });
    };

    onDismissMyE = () => {
        this.setState({ showAlertMyE: false, showEventsMyE: false });
    };

    selectEvent = (event) => {
        let inHoiday = false;
        let cd = new Date();
        cd.setHours(0, 0, 0, 0);
        let date = new Date(event.resource.deals.date);
        let day = new Date(event.start);
        event.resource.deals_calendar.forEach((element) => {
            let eDay = new Date(element.date);
            if (+day === +eDay) {
                this.state.holidayList.forEach((holiday) => {
                    let stdh = new Date(holiday.from_date);
                    let edh = new Date(holiday.to_date);
                    if (+edh >= +stdh && +stdh <= +eDay && +eDay <= +edh) {
                        inHoiday = true;
                    }
                });
            }
        });

        let dealCalendar = event.resource.deals_calendar.filter(
            (item) =>
                new Date(item.date).getTime() === new Date(event.start).getTime()
        );

        if (dealCalendar[0] !== undefined) {
            this.setState({
                profileModal: !this.state.profileModal,
                curentDealCalendarId: dealCalendar[0].id,
                currentDealCalendar: dealCalendar[0],
                inHoliday: inHoiday,
            });
        } else {
            this.setState({
                profileModal: !this.state.profileModal,
                curentDealCalendarId: event.resource.deals_calendar[0].id,
                curentDealCalendar: event.resource.deals_calendar[0],
                inHoliday: inHoiday,
            });
        }

        this.toggleModalShowDetailsD(
            event.resource.deals,
            event.resource.deals_calendar
        );
    };

    selectSlot = (slot) => {
        this.setState({
            startDateMakeDeal: {
                day: new Date(slot.start).getDate(),
                month: new Date(slot.start).getMonth() + 1,
                year: new Date(slot.start).getFullYear(),
            },
            endDateMakeDeal: {
                day: new Date(slot.end).getDate(),
                month: new Date(slot.end).getMonth() + 1,
                year: new Date(slot.end).getFullYear(),
            },
        });
        this.toogleModalmakeEvent(true);
    };

    reloadEventsCallback = () => {
        this.getMyEvents();
        this.getMyDeals();
        this.setState({ updateEvent: false });
        this.setState({ modalMakeEvents: false });
    };

    getDealById = async (event_id) => {

        const url = "getdealbyid";
        const data = new FormData();
        data.append("deal_id", event_id);
        const response = await fetchApi(url, "POST", data);
        if (response.success) {
            if (response.deal_details && response.deal_details[0]) {
                let event = response.deal_details[0].deal[0];
                this.setState({
                    //profileModal: !this.state.profileModal,
                    currentEvent: event,
                    modalTitle: "Deal details",
                    // currentMultiprice: multiprice,
                    currentCalendar: response.deal_details[0].deal_calendar,
                    curentDealCalendarId: 0,
                    eventLogo: response.deal_details[0].deal[0].logo,
                    modalShowDetails: true,
                });
            }
        }
    };
    toggleUserProfileModal = (userId) => {
        this.setState({
            userProfileModal: !this.state.userProfileModal,
            userProfileId: userId,
        });
        this.props.resetShowUserInfoId();
    };
    EventWrapper = (event, dealName, dealHour) => {
        return (
            <span>
                {dealHour + " " + dealName}
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="approveRejectDeals"
                    color={"#f5b91c"}
                    title={"You have pendings users to this deal!"}
                />
            </span>
        );
    };
    getMyDeals = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofdealsperbusiness";
        const data = new FormData();
        data.append("business_user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            let arr = [];
            response.deals.forEach((felement) => {
                felement.deals_calendar.forEach((element) => {
                    let endDate =
                        element.date.substring(0, 10) + " " + felement.deals.end_hour;
                    arr.push({
                        start: moment(element.date).toDate(),
                        end: moment(endDate).toDate(),
                        title:
                            element.needtoaccept > 0
                                ? this.EventWrapper(
                                    element,
                                    felement.deals.name,
                                    felement.deals.start_hour
                                )
                                : felement.deals.start_hour.substring(0, 5) +
                                " " +
                                felement.deals.name,
                        resource: felement,
                    });
                });
            });
            let dateIS = [];
            let dateIE = [];
            let counter = [];
            let count = 0;

            let holidayList = [];
            let descriptionList = [];

            response.holidays.forEach((element) => {
                descriptionList.push(element.description);

                dateIS.push({
                    year: new Date(element.from_date).getFullYear(),
                    month: new Date(element.from_date).getMonth() + 1,
                    day: new Date(element.from_date).getDate(),
                });

                dateIE.push({
                    year: new Date(element.to_date).getFullYear(),
                    month: new Date(element.to_date).getMonth() + 1,
                    day: new Date(element.to_date).getDate(),
                });

                holidayList.push({
                    id: element.id,
                    idS: count++,
                    description: element.description,
                    from_date: element.from_date,
                    to_date: element.to_date,
                });
            });
            this.setState({
                dealsDateList: arr,
                holidayList: holidayList,
                dateIS,
                dateIE,
                counter,
                descriptionList,
            });
        }
    };

    getSearchedEvents = async () => {
        const url = "listofeventsfromlocation";
        const data = new FormData();
        data.append("location", this.state.where);
        data.append("latitude", this.state.latitude ? this.state.latitude : "");
        data.append("longitude", this.state.longitude ? this.state.longitude : "");
        data.append(
            "date",
            this.state.startDate !== ""
                ? moment(
                    new Date(
                        this.state.startDate.year +
                        "-" +
                        this.state.startDate.month +
                        "-" +
                        this.state.startDate.day
                    )
                ).format("YYYY-MM-DD")
                : ""
        );
        data.append("alias_category_id", this.state.useAliasId ? this.state.categoryIdMyE : 0);
        data.append("category", this.state.categoryId);
        data.append("user_id", userCredentials.userId);
        data.append("searchWord", this.state.categoryText);

        const response = await fetchApi(url, "POST", data);


        if (response.success) {

            if (response.events.length === 0) {
                this.setState({ showAlert: true, showEvents: false });
            } else {

                let allEvents = response.events.reverse();
                let promotedEvents = [];
                let normalEvents = [];
                let combinedEvents = [];
                let closedEvents = [];
                let repeatNr = 1;
                let jumpNr = 5;
                let stackedPromoted = [];


                for (let i = 0; i < allEvents.length; i++) {
                    let promoted = moment().isSameOrBefore(allEvents[i].event.end_promoted_date);
                    if (allEvents[i].event.is_promoted === "1" && promoted) {
                        promotedEvents.push(allEvents[i])
                    } else if (allEvents[i].is_closed) {
                        closedEvents.push(allEvents[i]);
                    } else {
                        normalEvents.push(allEvents[i])
                    }
                }


                normalEvents = [...normalEvents, ...closedEvents];

                let numberPromoted = promotedEvents.length;
                let numberNormal = normalEvents.length;

                if (numberPromoted !== 0) {

                    if (numberPromoted * 5 * 4 < numberNormal) {
                        repeatNr = 4;
                        jumpNr = 5
                    } else if (numberPromoted * 5 * 3 < numberNormal) {
                        repeatNr = 3;
                        jumpNr = 5
                    } else if (numberPromoted * 4 * 3 < numberNormal) {
                        repeatNr = 3;
                        jumpNr = 4;
                    } else if (numberPromoted * 5 * 2 < numberNormal) {
                        repeatNr = 2;
                        jumpNr = 5;
                    } else if (numberPromoted * 4 * 2 < numberNormal) {
                        repeatNr = 2;
                        jumpNr = 4;
                    } else if (numberPromoted * 4 < numberNormal) {
                        repeatNr = 1;
                        jumpNr = 4;
                    } else {
                        repeatNr = 1;
                        jumpNr = 3;
                    }

                    for (let i = 0; i < repeatNr; i++) {

                        stackedPromoted = stackedPromoted.concat(promotedEvents)
                    }


                    const merge = (normal, promoted, jump = 0, position) => normal.splice(jump, 0, promoted[position]) && normal;

                    let newEvents = [];

                    for (let i = 0; i < stackedPromoted.length; i++) {
                        newEvents = merge(normalEvents, stackedPromoted, i * jumpNr, i);
                    }

                    combinedEvents = newEvents;

                    for (let i = 0; i < combinedEvents.length; i++) {

                        combinedEvents[i].event = {
                            ...combinedEvents[i].event, ...{
                                event_type: combinedEvents[i].event.user_id.toString() === userCredentials.userId.toString() ? 2 : 3,
                                joinedEvent: false
                            }
                        };
                    }

                    this.setState({
                        eventsList: combinedEvents, hasSearched: true,

                    });

                } else {
                    for (let i = 0; i < allEvents.length; i++) {

                        normalEvents[i].event = {
                            ...normalEvents[i].event, ...{
                                event_type: normalEvents[i].event.user_id.toString() === userCredentials.userId.toString() ? 2 : 3,
                                joinedEvent: false
                            }
                        };
                    }

                    this.setState({
                        eventsList: normalEvents, hasSearched: true,

                    });
                }
                if (this.state.eventsList.length > 10) {
                    this.addEvents(0);
                } else {
                    this.setState({ pagedEvents: this.state.eventsList, hasEvents: false })
                }
            }

        }

    };

    addEvents = (page) => {
        const newEvents = [];
        for (let i = page * 10, il = i + 10; i < il && i <
            this.state.eventsList.length; i++) {
            newEvents.push(this.state.eventsList[i]);
        }

        this.setState({
            pagedEvents: [...this.state.pagedEvents, ...newEvents]
        });

        if (this.state.eventsList.length > this.state.pagedEvents.length) {
            this.setState({ hasEvents: true })
        } else {
            this.setState({ hasEvents: false })
        }


    };

    addEventsUser = (page) => {
        const newEvents = [];
        for (let i = page * 5, il = i + 5; i < il && i <
            this.state.eventsFromUserList.length; i++) {
            newEvents.push(this.state.eventsFromUserList[i]);
        }

        this.setState({
            pagedEventsUser: [...this.state.pagedEventsUser, ...newEvents]
        });

        if (this.state.eventsFromUserList.length > this.state.pagedEventsUser.length) {
            this.setState({ hasEventsUser: true })
        } else {
            this.setState({ hasEventsUser: false })
        }
    };

    onScrollHandler = () => {
        this.setState({
            page: this.state.page + 1
        }, () => {
            this.addEvents(this.state.page);
        });
    };

    onScrollHandlerUser = () => {
        this.setState({
            pageUser: this.state.pageUser + 1
        }, () => {
            this.addEventsUser(this.state.pageUser);
        });

    }

    toggleDropDown = e => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    };
    toggleDropDownMyE = e => {
        this.setState({
            dropdownOpenMyE: !this.state.dropdownOpenMyE
        });
    };

    setCreateEvent = nr => {
        this.setState({ createEventSelection: nr });
    };

    onSearchClick = () => {
        this.setState({
            showAlert: false,
            pagedEvents: [],
            pagedEventsUser: [],
            page: 0,
            searchCheck: false,
        });

        this.getSearchedEvents();
    };

    onSearchClickMyE = () => {
        this.setState({ showAlertMyE: false, pagedEvents: [], pagedEventsUser: [], pageUser: 0 });

        this.getSearchedEventsFromUser();
    };

    openFirstEvent() {
        if (this.props.selectedEventId) {

            this.getEvents().then(() => {
                this.getMyEvents().then(() => {
                    let allEvents = this.state.eventsList.concat(
                        this.state.eventsFromUserList
                    );
                    const evtIndex = allEvents.findIndex((s) => {
                        return s.event.id === this.props.selectedEventId;
                    });

                    if (evtIndex !== -1) {
                        this.setState({
                            currentEvent: allEvents[evtIndex].event,
                            currentMultiprice: allEvents[evtIndex].multiprice,
                            eventLogo: allEvents[evtIndex].user_logo,
                        });
                        this.setState({ modalShowDetails: true });
                    }
                });
            });
        }
    }

    // getCategories = async () => {
    //     // const url = "get_categories";
    //     const url = "get_firstlevelcategoriesandalias";
    //     const response = await fetchApi(url, "POST", null);
    //     if (response.success) {
    //         this.setState({categories: response.list_of_categories});
    //     }
    // };
    getCategories = async () => {
        const url = "get_firstlevelcategoriesandalias";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);
        let list = [],
            alias = [],
            categs;
        if (response.success) {
            for (let i = 0; i < response.list_of_categories.length; i++) {
                categs = response.list_of_categories[i].category;
                list.push(categs);
                for (let j = 0; j < response.list_of_categories[i].alias.length; j++) {
                    alias = response.list_of_categories[i].alias[j];
                    if (alias.name_of_subcategory.length !== 0) {
                        list.push(alias);
                    }
                }
            }
            this.setState({ aliasId: list.id })
            this.setState({ categories: list });
        }
    };

    // getAccount = async () => {
    //     let authKey = userCredentials.authKey;
    //     let data = new FormData();
    //     let url = "getaccountinformations";
    //     data.append("user_id", userCredentials.userId);

    //     const response = await fetchApi(url, "POST", data, authKey);
    // };

    // getCountry = async id => {
    //     const url = "listofcountries";
    //     const response = await fetchApi(url, "POST");
    //
    //
    //     if (response.success) {
    //         let country = response.listofcountries.filter(
    //             item => parseInt(item.id) === parseInt(id)
    //         );
    //         if (country && country.length > 0) {
    //             this.setState({city: country[0].en_name});
    //         }
    //     }
    // };

    onAddressChange = evt => {
        this.setState({ address: evt.target.value });
    };

    getProfile = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "getprofile";
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success && response.user_informations !== null) {
            if (response.user_informations.address !== null) {
                this.setState({
                    address: response.user_informations.address
                });
            }
            this.setState({
                email: response.user_informations.email,
                companyName: response.user_informations.company_name,
                cui: response.user_informations.company_registration_number,
                categoryName: response.category_name,
                myBirthday: response.user_informations.birthday
            });

            // this.getCountry(response.user_informations.country);
        }
    };

    fromWrapper() {
        const link = queryString.parse(window.location.search);

        if (link) {
            const fromWrapper = link.from;
            if (fromWrapper) {
                this.setState({ fromWrapper: false })
            } else {
                this.setState({ fromWrapper: true })
            }
        }
    }

    getStoreLinks = async () => {
        const url = "get_hpcomponents";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            this.setState({
                minPriceLei: response.minpricelei[0].minpricelei,
                minPriceEuro: response.minpriceeuro[0].minpriceeuro,
                minPriceDolar: response.minpricedolar[0].minpricedolar,
                minPriceLire: response.minpricelira[0].minpricelira,

                googlePlayLink: response.googlePlayLinkScanner && response.googlePlayLinkScanner.length > 0 ? response.googlePlayLinkScanner[0].googlePlayLinkScanner : "",
                appStoreLink: response.appStoreLinkScanner && response.appStoreLinkScanner.length > 0 ? response.appStoreLinkScanner[0].appStoreLinkScanner : ""
            });
        }
    };

    componentDidMount = () => {
        this.fromWrapper();
        this.getStoreLinks();
        this.getUserCards();
        this.getCategories();
        this.getProfile();
        this.getMyVouchers("", "", "", "");
        this.getEvents();
        this.getMyDeals();
        this.fetchCountry();
        this.getContactInfo();
    };

    getMyEvents = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofeventsperuser";
        const data = new FormData();
        data.append("location", "");
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("date", "");
        data.append("category", "0");
        data.append("alias_category_id", "0");
        data.append("user_id", userCredentials.userId);
        data.append("searchWord", "");

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            let allEvents = response.events.reverse();

            let normalEvents = [];
            let closedEvents = [];

            for (let i = 0; i < allEvents.length; i++) {

                if (allEvents[i].is_closed) {
                    closedEvents.push(allEvents[i]);
                } else {
                    normalEvents.push(allEvents[i]);
                }
            }

            normalEvents = [...normalEvents, ...closedEvents];

            for (let i = 0; i < normalEvents.length; i++) {
                normalEvents[i].event = {
                    ...normalEvents[i].event,
                    ...{
                        event_type:
                            normalEvents[i].event.user_id.toString() ===
                                userCredentials.userId.toString()
                                ? 2
                                : 3,
                        joinedEvent: true,
                    },
                };
            }

            this.setState({
                eventsFromUserList: normalEvents.reverse(),
            });

            if (response.events === null) {
                this.setState({ showAlertMyE: true, showEventsMyE: false });
            }
        }
        if (
            this.state.eventsFromUserList &&
            this.state.eventsFromUserList.length == 0
        ) {
            this.setState({ showAlertMyE: true });
            this.clickedDeleteFBus(0)
        } else {
            this.setState({ showAlertMyE: false });
            this.clickedDeleteFBus(this.state.eventsFromUserList.length)
        }


        if (this.state.eventsFromUserList.length > 5) {
            this.addEventsUser(0);
        } else {
            this.setState({ pagedEventsUser: this.state.eventsFromUserList, hasEventsUser: false })

        }
    };

    clickedDeleteFBus(numberEventsNormal) {
        this.props.handleInputBus(numberEventsNormal)
    };

    getEvents = async () => {
        const url = "listofeventsfromlocation";

        const data = new FormData();
        data.append("location", "");
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("date", "");
        data.append("category", "0");
        data.append("alias_category_id", "0");

        data.append("user_id", userCredentials.userId);
        data.append("searchWord", "");
        const response = await fetchApi(url, "POST", data);

        if (response.success) {

            let allEvents = response.events.reverse();
            let promotedEvents = [];
            let normalEvents = [];
            let closedEvents = [];
            let combinedEvents = [];
            let repeatNr = 1;
            let jumpNr = 5;
            let stackedPromoted = [];


            for (let i = 0; i < allEvents.length; i++) {
                let promoted = moment().isSameOrBefore(allEvents[i].event.end_promoted_date);
                if (allEvents[i].event.is_promoted === "1" && promoted) {
                    promotedEvents.push(allEvents[i]);
                } else if (allEvents[i].is_closed) {
                    closedEvents.push(allEvents[i]);
                } else {
                    normalEvents.push(allEvents[i]);
                }
            }


            normalEvents = [...normalEvents, ...closedEvents];


            let numberPromoted = promotedEvents.length;
            let numberNormal = normalEvents.length;
            if (numberPromoted !== 0) {

                if (numberPromoted * 5 * 4 < numberNormal) {
                    repeatNr = 4;
                    jumpNr = 5
                } else if (numberPromoted * 5 * 3 < numberNormal) {
                    repeatNr = 3;
                    jumpNr = 5
                } else if (numberPromoted * 4 * 3 < numberNormal) {
                    repeatNr = 3;
                    jumpNr = 4;
                } else if (numberPromoted * 5 * 2 < numberNormal) {
                    repeatNr = 2;
                    jumpNr = 5;
                } else if (numberPromoted * 4 * 2 < numberNormal) {
                    repeatNr = 2;
                    jumpNr = 4;
                } else if (numberPromoted * 4 < numberNormal) {
                    repeatNr = 1;
                    jumpNr = 4;
                } else {
                    repeatNr = 1;
                    jumpNr = 3;
                }


                for (let i = 0; i < repeatNr; i++) {

                    stackedPromoted = stackedPromoted.concat(promotedEvents)
                }


                const merge = (normal, promoted, jump = 0, position) => normal.splice(jump, 0, promoted[position]) && normal;

                let newEvents = [];

                for (let i = 0; i < stackedPromoted.length; i++) {
                    newEvents = merge(normalEvents, stackedPromoted, i * jumpNr, i);
                }

                combinedEvents = newEvents;

                for (let i = 0; i < combinedEvents.length; i++) {

                    combinedEvents[i].event = {
                        ...combinedEvents[i].event, ...{
                            event_type: combinedEvents[i].event.user_id.toString() === userCredentials.userId.toString() ? 2 : 3,
                            joinedEvent: false
                        }
                    };
                }

                this.setState({ eventsList: combinedEvents });

            } else {
                for (let i = 0; i < normalEvents.length; i++) {

                    normalEvents[i].event = {
                        ...normalEvents[i].event, ...{
                            event_type: normalEvents[i].event.user_id.toString() === userCredentials.userId.toString() ? 2 : 3,
                            joinedEvent: false
                        }
                    };
                }
                this.setState({ eventsList: normalEvents });

            }

            if (this.state.eventsList.length > 10) {
                this.addEvents(0);
            } else {
                this.setState({ pagedEvents: this.state.eventsList, hasEvents: false })
            }


            //var mySubString = window.location.href.split("snid=").pop().split("?")[0];
            const link = queryString.parse(window.location.search);
            if (link) {
                const mySubString = link.snid;
                if (mySubString && TryParseInt(mySubString, null) !== null && this.state.firstTime) {
                    let event = response.events.filter(
                        (element) => parseInt(element.event.id) === parseInt(mySubString)
                    );

                    setTimeout(() => {
                        this.toggleModalShowDetails(
                            event[0].event,
                            event[0].multiprice,
                            event[0].user_logo
                        );
                    }, 3500);



                    this.setState({ firstTime: false });
                }
            }
        }

        if (this.state.eventsList && this.state.eventsList.length === 0
        ) {
            this
                .setState({ showAlert: true });
        } else {
            this.setState({ showAlert: false });
        }


    };

    getMyVouchers = async (address, lat, lng, catId) => {
        let authKey = userCredentials.authKey;
        const url = "listofvouchersperbusiness";
        const data = new FormData();
        data.append("business_user_id", userCredentials.userId);
        // data.append("latitude", lat ? lat : "");
        // data.append("longitude", lng ? lng : "");
        // data.append("address", address ? address : "");
        //data.append("category_id", catId ? catId : "");
        data.append("country", -1);


        data.append("latitude", "");
        data.append("longitude", "");
        data.append("address", "");


        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.setState({
                voucherList: response.vouchers,
            });
        }
    };

    searchForVoucher = async (address, lat, lng, catId) => {
        let authKey = userCredentials.authKey;
        const url = "listofvouchersperbusiness";
        const data = new FormData();
        data.append("business_user_id", userCredentials.userId);
        data.append("country", this.state.countryId);


        if (this.state.countryId === 0) {
            data.append("latitude", "");
            data.append("longitude", "");
            data.append("address", "");
        } else {
            data.append("latitude", this.state.latitude ? this.state.latitude : "");
            data.append("longitude", this.state.longitude ? this.state.longitude : "");
            data.append("address", "");
        }


        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.setState({
                voucherList: response.vouchers,
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.businessUserId !== this.props.selectedBusinessId) {
            if (this.props.selectedBusinessId === prevProps.selectedBusinessId) {

                this.setState({ businessUserId: this.props.selectedBusinessId, openBusinessModal: true });
            }
        }

        if (!this.state.openFirstEvent) {
            this.setState({ openFirstEvent: true })
            this.openFirstEvent()
        }

        if (prevState.showContent !== this.props.openTag) {
            if (this.props.openTag !== prevProps.openTag) {

                if (this.props.openTag === 1) {
                    this.getEvents()
                    this.setState({ showContent: this.props.openTag });
                } else if (this.props.openTag === 2) {
                    this.getMyEvents()
                    this.setState({ showContent: this.props.openTag });

                } else if (this.props.openTag === 3) {
                    this.handleDealsClick()
                    this.setState({ showContent: this.props.openTag });
                } else if (this.props.openTag === 4) {
                    this.setState({ showContent: this.props.openTag });
                } else if (this.props.openTag === 5) {
                    this.getMyVouchers()
                    this.setState({ showContent: this.props.openTag });
                } else if (this.props.openTag === 6) {
                    this.setState({ showContent: this.props.openTag });
                } else if (this.props.openTag === 7) {
                    this.setState({ showContent: this.props.openTag });
                }

            }
        }
        if (prevState.modalMakeEvents !== this.props.openEventFromPromo) {
            if (this.props.openEventFromPromo !== prevProps.openEventFromPromo) {
                this.setState({ modalMakeEvents: true });
            }
        }


        if (prevProps.showEventInfoId !== this.props.showEventInfoId) {
            if (this.props.showEventInfoId) {
                this.getMyEvents().then(() => {
                    this.getEvents().then(() => {
                        let allEvents = this.state.eventsList.concat(
                            this.state.eventsFromUserList
                        );
                        const evtIndex = allEvents.findIndex((s) => {
                            return s.event.id === this.props.showEventInfoId;
                        });
                        if (evtIndex !== -1) {
                            this.setState({
                                currentEvent: allEvents[evtIndex].event,
                                currentMultiprice: allEvents[evtIndex].multiprice,
                                eventLogo: allEvents[evtIndex].user_logo,
                            });
                            this.setState({ modalShowDetails: true });
                            this.props.resetShowEventInfoId();
                        }
                    });
                });
            }
        }

        if (prevProps.showDealInfoId !== this.props.showDealInfoId) {
            if (this.props.showDealInfoId) {
                this.getDealById(this.props.showDealInfoId);
            }
        }

        if (prevProps.showUserInfoId !== this.props.showUserInfoId) {
            if (this.props.showUserInfoId) {
                this.toggleUserProfileModal(this.props.showUserInfoId);
            }
        }

        // if (prevState.updateEvents != this.state.updateEvents && this.state.updateEvents) {
        //     this.getMyEvents();
        //     this.setState({updateEvents: false});
        // }
        // if (prevState.updateMyEvents != this.state.updateMyEvents && this.state.updateMyEvents) {
        //     this.getEvents();
        //     this.setState({updateMyEvents: false});
        // }
    }
    ;

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };
    handleChangeMyE = date => {
        this.setState({
            startDateMyE: date
        });
    };


    toggleModalShowDetails = (item, multiprice, logo) => {
        this.setState({
            currentEvent: item,
            currentMultiprice: multiprice,
            eventLogo: logo
        });

        this.setState({ modalShowDetails: !this.state.modalShowDetails });
        if (this.state.showVoucherInput) {
            this.setState({ showVoucherInput: false });
        }
        if (this.state.showVoucherDetails) {
            this.setState({ showVoucherDetails: false });
        }

        if (this.state.currentCalendar !== undefined) {
            this.setState({
                currentCalendar: undefined,
                curentDealCalendarId: undefined,
            });
        }
        if (this.state.curentDealCalendar !== undefined) {
            this.setState({ curentDealCalendar: undefined });
        }

        this.props.resetShowDealInfoId();
    };

    toggleModalShowDetailsD = (item, calendar) => {
        this.setState({
            currentEvent: item,
            currentMultiprice: [
                {
                    id: 1,
                    price: calendar.length > 0 ? calendar[0].price : 0,
                    nOf: item.max_persons,
                    indicativePrice: item.currency,
                },
            ],
            eventLogo: item.logo,
            currentCalendar: calendar,
        });

        this.setState({ modalShowDetails: !this.state.modalShowDetails });
    };

    showVoucer = () => {
        this.setState({ showVoucherInput: true, modalShowDetails: true });

    };

    reloadVouchersCallBack = () => {
        this.getMyVouchers("", "", "", "", "");
        this.toggleModalMakeVoucher();
    };


    handleSeetingsClick = () => {

        this.setState({ pagedEvents: [], pagedEventsUser: [], page: 0, pageUser: 0 });
        this.props.changeTagsEventUser();
        this.getEvents();
        // this.setState({
        //     showContent: 1,
        // });
        if (this.searchbarRef.current)
            this.searchbarRef.current.resetSearchValues();
    };

    handleEventsClick = () => {

        this.setState({ pagedEvents: [], pagedEventsUser: [], page: 0, pageUser: 0 });
        this.props.changeTagsEvent();
        this.getMyEvents();
        // this.setState({
        //     showContent: 2,
        // });
        if (this.searchbarRef.current)
            this.searchbarRef.current.resetSearchValues();
    };

    handleDealsClick = () => {
        //this.refreshInputs()
        this.props.changeDeals();
        this.getMyDeals()
        this.setState({
            // showContent: 3,
            updateMyEvents: true,
            updateEvents: true,
        });

        let currentMonthList = [];

        for (let el of this.state.dealsDateList) {
            let d1 = new Date(el.start);
            let d2 = new Date();
            d2.setHours(0, 0, 0, 0);
            d1.setHours(0, 0, 0, 0);
            d2.setDate(0);
            let nd1 = new Date(d2);
            d2.setDate(d2.getDate() + 1);
            d2.setMonth(d2.getMonth() + 1);

            let d3 = new Date(d2);
            d3.setDate(d3.getDate() + 1);
            let d4 = new Date(d3);
            d4.setDate(d4.getDate() + 1);
            let d5 = new Date(d4);
            d5.setDate(d5.getDate() + 1);
            let d6 = new Date(d5);
            d6.setDate(d6.getDate() + 1);
            let d7 = new Date(d6);
            d7.setDate(d7.getDate() + 1);

            let nd2 = new Date(nd1);
            nd2.setDate(nd2.getDate() - 1);
            let nd3 = new Date(nd2);
            nd3.setDate(nd3.getDate() - 1);
            let nd4 = new Date(nd3);
            nd4.setDate(nd4.getDate() - 1);
            let nd5 = new Date(nd4);
            nd5.setDate(nd5.getDate() - 1);
            let nd6 = new Date(nd5);
            nd6.setDate(nd6.getDate() - 1);

            if (
                (new Date(el.start).getMonth() === new Date().getMonth() &&
                    new Date(el.start).getFullYear() === new Date().getFullYear()) ||
                +d1 === +d2 ||
                +d1 === +d3 ||
                +d1 === +d4 ||
                +d1 === +d5 ||
                +d1 === +d6 ||
                +d1 === +d7 ||
                +d1 === +nd1 ||
                +d1 === +nd2 ||
                +d1 === +nd3 ||
                +d1 === +nd4 ||
                +d1 === +nd5 ||
                +d1 === +nd6
            ) {
                currentMonthList.push(el);
            }
        }

        this.setState({
            currentMonthList: currentMonthList,
        });
    };

    // handleMessengerClick = () => {
    //   this.setState({ showContent: 4 });
    // };

    handleBookkeperClick = () => {
        //this.refreshInputs()
        this.props.changeBookKeeper();
        this.setState({
            // showContent: 4,
            updateMyEvents: true,
            updateEvents: true
        });
    };

    handleNotificationsClick = () => {
        //this.refreshInputs()
        this.props.changeVouchers();
        this.setState({
            // showContent: 5,
            updateMyEvents: true,
            updateEvents: true
        });
    };

    findPeopleClick = () => {
        //this.refreshInputs()
        // this.props.changeFindPeople();
        this.setState({
            // showContent: 6,
            updateMyEvents: true,
            updateEvents: true
        });
    };

    myTicketsClick = () => {
        //this.refreshInputs()
        this.props.changeMyTickets();
        this.setState({
            // showContent: 7,
            updateMyEvents: true,
            updateEvents: true
        });
    };

    changeLoginState = () => {
        this.setState({ isLogged: !this.state.isLogged });
    };

    toogleModalmakeEvent(type) {
        this.setState({
            modalMakeEvents: !this.state.modalMakeEvents,
            deal: type,
            updatePeopleEvent: false,
        });
        if (this.state.updateEvent) {
            this.setState({ updateEvent: false });
        }
    }

    toogleModalShowDetails = name => {
        this.setState({
            currentEvent: name
        });
        this.setState({ modalShowDetails: !this.state.modalShowDetails });
    };

    onInputChange = (value, searchWord) => {
        if (searchWord.id) {
            this.setState({
                categoryId: searchWord.id,
                // categoryText: "",
                useAliasId: searchWord.parent_category_id ? true : false
            });
        } else {
            this.setState({
                categoryId: 0,
                // categoryText: searchWord
            });
        }
    };
    onInputChangeWord = (value, searchWord) => {
        this.setState({
            categoryText: value
        });
    };
    onInputChangeWordMyE = (value, searchWord) => {
        this.setState({
            categoryTextMyE: value
        });
    };
    onInputChangeMyE = (value, searchWord) => {
        if (searchWord.id) {
            this.setState({
                categoryIdMyE: searchWord.id,
                // categoryTextMyE: "",
                useAliasId: searchWord.parent_category_id ? true : false
            });
        } else {
            this.setState({
                categoryIdMyE: 0,
                // categoryTextMyE: searchWord
            });
        }

    };
    onDropdownItem_Click = sender => {
        var newState = {
            dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue")
        };
        this.setState({
            inputValue: newState.dropDownValue2,
            dropdownOpen: !this.state.dropdownOpen
        });
        this.state.categories.forEach(element => {
            if (element.name === newState.dropDownValue2) {
                this.setState({ categoryId: element.id });
            }
        });
    };
    onDropdownItem_ClickMyE = sender => {
        var newState = {
            dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue")
        };
        this.setState({
            inputValueMyE: newState.dropDownValue2,
            dropdownOpenMyE: !this.state.dropdownOpen
        });
        this.state.categories.forEach(element => {
            if (element.name === newState.dropDownValue2) {
                this.setState({ categoryIdMyE: element.id });
            }
        });
    };

    onWhereChange = (address, lat, lng) => {
        this.setState({ where: address });
    };
    onWhereChangeMyE = (address, lat, lng) => {
        this.setState({ whereMyE: address, latitude: lat, longitude: lng });
    };

    toggleModalShowDetailsVoucher = (voucher) => {
        this.setState({
            currentVoucher: voucher,
            modalShowDetails: true,
            showVoucherDetails: true,
            requestsList: [],
        });

        voucher.request_to_assign.forEach(async (element) => {
            let authKey = userCredentials.authKey;
            let data = new FormData();
            let url = "getselecteduserprofile";
            data.append("fub_user_id", element.user_id);
            data.append("user_id", userCredentials.userId);
            const response = await fetchApi(url, "POST", data, authKey);
            if (response.success) {
                this.setState({
                    requestsList: [
                        ...this.state.requestsList,
                        {
                            requestId: element.id,
                            voucherId: element.voucher_id,
                            logo: response.logo,
                            name: response.name,
                            approved: element.is_approved,
                            rejected: element.is_rejected,
                            request_user_id: element.user_id,
                            request_event_id: element.event_id
                        },
                    ],
                });
            }
        });
    };

    approveVoucher = async (requestId, voucherId) => {
        let authKey = userCredentials.authKey;
        const url = "approverejectvoucher";
        const data = new FormData();
        data.append("voucher_id", voucherId);
        data.append("voucher_assigned_id", requestId);
        data.append("is_approve", 1);
        data.append("is_rejected", 2);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.getMyVouchers();
            swal({
                title: "Success",
                text: "Operation completed successfully",
                icon: "success",
                buttons: {
                    add: {
                        text: "Ok",
                        value: 1
                    },
                }
            }).then(val => {
                if (val === 1) {
                    this.setState({ modalShowDetails: false });
                } else {
                    this.setState({ modalShowDetails: false });
                }
            });


        }
    };

    rejectVoucher = async (requestId, voucherId) => {
        let authKey = userCredentials.authKey;
        const url = "approverejectvoucher";
        const data = new FormData();
        data.append("voucher_id", voucherId);
        data.append("voucher_assigned_id", requestId);
        data.append("is_approve", 2);
        data.append("is_rejected", 1);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.getMyVouchers();
            swal({
                title: "Success",
                text: "Operation completed successfully",
                icon: "success",
                buttons: {
                    add: {
                        text: "Ok",
                        value: 1
                    },
                }
            }).then(val => {
                if (val === 1) {
                    this.setState({ modalShowDetails: false });
                } else {
                    this.setState({ modalShowDetails: false });
                }
            });

        }
    };

    updateEvent = (event, multiprice) => {
        if (
            event.persons_left === event.max_persons &&
            parseInt(event.user_id) === userCredentials.userId
        ) {
            this.setState({
                eventToUpdate: event,
                multipriceToUpdate: multiprice,
                modalMakeEvents: true,
                updateEvent: true,
                deal: false,
                updatePeopleEvent: false,
            });
        } else if (
            event.persons_left !== event.max_persons &&
            parseInt(event.user_id) === userCredentials.userId
        ) {
            // //this.setState({ showAlert: true });
            // swal("Info!", "This event can't be updated", "info");
            this.setState({
                updatePeopleEvent: true,
                eventToUpdate: event,
                multipriceToUpdate: multiprice,
                modalMakeEvents: true,
                updateEvent: true,
                deal: false,
            });
        }
    };

    resetEventsParams = () => {
        this.setState({
            where: "",
            startDate: "",
            latitude: "",
            longitude: "",
            categoryId: "",
            searchWord: "",
            categoryText: "",
            updateEvents: true,
            eventsList: [],
            searchedEvents: [],
            showAlert: false
        });
    }
    resetMyEventsParams = () => {
        this.setState({
            whereMyE: "",
            startDateMyE: "",
            latitude: "",
            longitude: "",
            categoryIdMyE: "",
            searchWord: "",
            updateMyEvents: true,
            eventsFromUserList: [],
            searchedEventsMyE: [],
            categoryTextMyE: "",
            showAlertMyE: false
        });
    }

    openDownload = () => {
        this.setState({ openDownload: !this.state.openDownload })
    }

    renderMakeEvents = (showVoucher) => {
        return (
            <div>
                <Row className="justify-content-end makeEventRow mt-3">

                    {showVoucher ? (
                        <Col
                            lg="3"
                            className=" justify-content-center align-items-center text-white py-2 makeEventBtn  "
                            onClick={this.toggleModalMakeVoucher}
                        >
                            New Voucher
                        </Col>
                    ) : null}

                    {this.state.showContent === 3 ?
                        <Row className="parentBoth "
                        >
                            {this.state.fromWrapper ? <Col
                                lg="3"
                                className=" py-2  makeEventBtn-mine"
                                onClick={() => this.openDownload()}
                            >
                                Get Scanner
                        </Col> : null}

                            <Col lg="3"
                                className="py-2 makeEventBtn-mine "
                                onClick={() => this.toogleModalmakeEvent(true)}
                            >
                                New Deal
                         </Col>
                        </Row>
                        : null}

                    {this.state.showContent === 2 ?
                        <Row className="parentBoth" lg="12" >
                            {this.state.fromWrapper ? <Col
                                lg="3"
                                className=" py-2  makeEventBtn-mine"
                                onClick={() => this.openDownload()}
                            >
                                Get Scanner
                        </Col> : null}
                            <Col
                                lg="3"
                                className="py-2 makeEventBtn-mine "
                                onClick={() => this.toogleModalmakeEvent(false)}
                            >
                                New Event
                         </Col>
                        </Row>
                        : null}
                    {this.state.showContent === 1 ?
                        <Row className="parentBoth" lg="12" >
                            <Col
                                lg="3"
                                className="d-flex justify-content-center align-items-center   py-2 makeEventBtn "
                                onClick={() => this.toogleModalmakeEvent(false)}
                            >
                                New Event
                    </Col>
                        </Row>
                        :
                        (

                            (null)
                        )}

                </Row>
                <Modal
                    isOpen={this.state.openDownload}
                    size="lg"
                    // toggle={() => this.openDownload()}
                    className="modalMakeEvt"
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            Download Scanner
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.openDownload()}
                        />
                    </div>
                    <ModalBody>
                        <Row className="justify-content-around">
                            <Col className="text-center">
                                <a href={this.state.appStoreLink} target="_blank" className="w-100">
                                    <Media
                                        className="appBtnScanner"
                                        object
                                        src={apple}
                                        alt="image"
                                    />
                                </a>
                            </Col>
                            <Col className="text-center">
                                <a href={this.state.googlePlayLink} target="_blank" className="w-100">
                                    <Media className="appBtnScanner" object src={google} alt="image" />
                                </a>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.modalMakeEvents}
                    size="lg"
                    // toggle={() => this.toogleModalmakeEvent(this.state.deal)}
                    className="modalMakeEvt"
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            New/Update {this.state.deal ? "deal" : "event"}
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.toogleModalmakeEvent(this.state.deal)}
                        />
                    </div>
                    <ModalBody>
                        <ErrorBoundary>
                            {/*{!this.state.deal ? (*/}
                            {/*    null*/}
                            {/*    // <Col>*/}
                            {/*    //     <ButtonGroup className="w-100">*/}
                            {/*    //         /!*<Button*!/*/}
                            {/*    //         /!*    className="greenBtn"*!/*/}
                            {/*    //         /!*    onClick={() => this.setCreateEvent(1)}*!/*/}
                            {/*    //         /!*    active={this.state.createEventSelection === 1}*!/*/}
                            {/*    //         /!*>*!/*/}
                            {/*    //         /!*    Normal {this.state.deal ? "Deal" : "Event"}*!/*/}
                            {/*    //         /!*</Button>*!/*/}
                            {/*    //         /!*<Button*!/*/}
                            {/*    //         /!*    className="greenBtn centerGreenBtn"*!/*/}
                            {/*    //         /!*    onClick={() => this.setCreateEvent(2)}*!/*/}
                            {/*    //         /!*    active={this.state.createEventSelection === 2}*!/*/}
                            {/*    //         /!*>*!/*/}
                            {/*    //         /!*    Video*!/*/}
                            {/*    //         /!*</Button>*!/*/}
                            {/*    //         /!*<Button*!/*/}
                            {/*    //         /!*    className="greenBtn"*!/*/}
                            {/*    //         /!*    onClick={() => this.setCreateEvent(3)}*!/*/}
                            {/*    //         /!*    active={this.state.createEventSelection === 3}*!/*/}
                            {/*    //         /!*>*!/*/}
                            {/*    //         /!*    Offer*!/*/}
                            {/*    //         /!*</Button>*!/*/}
                            {/*    //     </ButtonGroup>*/}
                            {/*    // </Col>*/}
                            {/*) : null}*/}
                            {this.state.deal ? (
                                <MakeDeal
                                minPriceLei={this.state.minPriceLei}
                                minPriceEuro={this.state.minPriceEuro}
                                minPriceDolar={this.state.minPriceDolar}
                                minPriceLire={this.state.minPriceLire}

                                    currentMultiprice={this.state.multipriceToUpdate}
                                    reloadEventsCallback={this.reloadEventsCallback}
                                    startDate={this.state.startDateMakeDeal}
                                    endDate={this.state.endDateMakeDeal}
                                />
                            ) : this.state.createEventSelection === 1 ? (
                                <MakeEventsBusiness
                                    minPriceLei={this.state.minPriceLei}
                                    minPriceEuro={this.state.minPriceEuro}
                                    minPriceDolar={this.state.minPriceDolar}
                                    minPriceLire={this.state.minPriceLire}

                                    //isDeal={this.state.deal}
                                    reloadEventsCallback={this.reloadEventsCallback}
                                    eventToUpdate={this.state.eventToUpdate}
                                    updateEvent={this.state.updateEvent}
                                    updatePeopleEvent={this.state.updatePeopleEvent}
                                    categories={this.state.categories}
                                    ageCategoryList={this.state.ageCategoryList}
                                    profileImg={this.state.profileImg}
                                    name={this.state.name}
                                    currentMultiprice={this.state.multipriceToUpdate}
                                />
                            ) : this.state.createEventSelection === 2 ? (
                                <MakeVideoEvent />
                            ) : (
                                <MakeOfferEvent />
                            )}
                        </ErrorBoundary>
                    </ModalBody>
                </Modal>
                {
                    showVoucher ? (
                        <Modal
                            isOpen={this.state.modalMakeVoucher}
                            size="lg"
                            // toggle={this.toggleModalMakeVoucher}
                            className="modalMakeEvt"
                        >
                            <div className="header-p">
                                <ModalHeader className="head-1  text-white">
                                    New Voucher
                                </ModalHeader>
                                <div className="logo_image">
                                    <img className="my_logo" src={SLogo} alt="my_logo" />
                                </div>
                                <ModalHeader
                                    className="head-2"
                                    toggle={this.toggleModalMakeVoucher}
                                />
                            </div>
                            <ModalBody>
                                <ErrorBoundary>

                                    <VoucherForm
                                        minPriceLei={this.state.minPriceLei}
                                        minPriceEuro={this.state.minPriceEuro}
                                        minPriceDolar={this.state.minPriceDolar}
                                        minPriceLire={this.state.minPriceLire}

                                        currentVoucher={this.state.currentVoucher}
                                        updateVoucher={this.state.updateVoucher}
                                        companyName={this.state.companyName}
                                        reloadVouchersCallBack={this.reloadVouchersCallBack}
                                    />

                                </ErrorBoundary>
                            </ModalBody>
                        </Modal>
                    ) : null
                }
            </div>
        );
    };

    // renderDeal = (deal, idx) => {
    //     return (
    //         <div className="my-1 " key={deal.deals.id}>
    //             <ErrorBoundary>
    //                 <ContentEvents

    //                     isClosed={!deal.is_closed}
    //                     eventId={deal.deals.id}

    //                     userIdE={deal.deals.user_id}
    //                     name={deal.deals.name}
    //                     category={deal.deals.category_id}
    //                     text={deal.deals.description}
    //                     date={deal.deals.date ? deal.deals.date.substring(0, 11) : ""}
    //                     area={deal.deals.address}
    //                     time={
    //                         deal.deals.start_hour ? deal.deals.start_hour.substring(0, 5) : ""
    //                     }
    //                     teams={deal.deals.team_number}
    //                     participants={deal.deals.max_persons - deal.deals.persons_left}
    //                     price={
    //                         deal.deals_calendar.length > 0
    //                             ? deal.deals_calendar.price === 0
    //                             ? "FREE"
    //                             : "PAY"
    //                             : "FREE"
    //                     }
    //                     gender={deal.deals.gender}
    //                     ageCategory={deal.deals.age_category}
    //                     latitude={deal.deals.latitude}
    //                     longitude={deal.deals.longitude}
    //                     team_number={deal.deals.team_number}
    //                     max_persons={deal.deals.max_persons}
    //                     persons_left={deal.deals.persons_left}
    //                     type="free"
    //                     toogleModalShowDetails={() =>
    //                         this.toggleModalShowDetailsD(deal.deals, deal.deals_calendar)
    //                     }
    //                     reloadEventsCallback={this.reloadEventsCallback}
    //                     categories={this.state.categories}
    //                     userLogo={deal.deals.logo}
    //                     showVoucer={this.showVoucer}
    //                     description={deal.deals.name}
    //                     // showButtons={
    //                     //   parseInt(eventInfo.event.user_id) ===
    //                     //   parseInt(userCredentials.userId)
    //                     //     ? true
    //                     //     : false
    //                     // }
    //                     // updateEvent={() =>
    //                     //   this.updateEvent(eventInfo.event, eventInfo.multiprice)
    //                     // }
    //                     // showEventInfoId={this.state.showEventInfoId}
    //                 />
    //             </ErrorBoundary>
    //         </div>
    //     );
    // };

    renderEvents = (eventInfo, idx) => {
        let promoted = moment().isSameOrBefore(eventInfo.event.end_promoted_date);
        return (
            <div
                className="my-1 "
                key={idx}
                style={
                    this.state.searchCheck && eventInfo.user_type === 3
                        ? { display: "none" }
                        : {}
                }
            >
                <ErrorBoundary>
                    <ContentEvents
                        categName={eventInfo.event.name_of_subcategory}
                        aliasName={eventInfo.event.name_of_aliascategory}
                        isClosed={!eventInfo.is_closed}
                        currencyOcta={eventInfo.event.currency}
                        eventEndDate={eventInfo.event.end_date}
                        isPromoted={promoted ? eventInfo.event.is_promoted : "2"}
                        startDatePromotion={eventInfo.event.start_promoted_date}
                        endDatePromotion={eventInfo.event.end_promoted_date}
                        promotionType={eventInfo.event.promoted_type}
                        promotionDescription={eventInfo.event.promoted_comment}
                        numberOfMsg={eventInfo.number_of_messages}
                        eventType={eventInfo.event.event_type}
                        joinedEvent={eventInfo.event.joinedEvent}
                        eventId={eventInfo.event.id}
                        userIdE={eventInfo.event.user_id}
                        name={eventInfo.event.name}
                        category={eventInfo.event.category_id}
                        text={eventInfo.event.description}
                        date={
                            eventInfo.event.date ? eventInfo.event.date.substring(0, 11) : ""
                        }
                        area={eventInfo.event.address}
                        time={
                            eventInfo.event.start_hour
                                ? eventInfo.event.start_hour.substring(0, 5)
                                : ""
                        }
                        teams={eventInfo.event.team_number}
                        participants={
                            eventInfo.event.max_persons - eventInfo.event.persons_left
                        }
                        state="closed"
                        price={
                            eventInfo.multiprice.length === 0
                                ? "FREE"
                                : eventInfo.multiprice.length === 1
                                    ? eventInfo.multiprice[0].price === "0"
                                        ? "FREE"
                                        : "PAY"
                                    : "PAY"
                        }
                        multiprice={eventInfo.multiprice}
                        gender={eventInfo.event.gender}
                        ageCategory={eventInfo.event.age_category}
                        latitude={eventInfo.event.latitude}
                        longitude={eventInfo.event.longitude}
                        team_number={eventInfo.event.team_number}
                        max_persons={eventInfo.event.max_persons}
                        persons_left={eventInfo.event.persons_left}
                        type="free"
                        toogleModalShowDetails={() =>
                            this.toggleModalShowDetails(
                                eventInfo.event,
                                eventInfo.multiprice,
                                eventInfo.user_logo
                            )
                        }
                        reloadEventsCallback={this.reloadEventsCallback}
                        categories={this.state.categories}
                        userLogo={eventInfo.user_logo}
                        showVoucer={this.showVoucer}
                        userTypeBusiness={eventInfo.user_type}
                        showButtons={
                            parseInt(eventInfo.event.user_id) ===
                                parseInt(userCredentials.userId)
                                ? true
                                : false
                        }
                        updateEvent={() =>
                            this.updateEvent(eventInfo.event, eventInfo.multiprice)
                        }
                        showEventInfoId={this.state.showEventInfoId}
                        description={eventInfo.event.name}
                        voucherLogo={eventInfo.voucher_logo}
                        voucherVideo={eventInfo.voucher_video}
                    />
                </ErrorBoundary>
            </div>
        );
    };

    renderEventsUser(eventInfo, idx) {
        let promoted = moment().isSameOrBefore(eventInfo.event.end_promoted_date);
        return (
            <div className="my-1 " key={idx}>
                <ErrorBoundary>
                    <ContentEvents
                        isClosed={!eventInfo.is_closed}
                        currencyOcta={eventInfo.event.currency}
                        eventEndDate={eventInfo.event.end_date}
                        isPromoted={promoted ? eventInfo.event.is_promoted : "2"}
                        startDatePromotion={eventInfo.event.start_promoted_date}
                        endDatePromotion={eventInfo.event.end_promoted_date}
                        promotionType={eventInfo.event.promoted_type}
                        promotionDescription={eventInfo.event.promoted_comment}
                        userTypeBusinessMyE={eventInfo.user_type}
                        numberOfMsg={eventInfo.number_of_messages}
                        eventType={
                            eventInfo.event.user_id.toString() ===
                                userCredentials.userId.toString()
                                ? 2
                                : 3
                        }
                        joinedEvent={
                            eventInfo.event.user_id.toString() !==
                            userCredentials.userId.toString()
                        }
                        eventId={eventInfo.event.id}
                        userIdE={eventInfo.event.user_id}
                        categName={eventInfo.event.name_of_subcategory}
                        aliasName={eventInfo.event.name_of_aliascategory}

                        name={eventInfo.event.name}
                        category={eventInfo.event.category_id}
                        text={eventInfo.event.description}
                        date={eventInfo.event.date.substring(0, 11)}
                        area={eventInfo.event.address}
                        time={eventInfo.event.start_hour.substring(0, 5)}
                        teams={eventInfo.event.team_number}
                        participants={
                            eventInfo.event.max_persons - eventInfo.event.persons_left
                        }
                        state="closed"
                        price={
                            eventInfo.multiprice.length === 0
                                ? "FREE"
                                : eventInfo.multiprice.length === 1
                                    ? eventInfo.multiprice[0].price === "0"
                                        ? "FREE"
                                        : "PAY"
                                    : "PAY"
                        }
                        gender={eventInfo.event.gender}
                        ageCategory={eventInfo.event.age_category}
                        latitude={eventInfo.event.latitude}
                        longitude={eventInfo.event.longitude}
                        team_number={eventInfo.event.team_number}
                        max_persons={eventInfo.event.max_persons}
                        persons_left={eventInfo.event.persons_left}
                        type="free"
                        toogleModalShowDetails={() =>
                            this.toggleModalShowDetails(eventInfo.event)
                        }
                        toggleModalGetVouchers={() =>
                            this.toggleModalGetVouchers(eventInfo.event)
                        }
                        reloadEventsCallback={this.reloadEventsCallback}
                        categories={this.state.categories}
                        multiprice={eventInfo.multiprice}
                        userLogo={eventInfo.user_logo}
                        showButtons={
                            parseInt(eventInfo.event.user_id) === userCredentials.userId
                        }
                        updateEvent={() =>
                            this.updateEvent(eventInfo.event, eventInfo.multiprice)
                        }
                        hideVoucherButton={true}
                        showJoin={true}
                        showVoucer={this.showVoucer}
                        description={eventInfo.event.name}
                        voucherLogo={eventInfo.voucher_logo}
                        voucherVideo={eventInfo.voucher_video}
                    />
                </ErrorBoundary>
            </div>
        );
    }

    onChangeCategory = (evt, value) => {
        if (value !== null) {
            this.setState({ catIdSV: value.id });
            this.setState({ supressBlur: true });
        } else {
            this.setState({ supressBlur: false, catIdSV: "" });
        }
    };

    onDismissVoucher = () => {
        this.getMyVouchers("", "", "", "");
    };

    onWhereChangeVoucherSearch = (address, lat, lng) => {
        if (lat !== 0 && lng !== 0) {
            this.setState({ latSV: lat, lngSV: lng, addressSV: address });
        } else {
            this.setState({ latSV: "", lngSV: "", addressSV: "" });
        }
    };

    onSearchVoucherClick = () => {
        let address = this.state.addressSV !== "" ? this.state.addressSV : "";
        let lat = this.state.latSV !== "" ? this.state.latSV : "";
        let lng = this.state.lngSV !== "" ? this.state.lngSV : "";
        let catId = this.state.catIdSV !== "" ? this.state.catIdSV : "";
        this.searchForVoucher("", lat, lng, catId);
        //this.setState({ catIdSV: "", latSv: "", lngSV: "", addressSV: "" });
    };

    //   obBlurCategory = (evt) => {
    //     if (!this.state.supressBlur) {
    //       this.props.onInputChange(0, evt.target.value);
    //     }
    //     this.setState({ supressBlur: false });
    //   };

    onBusinessFilter = () => {

        this.setState({ searchCheck: !this.state.searchCheck });
    };
    navigateThroughCalendar = (date) => {
        let currentMonthList = [];

        for (let el of this.state.dealsDateList) {
            let d1 = new Date(el.start);
            let d2 = new Date(date);
            d2.setHours(0, 0, 0, 0);
            d1.setHours(0, 0, 0, 0);
            d2.setDate(0);
            let nd1 = new Date(d2);
            d2.setDate(d2.getDate() + 1);
            d2.setMonth(d2.getMonth() + 1);

            let d3 = new Date(d2);
            d3.setDate(d3.getDate() + 1);
            let d4 = new Date(d3);
            d4.setDate(d4.getDate() + 1);
            let d5 = new Date(d4);
            d5.setDate(d5.getDate() + 1);
            let d6 = new Date(d5);
            d6.setDate(d6.getDate() + 1);
            let d7 = new Date(d6);
            d7.setDate(d7.getDate() + 1);

            let nd2 = new Date(nd1);
            nd2.setDate(nd2.getDate() - 1);
            let nd3 = new Date(nd2);
            nd3.setDate(nd3.getDate() - 1);
            let nd4 = new Date(nd3);
            nd4.setDate(nd4.getDate() - 1);
            let nd5 = new Date(nd4);
            nd5.setDate(nd5.getDate() - 1);
            let nd6 = new Date(nd5);
            nd6.setDate(nd6.getDate() - 1);

            if (
                (new Date(el.start).getMonth() === new Date(date).getMonth() &&
                    new Date(el.start).getFullYear() === new Date(date).getFullYear()) ||
                +d1 === +d2 ||
                +d1 === +d3 ||
                +d1 === +d4 ||
                +d1 === +d5 ||
                +d1 === +d6 ||
                +d1 === +d7 ||
                +d1 === +nd1 ||
                +d1 === +nd2 ||
                +d1 === +nd3 ||
                +d1 === +nd4 ||
                +d1 === +nd5 ||
                +d1 === +nd6
            ) {
                currentMonthList.push(el);
                //   new Date(el.start).getFullYear(),
                //   new Date(date).getFullYear(),
                //   yearIncrement
                // );
            }
        }

        this.setState({
            currentMonthList: currentMonthList,
        });
    };
    selectCityLocation = (location, lat, lng) => {
        this.setState({ cityLocation: location, latitude: lat, longitude: lng });
    };

    toggle = () => {
        this.setState({ isOpenColapse: !this.state.isOpenColapse });
    };

    renderSwitch = (param) => {
        let list = this.state.pagedEvents;
        let listUser = this.state.pagedEventsUser;

        const searchCity = {
            types: ["(cities)"],
            componentRestrictions: { country: [this.state.selectedZone] },
        };


        switch (param) {
            case 1:
                return (
                    <div>
                        {this.renderMakeEvents()}
                        <Container className="d-flex justify-content-center">
                            <Modal
                                isOpen={this.state.showSearchBusiness}
                                // toggle={this.toggleSearchBusinsess}
                                size="lg"
                                className="businessSearchModal"
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1  text-white">
                                        Search business
                            </ModalHeader>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo" />
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={this.toggleSearchBusinsess}
                                    />
                                </div>
                                <ModalBody className="">
                                    <FindPeople
                                        selectedCategory={this.state.categoryId ? this.state.categoryId : 0}
                                        // selectedAddress={this.state.currentAddress}
                                        selectedLat={this.state.currentLat}
                                        selectedLng={this.state.currentLng}
                                        selectedAddress={this.state.where}
                                        selectedText={this.state.categoryText}
                                        businessOnly={true}
                                        fromHeader={true}
                                        categoryList={this.state.categories}
                                    />
                                </ModalBody>
                            </Modal>
                            <Col lg="13">
                                <SearchBar
                                    toggleSearchBusinsess={this.toggleSearchBusinsess}

                                    isLoggedSearch={this.state.isLoggedinn}
                                    ref={this.searchbarRef}
                                    inputValue={this.state.inputValue}
                                    onInputChangeWord={this.onInputChangeWord}
                                    onInputChange={this.onInputChange}
                                    dropdownOpen={this.state.dropdownOpen}
                                    toggleDropDown={this.toggleDropDown}
                                    onDropdownItem_Click={this.onDropdownItem_Click}
                                    categories={this.state.categories}
                                    startDate={this.state.startDate}
                                    handleChange={this.handleChange}
                                    where={this.state.where}
                                    onWhereChange={this.onWhereChange}
                                    onSearchClick={this.onSearchClick}
                                    resetFunction={this.resetEventsParams}
                                    searchWord={this.state.categoryText}
                                />
                                <Col className="showEvtBus" >
                                    <FormGroup check className="">
                                        <Label check className="businessOnly">
                                            <Input
                                                type="checkbox"
                                                onChange={this.onBusinessFilter}
                                                checked={this.searchCheck}
                                            />
                                            Show business events only
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <div className="eventsArea shadowBox">
                                    <ErrorBoundary>
                                        {this.state.pagedEvents !== null &&
                                            !this.state.showAlert ? (
                                                <FlatList
                                                    list={list}
                                                    renderItem={(item, index) =>
                                                        this.renderEvents(item, index)
                                                    }
                                                    hasMoreItems={this.state.hasEvents}
                                                    loadMoreItems={this.onScrollHandler}
                                                />
                                            ) : (
                                                <UncontrolledAlert
                                                    isOpen={this.state.showAlert}
                                                    toggle={this.onDismiss}
                                                >
                                                    No event found
                                                </UncontrolledAlert>
                                            )}
                                    </ErrorBoundary>
                                </div>
                            </Col>
                        </Container>
                    </div>
                    // );
                );

            case 2:
                return (
                    <div>
                        {this.renderMakeEvents()}
                        <Container className=" events-cont  justify-content-center">
                            <Modal
                                isOpen={this.state.showSearchBusiness}
                                // toggle={this.toggleSearchBusinsess}
                                size="lg"
                                className="businessSearchModal"
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1  text-white">
                                        Search business
                            </ModalHeader>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo" />
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={this.toggleSearchBusinsessMyE}
                                    />
                                </div>
                                <ModalBody className="">
                                    <FindPeople
                                        selectedCategory={this.state.categoryIdMyE ? this.state.categoryIdMyE : 0}
                                        // selectedAddress={this.state.currentAddress}
                                        selectedLat={this.state.currentLat}
                                        selectedLng={this.state.currentLng}
                                        selectedAddress={this.state.whereMyE}
                                        selectedText={this.state.categoryTextMyE}
                                        businessOnly={true}
                                        fromHeader={true}
                                        categoryList={this.state.categories}
                                    />
                                </ModalBody>
                            </Modal>
                            <Col className="" lg="13">
                                <SearchBar
                                    toggleSearchBusinsess={this.toggleSearchBusinsessMyE}
                                    isLoggedSearch={this.state.isLoggedinn}
                                    ref={this.searchbarRef}
                                    inputValue={this.state.inputValueMyE}
                                    onInputChange={this.onInputChangeMyE}
                                    onInputChangeWord={this.onInputChangeWordMyE}
                                    dropdownOpen={this.state.dropdownOpenMyE}
                                    toggleDropDown={this.toggleDropDownMyE}
                                    onDropdownItem_Click={this.onDropdownItem_ClickMyE}
                                    categories={this.state.categories}
                                    startDate={this.state.startDateMyE}
                                    handleChange={this.handleChangeMyE}
                                    where={this.state.whereMyE}
                                    onWhereChange={this.onWhereChangeMyE}
                                    onSearchClick={this.onSearchClickMyE}
                                    resetFunction={this.resetMyEventsParams}
                                    searchWord={this.state.categoryTextMyE}
                                />
                                <div className="topMrg eventsArea">
                                    <ErrorBoundary>
                                        {this.state.pagedEventsUser !== null &&
                                            !this.state.showAlertMyE ? (
                                                <FlatList
                                                    list={listUser}
                                                    renderItem={(item, index) =>
                                                        this.renderEventsUser(item, index)
                                                    }
                                                    hasMoreItems={this.state.hasEventsUser}
                                                    loadMoreItems={this.onScrollHandlerUser}
                                                />
                                            ) : (
                                                <UncontrolledAlert
                                                    isOpen={this.state.showAlertMyE}
                                                    toggle={this.onDismissMyE}
                                                >
                                                    No event found
                                                </UncontrolledAlert>
                                            )}
                                    </ErrorBoundary>
                                </div>
                            </Col>
                        </Container>
                    </div>
                );
            case 3:
                const CURRENT_DATE = moment().toDate();

                const ColoredDateCellWrapper = ({ children, value }) => {
                    let evtPresent = this.state.currentMonthList.filter((x) => {
                        let curDate = new Date(value);
                        curDate.setHours(0, 0, 0, 0);
                        let dealDateStart = new Date(x.start);
                        dealDateStart.setHours(0, 0, 0, 0);
                        let dealDateEnd = moment(x.end);
                        if (+curDate === +dealDateStart) {
                            return x;
                        }
                    });
                    return React.cloneElement(Children.only(children), {
                        style: {
                            ...children.style,
                            backgroundColor:
                                evtPresent.length > 0 ? "lightgreen" : "lightyellow",
                        },
                    });
                };

                const { width } = this.state;
                const isMobile = width <= 500;
                return (
                    <div>
                        {this.renderMakeEvents()}

                        {/* <Deals events={this.state.dealsDateList} /> */}
                        <Col className="d-flex justify-content-center">
                            {/* aici deals */}
                            <Calendar
                                messages={{
                                    showMore: total => (
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onMouseOver={e => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                        >SEE all deals
                                        </div>
                                    ),
                                }}
                                localizer={localizer}
                                defaultDate={new Date()}
                                defaultView="month"
                                events={this.state.currentMonthList}
                                style={{
                                    fontSize: "12px",
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "#fff",
                                    minHeight: "700px",
                                }}
                                selectable={true}
                                onSelectSlot={this.selectSlot}
                                onSelectEvent={(event) => this.selectEvent(event)}
                                components={{
                                    dateCellWrapper: ColoredDateCellWrapper,
                                }}
                                eventPropGetter={this.eventStyleGetter}
                                onNavigate={this.navigateThroughCalendar}
                            />
                        </Col>

                        <div className="holidayContainer">
                            <Col lg="3">
                                <div className="holidayTitle">Holidays</div>
                            </Col>
                            <Row className="m-0 p-0">
                                <Col lg="3" xs="12 mt-1">
                                    <div className="datePicker evtDatepicker">
                                        <DatePicker
                                            dateFormat={"YYYY-MM-DD"}
                                            value={this.state.startDateH}
                                            onChange={(date) => this.handleChangeS(date)}
                                            inputPlaceholder="Select start date"
                                            shouldHighlightWeekends
                                            colorPrimary="#007bff"
                                            // wrapperClassName="datePicker"
                                            inputClassName="datePickerDeal-chld"

                                            // inputClassName="text-white"
                                            style={{
                                                zIndex: "9999",
                                            }}
                                        />
                                    </div>
                                </Col>

                                <Col lg="3" xs="12 mt-1">
                                    <div className="datePicker evtDatepicker">
                                        <DatePicker
                                            dateFormat={"YYYY-MM-DD"}
                                            value={this.state.endDateH}
                                            onChange={(date) => this.handleChangeE(date)}
                                            inputPlaceholder="Select end date"
                                            shouldHighlightWeekends
                                            colorPrimary="#007bff"
                                            //   wrapperClassName="datePicker"
                                            inputClassName="datePickerDeal-chld"
                                            style={{
                                                zIndex: "9999",
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col lg="3" xs="12 mt-1">
                                    <Input
                                        className="inputHoliday"
                                        value={this.state.holidayDescription}
                                        onChange={this.onChangeHolidayDescription}
                                        placeholder="holiday description..."
                                    />
                                </Col>
                                <Col
                                    lg="3"
                                    xs="12 mt-1"
                                    className="d-flex justify-content-center"
                                >
                                    <Button className="greenBtn-Deals" onClick={this.addHoliday}>
                                        Add
                  </Button>
                                </Col>
                            </Row>
                            {this.state.holidayList.map((item) => (
                                <Row key={item.idS} className="m-0 p-0 mt-2">
                                    <Col lg="3" xs="12 mt-1">
                                        <DatePicker
                                            dateFormat={"YYYY-MM-DD"}
                                            value={this.state.dateIS[item.idS]}
                                            onChange={(date) => this.handleChangeIS(date, item.idS)}
                                            inputPlaceholder="Select start date"
                                            shouldHighlightWeekends
                                            colorPrimary="#007bff"
                                            //   wrapperClassName="datePicker"
                                            inputClassName="datePickerDeal-chld"

                                            style={{
                                                zIndex: "9999",
                                            }}
                                        />
                                    </Col>
                                    <Col lg="3" xs="12 mt-1">
                                        <DatePicker
                                            dateFormat={"YYYY-MM-DD"}
                                            value={this.state.dateIE[item.idS]}
                                            onChange={(date) => this.handleChangeIE(date, item.idS)}
                                            inputPlaceholder="Select end date"
                                            shouldHighlightWeekends
                                            colorPrimary="#007bff"
                                            //   wrapperClassName="datePicker"
                                            inputClassName="datePickerDeal-chld"

                                            style={{
                                                zIndex: "9999",
                                            }}
                                        />
                                    </Col>
                                    <Col lg="3" xs="12 mt-1">
                                        <Input
                                            value={this.state.descriptionList[item.idS]}
                                            onChange={(evt) => this.updateDescription(evt, item.idS)}
                                        ></Input>
                                    </Col>
                                    <Col
                                        lg="3"
                                        xs="12 mt-1"
                                        className="d-flex justify-content-center"
                                    >
                                        <Button
                                            className="greenBtn mr-2"
                                            onClick={() => this.updateHoliday(item.id, item.idS)}
                                        >
                                            <FontAwesomeIcon icon={faSave} />
                                        </Button>
                                        <Button
                                            className="greenBtn"
                                            onClick={() => this.deleteHoliday(item.id)}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </div>
                );
            // case 3:
            //   return (
            //   <div>
            //     {this.renderMakeEvents()}
            //     <Messenger />
            //   </div>
            //   );
            case 4:
                return (
                    <div>
                        {this.renderMakeEvents()}
                        <ErrorBoundary>
                            <BookKeeper />
                        </ErrorBoundary>
                    </div>
                );
            case 5:
                return (
                    <div className="businessVoucherContainer">
                        {this.renderMakeEvents(true)}
                        {this.state.width < 500 ? (
                            <div>
                                <div
                                    className="mobileSearch mb-1"
                                    onClick={() => this.toggle()}
                                >
                                    Search voucher{" "}
                                    <FontAwesomeIcon icon={faSearch} className="ml-2" />
                                </div>
                                <Collapse isOpen={this.state.isOpenColapse}>
                                    <Row className="search-bar-div-voucher bg-white py-2 m-0">
                                        <Col
                                            xs="12"
                                            lg="5"
                                            md="4"
                                            sm="3"
                                            className="search-event-border divv-flex bRadius p-0"
                                            style={{ zIndex: 999 }}
                                        >
                                            <Autocomplete
                                                // id="combo-box-demo4"
                                                options={this.state.countryList}
                                                getOptionLabel={(option) => option.name}
                                                className="drop-down-stl  autoSearch"
                                                onChange={(e, val) => {
                                                    this.setState({
                                                        country:
                                                            val && val.en_name
                                                                ? val.en_name
                                                                : val && val.name
                                                                    ? val.name
                                                                    : "",
                                                        addressSV: val && val.en_name ? val.en_name : "",
                                                        countryId: val ? val.id : -1,
                                                        selectedZone: val ? val.code.toLowerCase() : "",
                                                        cityLocation: "",
                                                        latitude: "",
                                                        longitude: "",
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        value={this.state.country}
                                                        variant="outlined"
                                                        // className="categoryAC placeholder1"
                                                        placeholder={"Search Places ..."}
                                                    />
                                                )}
                                            />
                                        </Col>

                                        <Col
                                            xs="12"
                                            lg="5"
                                            md="3"
                                            sm="3"
                                            className="search-event-border divv-flex bRadius p-0"
                                        >
                                            <SelectLocation
                                                key={this.state.selectedZone}
                                                cityType={true}
                                                placeHolder={
                                                    this.state.selectedZone !== "" &&
                                                        this.state.country !== ""
                                                        ? "Select City"
                                                        : this.state.countryId === 0
                                                            ? "All countries"
                                                            : "Select country first"
                                                }
                                                enabledText={
                                                    this.state.selectedZone !== "" &&
                                                    this.state.country !== ""
                                                }
                                                selectCity={this.selectCityLocation}
                                                selectedZone={this.state.selectedZone}
                                                searchOptions={searchCity}
                                            />
                                        </Col>
                                        <Col
                                            lg="1"
                                            md="1"
                                            sm="1"
                                            className="rounded-circle searchContainer d-flex justify-content-center align-items-center mr-3 div-search"
                                            onClick={this.onSearchVoucherClick}
                                        >
                                            <img
                                                src={require("../../../Assets/img/search.png")}
                                                className="searchImg"
                                            />
                                        </Col>
                                    </Row>
                                </Collapse>
                            </div>
                        ) : (
                                <Row className="search-bar-div-voucher bg-white py-2 m-0">
                                    <Col
                                        xs="12"
                                        lg="5"
                                        md="4"
                                        sm="3"
                                        className="p-0"
                                        style={{ zIndex: 999 }}
                                    >
                                        <Autocomplete
                                            // id="combo-box-demo4"
                                            options={this.state.countryList}
                                            getOptionLabel={(option) => option.name}
                                            className="text-white categoryInputB"
                                            onChange={(e, val) => {
                                                this.setState({
                                                    country:
                                                        val && val.en_name
                                                            ? val.en_name
                                                            : val && val.name
                                                                ? val.name
                                                                : "",
                                                    addressSV: val && val.en_name ? val.en_name : "",
                                                    countryId: val ? val.id : -1,
                                                    selectedZone: val ? val.code.toLowerCase() : "",
                                                    cityLocation: "",
                                                    latitude: "",
                                                    longitude: "",
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    value={this.state.country}
                                                    variant="outlined"
                                                    // className="categoryAC placeholder1"
                                                    placeholder={"Search Places ..."}
                                                />
                                            )}
                                        />
                                    </Col>

                                    <Col
                                        xs="12"
                                        lg="5"
                                        md="3"
                                        sm="3"
                                        className="search-event-border divv-flex bRadius p-0"
                                    >
                                        <SelectLocation
                                            key={this.state.selectedZone}
                                            cityType={true}
                                            placeHolder={
                                                this.state.selectedZone !== "" &&
                                                    this.state.country !== ""
                                                    ? "Select City"
                                                    : this.state.countryId === 0
                                                        ? "All countries"
                                                        : "Select country first"
                                            }
                                            enabledText={
                                                this.state.selectedZone !== "" &&
                                                this.state.country !== ""
                                            }
                                            selectCity={this.selectCityLocation}
                                            selectedZone={this.state.selectedZone}
                                            searchOptions={searchCity}
                                        />
                                    </Col>
                                    <Col
                                        lg="1"
                                        md="1"
                                        sm="1"
                                        className="rounded-circle searchContainer d-flex justify-content-center align-items-center mr-3 div-search"
                                        onClick={this.onSearchVoucherClick}
                                    >
                                        <img
                                            src={require("../../../Assets/img/search.png")}
                                            className="searchImg"
                                        />
                                    </Col>
                                </Row>

                            )}
                        <div className="eventsArea">

                            <ErrorBoundary>
                                {this.state.voucherList
                                    ? this.state.voucherList.map((item) => (
                                        <div key={item.voucher.id} className="mb-2">
                                            <Voucher
                                                toggleModalMakeVoucher={() => this.toggleModalMakeVoucher()}

                                                updateVoucher={() => this.updateVoucher(item.voucher)}

                                                logo={item.voucher.logo}
                                                titleVoucher={item.voucher.title}
                                                category={item.voucher.category}
                                                location={item.voucher.address}
                                                nOf={item.voucher.vouchers_number}
                                                price={item.voucher.voucher_value}
                                                reserved={
                                                    item.voucher.vouchers_number -
                                                    item.voucher.left_vouchers
                                                }
                                                toogleModalShowDetails={() =>
                                                    this.toggleModalShowDetailsVoucher(item)
                                                }
                                                showButton={true}
                                                requests={item.request_to_assign.length}
                                                conditions={item.voucher.promo_description}
                                                currency={item.voucher.currency}
                                            />
                                        </div>
                                    ))
                                    : null}
                                {
                                    <UncontrolledAlert
                                        isOpen={
                                            this.state.voucherList
                                                ? this.state.voucherList.length === 0
                                                    ? true
                                                    : false
                                                : false
                                        }
                                        toggle={this.onDismissVoucher}
                                    >
                                        No voucher found
                                    </UncontrolledAlert>
                                }
                            </ErrorBoundary>
                        </div>
                    </div>
                );
            case 6:
                return (
                    <Container className="findPeopleContainer">
                        {/* {this.renderMakeEvents()} */}
                        <ErrorBoundary>
                            {/* <FindPeople  /> */}
                        </ErrorBoundary>
                    </Container>
                );
            case 7:
                return (
                    <div>
                        {this.renderMakeEvents()}
                        {/* <Button> Hello</Button> */}
                        <div>
                            <ErrorBoundary>
                                <Jumbotron>
                                    <MyTickets
                                        myBirthday={this.state.myBirthday}
                                        categoriesList={this.state.categories}></MyTickets>
                                </Jumbotron>
                            </ErrorBoundary>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    showAddCard = () => {

        if (!this.state.userDeactivated) {
            this.setState({ showAddCard: true });
        } else {
            swal("Error!", "Invalid operation!", "error");
        }
    }
    toggleAddCard = (needRefresh) => {
        this.setState({ showAddCard: false });
        if (needRefresh === true) {
            this.getUserCards();
        }
    }

    getUserCards = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofcardsperuser";
        const data = new FormData();
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({ cardsList: response.cards });
        } else {
            this.setState({ userDeactivated: true });
        }
    }

    closeModal = () => {
        this.setState({ openBusinessModal: false })
    };


    fetchCountry = async () => {
        const url = "listofcountries";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            let countries = [...[{ id: 0, name: "All", code: "" }], ...response.listofcountries]

            this.setState({
                countryList: countries
            });
        }
    };

    showUserDetails = (user_id) => {
        this.setState({
            showProfile: !this.state.showProfile,
            userIdforModal: user_id,
        });
    };

    showEventDetails = async (event_id, logo) => {
        const url = "geteventbyid";
        const data = new FormData();
        data.append("event_id", event_id);

        const response = await fetchApi(url, "POST", data);
        if (response.success) {
            if (response.event && response.event.length > 0) {
                let event = response.event[0];
                this.setState({
                    requestedEvent: event,
                    currentLogo: event.logo,
                });
                this.toggleEventModal();
            }
        }
    };

    toggleEventModal = () => {
        this.setState({ eventModal: !this.state.eventModal });
    };

    getElementText = (response, elementName) => {
        return response.getElementsByTagName(elementName)[0].innerHTML;
    };

    getContactInfo = async () => {
        //const response1 = await fetchApiLocation("http://ip-api.com/json", "GET");
        const response1 = await fetchApiLocation("GET")
            .then((response) => {
                return response.text();
            })
            .then((xml) => {
                return new window.DOMParser().parseFromString(xml, "text/xml");
            })
            .then(async (xmlDoc) => {
                let country = this.getElementText(xmlDoc, "countryName");
                const urlC = "listofcountries";
                const responseC = await fetchApi(urlC, "POST", null, null);
                if (responseC.success) {
                    let countries = [
                        ...[{ id: 0, name: "All", code: "" }],
                        ...responseC.listofcountries,
                    ];
                    this.setState({
                        countryList: countries,
                    });

                    if (country !== undefined) {
                        country = responseC.listofcountries.filter(
                            (item) => item.en_name.toLowerCase() === country.toLowerCase()
                        );
                    }
                    if (country !== null) {
                        if (country[0] !== undefined && country[0] !== null) {
                            // const url = "contactus";
                            // let data = new FormData();
                            // data.append("country", country[0].id);
                            // const response = await fetchApi(url, "POST", data, null);
                            // if (response.success) {
                            //   this.setState({
                            //     contactUsList: response.contactus
                            //   });
                            // }

                            countryID = country[0].id;
                            this.position();
                        } else {
                            // const url = "contactus";
                            // let data = new FormData();
                            // data.append("country", 1);
                            // const response = await fetchApi(url, "POST", data, null);
                            // if (response.success) {
                            //   this.setState({
                            //     contactUsList: response.contactus
                            //   });
                            // }
                            this.position();
                        }
                    }
                }
            });
        //https://api.hostip.info/
    };

    position = async () => {
        await navigator.geolocation.getCurrentPosition(
            (position) =>
                this.setState(
                    {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    },
                    () => {
                        this.getBusinessEventsAndOffers(
                            this.state.latitude,
                            this.state.longitude
                        );
                    }
                ),
            (error) => {
                this.getBusinessEventsAndOffers("", "");
            }
        );
    };

    getBusinessEventsAndOffers = async (latitude, longitude) => {
        const url = "filterbusinessevents";
        const data = new FormData();
        data.append("address", "");
        data.append("latitude", latitude);
        data.append("longitude", longitude);
        data.append("country_id", this.state.longitude !== "" ? countryID : 0);
        let searchDate = "";
        let currentDate = new Date(
            this.state.startDate.year +
            "-" +
            this.state.startDate.month +
            "-" +
            this.state.startDate.day
        );
        if (this.state.startDate && this.state.startDate != "") {
            searchDate = moment(currentDate, "DD-MM-YYYY").startOf("day").format();
        }
        data.append("date", "");
        data.append(
            "category",
            this.state.categoryId ? this.state.categoryId : "0"
        );
        data.append("user_id", 0);
        data.append("searchWord", this.state.categoryText);
        const response = await fetchApi(url, "POST", data);

        if (response.success) {
            this.setState({
                offertsList: response.offerts,
                businessEventList: response.events,
                a51List: response.a51,
                a52List: response.a52,
            });
        }
    };
    toggleSearchBusinsess = () => {

        this.setState({ showSearchBusiness: !this.state.showSearchBusiness });

    }
    toggleSearchBusinsessMyE = () => {

        this.setState({ showSearchBusiness: !this.state.showSearchBusiness });

    }
    render() {
        return (
            <Container>



                <Modal
                    className="costume-modal-prof"
                    isOpen={this.state.showProfile}
                //toggle={() => this.showUserDetails(this.state.userIdforModal)}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1" />
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.showUserDetails(0)}
                        />
                    </div>
                    <UserProfile userId={this.state.userIdforModal} />
                </Modal>

                <Modal
                    size="lg"
                    isOpen={this.state.eventModal}
                //  toggle={() => this.toggleEventModal()}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            Event details
                        </ModalHeader>

                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.toggleEventModal()}
                        />
                    </div>
                    <ModalBody>
                        {this.state.requestedEvent !== undefined &&
                            !this.state.showVoucers &&
                            this.state.currentMultiprice !== null &&
                            this.state.requestedEvent.date !== undefined ? (
                                <BannerDetailsNormal
                                    categoryName={this.state.requestedEvent.name_of_subcategory}

                                    currencyBannner={this.state.requestedEvent.currency}
                                    isLogged={true}
                                    price={
                                        !this.state.currentMultiprice ||
                                            this.state.currentMultiprice.length === 0
                                            ? "FREE"
                                            : this.state.currentMultiprice.length === 1
                                                ? this.state.currentMultiprice[0].price === "0"
                                                    ? "FREE"
                                                    : "PAY"
                                                : "PAY"
                                    }
                                    id={this.state.requestedEvent.id}
                                    userIdE={this.state.requestedEvent.user_id}
                                    name={this.state.requestedEvent.name}
                                    gender={this.state.requestedEvent.gender}
                                    ageCategory={this.state.requestedEvent.age_category}
                                    startDate={this.state.requestedEvent.date.substring(0, 11)}
                                    endDate={this.state.requestedEvent.end_date.substring(0, 11)}
                                    startTime={this.state.requestedEvent.start_hour.substring(0, 5)}
                                    endTime={this.state.requestedEvent.end_hour.substring(0, 5)}
                                    latitude={this.state.requestedEvent.latitude}
                                    longitude={this.state.requestedEvent.longitude}
                                    team_number={this.state.requestedEvent.team_number}
                                    max_persons={this.state.requestedEvent.max_persons}
                                    persons_left={this.state.requestedEvent.persons_left}
                                    description={this.state.requestedEvent.description}
                                    multiprice={this.state.currentMultiprice}
                                    address={this.state.requestedEvent.address}
                                    userLogo={this.state.currentLogo}
                                    eventLogo={this.state.requestedEvent.logo}
                                    category={this.state.requestedEvent.category_id}
                                    closeModal={this.toggleEventModal}
                                    reloadEventsCallback={this.reloadEventsCallback}
                                    calendar={this.state.currentCalendar}
                                />
                            ) : null}
                    </ModalBody>

                </Modal>

                <Modal
                    className="costume-modal-prof"
                    isOpen={this.state.openBusinessModal}
                //   toggle={() => this.closeModal()}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1" />
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader className="head-2" toggle={() => this.closeModal()} />
                    </div>

                    <UserProfile
                        userId={this.state.businessUserId}
                        closeHandler={() => this.closeModal()}
                    />
                </Modal>
                <div className="bg" />
                <Col className="downContent">
                    <Row className="pt-3">
                        <Col
                            lg={
                                (this.state.businessEventList !== null &&
                                    this.state.businessEventList.length > 0) || (
                                        this.state.a51List !== null &&
                                        this.state.a51List.length > 0) || (

                                        this.state.a52List !== null &&
                                        this.state.a52List.length > 0)
                                    ? "8"
                                    : "12"
                            }
                            className="p-0"
                        >
                            <MenuBar
                                settingsClick={() => {
                                    this.handleSeetingsClick();
                                }}
                                // aboutClick={this.handleAboutClick}
                                eventsClick={() => {
                                    this.handleEventsClick();
                                }}
                                dealsClick={this.handleDealsClick}
                                // messengerClick={this.handleMessengerClick}
                                bookkeeperClick={this.handleBookkeperClick}
                                notificationsClick={this.handleNotificationsClick}
                                // findPeoleClick={this.findPeopleClick}
                                myTicketsClick={this.myTicketsClick}
                                selectedTab={this.state.showContent}
                            />

                            <div className="p-1" />
                            <div className="mt-2">
                                {this.renderSwitch(this.state.showContent)}
                            </div>
                        </Col>
                        {(this.state.businessEventList !== null &&
                            this.state.businessEventList.length > 0) || (
                                this.state.a51List !== null &&
                                this.state.a51List.length > 0) || (

                                this.state.a52List !== null &&
                                this.state.a52List.length > 0)
                            ? (
                                <Col sm={12} md={12} lg={4} className="rParentSize">
                                    <div className="RightParentBg">
                                        {this.state.businessEventList !== null ||
                                            this.state.offertsList !== null ? (
                                                <Col lg="4" md="4" className="mt-2 RightParent">
                                                    {/* <Sponsor /> */}
                                                    <ErrorBoundary>
                                                        <BusinessOffers

                                                            reloadEventsCallback={this.reloadEventsCallback}

                                                            businessEventList={this.state.businessEventList}
                                                            //offertsList={this.state.offertsList}
                                                            isLogged={true}
                                                            handler={this.props.handler}
                                                            a51List={this.state.a51List}
                                                            a52List={this.state.a52List}
                                                        />
                                                    </ErrorBoundary>
                                                </Col>
                                            ) : null}
                                    </div>
                                </Col>
                            ) : null}
                    </Row>
                </Col>
                <Modal
                    isOpen={this.state.modalShowDetails}
                    size="lg"
                // toggle={this.toggleModalShowDetails}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1 text-white">
                            {this.state.showVoucherInput
                                ? "Cards list"
                                : this.state.showVoucherDetails
                                    ? "Voucher Requests"
                                    : this.state.currentCalendar !== undefined
                                        ? "Deal details"
                                        : "Event details"}
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={this.toggleModalShowDetails}
                        />
                    </div>
                    <ModalBody>
                        <ErrorBoundary>
                            {this.state.showVoucherInput ? (
                                <div className="cardDetailsParent">
                                    <Row>
                                        <Col>
                                            {this.state.cardsList.length > 0 ? (
                                                this.state.cardsList.map((item) => (
                                                    <Row
                                                        key={item.id}
                                                        className="d-flex align-items-center voucherContainer cardsListContainer"
                                                    >
                                                        <Col xs="6">
                                                            <div>{item.name}</div>
                                                        </Col>
                                                        <Col xs="3">
                                                            {item.exp_month}/{item.exp_year}
                                                        </Col>
                                                        <Col xs="3">{item.last4}</Col>
                                                    </Row>
                                                ))
                                            ) : (
                                                    <div>No cards saved</div>
                                                )}
                                        </Col>
                                    </Row>

                                    <Row className="my-5">
                                        <Col lg="6" className="leftPart">
                                            <div>
                                                <Button
                                                    className="addCardBtn"
                                                    onClick={() => {
                                                        this.showAddCard();
                                                    }}
                                                >
                                                    + Add card
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="text">
                                                <span className="cardImg">
                                                    <FontAwesomeIcon icon={faLock} className="mr-2" />
                                                </span>
                                                Safe and in control
                                            </div>
                                            <div className="text">
                                                <span className="cardImg">
                                                    <FontAwesomeIcon
                                                        icon={faCreditCard}
                                                        className="mr-2"
                                                    />
                                                </span>{" "}
                                                Fast and simple
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="terms text-center">
                                        <a>
                                            I accept Zing Sport &nbsp;
                                            <a
                                                href="javascript:void(0);"
                                                onClick={() => this.showTermsAndCondition(true)}
                                            >
                                                Terms and Conditions
                                            </a>
                                            &nbsp; by adding a card
                                        </a>
                                    </div>
                                    <Modal
                                        className="costume-modal-prof"
                                        isOpen={this.state.showTerms}
                                    // toggle={() => this.showTermsAndCondition(false)}
                                    >
                                        <div className="header-p">
                                            <ModalHeader className="head-1" />
                                            <div className="logo_image">
                                                <img className="my_logo" src={SLogo} alt="my_logo" />
                                            </div>
                                            <ModalHeader
                                                className="head-2"
                                                toggle={() => this.showTermsAndCondition(false)}
                                            />
                                        </div>
                                        <ModalBody>
                                            <TermsAndConditions
                                                isTerms={this.state.isTerms}
                                            ></TermsAndConditions>
                                        </ModalBody>
                                    </Modal>
                                </div>
                            ) : this.state.currentEvent !== undefined &&
                                this.state.currentEvent.date !== undefined ? (

                                        <BannerDetailsNormal
                                            dealsDateList={this.state.dealsDateList}
                                            categoryName={this.state.currentEvent.name_of_subcategory}
                                            currentEvent={this.state.currentEvent}
                                            isLogged={this.state.isLogged}
                                            currencyBannner={this.state.currentEvent.currency}
                                            userLogged={true}
                                            id={this.state.currentEvent.id}
                                            userIdE={this.state.currentEvent.user_id}
                                            name={this.state.currentEvent.name}
                                            gender={this.state.currentEvent.gender}
                                            ageCategory={this.state.currentEvent.age_category}
                                            startDate={this.state.currentEvent.date.substring(0, 11)}
                                            endDate={
                                                this.state.currentEvent.end_date !== null
                                                    ? this.state.currentEvent.end_date.substring(0, 11)
                                                    : ""
                                            }
                                            price={
                                                !this.state.currentMultiprice ||
                                                    this.state.currentMultiprice.length === 0
                                                    ? "FREE"
                                                    : this.state.currentMultiprice.length === 1
                                                        ? this.state.currentMultiprice[0].price === "0"
                                                            ? "FREE"
                                                            : "PAY"
                                                        : "PAY"
                                            }
                                            startTime={this.state.currentEvent.start_hour.substring(0, 5)}
                                            endTime={this.state.currentEvent.end_hour.substring(0, 5)}
                                            latitude={this.state.currentEvent.latitude}
                                            longitude={this.state.currentEvent.longitude}
                                            team_number={this.state.currentEvent.team_number}
                                            max_persons={
                                                this.state.curentDealCalendar !== undefined
                                                    ? this.state.curentDealCalendar.number_of_persons
                                                    : this.state.currentEvent.max_persons
                                            }
                                            persons_left={
                                                this.state.curentDealCalendar !== undefined
                                                    ? this.state.curentDealCalendar.left_persons
                                                    : this.state.currentEvent.persons_left
                                            }
                                            description={this.state.currentEvent.description}
                                            multiprice={this.state.currentMultiprice}
                                            address={this.state.currentEvent.address}
                                            latitude={this.state.currentEvent.latitude}
                                            longitude={this.state.currentEvent.longitude}
                                            userLogo={
                                                this.state.eventLogo !== undefined
                                                    ? this.state.eventLogo
                                                    : this.state.currentEvent.logo
                                            }
                                            eventLogo={this.state.currentEvent.category_logo}
                                            category={this.state.currentEvent.category_id}
                                            closeModal={this.toggleModalShowDetails}
                                            reloadEventsCallback={this.reloadEventsCallback}
                                            calendar={this.state.currentCalendar}
                                            curentDealCalendarId={this.state.curentDealCalendarId}
                                            handicap_facilities={
                                                this.state.currentEvent.handicap_facilities
                                            }
                                            isDeal={
                                                this.state.currentCalendar !== undefined ? true : false
                                            }
                                            endDateEvent={this.state.currentEvent.end_date}
                                            inHoliday={this.state.inHoliday}
                                        />
                                    ) : this.state.showVoucherDetails ? (
                                        <div>
                                            {this.state.requestsList.length > 0 ? (
                                                this.state.requestsList.map((item) => (
                                                    <Row
                                                        key={item.requestId}
                                                        className="d-flex align-items-center voucherContainer"
                                                    >
                                                        <Col lg="2">
                                                            <img
                                                                className="myProfileImage"
                                                                src={item.logo}
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = profilePic;
                                                                }}
                                                                onClick={() => {
                                                                    this.showUserDetails(item.request_user_id);
                                                                }}
                                                            />
                                                        </Col>

                                                        <Col lg="5">
                                                            <div>{item.name}</div>
                                                        </Col>
                                                        {item.approved === "2" && item.rejected === "2" ? (
                                                            <Col lg="5">
                                                                <Row lg="5">
                                                                    <Col lg="6">
                                                                        <Button className="approve_green"
                                                                            onClick={() =>
                                                                                this.approveVoucher(
                                                                                    item.requestId,
                                                                                    item.voucherId
                                                                                )
                                                                            }
                                                                        >
                                                                            Approve
                                                                </Button>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <Button className="reject_red"
                                                                            onClick={() =>
                                                                                this.rejectVoucher(
                                                                                    item.requestId,
                                                                                    item.voucherId
                                                                                )
                                                                            }
                                                                        >
                                                                            Reject
                                                                </Button>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <div className="see_event_blue_text"
                                                                        onClick={() => {
                                                                            this.showEventDetails(
                                                                                item.request_event_id,
                                                                                item.user_logo
                                                                            )

                                                                        }}
                                                                    >See event details...
                                                            </div>
                                                                </Row>
                                                            </Col>
                                                        ) : item.approved === "1" ? (
                                                            <Col lg="2">
                                                                <div className="approve_green_text">Approved</div>
                                                            </Col>
                                                        ) : (
                                                                    <Col lg="2">
                                                                        <div className="reject_red_text">Rejected</div>
                                                                    </Col>
                                                                )}
                                                    </Row>
                                                ))
                                            ) : (
                                                    <div>No requests yet</div>
                                                )}
                                        </div>
                                    ) : null}
                        </ErrorBoundary>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.showAddCard}
                    size="lg"
                // toggle={this.toggleAddCard}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">Add card</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleAddCard} />
                    </div>
                    <ModalBody>
                        <ErrorBoundary>
                            <AddCard handleCancel={this.toggleAddCard}></AddCard>
                        </ErrorBoundary>
                    </ModalBody>
                </Modal>

                <Modal
                    className="costume-modal-prof"
                    isOpen={this.state.userProfileModal}
                // toggle={this.toggleUserProfileModal}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1" />
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo" />
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={this.toggleUserProfileModal}
                        />
                    </div>

                    <UserProfile userId={this.state.userProfileId} />
                </Modal>
            </Container>
        );
    }
}

class MenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            showSettings: true,
            // showAbout: false,
            showEvents: false,
            showDeals: false,
            // showMessenger: false,
            showBookkepper: false,
            showNotifications: false,
            // showFindPeople: false,
            showMyTickets: false,
        };
    }

    settingsClick = () => {
        this.setState({
            showSettings: true,
            // showAbout: false,
            showEvents: false,
            showDeals: false,
            // showMessenger: false,
            showBookkepper: false,
            showNotifications: false,
            // showFindPeople: false,
            showMyTickets: false,
        });
    };

    // aboutClick = () => {
    //   this.setState({
    //     // showSettings: false,
    //     // showAbout: true,
    //     showEvents: false,
    //     showDeals: false,
    //     // showMessenger: false,
    //     showBookkepper: false,
    //     showNotifications: false
    //   });
    // };
    eventsClick = () => {
        this.setState({
            showSettings: false,
            // showAbout: false,
            showEvents: true,
            showDeals: false,
            // showMessenger: false,
            showBookkepper: false,
            showNotifications: false,
            // showFindPeople: false,
            showMyTickets: false,
        });
    };

    dealsClick = () => {
        this.setState({
            showSettings: false,
            // showAbout: false,
            showEvents: false,
            showDeals: true,
            // showMessenger: false,
            showBookkepper: false,
            showNotifications: false,
            // showFindPeople: false,
            showMyTickets: false,
        });
    };

    // messengerClick = () => {
    //   this.setState({
    //     // showSettings: false,
    //     // showAbout: false,
    //     showEvents: false,
    //     showDeals: false,
    //     // showMessenger: true,
    //     showBookkepper: false,
    //     showNotifications: false
    //   });
    // };

    bookkeeperClick = () => {
        this.setState({
            showSettings: false,
            // showAbout: false,
            showEvents: false,
            showDeals: false,
            showMessenger: false,
            showBookkepper: true,
            showNotifications: false,
            // showFindPeople: false,
            showMyTickets: false,
        });
    };

    notificationsClick = () => {
        this.setState({
            showSettings: false,
            // showAbout: false,
            showEvents: false,
            showDeals: false,
            showMessenger: false,
            showBookkepper: false,
            showNotifications: true,
            // showFindPeople: false,
            showMyTickets: false,
        });
    };

    // findPeoleClick = () => {
    //     this.setState({
    //         showSettings: false,
    //         // showAbout: false,
    //         showEvents: false,
    //         showDeals: false,
    //         showMessenger: false,
    //         showBookkepper: false,
    //         showNotifications: false,
    //         // showFindPeople: true,
    //         showMyTickets: false,
    //     });
    // };

    myTicketsClick = () => {
        this.setState({
            showSettings: false,
            // showAbout: false,
            showEvents: false,
            showDeals: false,
            showMessenger: false,
            showBookkepper: false,
            showNotifications: false,
            // showFindPeople: false,
            showMyTickets: true,
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedTab !== this.props.selectedTab) {
            if (this.props.selectedTab === 1) {
                this.settingsClick();
            } else if (this.props.selectedTab === 2) {
                this.eventsClick();
            } else if (this.props.selectedTab === 3) {
                this.dealsClick();
            } else if (this.props.selectedTab === 4) {
                this.bookkeeperClick();
            } else if (this.props.selectedTab === 5) {
                this.notificationsClick();
                // } else if (this.props.selectedTab === 6) {
                // this.findPeoleClick();
            } else if (this.props.selectedTab === 7) {
                this.myTicketsClick();
            }
            // else if (this.props.selectedTab === 4) {
            //     this.findClick();
            // }
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {

        const { width } = this.state;
        const isMobile = width <= 500;
        if (isMobile) {
            return (


                <div></div>


            )
        } else {
            return (
                <Container className="container-height">
                    <Row className="menuBarN py-3 mx-0">
                        <div onClick={this.settingsClick}>
                            <div
                                className={
                                    this.state.showSettings ? "permanentGreen" : "permanentWhite"
                                }
                                onClick={
                                    this.state.showSettings === false
                                        ? this.props.settingsClick
                                        : null
                                }
                            >
                                Events
                            </div>
                        </div>

                        <div onClick={this.eventsClick}>
                            <div
                                className={
                                    this.state.showEvents ? "permanentGreen" : "permanentWhite"
                                }
                                onClick={
                                    this.state.showEvents === false ? this.props.eventsClick : null
                                }
                            >
                                My Events
                            </div>
                        </div>
                        <div onClick={this.dealsClick}>
                            <div
                                className={
                                    this.state.showDeals ? "permanentGreen" : "permanentWhite"
                                }
                                onClick={this.props.dealsClick}
                            >
                                Virtual manager
                            </div>
                        </div>
                        {/* <div onClick={this.messengerClick}>
    <div
    className={
    this.state.showMessenger ? "permanentGreen" : "permanentWhite"
    }
    onClick={this.props.messengerClick}
    >
    Messenger
    </div>
    </div> */}

                        <div onClick={this.bookkeeperClick}>
                            <div
                                className={
                                    this.state.showBookkepper ? "permanentGreen" : "permanentWhite"
                                }
                                onClick={this.props.bookkeeperClick}
                            >
                                Bookkeeper
                            </div>
                        </div>

                        <div onClick={this.notificationsClick}>
                            <div
                                className={
                                    this.state.showNotifications
                                        ? "permanentGreen"
                                        : "permanentWhite"
                                }
                                onClick={this.props.notificationsClick}
                            >
                                Vouchers
                            </div>
                        </div>

                        {/* <div onClick={this.findPeoleClick}>
                            <div
                                className={
                                    this.state.showFindPeople ? "permanentGreen" : "permanentWhite"
                                }
                                onClick={this.props.findPeoleClick}
                            >
                Find Business
                            </div>
                        </div> */}

                        <div onClick={this.myTicketsClick}>
                            <div
                                className={
                                    this.state.showMyTickets ? "permanentGreen" : "permanentWhite"
                                }
                                onClick={this.props.myTicketsClick}
                            >
                                My Tickets
                            </div>
                        </div>
                    </Row>
                </Container>
            )
        }

    }
}

function TryParseInt(str, defaultValue) {
    var retValue = defaultValue;
    if (str !== null) {
        if (str.length > 0) {
            if (!isNaN(str)) {
                retValue = parseInt(str);
            }
        }
    }
    return retValue;
}
