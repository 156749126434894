import React from "react";
import {
    Button,
    FormGroup,
    Label,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Container,
    Col,
    Row,
    Tooltip
} from "reactstrap";
import "./MyProfile.scss";
import Friends from "../Friends/Friends";
import {fetchApi} from "../../../fetchApi";
import {userCredentials} from "../../../globalVariables";
import AddCategory from "../../BothUsers/AddCategory/AddCategory";
// import {
//   EmailIcon,
//   FacebookIcon,
//   InstapaperIcon,
//   LineIcon,
//   LinkedinIcon,
//   LivejournalIcon,
//   MailruIcon,
//   OKIcon,
//   PinterestIcon,
//   PocketIcon,
//   RedditIcon,
//   TelegramIcon,
//   TumblrIcon,
//   TwitterIcon,
//   ViberIcon,
//   VKIcon,
//   WeiboIcon,
//   WhatsappIcon,
//   WorkplaceIcon
// } from "react-share";
// import { Typeahead } from "react-bootstrap-typeahead";
// import Checkbox from "react-simple-checkbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    // faBuilding,
    faMapMarkedAlt,
    // faPaste,
    faFlag,
    faAddressCard,
    // faLanguage,
    faExclamationTriangle,
    faPen,
    faCheck,
    faUserAlt, faLock, faCreditCard
} from "@fortawesome/free-solid-svg-icons";
import ReactFileReader from "react-file-reader";
import {Animated} from "react-animated-css";
import backDark from "../../../Assets/img/backDark.png";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
// import { Dropdown } from "primereact/dropdown";
import ZingMap from "../../ZingMap/ZingMap.js";
import swal from 'sweetalert';
import moment from "moment";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import AddCard from "../CardsManagement/AddCard";

let FilterWords = require('../../../FilterWords');

let profilePic = require("../../../Assets/img/profilePic.jpg");
let SLogo = require("../../../Assets/img/SLogo.png");
// import 'primereact/resources/themes/nova-light/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';

// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';

export default class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.handlerFriends = this.handlerFriends.bind(this);
        this.node = React.createRef();
        this.state = {
            file: "",
            imagePreviewUrl: "",
            category: "",
            categoryList: [
                "GREEN VEGETABLES",
                "CHEESE MARKET",
                "MIERE DE ALBINE",
                "APPLE",
                "FISH MARKET",
                "GUTUI"
            ],
            friendsList: [],
            socialNetworkList: [],
            selected: "",
            profileModal: false,
            selectedFile: null,
            showBusinessUserForm: false,
            makeItHeaderViedeo: false,
            makeItHeaderPhoto: false,
            modal: false,
            images: [],
            seeFriend: false,
            showFriendComponent: false,
            follow: false,
            description: "",
            imagesToUpload: [],
            showImagesFromServer: false,
            modalProfileImg: false,
            src: null,
            crop: {
                unit: "%",
                width: 30,
                aspect: 9 / 9
            },
            croppedImageUrl: null,
            profilePic: null,
            fileToUpload: null,
            imagesFromServer: [],
            showFilesToUpload: false,
            profileImg: null,
            showCropImage: false,
            citySelectItems: [],
            city: "",
            cities: "",
            selectedCities: [],
            logoName: "",
            showEdit: false,
            address: "",
            language: "",
            socialNetworkInput: "",
            showSocialNetworkInput: false,
            currentSocialNetwork: 0,
            userSnList: [],
            image1: null,
            image2: null,
            image3: null,
            imagesToUpload1: null,
            imagesToUpload2: null,
            imagesToUpload3: null,
            showFilesToUpload1: false,
            showFilesToUpload2: false,
            showFilesToUpload3: false,
            firstName: "",
            languageId: 0,
            words: "",
            listOfLanguages: [],
            zingMapList: [],
            showSelectCard: false,
            cardsList: [],
            showAddCard: false,
            userDeactivated: false,
            showTerms:false,
            tooltipOpen:false
        };
        this.openCategory = this.openCategory.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    openCategory = value => {
        this.setState({modal: !value});
        this.setState({setCategory: value});
    };

    saveSocialNetwork = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "assignsocialnetworks";
        data.append("user_id", this.props.userId);
        data.append("social_network_id", this.state.currentSocialNetwork);
        data.append("link", this.state.socialNetworkInput.indexOf("https://") != -1 ? this.state.socialNetworkInput : "https://" + this.state.socialNetworkInput);
        const response = await fetchApi(url, "POST", data, authKey);
        // console.log(response, userCredentials.userId);
        if (response.success) {
            this.getProfile();
            this.setState({showSocialNetworkInput: false});
            swal("Success!", "Social network saved!", "success");
        } else {
            swal("Error!", "Error on save social network!", "error");
            this.setState({showSocialNetworkInput: false});
        }
    };


    changeCurentSN = el => {
        this.toggleShowInput();
        let input = this.state.userSnList.filter(item => el.id === item.social_id);
        this.setState({
            currentSocialNetwork: el.id
        });
        if (input[0] !== undefined) {
            this.setState({socialNetworkInput: input[0].link});
        } else {
            this.setState({socialNetworkInput: ""});
        }
    };

    toggleShowInput = () => {
        this.setState({
            showSocialNetworkInput: true
        });
    };


    onChangeSocialNetwork = evt => {
        this.setState({socialNetworkInput: evt.target.value});
    };

    getForbidenWords = async () => {
        let data = new FormData();
        let url = "forbidenwords";
        const response = await fetchApi(url, "GET", data);
        // console.log(response);
        if (response.success) {
            this.setState({forbidenWords: response.forbiden_words});
        }
    };

    //   //   if(this.state.description === response.forbiden_words.text){
    //   //     return alert("good words")


    //   // }else{
    //   //   return alert("bad words")

    //   // }
    //   }

    // }

    showEdit = () => {
        if (this.verifyForBadWords(this.state.description)) {
            this.setState({showEdit: !this.state.showEdit});
            // this.updateProfile();

        } else {
            swal("Error!", "The description contains bad words!", "error");
        }
        // if( FilterWords( this.state.description) ){
        //   alert('Good Words')
        //   // this.updateProfile();
        // }else{
        //   alert("Bad Words")
        // }

        // if( FilterWords( this.state.description) ){
        //   alert('Good Words')
        //   // this.updateProfile();
        // }else{
        //   alert("Bad Words")
        // }

    };

  getProfile = async () => {
    let authKey = userCredentials.authKey;
    let data = new FormData();
    let url = "getprofile";
    data.append("user_id", userCredentials.userId);
    const response = await fetchApi(url, "POST", data, authKey);

    if (response.success && response.userprofile[0] !== undefined) {
      this.setState({
        imagePreviewUrl: response.userprofile[0].logo,
        imagesFromServer: response.userprofile_images,
        showCropImage: false,
        userSnList: response.userprofile_socialnetwork,
        address: response.user_informations.address,
        language:
          response.userprofile[0].language !== null &&
          response.userprofile[0].language !== "null"
            ? response.userprofile[0].language
            : "",
      });
      if (this.state.description !== null) {
        this.setState({ description: response.userprofile[0].description });
      }
      if (response.userprofile_images.length !== 0) {
        this.setState({ showImagesFromServer: true });
      }
    } else {
      this.setState({ images: response.message, images: "invalid" });
      swal("Error", "Could not get your user profile", "error");
      if (this.props.closeModal) this.props.closeModal();
    }
  };

    listoffriends = async () => {
        const url = "listoffriends";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);
        // console.log(response, "raspunsul asta il cauti");
        if (response.success) {
            if (response.list_of_friends.length > 5) {
                this.setState({friendsList: response.list_of_friends.slice(0, 5)});
            } else {
                this.setState({friendsList: response.list_of_friends});
            }
        }
    };

  componentDidMount = () => {
    this.getUserCards();
    this.getAllLocations();
    this.getForbidenWords();
    this.listoffriends();
    this.getProfile();
    this.getCategories();
    this.getSocialNetworks();
    this.getaccountinformations();
    document.addEventListener("mousedown", this.handleClickOutside);
  };

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(e) {
        if (this.node.current.contains(e.target)) {
        } else {
            this.setState({
                showSocialNetworkInput: false
            });
        }
    }

    updateProfileImages1 = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "updateprofileimages";

        if (
            this.state.imagesFromServer[0] !== undefined &&
            this.state.imagesToUpload1 !== null
        ) {
            data.append("user_id", this.props.userId);
            data.append("image_1", this.state.imagesToUpload1);
            data.append("img_1_update", 1);
            data.append("img_1_id", this.state.imagesFromServer[0].id);

            const response = await fetchApi(url, "POST", data, authKey);
            if (response.success) {
            } else {
                this.setState({images: response.message, images: "invalid"});
            }
            this.setState({images1: null, images2: null, images3: null});
        } else if (
            this.state.imagesFromServer[0] === undefined &&
            this.state.imagesToUpload1 !== null
        ) {
            data.append("user_id", this.props.userId);
            data.append("image_1", this.state.imagesToUpload1);
            const response = await fetchApi(url, "POST", data, authKey);
            if (response.success) {
            }
        }
    };

    updateProfileImages2 = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "updateprofileimages";
        if (
            this.state.imagesFromServer[1] !== undefined &&
            this.state.imagesToUpload2 !== null
        ) {
            data.append("user_id", this.props.userId);
            data.append("image_2", this.state.imagesToUpload2);
            data.append("img_2_update", 1);
            data.append("img_2_id", this.state.imagesFromServer[1].id);

            const response = await fetchApi(url, "POST", data, authKey);
            if (response.success) {
            } else {
                this.setState({images: response.message, images: "invalid"});
            }
            this.setState({images1: null, images2: null, images3: null});
        } else if (
            this.state.imagesFromServer[1] === undefined &&
            this.state.imagesToUpload2 !== null
        ) {
            data.append("user_id", this.props.userId);
            data.append("image_2", this.state.imagesToUpload2);
            data.append("img_2_update", 0);
            data.append("img_2_id", 0);
            const response = await fetchApi(url, "POST", data, authKey);
        }
    };

    updateProfileImages3 = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "updateprofileimages";
        if (
            this.state.imagesFromServer[2] !== undefined &&
            this.state.imagesToUpload3 !== null
        ) {
            data.append("user_id", this.props.userId);
            data.append("image_3", this.state.imagesToUpload3);
            data.append("img_3_update", 1);
            data.append("img_3_id", this.state.imagesFromServer[2].id);

            const response = await fetchApi(url, "POST", data, authKey);
            if (response.success) {
            } else {
                this.setState({images: response.message, images: "invalid"});
            }
            this.setState({images1: null, images2: null, images3: null});
        } else if (
            this.state.imagesFromServer[2] === undefined &&
            this.state.imagesToUpload3 !== null
        ) {
            data.append("user_id", this.props.userId);
            data.append("image_3", this.state.imagesToUpload3);
            data.append("img_3_update", 0);
            data.append("img_3_id", 0);
            const response = await fetchApi(url, "POST", data, authKey);
        }
    };

    onSave = () => {
        this.updateProfile();
        this.updateProfileImages1();
        this.updateProfileImages2();
        this.updateProfileImages3();
        this.props.closeModal();
    };

    getSocialNetworks = async () => {
        let url = "socialnetworks";
        const response = await fetchApi(url, "POST", null, null);
        if (response.success) {
            this.setState({socialNetworkList: response.list_of_social_networks});
        }
    };

    verifyForBadWords = (text) => {

        let badWords = this.state.forbidenWords.split(";");
        let count = 0;
        if (text && text.length !== 0) {
            badWords.forEach((element) => {
                if (text.includes(element)) {
                    count++;
                }
            });

            if (count === 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }

    };

  updateProfile = async () => {
    let authKey = userCredentials.authKey;
    let data = new FormData();
    let url = "updateprofile";
    let verifyDescription = this.verifyForBadWords(this.state.description);
    console.log(
      // this.state.description,
      this.verifyForBadWords(this.state.description)
    );

    if (this.state.fileToUpload !== null && verifyDescription) {
      data.append("user_id", userCredentials.userId);
      data.append("language", this.state.language);
      data.append("logo", this.state.fileToUpload);
      data.append(
        "description",
        this.state.description !== null ? this.state.description : ""
      );
      data.append("address", this.state.address);
      data.append("latitude", this.state.latitude);
      data.append("longitude", this.state.longitude);
      const response = await fetchApi(url, "POST", data, authKey);
      // console.log(response, userCredentials.userId);
      if (response.success) {
        this.getProfile();
        swal("Success!", "Profile has been updated!", "success");
      } else {
        this.setState({ images: response.message, images: "invalid" });
      }
    } else if (
      this.state.longitude !== null &&
      this.state.latitude !== null &&
      this.state.address !== null &&
      verifyDescription
    ) {
      data.append("description", this.state.description);
      data.append("user_id", userCredentials.userId);
      data.append("language", this.state.language);
      data.append("address", this.state.address);
      if (
        this.state.longitude !== undefined &&
        this.state.latitude !== undefined
      ) {
        data.append("latitude", this.state.latitude);
        data.append("longitude", this.state.longitude);
      }

      const response = await fetchApi(url, "POST", data, authKey);

      if (response.success) {
        this.getProfile();
      } else {
        this.setState({ images: response.message, images: "invalid" });
      }
    } else if (!verifyDescription) {
      swal("Error!", "The description contains bad words!", "error");
    }
  };

    async getaccountinformations() {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", this.props.userId);

        const url = "getaccountinformations";
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            let info = response.account_info[0];
            this.setState({
                id: info.id,
                user_id: info.user_id,
                email: info.email,
                firstName: info.first_name,
                lastName: info.last_name,
                birthDay: info.birthday,
                genderChangeM: info.gender === "1",
                genderChangeF: info.gender === "2",
                selectedGender: info.gender === "1" ? "1" : "2"
            });
        }
    }

    openFollow() {
        this.setState({
            follow: !this.state.follow
        });
    }

    toggle = () => {
        this.setState({modal: !this.state.modal});
    };

    videoCheckBoxHandler = () => {
        this.setState({makeItHeaderViedeo: !this.state.makeItHeaderViedeo});
    };

    photoCheckBoxHandler = () => {
        this.setState({makeItHeaderPhoto: !this.state.makeItHeaderPhoto});
    };

    checkHandler = () => {
        this.setState({showBusinessUserForm: !this.state.showBusinessUserForm});
    };

    toggleProfileModal = () => {
        //console.log(this.state.modalProfileImg);
        this.setState({modalProfileImg: !this.state.modalProfileImg});
    };

    onChangeSocial(selected) {
        this.setState({selected});
        let newList = this.state.socialNetworkList;
        let iteminlist = selected + "";
        if (newList.includes(iteminlist)) {
            return -1;
        } else {
            newList.push(iteminlist);
            this.setState({socialNetworkList: newList});
        }
    }

    pushCategory = evt => {
        var newList = this.state.categoryList;
        if (this.state.category !== "") {
            newList.push(this.state.category);
            this.setState({categoryList: newList});
        }
        this.setState({category: ""});
    };

    removeCategory = evt => {
        let newList = this.state.socialNetworkList;
        newList.pop();
        this.setState({socialNetworkList: newList});
    };

    onChangeCategory = evt => {
        this.setState({category: evt.target.value});
    };

    onWhereChange = evt => {
        this.setState({
            WhereValue: evt.target.value
        });
    };

    handlerFriends(val) {
        this.setState({showFriendComponent: !this.state.showFriendComponent});
    }

    fromCroppedToProfile = () => {
        if (this.state.croppedImageUrl != null) {
            this.setState({imagePreviewUrl: this.state.croppedImageUrl});
        }
        this.toggleProfileModal();
    };
    getCategories = async () => {
        const url = "get_categories";
        const response = await fetchApi(url, "POST", null);
        //console.log(response)

        if (response.success) {
            this.setState({citySelectItems: response.list_of_categories});
        }
    };

    delete(id) {
        //console.log(id);
        this.setState(prevState => ({
            selectedCities: prevState.selectedCities.filter(el => el !== id)
        }));
    }

    handleMultipleFiles = files => {
        if (files && files.fileList.length > 0) {
            let file = files.fileList[0];
            if (file) {
                let newFileName = this.props.userId + "_" + moment().valueOf() + "_" + file.name;
                var formDataAux = new FormData();
                formDataAux.append('file', file, newFileName);
            }
        }
        this.setState({
            images1: files.base64,
            imagesToUpload1: formDataAux.get("file"),
            getImageFromServer: false
        });
        if (files.base64 !== null) {
            this.setState({showFilesToUpload1: true});
        }
    };
    handleMultipleFiles2 = files => {
        if (files && files.fileList.length > 0) {
            let file = files.fileList[0];
            if (file) {
                let newFileName = this.props.userId + "_" + moment().valueOf() + "_" + file.name;
                var formDataAux = new FormData();
                formDataAux.append('file', file, newFileName);
            }
        }
        this.setState({
            images2: files.base64,
            imagesToUpload2: formDataAux.get("file"),
            getImageFromServer2: false
        });
        if (files.base64 !== null) {
            this.setState({showFilesToUpload2: true});
        }
    };
    handleMultipleFiles3 = files => {
        if (files && files.fileList.length > 0) {
            let file = files.fileList[0];
            if (file) {
                let newFileName = this.props.userId + "_" + moment().valueOf() + "_" + file.name;
                var formDataAux = new FormData();
                formDataAux.append('file', file, newFileName);
            }
        }
        this.setState({
            images3: files.base64,
            imagesToUpload3: formDataAux.get("file"),
            getImageFromServer: false
        });
        if (files.base64 !== null) {
            this.setState({showFilesToUpload3: true});
        }
    };

    handleFiles = files => {
        //console.log(files);
        this.setState({imagePreviewUrl: files[0]});
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({src: reader.result})
            );

            reader.readAsDataURL(e.target.files[0]);
            let fileName = userCredentials.userId + "_" + e.target.files[0].name
            this.setState({logoName: fileName});
        }
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({crop});
    };

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                var file = new File([blob], fileName, {type: "image.*"});
                //let file = this.blobToFile(blob, "img.png");
                this.setState({fileToUpload: file});

                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    blobToFile(theBlob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.logoName
            );
            this.setState({croppedImageUrl, showCropImage: true});
        }
    }

    onDescriptionChange = evt => {
        this.setState({description: evt.target.value});
    };

  onChangeLanguage = (evt) => {
    this.setState({ language: evt.target.value });
  };

    // onChangeAddress = evt => {
    //   this.setState({ address: evt.target.value });
    // };
    onChangeAddress = (address, lat, lng) => {
        this.setState({address: address});
        this.setState({latitude: lat});
        this.setState({longitude: lng});
        // this.setState({ address: evt.target.value });
    };

    getAllLocations = async () => {
        //console.log("response");
        const data = new FormData();
        const url = "getalllocations";
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("category", "");
        const response = await fetchApi(url, "POST", data);
        if (response.success && response.locations && response.locations.length > 0) {
            this.setState({zingMapList: response.locations});
        }
    };
    checkForDuplicate = (arr, value) => {
        let res = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].description.toLowerCase() === value.toLowerCase()) {
                res++;
            }
        }
        if (res === 0) {
            return true;
        } else {
            return false;
        }
    };


    showSelectCard = () => {
        this.setState({showSelectCard: true});
    };

    toggleSelectCard = () => {
        this.setState({showSelectCard: false});
    };

    showAddCard = () => {
        if (!this.state.userDeactivated) {
            this.setState({showAddCard: true});
        } else {
            swal("Error!", "Invalid operation!", "error");
        }
    };

    toggleAddCard = (needRefresh) => {
        this.setState({showAddCard: false});
        if (needRefresh === true) {
            this.getUserCards();
        }
    };

    getUserCards = async () => {
        let authKey = userCredentials.authKey;

        const url = "listofcardsperuser";
        const data = new FormData();
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.setState({cardsList: response.cards});
        } else {
            this.setState({userDeactivated: true});
        }
    };

    setDefaultCard = async (cardid) => {
        const oldIndex = this.state.cardsList.findIndex((s) => {
            return s.default == true;
        });

        let authKey = userCredentials.authKey;
        const url = "setdefaultcardperuser";
        const data = new FormData();
        data.append("card_id", cardid);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            const newDefaultIndex = this.state.cardsList.findIndex((s) => {
                return s.id === cardid;
            });

      if (oldIndex !== -1) {
        this.setState((prevState) => ({
          cardsList: prevState.cardsList.map((el) =>
            el.default == true ? { ...el, default: false } : el
          ),
        }));
      }
      if (newDefaultIndex !== -1) {
        this.setState((prevState) => ({
          cardsList: prevState.cardsList.map((el) =>
            el.id == cardid ? { ...el, default: true } : el
          ),
        }));
      }
      swal("Success!", "Card successfully set as default!", "success");
    } else {
      swal("Error!", "Error on set default card!", "error");
    }
  };
  showTermsAndCondition = (isTermsWindow) => {
    const doesShow = this.state.showTerms;
    this.setState({ showTerms: !doesShow });
    this.setState({ isTerms: isTermsWindow });
  };

  searchFriends = (event) => {
    let friend = this.state.friendsList;
    this.setState({ filterVal: event.target.value.substr(0, 10) });
    if (event.target.value !== "") {
      const filteredList = friend.filter(
        (friendsList) =>
          friendsList[0].name
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) !== -1
      );
      this.setState({ friendsList: filteredList });
    } else {
      this.listoffriends();

      //this.setState({ friendsList: friend });
    }
  };

  getAllFriendsEventHandler = (event) => {
    this.setState({ filterVal: "" });
    let friendsList = this.state.friendsList;
    if (this.state.friendsList.length === 5) {
      let currentDisplayedFriends = this.state.friendsList;
      if (!currentDisplayedFriends.some((friend) => friend.key === "5"))
        this.setState({ friendsList: currentDisplayedFriends });

      this.listoffriends();
    } else {
      this.setState({ friendsList: friendsList.slice(0, 5) });
      this.setState({ filteredList: friendsList.slice(0, 5) });
    }
  };
  toggleTooltip = () => {
    this.setState({tooltipOpen: !this.state.tooltipOpen})
}
  renderToolTip() {
    return (<div>
        <div className="justify-content-center align-bottom">
            <FontAwesomeIcon className="iconTooltipProfile" icon={faExclamationTriangle} id="toolTipCategory" color={"#f5b91c"}/>
        </div>
        <Tooltip
            placement="top"
            isOpen={this.state.tooltipOpen}
            target="toolTipCategory"
            toggle={this.toggleTooltip}
            hideArrow
        >
            <div className="toolTipGeneral">
                <h6>
                PROFILE
                </h6>
                <p  >
                Contact your social networks to share events
                </p>
            </div>
        </Tooltip>
    </div>)
}
  render() {
    return (
      <Container className=" trans-ition ">
        <Modal
          isOpen={this.state.showSelectCard}
          size="lg"
        //   toggle={this.toggleSelectCard}
        >
          <div className="header-p">
            <ModalHeader className="head-1  text-white">
              Select card
            </ModalHeader>
            <div className="logo_image">
              <img className="my_logo" src={SLogo} alt="my_logo" />
            </div>
            <ModalHeader className="head-2" toggle={this.toggleSelectCard} />
          </div>
          <ModalBody>
            <div className="cardDetailsParent">
              <Row>
                <Col>
                  {this.state.cardsList.length > 0 ? (
                    this.state.cardsList.map((item) => (
                      <Row
                        key={item.id}
                        className="d-flex align-items-center voucherContainer cardsListContainer"
                        onClick={() => {
                          this.setDefaultCard(item.id);
                        }}
                      >
                        <Col xs="6">
                          <div>{item.name}</div>
                        </Col>
                        <Col xs="2">
                          {item.exp_month}/{item.exp_year}
                        </Col>
                        <Col xs="2">{item.last4}</Col>
                        <Col xs="2">
                          {item.default ? (
                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                          ) : null}
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <div>No cards saved</div>
                  )}
                </Col>
              </Row>

                            <Row className="my-5">
                                <Col lg="6" className="leftPart">
                                    <div>
                                        {/*<Button*/}
                                        {/*    className="addCardBtn"*/}
                                        {/*    onClick={() => {*/}
                                        {/*        this.showAddCard();*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    + Add card*/}
                                        {/*</Button>*/}
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="text">
                    <span className="cardImg">
                      <FontAwesomeIcon icon={faLock} className="mr-2"/>
                    </span>
                                        Safe and in control
                                    </div>
                                    <div className="text">
                    <span className="cardImg">
                      <FontAwesomeIcon icon={faCreditCard} className="mr-2"/>
                    </span>{" "}
                                        Fast and simple
                                    </div>
                                </Col>
                            </Row>
                            <div className="terms text-center">
                                <a>
                                    I accept Zing Sport &nbsp;
                                    <a
                                        href="javascript:void(0);"
                                        onClick={() => this.showTermsAndCondition(true)}
                                    >
                                        Terms and Conditions
                                    </a>
                                    &nbsp; by adding a card
                                </a>
                            </div>
                            <Modal
                                className="costume-modal-prof"
                                isOpen={this.state.showTerms}
                                // toggle={() => this.showTermsAndCondition(false)}
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1"/>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={() => this.showTermsAndCondition(false)}
                                    />
                                </div>
                                <ModalBody>
                                    <TermsAndConditions
                                        isTerms={this.state.isTerms}
                                    ></TermsAndConditions>
                                </ModalBody>
                            </Modal>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.showAddCard}
                    size="lg"
                    // toggle={this.toggleAddCard}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">Add card</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleAddCard}/>
                    </div>
                    <ModalBody>
                        <AddCard handleCancel={this.toggleAddCard} saveCard={true}></AddCard>
                    </ModalBody>
                </Modal>
                <Animated
                    animationIn="bounceInUp"
                    animationOut="slideOutUp"
                    isVisible={true}
          animationInDuration={userCredentials.userType === 2 ? 0 : 1000}
                >

                    <Col className="myProfileContainer">
                        {/* <div className="imgPreview">{$imagePreview}</div> */}
                        <Row className="topParent myProfileEdit">
              {userCredentials.userType !== 2 ? (
                <Col
                  xs="3"
                  sm="2"
                  md="2"
                  lg="2"
                  className="d-flex justify-content-center flex-column pl-0"
                >
                  {this.state.croppedImageUrl !== null &&
                  this.state.showCropImage ? (
                    <img
                      className="myProfileImage"
                      src={this.state.croppedImageUrl}
                    />
                  ) : (
                    <img
                      className="myProfileImage"
                      src={
                        this.state.imagePreviewUrl !== null &&
                        this.state.imagePreviewUrl !== "" &&
                        this.state.imagePreviewUrl !== undefined
                          ? this.state.imagePreviewUrl
                          : profilePic
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = profilePic;
                      }}
                    ></img>
                  )}
                  {this.state.showEdit ? (
                    <Col className="Parent-addProfile">
                      <Button
                        onClick={this.toggleProfileModal}
                        className="buttonUpload-Profile"
                      >
                        Upload
                      </Button>
                    </Col>
                  ) : null}
                </Col>
              ) : null}

              {userCredentials.userType !== 2 ? (
                <Col xs="9" sm="10" md="10" lg="10" className="pl-0">
                  {!this.state.showEdit ? (
                    <div className="profileData profileSpace">
                      {this.props.showModificationsOptions ? (
                        <Col className=" p-0 m-0">
                          <Button onClick={this.showEdit} className="profEdit">
                            {" "}
                            <FontAwesomeIcon icon={faPen} />{" "}
                          </Button>
                        </Col>
                      ) : null}
                      <div>
                        <div className="d-flex">
                          <span className="profileDataLabel">
                            <FontAwesomeIcon
                              icon={faUserAlt}
                              className="mr-2"
                            />{" "}
                            Name:
                        </span>
                                                {/* {this.state.name} */}
                                                <Label> {this.state.firstName}</Label>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="mt-2 d-flex">
                        <span className="profileDataLabel">
                          <FontAwesomeIcon icon={faFlag} className="mr-2"/>{" "}
                            Language:
                        </span>
                                                <Label  > {this.state.language}</Label>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="mt-2 d-flex">
                        <span className="profileDataLabel">
                          <FontAwesomeIcon
                              icon={faMapMarkedAlt}
                              className="mr-2"
                          />{" "}
                            Address:
                        </span>
                                                <Label> {this.state.address}</Label>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="mt-2 d-flex">
                        <span className="profileDataLabel">
                          <FontAwesomeIcon
                              icon={faAddressCard}
                              className="mr-2"
                          />{" "}
                            Description:
                        </span>
                        <Label className="descriptionBox">
                          {" "}
                          {this.state.description}
                        </Label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Animated
                    animationIn="pulse"
                    animationOut="zoomOut"
                    isVisible={true}
                  >
                    <div>
                      <FormGroup>
                        <Col className="input-group d-flex p-0 m-0">
                          <Input
                            className="language-profile"
                            placeholder="language..."
                            value={this.state.language}
                            onChange={this.onChangeLanguage}
                            id="language"
                          ></Input>
                                                    <Button onClick={this.showEdit} className="profEdit-selected">
                                                        {" "}
                                                        <FontAwesomeIcon icon={faCheck}/>{" "}
                                                    </Button>

                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Row className="input-group p-0 m-0">
                                              
                                                    <Col lg="4" className="pl-0 mt-1 makeeventlocation">
                                                        <ZingMap
                                                            initialAddress={this.state.address}
                                                            showSearchTextBox={true}
                                                            showOpenMapButton={true}
                                                            inputClassName="makeEvents-profile text-white"
                                                            
                                                            buttonClassName=""
                                                            enableMarkerAdd={false}
                                                            selectedLocationHandler={this.onChangeAddress}
                                                            list={this.state.zingMapList}
                                                        ></ZingMap>

                                                    </Col>
                                                  
                                                </Row>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col className="input-group p-0 m-0">
                                                    <Input
                                                        type="textarea"
                                                        placeholder="description..."
                                                        value={this.state.description}
                                                        onChange={this.onDescriptionChange}
                                                        id="desc"
                                                    />
                                                </Col>
                                            </FormGroup>
                                            {/* <Col className="saveBtnContainer">
                        <Button onClick={this.showEdit} className="saveUrlBtn">Save</Button>
                        <Button onClick={this.showEdit} className="saveUrlBtn">
                          Save
                      </Button>
                      </Col> */}
                      </div>
                    </Animated>
                  )}
                </Col>
              ) : null}
            </Row>
            <br />
            <Row className="justify-content-between Boxes-stl ">
              {this.state.imagesFromServer[0] !== undefined &&
              !this.state.showFilesToUpload1 ? (
                <Col className="d-flex justify-content-center imageBoxMyProfile ">
                  <img
                    className="d-flex justify-content-center imageBoxMyProfile"
                    src={this.state.imagesFromServer[0].image} //this.state.imagesToUpload[0].image
                    onError={(e) => {
                      e.target.onerror = null;
                    }}
                  />
                </Col>
              ) : this.state.images1 !== null ? (
                <Col className="d-flex justify-content-center imageBoxMyProfile">
                  <img
                    className="d-flex justify-content-center imageBoxMyProfile"
                    src={this.state.images1}
                  />
                </Col>
              ) : (
                <Col className="d-flex justify-content-center imageBoxMyProfile"></Col>
              )}

                            {this.state.imagesFromServer[1] !== undefined &&
                            !this.state.showFilesToUpload2 ? (
                                <Col className="d-flex justify-content-center imageBoxMyProfile">
                                    <img
                                        className="d-flex justify-content-center imageBoxMyProfile"
                                        src={this.state.imagesFromServer[1].image} //this.state.imagesToUpload[0].image
                                        onError={e => {
                                            e.target.onerror = null;
                                        }}
                                    />
                                </Col>
                            ) : this.state.images2 !== null ? (
                                <Col className="d-flex justify-content-center imageBoxMyProfile">
                                    <img
                                        className="d-flex justify-content-center imageBoxMyProfile"
                                        src={this.state.images2}
                                    />
                                </Col>
                            ) : (
                                <Col className="d-flex justify-content-center imageBoxMyProfile"></Col>
                            )}

                            {this.state.imagesFromServer[2] !== undefined &&
                            !this.state.showFilesToUpload3 ? (
                                <Col className="d-flex justify-content-center imageBoxMyProfile">
                                    <img
                                        className="d-flex justify-content-center imageBoxMyProfile"
                                        src={this.state.imagesFromServer[2].image} //this.state.imagesToUpload[0].image
                                        onError={e => {
                                            e.target.onerror = null;
                                        }}
                                    />
                                </Col>
                            ) : this.state.images3 !== null ? (
                                <Col className="d-flex justify-content-center imageBoxMyProfile">
                                    <img
                                        className="d-flex justify-content-center imageBoxMyProfile"
                                        src={this.state.images3}
                                    />
                                </Col>
                            ) : (
                                <Col className="d-flex justify-content-center imageBoxMyProfile"></Col>
                            )}
                        </Row>
                        {this.props.showModificationsOptions ? (
                            <div>
                                <Row>
                                    <Col xs="12" md={4} sm={4} lg={4} className="">
                                        <ReactFileReader
                                            fileTypes="image/*"
                                            base64
                                            handleFiles={this.handleMultipleFiles}
                                        >
                                            <Button className="btn-stl buttonUpload">Upload</Button>
                                        </ReactFileReader>
                                    </Col>
                                    <Col xs="12" md={4} sm={4} lg={4} className="">
                                        <ReactFileReader
                                            fileTypes="image/*"
                                            base64
                                            handleFiles={this.handleMultipleFiles2}
                                        >
                                            <Button className="btn-stl buttonUpload">Upload</Button>
                                        </ReactFileReader>
                                    </Col>
                                    <Col xs="12" md={4} sm={4} lg={4} className="">
                                        <ReactFileReader
                                            fileTypes="image/*"
                                            base64
                                            handleFiles={this.handleMultipleFiles3}
                                        >
                                            <Button className="btn-stl buttonUpload">Upload</Button>
                                        </ReactFileReader>
                                    </Col>
                                </Row>

                                <Row className="display-row-parent">
                                    <Button onClick={() => this.openCategory(true)} className="chooseCategBtn">Choose
                                        categories</Button>
                                    <Modal
                                                // toggle={() => this.openCategory(false)}
                                    
                                        className="costume-modal-prof"
                                        isOpen={this.state.setCategory}
                                    >
                                        <div className="header-p">
                                            <ModalHeader className="head-1 text-white">
                                                Add category
                                            </ModalHeader>
                                            <div className="logo_image">
                                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                                            </div>
                                            <ModalHeader
                                                className="head-2"
                                                toggle={() => this.openCategory(false)}
                                            />
                                        </div>
                                        <AddCategory/>
                                        <ModalFooter className=" categ-save-btn "></ModalFooter>
                                    </Modal>
                                </Row>

                                <Row className="">
                                    {/* {this.state.categoryList.map(item => (
                  <Col lg="3" className="" key={item}>
                    {item}
                  </Col>
                ))} */}
                                    {this.state.selectedCities.map(items => (
                                        <div className=" parent-selected-items">
                                            <Input
                                                className="categ-input"
                                                key={items.id}
                                                value={items.name}
                                                delete={this.delete}
                                            ></Input>{" "}
                                            <a
                                                className="delete-categ"
                                                onClick={this.delete.bind(this, items)}
                                            >
                                                {" "}
                                                X
                                            </a>
                                        </div>
                                    ))}
                                </Row>

                            </div>
                        ) : null}

            <Row className="myProfFriends">
              <Friends
                friendsList={this.state.friendsList}
                handlerFriends={this.handlerFriends}
                refreshAllFriends={this.listoffriends}
                getAllFriendsEventHandler={this.getAllFriendsEventHandler}
                searchEventHandler={this.searchFriends}
              />
            </Row>

                        <Col className="d-flex mt-1 mb-4">
                            <Col className="w-50 justify-content-center text-left">
                                <Button onClick={this.props.showAll} className="save-close-btn showAllProf">
                                    Show all
                                </Button>
                            </Col>
                            <Col className="w-50 justify-content-center text-right">
                                <Button onClick={this.showAddCard} className="save-close-btn showAllProf margin-r-l">

                                    Add card
                                </Button>

                                <Button onClick={this.showSelectCard} className="save-close-btn showAllProf">
                                    Select card
                                </Button>
                            </Col>
                        </Col>
                        {this.props.showModificationsOptions ? (
                            <div ref={this.node}>
                                <Row>
                                    {this.state.socialNetworkList.map(item => (
                                        <div
                                            key={item.id}
                                            onClick={() => this.changeCurentSN(item)}
                                        >
                                            <img src={item.logo} className="socialNetworkImg"/>
                                        </div>
                                    ))}
                                    <span
                                        className="socialNetworksLinks">Click icons to edit social networks links</span>
                                {this.renderToolTip()}

                                </Row>
                                {this.state.showSocialNetworkInput ? (
                                    <Row className="p-0 m-0">
                                        <Col xs="8" lg="6">
                                            <Input
                                                value={this.state.socialNetworkInput}
                                                onChange={this.onChangeSocialNetwork}
                                                placeholder="url..."
                                                className="socialInput"
                                            />
                                        </Col>
                                        <Col xs="4" lg={2} className="pl-0">
                                            <Button
                                                onClick={this.saveSocialNetwork}
                                                className="save-close-btn"
                                            >
                                                Save
                                            </Button>
                                        </Col>

                                    </Row>
                                ) : null}
                            </div>
                        ) : (
                            <div>
                                <Row>
                                    {this.state.socialNetworkList.map(item => (
                                        <div
                                            key={item.id}
                                            onClick={() => this.changeCurentSN(item)}
                                        >
                                            <img src={item.logo} className="socialNetworkImg"/>
                                        </div>
                                    ))}
                                </Row>
                                {this.state.showSocialNetworkInput ? (
                                    <Row className="p-0 m-0">
                                        <Col lg="8">
                                            <div className="socialNetworkText">
                                                {this.state.socialNetworkInput}
                                            </div>
                                        </Col>
                                    </Row>
                                ) : null}
                            </div>
                        )}
                        {this.props.showModificationsOptions && !this.state.showSocialNetworkInput ? (
                            <div>
                                <Col className="d-flex justify-content-center mt-3">
                                    <Button
                                        onClick={this.onSave}
                                        className="save-close-btn"
                                    >
                                        Save & Close
                                    </Button>
                                </Col>
                            </div>
                        ) : null}
                        <Modal
                            isOpen={this.state.modalProfileImg}
                            // toggle={this.toggleProfileModal}
                            centered={true}
                            size="lg"
                        >
                            <ModalBody className="d-flex flex-column">
                                <div>
                                    <input
                                        className="fileChoose"
                                        type="file"
                                        accept="image/*"
                                        onChange={this.onSelectFile}
                                    />
                                </div>
                                {this.state.src && (
                                    <ReactCrop
                                        src={this.state.src}
                                        crop={this.state.crop}
                                        ruleOfThirds
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                )}
                                <Button className="doneBtn" onClick={this.fromCroppedToProfile}>Done</Button>
                            </ModalBody>
                        </Modal>
                    </Col>
                </Animated>
            </Container>
        );
    }
}
