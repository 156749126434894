import React from 'react';
import {Button, Input, Label, Modal, ModalHeader, Col, Container, Row} from "reactstrap";
import "./Messages.scss"
import {fetchApi} from "../../../fetchApi";
import {userCredentials} from "../../../globalVariables";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShare} from "@fortawesome/free-solid-svg-icons";
import Divider from "@material-ui/core/Divider";
import swal from 'sweetalert';
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import UserProfile from "../../BothUsers/UserProfile/UserProfile";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

let SLogo = require("../../../Assets/img/SLogo.png");
export default class Messages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            messageFriends: [],
            messageEvents: [],
            messageOngoings: [],
            currentMessages: [],
            intervalId: null,
            eventPosition: 0,
            loadedOutside: false
        };
        this.refreshMessages = this.refreshMessages.bind(this);

    }

    componentDidMount() {

        this.getAllMessages(0);
        this.checkMessages(1);
        // this.interval = setInterval(() => {
        //     this.getAllMessages(this.state.selectedTab);
        // }, 2000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    refreshMessages(val) {
        this.getAllMessages(val);
    }

    async checkMessages(type) {
        const data = new FormData();
        const url = "checkmessages";
        // data.append("auth_key", userCredentials.authKey);
        data.append("user_id", userCredentials.userId);
        data.append("type", type);
        const response = await fetchApi(url, "POST", data, userCredentials.authKey);
        if (response.success) {
            if (this.props.refreshNumber) {
                this.props.refreshNumber();
            }
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.needToRefreshChat && this.props.needToRefreshChat !== prevProps.needToRefreshChat) {
            this.checkMessages(this.state.selectedTab)
            this.getAllMessages(this.state.selectedTab)
        }
    }

    getAllMessages = async (type) => {

        const url = "getnewmessages";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (this.props.refreshNumber) {
            this.props.refreshNumber();
        }
        if (response.success) {
            this.setState({
                messageFriends: this.state.messageFriends !== response.messages_from_friends ? response.messages_from_friends : this.state.messageFriends,
                messageEvents: this.state.messageEvents !== response.messages_from_myevents ? response.messages_from_myevents : this.state.messageEvents,
                messageOngoings: this.state.messageOngoings !== response.messages_from_ongoing ? response.messages_from_ongoing : this.state.messageOngoings,
            });

            if (type === 0 && this.state.currentMessages !== response.messages_from_friends) {
                this.setState({currentMessages: response.messages_from_friends})
            } else if (type === 1 && this.state.currentMessages !== response.messages_from_myevents) {
                this.setState({currentMessages: response.messages_from_myevents})
            } else if (type === 2 && this.state.currentMessages !== response.messages_from_ongoing) {
                this.setState({currentMessages: response.messages_from_ongoing})
            }
        }

        if (this.props.messageType && !this.state.loadedOutside) {

            this.changeMessage(this.props.messageType);
            this.setState({eventPosition: this.props.messageEventId, loadedOutside: true})

        }
    };


    changeMessage(tab) {

        if (tab === 0) {
            this.setState({selectedTab: 0, currentMessages: this.state.messageFriends});
            this.checkMessages(1);
        } else if (tab === 1) {
            this.setState({selectedTab: 1, currentMessages: this.state.messageEvents});
            this.checkMessages(2);
        } else if (tab === 2) {
            this.setState({selectedTab: 2, currentMessages: this.state.messageOngoings});
            this.checkMessages(3);
        }

    }

    render() {
        let inboxMessages = this.state.currentMessages;

        return (
            <div>
                <div className="messagesHeader">
                </div>
                <div className="messagesTypes">
                    <Button className="lBorderRadius messagesButtons friendsType"
                            onClick={() => this.changeMessage(0)}>Friends</Button>
                    <Button className="messagesButtons myEventsType" onClick={() => this.changeMessage(1)}>My
                        Events</Button>
                    <Button className="rBorderRadius messagesButtons otherEventsType"
                            onClick={() => this.changeMessage(2)}>Ongoing Events</Button>
                </div>
                <div>
                    {this.state.selectedTab === 0 ?
                        <InboxFriends messages={inboxMessages} refreshMsg={this.refreshMessages}/> : null}
                    {this.state.selectedTab === 1 ?
                        <InboxEvents messages={inboxMessages} refreshMsg={this.refreshMessages}
                                     eventPosition={this.state.eventPosition}/> : null}
                    {this.state.selectedTab === 2 ?
                        <InboxOngoings messages={inboxMessages} refreshMsg={this.refreshMessages}
                                       eventPosition={this.state.eventPosition}/> : null}
                </div>
            </div>
        );
    }
}

class InboxFriends extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openAll: false,
            messageText: "",
            position: 0,
            openUserProfile: false,
            selectedUser: null,
            canSendAgain: true,
            selectedMessageId: null,
        }
    }

    scrollToBottom = (newMessageId) => {
        this.setState({selectedMessageId: newMessageId})
        this.messageListFriends.scrollIntoView({behavior: "smooth"});
    }

    checkIfMounted() {
        return this.messageListFriends != null;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.checkIfMounted()) {
            let newMessageId = this.messageListFriends.id
            if (newMessageId !== prevState.selectedMessageId) {
                this.scrollToBottom(newMessageId);
            }
        }
    }

    async deleteMsg(eventId, messageId) {

        const url = "deletemessages";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("message_id", messageId);
        data.append("event_id", eventId);

        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.props.refreshMsg(0);
        } else {
            swal("Error!", "Could not delete! Please try again later...", "error");
        }

    }


    async sendMessage(friendID) {
        this.setState({canSendAgain: false})
        const url = "sendmessages";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        data.append("message_type", 1);
        data.append("friend_id", friendID);
        data.append("event_id", 0);
        data.append("message", this.state.messageText);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({messageText: "", position: 0});
            setTimeout(() => {
                this.setState({canSendAgain: true});
            }, 750);

            this.props.refreshMsg(0);

        } else {
            swal("Error!", "Message was not able to be sent! Please try again later...", "error");
        }
    }

    renderChatMessages(msg) {
        let messages = msg["friend"].messages;
        messages.sort(function (a, b) {
            var c = new Date(a.created_at.replace(/-/g, '/'));
            var d = new Date(b.created_at.replace(/-/g, '/'));
            return d - c;
        });
        return (
            <div className="parentChatAndSend">
                <div className="chatContainer">
                    {this.mapMessages(messages, msg["friend"].logo, msg["friend"].last_name,)}
                </div>

                <div className="messageReplyContainer">
                    <Row className="justify-content-between d-flex">
                        <div className="text-reply">
                            <TextareaAutosize
                                rowsMax={4}
                                aria-label="maximum height"
                                className="textarea-style"
                                type="textarea" placeholder="Start typing..."
                                value={this.state.messageText}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (this.state.messageText.length !== 0 && this.state.canSendAgain) {

                                            this.sendMessage(msg["friend"].friend_id)


                                        }
                                    }
                                }}
                                onChange={(val) => this.setState({messageText: val.target.value})}/>
                        </div>
                        <div className="childChatAndSend">
                            <Button
                                className={this.state.messageText.length > 0 ? "send_message_button emptyMessage" : "send_message_button"}
                                onClick={() => {
                                    if (this.state.messageText.length !== 0 && this.state.canSendAgain) {

                                        this.sendMessage(msg["friend"].friend_id)

                                    }
                                }}>
                                <FontAwesomeIcon icon={faShare}/>
                            </Button>

                        </div>

                    </Row>
                </div>
            </div>
        )
    }

    mapMessages(friendChat, logo, name) {
        let newMessages = friendChat.reverse();
        return (
            <div className="p-3 textCont">
                {newMessages.map((msg, index) =>
                    <div key={index}
                         className={userCredentials.userId.toString() !== msg.friend_id ? "usrmsg d-flex justify-content-end" : "usrmsg d-flex"}>
                        {userCredentials.userId.toString() !== msg.friend_id ? null :
                            <img src={logo} className="friendLogoImage"
                                 onClick={() => this.setState({selectedUser: msg.user_id, openUserProfile: true})}/>}
                        <div
                            className={userCredentials.userId.toString() !== msg.friend_id ? "messageText textRight" : "messageText textLeft"}>{userCredentials.userId.toString() !== msg.friend_id ? null : name + ": "}
                            {msg.message}
                        </div>
                        {/*{userCredentials.userId.toString() === msg.friend_id ? null :*/}
                        {/*    <img src={userCredentials.userLogo} className="friendLogoImage"/>}*/}

                        {userCredentials.userId.toString() === msg.friend_id ? null :
                            <div>
                                <Button
                                    className="delete_message_button"
                                    onClick={() => {
                                        swal("Warning!", "Are you sure you want to delete this message?", "warning", {
                                            icon: "warning",
                                            buttons: ["Cancel", "Yes"],
                                        }).then((value) => {
                                            if (value) {
                                                this.deleteMsg(0, msg.id)
                                            }
                                        });
                                    }}>
                                    <FontAwesomeIcon icon={faTrashAlt} color={"brown"}/>
                                </Button>

                            </div>}
                        <div style={{float: "left", clear: "both"}} id={msg.id}
                             ref={(el) => {
                                 this.messageListFriends = el;
                             }}>
                        </div>
                    </div>)}


            </div>
        )
    }

    closeUserProfile = () => {
        this.setState({openUserProfile: false})
    };


    render() {

        let friendMsg = this.props.messages;
        friendMsg && friendMsg.sort(function (a, b) {
            var c = new Date(a["friend"].last_message_date.replace(/-/g, '/'));
            var d = new Date(b["friend"].last_message_date.replace(/-/g, '/'));


            return d - c;
        });


        return (
            <div className="allChat">
                {this.state.openUserProfile ?
                    <OpenUserProfile userId={this.state.selectedUser} closeModal={this.closeUserProfile}/> : null}
                <div className="usersContainer">
                    {this.props.messages && Array.isArray(this.props.messages) && this.props.messages.length !== 0 ? this.props.messages.map((msg, index) => (
                        <div key={index}>

                            <div
                                className={this.state.position === index ? "active messageInbox friendsType" : "messageInbox friendsType"}
                                onClick={() => {
                                    this.setState({position: index});
                                    this.setState({openAll: true});
                                }}>
                                <div className="logo_and_expand">
                                    <div className="centerlogo">
                                        <img src={msg["friend"].logo} className="friendLogoImage"/>
                                    </div>
                                    <div className="topInbox">
                                        <Label>
                                            {msg["friend"].first_name}
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : <div className="noMsg">No messages from friends</div>}
                </div>
                <Divider orientation="vertical" flexItem/>
                {this.props.messages && this.props.messages[this.state.position] !== undefined ? this.renderChatMessages(friendMsg[this.state.position]) : null}

            </div>
        );
    }
}


class InboxEvents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAll: false,
            messageText: "",
            position: 0,
            selectedUser: null,
            openUserProfile: false,
            canSendAgain: true,
            selectedMessageId: null,
        }

    }

    scrollToBottom = (newMessageId) => {
        this.setState({selectedMessageId: newMessageId})
        this.messageListEvents.scrollIntoView({behavior: "smooth"});
    }

    checkIfMounted() {
        return this.messageListEvents != null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.checkIfMounted()) {
            let newMessageId = this.messageListEvents.id
            if (newMessageId !== prevState.selectedMessageId) {
                this.scrollToBottom(newMessageId);
            }
        }

        if (prevProps.eventPosition !== this.props.eventPosition) {
            let allMessages = this.props.messages;
            const positionIndex = allMessages.findIndex(index => {
                return index.event.info_event.id === this.props.eventPosition;
            });
            if (positionIndex !== -1) {
                this.setState({position: positionIndex})
            } else {
                this.setState({position: 0})
            }
        }
    }

    async deleteMsg(eventId, messageId) {

        const url = "deletemessages";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("message_id", messageId);
        data.append("event_id", eventId);

        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.props.refreshMsg(1);
        } else {
            swal("Error!", "Could not delete! Please try again later...", "error");
        }

    }

    async sendMessage(myEventID) {
        this.setState({canSendAgain: false})
        const url = "sendmessages";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        data.append("message_type", 2);
        data.append("friend_id", 0);
        data.append("event_id", myEventID);
        data.append("message", this.state.messageText);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({messageText: "", position: 0});
            setTimeout(() => {
                this.setState({canSendAgain: true});
            }, 750);
            this.props.refreshMsg(1);
        } else {
            swal("Error!", "Message was not able to be sent! Please try again later...", "error");
        }
    }

    renderChatMessages(msg) {
        let messages = msg["event"].messages;
        let messageId = msg["event"].info_event.id;
        messages.sort(function (a, b) {
            var c = new Date(a.created_at.replace(/-/g, '/'));
            var d = new Date(b.created_at.replace(/-/g, '/'));
            return d - c;
        });

        return (
            <div className="parentChatAndSend">
                <div className="chatContainer">
                    {this.mapMessages(messages, messageId)}


                </div>
                <div className="messageReplyContainer">
                    <Row className="justify-content-between d-flex">
                        <div className="text-reply">
                            <TextareaAutosize rowsMax={4}
                                              aria-label="maximum height" className="textarea-style"
                                              type="textarea" placeholder="Start typing..."
                                              value={this.state.messageText}
                                              onKeyDown={(e) => {
                                                  if (e.key === 'Enter') {
                                                      if (this.state.messageText.length !== 0 && this.state.canSendAgain) {

                                                          this.sendMessage(msg["event"].info_event.id)

                                                      }
                                                  }
                                              }}
                                              onChange={(val) => this.setState({messageText: val.target.value})}/>
                        </div>


                        <div className="parrent-buttons">
                            <div>
                                <Button
                                    className={this.state.messageText.length > 0 ? "send_message_button emptyMessage" : "send_message_button"}
                                    onClick={() => {
                                        if (this.state.messageText.length !== 0 && this.state.canSendAgain) {


                                            this.sendMessage(msg["event"].info_event.id)


                                        }
                                    }}>
                                    <FontAwesomeIcon icon={faShare}/>
                                </Button>
                            </div>

                            <div className="childChatAndSend">
                                <Button
                                    className={"delete_conv_button"}
                                    onClick={() => {
                                        swal("Warning!", "Are you sure you want to delete this conversation?", "warning", {
                                            icon: "warning",
                                            buttons: ["Cancel", "Yes"],
                                        }).then((value) => {
                                            if (value) {
                                                this.deleteMsg(msg["event"].info_event.id, 0)
                                            }
                                        });
                                    }}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
        )
    }

    mapMessages(friendChat, selectedIndexMsg) {
        let newMessages = friendChat.reverse();
        return (
            <div className="p-3 textCont">
                {newMessages.map((msg, index) =>
                    <div key={index}
                         className={userCredentials.userId.toString() === msg.user_id ? "usrmsg d-flex justify-content-end" : "usrmsg d-flex"}>

                        {userCredentials.userId.toString() === msg.user_id ? null :
                            <div>
                                {/*<Button*/}
                                {/*    className="delete_message_button"*/}
                                {/*    onClick={() => {*/}
                                {/*        swal("Warning!", "Are you sure you want to delete this message?", "warning", {*/}
                                {/*            icon: "warning",*/}
                                {/*            buttons: ["Cancel", "Yes"],*/}
                                {/*        }).then((value) => {*/}
                                {/*            if (value) {*/}
                                {/*                this.deleteMsg(0, msg.id)*/}
                                {/*            }*/}
                                {/*        });*/}
                                {/*    }}>*/}
                                {/*    <FontAwesomeIcon icon={faTrashAlt} color={"brown"}/>*/}
                                {/*</Button>*/}
                                <img src={msg.logo} className="friendLogoImage"
                                     onClick={() => this.setState({selectedUser: msg.user_id, openUserProfile: true})}/>
                            </div>}
                        <div
                            className={userCredentials.userId.toString() === msg.user_id ? "messageText textRight" : "messageText textLeft"}>
                            {userCredentials.userId.toString() !== msg.user_id ? msg.last_name + ": " : null} {msg.message}
                        </div>
                        {userCredentials.userId.toString() !== msg.user_id ? null :
                            <div>
                                {/*<img src={userCredentials.userLogo} className="friendLogoImage"/>*/}
                                <Button
                                    className="delete_message_button"
                                    onClick={() => {
                                        swal("Warning!", "Are you sure you want to delete this message?", "warning", {
                                            icon: "warning",
                                            buttons: ["Cancel", "Yes"],
                                        }).then((value) => {
                                            if (value) {
                                                this.deleteMsg(0, msg.id)
                                            }
                                        });
                                    }}>
                                    <FontAwesomeIcon icon={faTrashAlt} color={"brown"}/>
                                </Button>
                            </div>}
                        <div style={{float: "left", clear: "both"}} id={selectedIndexMsg}
                             ref={(el) => {
                                 this.messageListEvents = el;
                             }}>
                        </div>
                    </div>)}
            </div>
        )
    }

    closeUserProfile = () => {
        this.setState({openUserProfile: false})
    };

    render() {

        let eventMsg = this.props.messages;
        eventMsg && eventMsg.sort(function (a, b) {
            var c = new Date(a["event"].last_message_date.replace(/-/g, '/'));
            var d = new Date(b["event"].last_message_date.replace(/-/g, '/'));

            return d - c;
        });
        return (
            <div className="allChat">
                {this.state.openUserProfile ?
                    <OpenUserProfile userId={this.state.selectedUser} closeModal={this.closeUserProfile}/> : null}
                <div className="usersContainer">
                    {this.props.messages && Array.isArray(this.props.messages) && this.props.messages.length !== 0 ? this.props.messages.map((msg, index) => (
                        <div key={index}>

                            <div
                                className={this.state.position === index ? "active messageInbox myEventsType" : "messageInbox myEventsType"}
                                onClick={() => {
                                    this.setState({position: index});
                                    this.setState({openAll: true});
                                }}>
                                <div className="logo_and_expand">
                                    <div className="centerlogo">
                                        <img
                                            src={msg["event"].info_event.logo_category ? msg["event"].info_event.logo_category : require("../../../Assets/img/profilePic.jpg")}
                                            className="friendLogoImage"/>
                                    </div>
                                </div>
                                <div className="topInbox">
                                    <Label>
                                        {msg["event"].info_event.name}
                                    </Label>
                                </div>
                            </div>
                        </div>
                    )) : <div className="noMsg">No messages from your events</div>}
                </div>
                <Divider orientation="vertical" flexItem/>
                {this.props.messages && this.props.messages[this.state.position] !== undefined ? this.renderChatMessages(eventMsg[this.state.position]) : null}
            </div>
        );
    }
}

class InboxOngoings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openAll: false,
            messageText: "",
            position: 0,
            openUserProfile: false,
            selectedUser: null,
            canSendAgain: true,
            selectedMessageId: null,
        }
    }

    scrollToBottom = (newMessageId) => {
        this.setState({selectedMessageId: newMessageId})
        this.messageListOngoings.scrollIntoView({behavior: "smooth"});
    }

    checkIfMounted() {
        return this.messageListOngoings != null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.checkIfMounted()) {
            let newMessageId = this.messageListOngoings.id
            if (newMessageId !== prevState.selectedMessageId) {
                this.scrollToBottom(newMessageId);
            }
        }
        if (prevProps.eventPosition !== this.props.eventPosition) {
            let allMessages = this.props.messages;
            const positionIndex = allMessages.findIndex(index => {
                return index.event.info_event.id === this.props.eventPosition;
            });
            if (positionIndex !== -1) {
                this.setState({position: positionIndex})
            } else {
                this.setState({position: 0})
            }
        }
    }

    async deleteMsg(eventId, messageId) {

        const url = "deletemessages";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("message_id", messageId);
        data.append("event_id", eventId);

        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.props.refreshMsg(2);
        } else {
            swal("Error!", "Could not delete! Please try again later...", "error");
        }

    }


    async sendMessage(ongoingEventId) {
        this.setState({canSendAgain: false})
        const url = "sendmessages";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        data.append("message_type", 3);
        data.append("friend_id", 0);
        data.append("event_id", ongoingEventId);
        data.append("message", this.state.messageText);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({messageText: "", position: 0});
            setTimeout(() => {
                this.setState({canSendAgain: true});
            }, 750);

            this.props.refreshMsg(2);
        } else {
            swal("Error!", "Message was not able to be sent! Please try again later...", "error");
        }
    }

    renderChatMessages(msg) {
        let messages = msg["event"].messages;

        let messageId = msg["event"].info_event.id;
        messages.sort(function (a, b) {
            var c = new Date(a.created_at.replace(/-/g, '/'));
            var d = new Date(b.created_at.replace(/-/g, '/'));
            return d - c;
        });

        return (
            <div className="parentChatAndSend">
                <div className="chatContainer">
                    {this.mapMessages(messages, messageId)}

                </div>

                <div className="messageReplyContainer">
                    <Row className="justify-content-between d-flex">
                        <div className="text-reply">
                            <TextareaAutosize rowsMax={4}
                                              aria-label="maximum height"
                                              className="textarea-style"
                                              placeholder="Start typing..."
                                              value={this.state.messageText}
                                              onKeyDown={(e) => {
                                                  if (e.key === 'Enter') {
                                                      if (this.state.messageText.length !== 0 && this.state.canSendAgain) {
                                                          this.sendMessage(msg["event"].info_event.id)
                                                      }
                                                  }
                                              }}
                                              onChange={(val) => this.setState({messageText: val.target.value})}/>
                        </div>
                        <div>
                            <Button
                                className={this.state.messageText.length > 0 ? "send_message_button emptyMessage" : "send_message_button"}
                                onClick={() => {
                                    if (this.state.messageText.length !== 0 && this.state.canSendAgain) {

                                        this.sendMessage(msg["event"].info_event.id)

                                    }
                                }}>
                                <FontAwesomeIcon icon={faShare}/>
                            </Button>
                        </div>
                    </Row>
                </div>
            </div>
        )
    }

    mapMessages(friendChat, selectedIndexMsg) {
        let newMessages = friendChat.reverse();
        return (
            <div className="p-3 textCont">
                {newMessages.map((msg, index) =>
                    <div key={index}
                         className={userCredentials.userId.toString() === msg.user_id ? "usrmsg d-flex justify-content-end" : "usrmsg d-flex"}>
                        {userCredentials.userId.toString() === msg.user_id ? null :
                            <img src={msg.logo} className="friendLogoImage"
                                 onClick={() => this.setState({selectedUser: msg.user_id, openUserProfile: true})}/>}
                        <div
                            className={userCredentials.userId.toString() === msg.user_id ? "messageText textRight" : "messageText textLeft"}>
                            {userCredentials.userId.toString() !== msg.user_id ? msg.last_name + ": " : null} {msg.message}
                        </div>

                        {/*{userCredentials.userId.toString() !== msg.user_id ? null :*/}
                        {/*    <img src={userCredentials.userLogo} className="friendLogoImage"/>}*/}
                        {userCredentials.userId.toString() !== msg.user_id ? null :
                            <div>
                                <Button
                                    className="delete_message_button"
                                    onClick={() => {
                                        swal("Warning!", "Are you sure you want to delete this message?", "warning", {
                                            icon: "warning",
                                            buttons: ["Cancel", "Yes"],
                                        }).then((value) => {
                                            if (value) {
                                                this.deleteMsg(0, msg.id)
                                            }
                                        });
                                    }}>
                                    <FontAwesomeIcon icon={faTrashAlt} color={"brown"}/>
                                </Button>
                            </div>}
                    </div>)}
                <div style={{float: "left", clear: "both"}} id={selectedIndexMsg}
                     ref={(el) => {
                         this.messageListOngoings = el;
                     }}>
                </div>
            </div>
        )
    }

    closeUserProfile = () => {
        this.setState({openUserProfile: false})
    };

    render() {

        let ongoingMsg = this.props.messages;
        ongoingMsg && ongoingMsg.sort(function (a, b) {
            var c = new Date(a["event"].last_message_date.replace(/-/g, '/'));
            var d = new Date(b["event"].last_message_date.replace(/-/g, '/'));

            return d - c;
        });

        return (
            <div className="allChat">

                {this.state.openUserProfile ?
                    <OpenUserProfile userId={this.state.selectedUser} closeModal={this.closeUserProfile}/> : null}
                <div className="usersContainer">
                    {this.props.messages && Array.isArray(this.props.messages) && this.props.messages.length !== 0 ? this.props.messages.map((msg, index) => (
                        <div key={index}>
                            <div
                                className={this.state.position === index ? "active messageInbox otherEventsType" : "messageInbox otherEventsType"}
                                onClick={() => {
                                    this.setState({position: index});
                                    this.setState({openAll: true});
                                }}>
                                <div className="logo_and_expand">
                                    <div className="centerlogo">
                                        <img
                                            src={msg["event"].info_event.logo_category ? msg["event"].info_event.logo_category : require("../../../Assets/img/profilePic.jpg")}
                                            className="friendLogoImage"/>
                                    </div>
                                </div>
                                <div className="topInbox">
                                    <Label>
                                        {msg["event"].info_event.name}
                                    </Label>
                                </div>
                            </div>

                        </div>
                    )) : <div className="noMsg">No messages from your ongoing events</div>}
                </div>
                <Divider orientation="vertical" flexItem/>
                {this.props.messages && this.props.messages[this.state.position] !== undefined ? this.renderChatMessages(ongoingMsg[this.state.position]) : null}
            </div>
        );
    }
}

class OpenUserProfile extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <Modal
                className="costume-modal-prof"
                isOpen={true}
                // toggle={() => this.props.closeModal()}
            >
                <div className="header-p">
                    <ModalHeader className="head-1"/>
                    <div className="logo_image">
                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                    </div>
                    <ModalHeader
                        className="head-2"
                        toggle={() => this.props.closeModal()}
                    />
                </div>

                <UserProfile
                    userId={this.props.userId}
                    closeHandler={() => this.props.closeModal()}

                />
            </Modal>

        );
    }
}
