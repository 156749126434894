import React from "react";
import {
    Button,
    ButtonGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroupButtonDropdown,
    Row,
    Col,
    Container,
    Label,
    FormGroup,
    FormFeedback,
    Tooltip,
} from "reactstrap";
import {
    faPoundSign,
    faDollarSign,
    faMapMarkerAlt,
    faEuroSign,
    faCalendarAlt,
    faMale,
    faFemale,
    faRestroom,
    faUsers,
    faUserTag,
    faSignature,
    faWheelchair,
    faMoneyBill,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./MakeEventsBusiness.scss";
import "../../../index.css";
import ModernDatepicker from "react-modern-datepicker";
import TimePicker from "react-times";
import "react-times/css/material/default.css";
import moment from "moment";
import {fetchApi} from "../../../fetchApi.js";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import {userCredentials} from "../../../globalVariables";
import {red} from "@material-ui/core/colors";
import Checkbox from "react-simple-checkbox";
import ZingMap from "../../ZingMap/ZingMap";
import swal from "sweetalert";
import ReactFileReader from "react-file-reader";
import DatePicker from "react-modern-calendar-datepicker";

let profilePic = require("../../../Assets/img/profilePic.jpg");

export default class MakeEventsBusiness extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: "",
            dropdownOpenCategory: false,
            startDate: null,
            endDate: null,
            prices: [{id: 0}],
            companyName: "",
            Location: "suceava",
            date: "13-02-2020",
            startTime: "00:00:00",
            endTime: "00:00:00",
            description: "",
            latitude: "0",
            longitude: "0",
            nOffers: "2",
            nParticipants: [],
            numberOf: [],
            price: [],
            text: [""],
            currentIndex2: 0,
            hasChanged: false,
            hasChanged2: false,
            allTogether: [],
            gender: "0",
            categoryName: "",
            categoryId: "",
            categories: [],
            imagePreviewUrl: "",
            eventName: "",
            alliasId: "",
            isHandicap: false,
            country: "",
            zingMapList: [],
            location: "",
            isValidEventName: false,
            isValidDate: false,
            imagesFromServer: [],
            startTrue: false,
            endTrue: false,
            isHandicapBus: "0",
            isHandicapTrue: false,
            nameOfSubcategory: '',
            priceType: [
                {name: "RON"},
                {name: "USD"},
                {name: "EUR"},
                {name: "GBP"},
            ],
            selectedPriceType: "RON",
            moneySign: faEuroSign,
            tooltipOpen: false,
            tooltipOpenStripe: false,
            pricesForUpdate: [],
            numberOfU: [],
            priceU: [],
            textU: [""],
            isStripeConnected: false,
            priceInput:''

            
        };
    }

    getForbidenWords = async () => {
        let data = new FormData();
        let url = "forbidenwords";
        const response = await fetchApi(url, "GET", data);
        // console.log(response);
        if (response.success) {
            this.setState({forbidenWords: response.forbiden_words});
        }
    };

    componentDidMount = () => {
        this.getForbidenWords();
        this.getCategories();
        this.getProfile();
        this.getMyLocations();

        if (this.props.updateEvent) {
            this.getEventImages();
            let priceList = [];
            let nOfList = [];
            let indicativePriceList = [];
            let prices = [];
            let k = 0;
            this.props.currentMultiprice.forEach((element) => {
                let price = element.price;
                let nof = element.number_of_tickets;
                let indicativePrice = element.indicative_price;
                priceList.push(price);
                nOfList.push(nof);
                indicativePriceList.push(indicativePrice);
                prices.push({id: k++});
            });

            let startYear = moment(new Date(this.props.eventToUpdate.date)).format(
                "YYYY"
            );
            let startMonth = moment(new Date(this.props.eventToUpdate.date)).format(
                "MM"
            );
            let startDay = moment(new Date(this.props.eventToUpdate.date)).format(
                "DD"
            );

            let endYear = moment(new Date(this.props.eventToUpdate.end_date)).format(
                "YYYY"
            );
            let endMonth = moment(new Date(this.props.eventToUpdate.end_date)).format(
                "MM"
            );
            let endDay = moment(new Date(this.props.eventToUpdate.end_date)).format(
                "DD"
            );

            let startDate = {
                day: parseInt(startDay),
                month: parseInt(startMonth),
                year: parseInt(startYear),
            };
            let endDate = {
                day: parseInt(endDay),
                month: parseInt(endMonth),
                year: parseInt(endYear),
            };

            this.setState({
                location: this.props.eventToUpdate.address,
                description: this.props.eventToUpdate.description,
                categoryId: this.props.eventToUpdate.category_id,
                endDate: endDate,
                startDate: startDate,
                startTime: this.props.eventToUpdate.start_hour,
                endTime: this.props.eventToUpdate.end_hour,
                gender: this.props.eventToUpdate.gender,
                eventName: this.props.eventToUpdate.name,
                // isHandicapTrue: this.props.eventToUpdate.handicap_facilities === "1" ? true : false,
                isHandicapBus: this.props.eventToUpdate.handicap_facilities,
                selectedPriceType: this.props.eventToUpdate.currency,
                numberOfU: nOfList,
                priceU: priceList,
                textU: indicativePriceList,
                pricesForUpdate: prices,
                categoryName: this.state.categories.filter(
                    (element) => parseInt(element.id) === parseInt(this.props.category_id)
                ).en_name,
            });
            
        } else {
            //console.log("altfel");
        }
    };
    selectHandicapYes = () => {
        // if(this.state.isHandicap){
        this.setState({isHandicapTrue: !this.state.isHandicapTrue});
        this.selectHandicapNo();
    };
    selectHandicapNo = () => {
        if (this.state.isHandicapTrue === false) {
            this.setState({isHandicapBus: "1"});
        } else {
            this.setState({isHandicapBus: "0"});
        }
    };

    getEventImages = async () => {
        const authKey = userCredentials.authKey;
        const url = "geteventimages";
        const data = new FormData();
        data.append("event_id", this.props.eventToUpdate.id);
        const response = await fetchApi(url, "POST", data, authKey);
        //console.log(response, this.props.id);
        if (response.success) {
            this.setState({imagesFromServer: response.event_images});
        }
    };

    getMyLocations = async () => {

        const data = new FormData();
        const url = "getalllocations";
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("category", "");
        const response = await fetchApi(url, "POST", data);

        if (
            response.success &&
            response.locations &&
            response.locations.length > 0
        ) {
            this.setState({zingMapList: response.locations});
        }
    };

    checkForDuplicate = (arr, value) => {
        let res = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].description.toLowerCase() === value.toLowerCase()) {
                res++;
            }
        }
        if (res === 0) {
            return true;
        } else {
            return false;
        }
    };

    getProfile = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "getprofile";
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);

    if (response.success && response.userprofile != null) {
      this.setState({
        imagePreviewUrl: response.userprofile[0].logo,
        companyName: response.user_informations.company_name,
        country: response.user_informations.country,
        isStripeConnected: response.is_stripe_connected,
        // price: response.is_stripe_connected ? [] : 0,
      });

      if (!response.is_stripe_connected) {
        let list = this.state.price;
        list[0] = 0;
        this.setState({
          price: list,
          priceIsTooBig: false,
        });
      }
    }
  };

    getCategories = async () => {
        const url = "get_firstlevelcategoriesandalias";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);
        let list = [],
            alias = [],
            categs;
        if (response.success) {
            if (this.props.updateEvent) {
                let categoryName = response.list_of_categories.filter(
                    (element) =>
                        parseInt(element.category.id) ===
                        parseInt(this.props.eventToUpdate.category_id)
                );

                if (categoryName.length !== 0) {
                    this.setState({categoryName: categoryName[0].category.name});
                }
            }

            for (let i = 0; i < response.list_of_categories.length; i++) {
                categs = response.list_of_categories[i].category;
                list.push(categs);
                for (let j = 0; j < response.list_of_categories[i].alias.length; j++) {
                    alias = response.list_of_categories[i].alias[j];
                    if (alias.name_of_subcategory.length !== 0) {
                        list.push(alias);
                    }
                }
            }
            this.setState({categories: list});
        }
    };

    onChangeCategory = (evt, value) => {
        if (value !== null) {
            if (value.parent_category_id) {
                this.setState({
                    categoryId: value.parent_category_id,
                    alliasId: value.id,
                    category: evt.target.value,
                    categoryName:
                        value.name !== undefined ? value.name : value.name_of_subcategory,
                });
            } else {
                this.setState({
                    category: evt.target.value,
                    categoryName:
                        value.name !== undefined ? value.name : value.name_of_subcategory,
                    categoryId: value.id,
                    alliasId: false,
                });
            }
        } else {
            this.setState({categoryId: ""});
        }
    };

    verifyData = () => {
        if (this.state.categoryId === "") {
            return false;
        } else if (this.state.startTime === "") {
            return false;
        } else if (this.state.endTime === "") {
            return false;
        } else if (this.state.description === "") {
            return false;
        } else if (this.state.location === "") {
            return false;
        } else {
            return true;
        }
    };

  verifyPrices = (list) => {
    let count = 0;
    if (list.length > 0) {
      list.forEach((element) => {
        if (parseFloat(element) <= 999999.99) {
        } else {
          count = count + 1;
        }
      });
    } else {
      count = count + 1;
    }
    if (count === 0) {
      return true;
    } else {
      return false;
    }
  };

  verifyArrayItems = () => {
    if (this.state.isStripeConnected) {
      if (!this.state.price.length > 0 || this.state.price.includes("")) {
        return false;
      } else if (
        !this.state.numberOf.length > 0 ||
        this.state.numberOf.includes("")
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (!this.state.numberOf.length > 0 || this.state.numberOf.includes("")) {
        return false;
      } else {
        return true;
      }
    }
  };

    verifyDateAndTime = () => {
        let curentDate = moment();
        let d1, d2;
        let count = 0;

        if (this.state.startDate !== null && this.state.startTime !== null) {
            d1 = new Date(
                this.state.startDate.year +
                "/" +
                this.state.startDate.month +
                "/" +
                this.state.startDate.day +
                " " +
                this.state.startTime
            );
        } else {
            count++;
        }

        if (this.state.endDate !== null && this.state.endTime !== null) {
            d2 = new Date(
                this.state.endDate.year +

                "/" +
                this.state.endDate.month +
                "/" +
                this.state.endDate.day +
                " " +
                this.state.endTime
            );
        } else {
            count++;
        }

        let firstDate = moment(d1);
        let lastDate = moment(d2);

        return (
            curentDate.isBefore(firstDate) &&
            firstDate.isBefore(lastDate) &&
            count === 0
        );

        // let t1 = this.state.startTime.toString();
        // let t2 = this.state.endTime.toString();
        //
        // if (d1 >= cd && d2 >= cd && count === 0) {
        //     console.log("first if")
        //     if (d1 < d2) {
        //         return false;
        //     } else if (d1.getTime() === d2.getTime() && t1 < t2) {
        //         return false;
        //     } else if (d1.getTime() === d2.getTime() && t1 >= t2) {
        //         return true;
        //     } else {
        //         return true;
        //     }
        // } else {
        //     return true;
        // }
    };

    verifyForBadWords = (text) => {
        let badWords = this.state.forbidenWords.split(";");
        let count = 0;
        if (text && text.length !== 0) {
            badWords.forEach((element) => {
                if (text.includes(element)) {
                    count++;
                }
            });

            if (count === 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    makeEventHandler = () => {
        let priceLei = parseFloat(this.props.minPriceLei)
        let priceLire = parseFloat(this.props.minPriceLire)
        let priceEuro = parseFloat(this.props.minPriceEuro)
        let priceDolar = parseFloat(this.props.minPriceDolar)

        if( this.props.minPriceDolar !== undefined && 
            this.props.minPriceDolar !== null && 

            this.props.minPriceEuro !== undefined && 
            this.props.minPriceEuro !== null && 

            this.props.minPriceLire !== undefined && 
            this.props.minPriceLire !== null && 

            this.props.minPriceLei !== undefined && 
            this.props.minPriceLei !== null 
             ){
              
      if(this.state.selectedPriceType === "RON"){
  
          if(this.state.priceInput >= priceLei){
            this.doneHandler();
          }else{
              swal("Warning!", "Price per ticket cannot be les then " +  priceLei + " RON" , "warning");
          }
  
      }else if(this.state.selectedPriceType === "USD"){
  
          if(this.state.priceInput >= priceDolar){
            this.doneHandler();
          }else{
            swal("Warning!", "Price per ticket cannot be les then " +  priceDolar + " Dolar" , "warning");
              
          }
  
      }else if(this.state.selectedPriceType === "GBP"){
         if(this.state.priceInput >= priceLire){
            this.doneHandler();
          }else{
            swal("Warning!", "Price per ticket cannot be les then " +  priceLire + " Pounds" , "warning");

          }
      }else if(this.state.selectedPriceType === "EUR"){
         if(this.state.priceInput >= priceEuro){
            this.doneHandler();
          }else{
            swal("Warning!", "Price per ticket cannot be les then " +  priceEuro + " Euro" , "warning");

          }
      }
      
    }else{
        swal("Warning!", "Something went wrong, please contact administrator", "warning");
    }
        // this.doneHandler();
    };

    updateHandler = () => {
        this.updateEventHandler();
    };

    doneHandler = async () => {
        let authKey = userCredentials.authKey;
        let list = [];
        let persons = 0;
        for (let i = 0; i < this.state.prices.length; i++) {
            persons += parseInt(this.state.numberOf[i]);
            list.push({
                price: this.state.isStripeConnected ? this.state.price[i] : 0,
                nOf: this.state.numberOf[i],
                indicativePrice: this.state.text[i],
            });
        }

        this.setState({
            isValidDate: !this.verifyDateAndTime(),
            isValidMultipay: !this.verifyArrayItems(),
        });

        let newData = JSON.stringify(list);
        if (this.state.eventName === "") {
            this.setState({isValidEventName: true});
        } else {
            this.setState({isValidEventName: false});
        }
        if (this.state.categoryId === "") {
            this.setState({isValidCategoryId: true});
        } else {
            this.setState({isValidCategoryId: false});
        }
        if (this.state.location === "") {
            this.setState({isValidLocation: true});
        } else {
            this.setState({isValidLocation: false});
        }
        if (
            this.state.description === "" ||
            !this.verifyForBadWords(this.state.description)
        ) {
            this.setState({isValidDescription: true});
        } else {
            this.setState({isValidDescription: false});
        }

        let data = new FormData();
        if (
            this.verifyArrayItems() &&
            this.verifyData() &&
            this.verifyDateAndTime() &&
      this.verifyForBadWords(this.state.description) &&
      this.verifyPrices(this.state.price)
    ) {
      const url = "addbusinessevent";

            data.append("name", this.state.eventName);
            data.append("company_name", this.state.companyName);
            data.append("country", this.state.country);
            data.append("category_id", this.state.categoryId);
            data.append("address", this.state.location);
            data.append("latitude", this.state.latitude);
            data.append("longitude", this.state.longitude);

            if (this.state.alliasId) {
                data.append("alias_category_id", this.state.alliasId);
                data.append("name_of_subcategory", this.state.categoryName);
            }

            data.append("description", this.state.description);
            data.append("user_id", userCredentials.userId);
            data.append("currency", this.state.selectedPriceType);
            data.append("team_number", "0");
            data.append("max_persons", persons);
            data.append("persons_left", persons);
            data.append("gender", this.state.gender);
            data.append("handicap_facilities", this.state.isHandicapBus);
            data.append("age_category", "0");

            if (!this.props.isDeal) {
                data.append("multiprice", newData);
            }
            if (this.props.isDeal) {
            }

            data.append("start_hour", this.state.startTime);
            data.append("end_hour", this.state.endTime);
            data.append(
                "date",
                this.state.startDate.year +
                "/" +
                this.state.startDate.month +
                "/" +
                this.state.startDate.day +
                "T" +
                this.state.startTime
            );
            data.append(
                "end_date",
                this.state.endDate.year +
                "/" +
                this.state.endDate.month +
                "/" +
                this.state.endDate.day +
                "T" +
                this.state.endTime
            );

            const response = await fetchApi(url, "POST", data, authKey);

            if (response.success) {
                swal("Success!", "Event created with success", "success");
                this.saveImages(response.event_id);
                this.props.reloadEventsCallback();
                this.setState({
                    category: "",
                    prices: [{id: 0}],
                    description: "",
                    text: [],
                    numberOf: [],
                    price: [],
                });
            } else {
                swal("Error!", "Could not create event!", "error");
            }
        }
    };

    updateEventHandler = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let list = [];
        let persons = 0;

        for (let i = 0; i < this.state.pricesForUpdate.length; i++) {
            persons += parseInt(this.state.numberOfU[i]);

        }

        for (let i = 0; i < this.state.prices.length; i++) {
            if (this.state.numberOf[i]) {
                persons += parseInt(this.state.numberOf[i]);
            }
            list.push({
                price: this.state.price[i],
                nOf: this.state.numberOf[i],
                indicativePrice: this.state.text[i],
            });

        }
        let newData = JSON.stringify(list);


        if (this.state.eventName === "") {
            this.setState({isValidEventName: true});
        } else {
            this.setState({isValidEventName: false});
        }
        if (this.state.categoryId === "") {
            this.setState({isValidCategoryId: true});
        } else {
            this.setState({isValidCategoryId: false});
        }
        if (this.state.location === "") {
            this.setState({isValidLocation: true});
        } else {
            this.setState({isValidLocation: false});
        }

        if (
            this.state.description === "" ||
            !this.verifyForBadWords(this.state.description)
        ) {
            this.setState({isValidDescription: true});
        } else {
            this.setState({isValidDescription: false});
        }

    if (
      (this.props.updatePeopleEvent || this.props.updateEvent) &&
      this.verifyData() &&
      this.verifyForBadWords(this.state.description)
    ) {
      data.append("name", this.state.eventName);
      data.append("country", this.state.country);
      data.append("category_id", this.state.categoryId);
      if (this.state.alliasId) {
        data.append("alias_category_id", this.state.alliasId);
        data.append("name_of_subcategory", this.state.categoryName);
      }
      data.append("address", this.state.location);
      data.append("latitude", this.state.latitude);
      data.append("longitude", this.state.longitude);
      data.append(
        "date",
        this.state.startDate.year +
          "/" +
          this.state.startDate.month +
          "/" +
          this.state.startDate.day +
          "T" +
          this.state.startTime
      );
      data.append(
        "end_date",
        this.state.endDate.year +
          "/" +
          this.state.endDate.month +
          "/" +
          this.state.endDate.day +
          "T" +
          this.state.endTime
      );

            data.append("start_hour", this.state.startTime);
            data.append("end_hour", this.state.endTime);
            data.append("description", this.state.description);
            data.append("user_id", userCredentials.userId);
            data.append("currency", this.state.selectedPriceType);
            data.append("team_number", this.state.team);
            data.append("max_persons", parseInt(persons));
            data.append(
                "persons_left",
                parseInt(persons) -
                (parseInt(this.props.eventToUpdate.max_persons) -
                    parseInt(this.props.eventToUpdate.persons_left))
            );
            data.append("gender", this.state.gender);
            data.append("handicap_facilities", this.state.isHandicapBus);
            data.append("age_category", this.state.ageCategoryId);
            data.append("event_id", this.props.eventToUpdate.id);
            data.append("multiprice", this.state.price.length > 0 ? newData : null);
            const url = "updateevent";
            const response = await fetchApi(url, "POST", data, authKey);
          
            if (response.success) {
                this.props.reloadEventsCallback();
                this.updateImages();

                swal("Success!", "The event has been updated", "success");
            } else {
                swal("Error!", "Could not update event!", "error");
            }
        } else {
        }
    };

    saveImages = async (eventId) => {
        const url = "seteventimages";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        data.append("event_id", eventId);
        data.append("image_1", this.state.imagesToUpload1);
        data.append("image_2", this.state.imagesToUpload2);
        data.append("image_3", this.state.imagesToUpload3);
        const response = await fetchApi(url, "POST", data, authKey);
        //console.log(response);
    };

    updateImages = async () => {
        const url = "seteventimages";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        data.append("event_id", this.props.eventToUpdate.id);
        if (
            this.state.imagesFromServer[0] !== undefined &&
            this.state.imagesToUpload1 !== null
        ) {
            data.append("user_id", userCredentials.userId);
            data.append("image_1", this.state.imagesToUpload1);
            data.append("img_1_update", 1);
            data.append("img_1_id", this.state.imagesFromServer[0].id);
            this.setState({images1: null, images2: null, images3: null});
        } else if (
            this.state.imagesFromServer[0] === undefined &&
            this.state.imagesToUpload1 !== null
        ) {
            data.append("user_id", userCredentials.userId);
            data.append("image_1", this.state.imagesToUpload1);
            data.append("img_1_update", 0);
        }
        if (
            this.state.imagesFromServer[1] !== undefined &&
            this.state.imagesToUpload2 !== null
        ) {
            data.append("user_id", userCredentials.userId);
            data.append("image_2", this.state.imagesToUpload2);
            data.append("img_2_update", 1);
            data.append("img_2_id", this.state.imagesFromServer[1].id);
            this.setState({images1: null, images2: null, images3: null});
        } else if (
            this.state.imagesFromServer[1] === undefined &&
            this.state.imagesToUpload2 !== null
        ) {
            data.append("user_id", this.props.userId);
            data.append("image_2", this.state.imagesToUpload2);
            data.append("img_2_update", 0);
        }
        if (
            this.state.imagesFromServer[2] !== undefined &&
            this.state.imagesToUpload3 !== null
        ) {
            data.append("user_id", userCredentials.userId);
            data.append("image_3", this.state.imagesToUpload3);
            data.append("img_3_update", 1);
            data.append("img_3_id", this.state.imagesFromServer[2].id);
            this.setState({images1: null, images2: null, images3: null});
        } else if (
            this.state.imagesFromServer[2] === undefined &&
            this.state.imagesToUpload3 !== null
        ) {
            data.append("user_id", userCredentials.userId);
            data.append("image_3", this.state.imagesToUpload3);
            data.append("img_3_update", 0);
        }

        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
        } else {
            this.setState({images: response.message});
        }
    };

    onIndexChange = (id) => {
        this.setState({currentIndex: id});
    };

  onPriceChange = (evt) => {
    let list = this.state.price;
    list[this.state.currentIndex] = evt.target.value;
    if (evt.target.value <= 999999.99) {
      this.setState({
        price: list,
        priceIsTooBig: false,
      });
    } else {
      this.setState({ priceIsTooBig: true });
    }

    this.setState({priceInput: list[this.state.currentIndex] = evt.target.value })
   
  };

    onTextChange = (evt) => {
        
        let list = this.state.text;
        list[this.state.currentIndex] = evt.target.value;
        this.setState({
            text: list,
        });
    };

    onNumberOfChange = (evt) => {
        if (evt.target.value === 0 || evt.target.value === "" || evt.target.value === "0") {
            this.setState({isValidMultipay: true})
        } else {
            let list = this.state.numberOf;
            list[this.state.currentIndex] = evt.target.value;
            this.setState({
                numberOf: list,
            });
        }
    };

    onNParticipantsChange = (evt) => {
        let list = this.state.nParticipants;
        list[this.state.currentIndex] = evt.target.value;
        this.setState({
            nParticipants: list,
        });
    };

    changeDescription = (evt) => {
        this.setState({description: evt.target.value});
    };

    addPrice = () => {
        if (this.state.isStripeConnected) {
            let newList = this.state.prices;
            newList.push({id: this.state.currentIndex2 + 1});
            this.setState({prices: newList});
            this.setState({currentIndex2: this.state.currentIndex2 + 1});
        } else {
            swal("Warning!", "You need to connect to Stripe in order to add multiprice!", "warning");
        }

    };

    onStartTimeChange(options) {
        const {hour, minute, meridiem} = options;
        this.setState({startTime: options.hour + ":" + options.minute});
    }

    onEndTimeChange(options) {
        const {hour, minute, meridiem} = options;
        this.setState({endTime: options.hour + ":" + options.minute});
    }

    onFocusChange(focusStatue) {
        // do something
    }

    handleChange(date) {
        this.setState({
            startDate: date,
        });
    }

    handleChangeEnd(date) {
        this.setState({
            endDate: date,
        });
    }

    toggleDropDownCategory = (e) => {
        this.setState({
            dropdownOpenCategory: !this.state.dropdownOpenCategory,
        });
    };
    onDropdownItemCategory_Click = (sender) => {
        var newState = {
            dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue"),
        };
        this.setState({
            category: newState.dropDownValue2,
        });
    };

    onCategoryChange = (evt) => {
        this.setState({
            category: evt.target.value,
        });
    };

    selectMale = () => {
        this.setState({gender: "1"});
    };

    selectFemale = () => {
        this.setState({gender: "2"});
    };
    seletUnisex = () => {
        this.setState({gender: "3"});
    };

    onChangeCompanyName = (evt) => {
        this.setState({companyName: evt.target.value});
    };

    onChangeLocation = (address, lat, lng) => {
        this.setState({location: address});
        this.setState({latitude: lat});
        this.setState({longitude: lng});
    };
    toggleDropDown = (e) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    };
    onDropdownItem_Click = (sender) => {
        var newState = {
            dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue"),
        };
        this.setState({
            inputValue: newState.dropDownValue2,
            dropdownOpen: !this.state.dropdownOpen,
        });
        this.state.categoryList.forEach((element) => {
            if (element.name === newState.dropDownValue2) {
                this.setState({categoryId: element.id});
            }
        });
    };

    onInputChange = (evt) => {
        this.setState({
            inputValue: evt.target.value,
        });
        this.state.categoryList.forEach((element) => {
            if (element.name === evt) {
                this.setState({categoryId: element.id});
            }
        });
    };

    onChangeEventName = (evt) => {
        this.setState({eventName: evt.target.value});
    };

    onChangeCurrency = (evt) => {
        this.setState({selectedPriceType: evt.target.value});
    };
    onChangeIndicativePrice = (evt) => {
        let list = this.state.numberOf;
        list[this.state.currentIndex] = evt.target.value;
        this.setState({text: list});
        // this.setState({ text: evt.target.value });
    };

    checkHandler = () => {
        this.setState({isHandicap: !this.state.isHandicap});
    };

    handleMultipleFiles = (files) => {
        if (files && files.fileList.length > 0) {
            let file = files.fileList[0];
            if (file) {
                let newFileName =
                    userCredentials.userId + "_" + moment().valueOf() + "_" + file.name;
                var formDataAux = new FormData();
                formDataAux.append("file", file, newFileName);
            }
        }
        this.setState({
            images1: files.base64,
            imagesToUpload1: formDataAux.get("file"),
            getImageFromServer: false,
        });
        if (files.base64 !== null) {
            this.setState({showFilesToUpload1: true});
        }
    };

    handleMultipleFiles2 = (files) => {
        if (files && files.fileList.length > 0) {
            let file = files.fileList[0];
            if (file) {
                let newFileName =
                    userCredentials.userId + "_" + moment().valueOf() + "_" + file.name;
                var formDataAux = new FormData();
                formDataAux.append("file", file, newFileName);
            }
        }
        this.setState({
            images2: files.base64,
            imagesToUpload2: formDataAux.get("file"),
            getImageFromServer2: false,
        });
        if (files.base64 !== null) {
            this.setState({showFilesToUpload2: true});
        }
    };

    handleMultipleFiles3 = (files) => {
        if (files && files.fileList.length > 0) {
            let file = files.fileList[0];
            if (file) {
                let newFileName =
                    userCredentials.userId + "_" + moment().valueOf() + "_" + file.name;
                var formDataAux = new FormData();
                formDataAux.append("file", file, newFileName);
            }
        }
        this.setState({
            images3: files.base64,
            imagesToUpload3: formDataAux.get("file"),
            getImageFromServer: false,
        });
        if (files.base64 !== null) {
            this.setState({showFilesToUpload3: true});
        }
    };
    toggleTooltip = () => {
        this.setState({tooltipOpen: !this.state.tooltipOpen});
    };
    toggleTooltipStripe = () => {
        this.setState({tooltipOpenStripe: !this.state.tooltipOpenStripe});
    };

    renderTooltipStripe() {
        return (
            <div>
                <div className="justify-content-center  align-bottom">
                    <FontAwesomeIcon
                    className="iconTooltipPrice"
                        icon={faExclamationTriangle}
                        id="toolTipStripe"
                        color={"#f5b91c"}
                    />
                </div>
                <Tooltip
                    placement="top"
                    isOpen={this.state.tooltipOpenStripe}
                    target="toolTipStripe"
                    toggle={this.toggleTooltipStripe}
                    hideArrow
                >
                    <div className="toolTipGeneral">
                        <h6>
                            Stripe required
                        </h6>
                        <p className="tooltipC">
                            In order to add price and multiprice to your tickets you need to connect to Stripe
                        </p>

                    </div>
                </Tooltip>
            </div>)
    }

    renderTooltip() {
        return (
            <div>
                <div className="justify-content-center  align-bottom">

                    <FontAwesomeIcon
                        className="iconTooltip"
                        icon={faExclamationTriangle}
                        id="toolTipMultiprice"
                        color={"#f5b91c"}
                    />
                </div>
                <Tooltip
                    placement="top"
                    isOpen={this.state.tooltipOpen}
                    target="toolTipMultiprice"
                    toggle={this.toggleTooltip}
                    hideArrow
                >
                    <div className="toolTipGeneral">
                        <h6>
                            Multiprice
                        </h6>
                        <p className="tooltipC">

                            THIS IS A MULTIPRICE. YOU CAN ADD AS MANY PRICES AS YOU WANT. WE RECOMMEND TO USE ENGLISH ON
                            "INDICATIVE PRICE" FIELD
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    }

    render() {
        return (
            <Container className="makeEventsbusiness">
                <Row>
                    <Col lg="2" className="m-3">
                        <img
                            className="eventProfilePic"
                            src={
                                this.state.imagePreviewUrl !== null &&
                                this.state.imagePreviewUrl !== "" &&
                                this.state.imagePreviewUrl !== undefined
                                    ? this.state.imagePreviewUrl
                                    : profilePic
                            }
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = profilePic;
                            }}
                        />
                    </Col>
                    <Col className="pt-3 eventUsrName">{this.state.companyName}</Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label for="eventName" className="">
                                {this.props.isDeal ? "Deal" : "Event"} name*
                            </Label>
                            <Col className="input-group p-0 m-0">
                                <Input
                                    type="text"
                                    placeholder={
                                        this.props.isDeal ? "Deal name..." : "Event name..."
                                    }
                                    value={this.state.eventName}
                                    onChange={this.onChangeEventName}
                                    id="eventName"
                                    invalid={this.state.isValidEventName}
                                />
                                <InputGroupAddon addonType="append" className="backgrounButton">
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={faSignature}/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <FormFeedback>This field is required</FormFeedback>
                            </Col>
                        </FormGroup>
                    </Col>

                    <Col lg="4" className="p-0 m-0 firstInput">
                        <FormGroup>
                            <Label for="combo-box-demo4" className="">
                                Category*
                            </Label>
                        </FormGroup>
                        <Col className="input-group  mt-n3 p-0">
                            <Autocomplete
                                id="combo-box-demo4"
                                options={this.state.categories}
                                getOptionLabel={(option) =>
                                    option.name_of_subcategory
                                        ? "\xa0\xa0\xa0\xa0" + option.name_of_subcategory
                                        : option.name
                                }
                                style={{}}
                                className="text-white categoryInputB"
                                onChange={(event, value) => this.onChangeCategory(event, value)}
                                renderInput={(params) => (
                                    <TextField
                                        className="categoryAC placeholder1"
                                        {...params}
                                        placeholder={
                                            this.props.updateEvent
                                                ? this.state.categoryName
                                                : "Category..."
                                        }
                                        value="asd"
                                        variant="outlined"
                                    />
                                )}
                            />
                            <Input
                                className="invisible"
                                valid={this.state.isValidCategoryId}
                                invalid={this.state.isValidCategoryId}
                            />
                            <FormFeedback>This field is required</FormFeedback>
                        </Col>
                    </Col>
                    <Col lg="4" className="">
                        <FormGroup>
                            <Label for="city" className="">
                                City*
                            </Label>

                            <Col className="p-0 m-0 makeeventlocation">
                                {this.props.updatePeopleEvent ? (
                                    <Input value={this.state.location} readOnly={true}/>
                                ) : (
                                    <ZingMap
                                        initialAddress={this.state.location}
                                        id="city"
                                        showSearchTextBox={true}
                                        showOpenMapButton={true}
                                        inputClassName="makeEvents"
                                        buttonClassName=""
                                        enableMarkerAdd={false}
                                        selectedLocationHandler={this.onChangeLocation}
                                        list={this.state.zingMapList}
                                    />
                                )}
                            </Col>
                            <Input
                                className="invisible"
                                valid={this.state.isValidLocation}
                                invalid={this.state.isValidLocation}
                            />
                            <FormFeedback>This field is required</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row xs="2">
                    <Col lg={6} className="p-0">
                        <FormGroup className=" px-3 py-0">
                            <Label for="genderSelect">Gender category</Label>
                            <Col className="p-0 m-0 genderButtonGroup">
                                <Row id="genderSelect" className="m-0">
                                    <div
                                        className={
                                            this.state.gender === "1"
                                                ? "genderIcon genIconPressed"
                                                : "genderIcon"
                                        }
                                        onClick={
                                            this.props.updatePeopleEvent ? null : this.selectMale
                                        }
                                    >
                                        <FontAwesomeIcon icon={faMale}/>
                                    </div>
                                    <div
                                        className={
                                            this.state.gender === "2"
                                                ? "genderIcon genIconPressed"
                                                : "genderIcon"
                                        }
                                        onClick={
                                            this.props.updatePeopleEvent ? null : this.selectFemale
                                        }
                                    >
                                        <FontAwesomeIcon icon={faFemale}/>
                                    </div>
                                    <div
                                        className={
                                            this.state.gender === "3"
                                                ? "genderIcon genIconPressed"
                                                : "genderIcon"
                                        }
                                        onClick={
                                            this.props.updatePeopleEvent ? null : this.seletUnisex
                                        }
                                    >
                                        <FontAwesomeIcon icon={faRestroom}/>
                                    </div>
                                </Row>
                            </Col>
                        </FormGroup>
                    </Col>

                    <Col xs="6">
                        <Col className="text-bus">Handicap Facilities</Col>
                        <Col>
                            <div
                                value={this.state.isHandicapBus}
                                className={
                                    this.state.isHandicapBus === "1"
                                        ? "handicapIcon genIconPressed "
                                        : "handicapIcon "
                                }
                                onClick={
                                    this.props.updatePeopleEvent ? null : this.selectHandicapYes
                                }
                            >
                                <FontAwesomeIcon icon={faWheelchair}/>
                            </div>
                        </Col>
                    </Col>
                </Row>

                <div>
                    {/*{!this.props.isDeal ?*/}
                    <Row>
                        <Col lg="6" className="mt-0 px-3 py-0">
                            <FormGroup className="px-0 py-0">
                                <Label for="calendar">Start date</Label>
                                <Col lg="12" className="m-0 p-0">
                                    <InputGroup className="">
                                        <div
                                            className={
                                                this.state.startTrue
                                                    ? "datePickermakeEventB index_big"
                                                    : "datePickermakeEventB index_small"
                                            }
                                            onMouseOver={() =>
                                                this.setState({startTrue: true, endTrue: false})
                                            }
                                        >
                                            {this.props.updatePeopleEvent ? (
                                                <Input
                                                    value={
                                                        this.state.startDate !== null
                                                            ? this.state.startDate.year +
                                                            "-" +
                                                            this.state.startDate.month +
                                                            "-" +
                                                            this.state.startDate.day
                                                            : ""
                                                    }
                                                    readOnly={true}
                                                />
                                            ) : (
                                                <DatePicker
                                                    id="calendar"
                                                    dateFormat={"YYYY-MM-DD"}
                                                    value={this.state.startDate}
                                                    onChange={(date) => this.handleChange(date)}
                                                    inputPlaceholder="Select a date"
                                                    shouldHighlightWeekends
                                                    colorPrimary="#007bff"
                                                />
                                            )}

                                        </div>
                                        <InputGroupAddon
                                            addonType="append"
                                            className="backgrounButton"
                                        >
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col lg="6" className="p-0 m-0">
                            <FormGroup>
                                <Label for="startTime">Start time</Label>
                                <TimePicker
                                    id="startTime"
                                    onFocusChange={this.onFocusChange.bind(this)}
                                    onTimeChange={this.onStartTimeChange.bind(this)}
                                    time={this.state.startTime}
                                    disabled={this.props.updatePeopleEvent}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    {/*: null}*/}
                    <Row>
                        <Col lg="6" className="mt-0  py-0 mb-0">
                            <FormGroup className="px-0 py-0">
                                <Label for="calendar2">End date</Label>
                                <Col lg="12" className="m-0 p-0">
                                    <InputGroup className="">
                                        <div
                                            className={
                                                this.state.endTrue
                                                    ? "datePickermakeEventB index_big"
                                                    : "datePickermakeEventB index_small"
                                            }
                                            onMouseOver={() =>
                                                this.setState({startTrue: false, endTrue: true})
                                            }
                                        >
                                            {this.props.updatePeopleEvent ? (
                                                <Input
                                                    value={
                                                        this.state.startDate !== null
                                                            ? this.state.endDate.year +
                                                            "-" +
                                                            this.state.endDate.month +
                                                            "-" +
                                                            this.state.endDate.day
                                                            : ""
                                                    }
                                                    readOnly={true}
                                                />
                                            ) : (
                                                <DatePicker
                                                    id="calendar2"
                                                    dateFormat={"YYYY-MM-DD"}
                                                    value={this.state.endDate}
                                                    onChange={(date) => this.handleChangeEnd(date)}
                                                    inputPlaceholder="Select a date"
                                                    shouldHighlightWeekends
                                                    colorPrimary="#007bff"
                                                    wrapperClassName="calendar"
                                                    inputClassName="text-white"
                                                    style={{
                                                        zIndex: "9999",
                                                    }}
                                                />
                                            )}

                                        </div>
                                        <InputGroupAddon
                                            addonType="append"
                                            className="backgrounButton"
                                        >
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col lg="6" className="p-0 m-0">
                            <FormGroup>
                                <Label for="endTime">End time</Label>
                                <TimePicker
                                    id="endTime"
                                    onFocusChange={this.onFocusChange.bind(this)}
                                    onTimeChange={this.onEndTimeChange.bind(this)}
                                    time={this.state.endTime}
                                    disabled={this.props.updatePeopleEvent}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                <Col className="ml-3 mt-0 pt-0">
                    <Input
                        className="invisible"
                        valid={this.state.isValidDate}
                        invalid={this.state.isValidDate}
                    />
                    <FormFeedback>
                        Start date and time must be smaller than end date and time
                    </FormFeedback>
                </Col>

                <Row className="justify-content-between Boxes-stl ">
                    {this.state.imagesFromServer[0] !== undefined &&
                    !this.state.showFilesToUpload1 ? (
                        <Col className="d-flex justify-content-center imageBoxMyProfile ">
                            <img
                                className="d-flex justify-content-center imageBoxMyProfile"
                                src={this.state.imagesFromServer[0].image} //this.state.imagesToUpload[0].image
                                onError={(e) => {
                                    e.target.onerror = null;
                                }}
                            />
                        </Col>
                    ) : this.state.images1 !== null ? (
                        <Col className="d-flex justify-content-center imageBoxMyProfile">
                            <img
                                className="d-flex justify-content-center imageBoxMyProfile"
                                src={this.state.images1}
                            />
                        </Col>
                    ) : (
                        <Col className="d-flex justify-content-center imageBoxMyProfile"></Col>
                    )}

                    {this.state.imagesFromServer[1] !== undefined &&
                    !this.state.showFilesToUpload2 ? (
                        <Col className="d-flex justify-content-center imageBoxMyProfile">
                            <img
                                className="d-flex justify-content-center imageBoxMyProfile"
                                src={this.state.imagesFromServer[1].image} //this.state.imagesToUpload[0].image
                                onError={(e) => {
                                    e.target.onerror = null;
                                }}
                            />
                        </Col>
                    ) : this.state.images2 !== null ? (
                        <Col className="d-flex justify-content-center imageBoxMyProfile">
                            <img
                                className="d-flex justify-content-center imageBoxMyProfile"
                                src={this.state.images2}
                            />
                        </Col>
                    ) : (
                        <Col className="d-flex justify-content-center imageBoxMyProfile"></Col>
                    )}

                    {this.state.imagesFromServer[2] !== undefined &&
                    !this.state.showFilesToUpload3 ? (
                        <Col className="d-flex justify-content-center imageBoxMyProfile">
                            <img
                                className="d-flex justify-content-center imageBoxMyProfile"
                                src={this.state.imagesFromServer[2].image} //this.state.imagesToUpload[0].image
                                onError={(e) => {
                                    e.target.onerror = null;
                                }}
                            />
                        </Col>
                    ) : this.state.images3 !== null ? (
                        <Col className="d-flex justify-content-center imageBoxMyProfile">
                            <img
                                className="d-flex justify-content-center imageBoxMyProfile"
                                src={this.state.images3}
                            />
                        </Col>
                    ) : (
                        <Col className="d-flex justify-content-center imageBoxMyProfile"></Col>
                    )}
                </Row>

                <Row className="p-0 mb-3">
                    <Col xs="12" md={4} sm={4} lg={4} className="">
                        <ReactFileReader
                            fileTypes="image/*"
                            base64
                            handleFiles={this.handleMultipleFiles}
                        >
                            <Button className="btn-stl buttonUpload">Upload</Button>
                        </ReactFileReader>
                    </Col>
                    <Col xs="12" md={4} sm={4} lg={4} className="">
                        <ReactFileReader
                            fileTypes="image/*"
                            base64
                            handleFiles={this.handleMultipleFiles2}
                        >
                            <Button className="btn-stl buttonUpload">Upload</Button>
                        </ReactFileReader>
                    </Col>
                    <Col xs="12" md={4} sm={4} lg={4} className="">
                        <ReactFileReader
                            fileTypes="image/*"
                            base64
                            handleFiles={this.handleMultipleFiles3}
                        >
                            <Button className="btn-stl buttonUpload">Upload</Button>
                        </ReactFileReader>
                    </Col>
                </Row>

                <Col className="px-3">
                    <FormGroup>
                        <Label className="px-3" for="description">
                            Description*
                        </Label>
                        <Col lg="12" className="">
                            <Input
                                id="description"
                                type="textarea"
                                placeholder={
                                    this.props.isDeal
                                        ? "Deal description..."
                                        : "Event description..."
                                }
                                value={this.state.description}
                                onChange={this.changeDescription}
                                valid={this.state.isValidDescription}
                                invalid={this.state.isValidDescription}
                            />
                            <FormFeedback>
                                This field is required and must not contain forbidden words
                            </FormFeedback>
                        </Col>
                    </FormGroup>
                </Col>
                {/* {!this.props.updateEvent ? ( */}
                <div>
                    <Row>
                        {this.state.prices.map((item) => (
                            <Col lg={12} key={item.id} className="p-0">
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label className="no-of-line" for="nOf">
                                                Number of people*
                                            </Label>
                                            <Col lg="12" className="p-0 m-0 input-group">
                                                <Input
                                                    id="nOf"
                                                    onChange={this.onNumberOfChange}
                                                    onFocus={() => this.onIndexChange(item.id)}
                                                    type="number"
                                                />
                                                <InputGroupAddon
                                                    addonType="append"
                                                    className="backgrounButton"
                                                >
                                                    <InputGroupText>
                                                        <FontAwesomeIcon icon={faUsers}/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="nOf">Indicative price*</Label>
                                            <Col lg="12" className="p-0 m-0 input-group w-100">
                                                <Input
                                                    value={this.state.indicativePrice}
                                                    className="inputDrop"
                                                    onChange={this.onTextChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>

                                    <Col className="pl-0">
                                        <FormGroup>
                                            <Row>
                                                <Label for="price">Price*</Label>
                                                <div className="MakeTooltip">
                                                    {!this.state.isStripeConnected ? this.renderTooltipStripe() : null}

                                                </div>
                                            </Row>

                      <Col lg="12" className="p-0 m-0 input-group">
                        <Input
                          id="price"
                          autoComplete="new-password"
                          disabled={!this.state.isStripeConnected}
                          placeholder={!this.state.isStripeConnected ? 0 : ""}
                          onChange={this.onPriceChange}
                          onFocus={() => this.onIndexChange(item.id)}
                          type="number"
                        />
                        <InputGroupAddon
                          addonType="append"
                          className="backgrounButton-price-sign"
                        >
                          <InputGroupText className="price-sign">
                            {/* {this.state.selectedPriceType === "RON" ?

                                                                <p className="p-ron" >RON</p> :
                                                                <FontAwesomeIcon icon={
                                                                    this.state.moneySign
                                                                }
                                                                 />
                                                            }  */}

                                                        <InputGroupAddon
                                                            addonType="append"
                                                            className="backgrounButton"
                                                        >
                                                            <InputGroupText>
                                                                <Input
                                                                    type="select"

                                                                    value={this.state.selectedPriceType}
                                                                    className="inputDrop"
                                                                    onChange={this.onChangeCurrency}
                                                                >
                                                                    {this.state.priceType &&
                                                                    this.state.priceType.map((option) => (
                                                                        <option
                                                                            key={option.name}
                                                                            value={option.name}
                                                                        >
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        {/* if (evt.target.value === "USD") {
            this.setState({ moneySign: faDollarSign })
        } else if (evt.target.value === "GBP") {
            this.setState({ moneySign: faPoundSign })
        } else if (evt.target.value === "EUR") {
            this.setState({ moneySign: faEuroSign })
        } */}
                          </InputGroupText>
                        </InputGroupAddon>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <Input className="invisible" invalid={this.state.isValidMultipay} />
          <FormFeedback className="ml-3 pl-3">
            All fields in multiprice are required
          </FormFeedback>
          {this.state.priceIsTooBig ? (
            <Col>
              <Input className="invisible" invalid={this.state.priceIsTooBig} />
              <FormFeedback>
                Maximum amount payable for one Deal or one Ticket is 999,999.99
              </FormFeedback>
            </Col>
          ) : null}

          {!this.props.isDeal && this.state.isStripeConnected ? (
            <Row className="m-3">
              <Button onClick={this.addPrice} className="greenBtnEvt">
                Add Price
              </Button>
              {this.renderTooltip()}
            </Row>
          ) : null}
        </div>
        {/* ) : null} */}

                <Row className="justify-content-end py-4 borderTop">
                    <Col lg="6" className="d-flex justify-content-end">
                        {this.props.updateEvent ? (
                            <Button onClick={this.updateHandler} className="greenBtnEvt">
                                Update
                            </Button>
                        ) : (
                            <Button onClick={this.makeEventHandler} className="greenBtnEvt">
                                Done
                            </Button>
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}
