import React, {Component, useState} from "react";
import {
    Form,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Container,
    Row,
    Col,
    Modal,
    UncontrolledAlert,
    ModalHeader,
    ModalBody,
    Label
} from "reactstrap";
import "animate.css/animate.min.css";
import "./Header.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalVideo from "react-modal-video";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import BannerDetailsNormal from "../../NormalUser/BannerDetails/BannerDetailsNormal";
import FlatList from "flatlist-react";
import {
    faMapMarkerAlt,
    faCalendarAlt,
    faBiking
} from "@fortawesome/free-solid-svg-icons";
import {fetchApi} from "../../../fetchApi";
import BusinessOffers from "./BusinessOffers.js";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";

import DatePicker from 'react-modern-calendar-datepicker';
import ZingMap from "../../ZingMap/ZingMap.js";
import FindPeople from "../../BothUsers/FindPeople/FindPeople.js";
import {fetchApiLocation} from "../../../fetchApiLocation.js";
import {timer_partners, sliderState} from "../../../globalVariables";

import {Slide} from '@material-ui/core';
import VisibilitySensor from "react-visibility-sensor";

let momentFormat = require("moment");
let BackImage = require("../../../Assets/img/image-01.jpg")
let SLogo = require("../../../Assets/img/SLogo.png");

let countryID = 0;

export default class Header extends Component {
    constructor(props) {

        let nowYear = moment(new Date()).format("YYYY");
        let nowMonth = moment(new Date()).format("MM");
        let nowDay = moment(new Date()).format("DD");

        let today = {day: parseInt(nowDay), month: parseInt(nowMonth), year: parseInt(nowYear)};


        super(props);
        this.state = {
            events: [],
            actions: [],
            inputValue: "",
            WhereValue: "",
            DateValue: "",
            dropDownValue2: "Select action",
            dropdownOpen: false,
            startDate: "",
            backgroundImages: "",
            titleText: "",
            titleTextLight: "",
            titleSecond: null,
            currentItem: 0,
            titleTextList: [{zing_hpTextSlider: ""}],
            titleTextListLight: [{zing_s_hpTextSlider: ""}],
            categories: [],
            categoryId: null,
            searchedEvents: [],
            hasSearched: false,
            showAlert: false,
            showEvents: false,
            latitude: "",
            longitude: "",
            currentEvent: null,
            toggleModalEvent: false,
            showBannerModal: false,
            hideParent: false,
            distance: 1000,
            businessEventList: [],
            offertsList: [],
            category: "",
            bannerAdvertisingList: [],
            currentAddress: "",
            currentLng: "",
            currentLat: "",
            zingMapList: [],
            freeText: "",
            supressBlur: false,
            eventIdOpen: "",
            eventLogo: "",
            showSearchBusiness: false,
            titleShow: false,
            sliderStopped: false,
            a51List: [],
            a52List: [],
            location: "",
            videoModal: false,
            selectedEventCategoryName:""
        };
    }

    onChangeCategory = (evt, value) => {

        if (value !== null) {
            this.setState({
                category: evt.target.value,
                categoryName: value.name,
                categoryId: value.id,
            });
            this.setState({supressBlur: true});
        } else {
            this.setState({
                category: "",
                categoryName: "",
                categoryId: 0,
            });
            this.setState({supressBlur: false});
        }
    };

    changeTextSearch = (evt) => {
        this.setState({
            freeText: evt.target.value
        });

    }

    getBusinessEventsAndOffers = async (latitude, longitude) => {
        const url = "filterbusinessevents";
        const data = new FormData();
        data.append("address", this.state.currentAddress);
        data.append("latitude", latitude);
        data.append("longitude", longitude);
      
        
        data.append("country_id",countryID );
        let searchDate = "";
        let currentDate = new Date(
            this.state.startDate.year +
            "-" +
            this.state.startDate.month +
            "-" +
            this.state.startDate.day
        );
        if (this.state.startDate && this.state.startDate != "") {
            searchDate = moment(currentDate, "DD-MM-YYYY").startOf("day").format();
        }
        data.append("date", "");
        data.append(
            "category",
            this.state.categoryId ? this.state.categoryId : "0"
        );
        data.append("user_id", 0);
        data.append("searchWord", this.state.freeText);
        const response = await fetchApi(url, "POST", data);
       
        if (response.success) {
            this.setState({
                //offertsList: response.offerts,
                businessEventList: response.events,
                a51List: response.a51,
                a52List: response.a52,
            });
        }
    };

    getSearchedBussinesAndOffers = async () => {
        const url = "filterbusinessevents";
        const data = new FormData();
        data.append("country_id",  countryID );
        data.append("address", this.state.currentAddress);
        data.append(
            "latitude",
            this.state.currentLat != 0 ? this.state.currentLat : ""
        );
        data.append(
            "longitude",
            this.state.currentLng != 0 ? this.state.currentLng : ""
        );

        let searchDate = "";
        let currentDate = new Date(
            this.state.startDate.year +
            "-" +
            this.state.startDate.month +
            "-" +
            this.state.startDate.day
        );

        if (this.state.startDate && this.state.startDate != "") {
            searchDate = moment(currentDate, "DD-MM-YYYY")
                .startOf("day")
                .format("YYYY-MM-DD");
        }

        data.append("date", searchDate);
        data.append(
            "category",
            this.state.categoryId ? this.state.categoryId : "0"
        );
        data.append("user_id", 0);
        data.append("searchWord", this.state.freeText);
        const response = await fetchApi(url, "POST", data);
        if (response.success) {
            this.setState({
                //offertsList: response.offerts,
                businessEventList: response.events,
                a51List: response.a51,
                a52List: response.a52,
            });
        }
    };

    position = async () => {
        await navigator.geolocation.getCurrentPosition(
            position =>

                this.setState({latitude: position.coords.latitude, longitude: position.coords.longitude },
                    
                    () => {
                        this.getEvents(position.coords.latitude, position.coords.longitude);
                        this.getBusinessEventsAndOffers(
                            position.coords.latitude,
                             position.coords.longitude
                        );
                    }
                ),
            error => {
                this.getEvents("", "");
                this.getBusinessEventsAndOffers("", "");
            }
        );
    };
    handleChange = date => {

        this.setState({
            startDate: date
        });

    };

    toggleDropDown = e => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    };
    onDropdownItem_Click = sender => {
        var newState = {
            dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue")
        };
        this.setState({
            inputValue: newState.dropDownValue2,
            dropdownOpen: !this.state.dropdownOpen
        });
        this.state.categories.forEach(element => {
            if (element.name === newState.dropDownValue2) {
                this.setState({categoryId: element.id});
            }
        });
    };

    onInputChange = evt => {
        this.setState({
            inputValue: evt.target.value
        });
        this.state.categories.forEach(element => {
            if (element.name === evt) {
                this.setState({categoryId: element.id});
            }
        });
    };


    getHpComponents = async () => {
        const url = "get_hpcomponents";
        const response = await fetchApi(url, "POST", null);

        if (response.success) {

            this.setState({
                backgroundImages: response.background_image[0].background,
                // titleText: response.zing_hpTextSlider[0].zing_hpTextSlider,
                //  titleTextLight: response.zing_s_hpTextSlider[0].zing_s_hpTextSlider,
                //  timeToRefresh: response.zing_hpTextSlider_timeinseconds * 1000,
                //  titleTextList: response.zing_hpTextSlider,
                //   titleTextListLight: response.zing_s_hpTextSlider
            });
        }

        // this.intervalID = setInterval(
        //     () => this.changeText(),
        //     response.zing_hpTextSlider_timeinseconds * 1000
        // );
    };


    getMainSponsor = async () => {

        const url = "listofsportevents";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            if (response.listOfSportEvents) {
                setTimeout(() => {
                    this.setState({
                        titleShow: true,
                        titleText: response.listOfSportEvents[0].description,
                        //   titleTextLight: response.zing_s_hpTextSlider[0].zing_s_hpTextSlider,
                        //timeToRefresh: response.zing_hpTextSlider_timeinseconds * 1000,
                        titleTextList: response.listOfSportEvents,
                        //   titleTextListLight: response.zing_s_hpTextSlider

                        // mainSponsor: response.listOfSportEvents,
                        // maxSponsorList: response.listOfSportEvents.length
                    });
                }, 500);

                // this.startSlider()
            }

        }
    };


    async getTimerForPartners() {
        const url = "getconfigvariable";
        let data = new FormData();
        data.append("configvariable", "zing_timeinsecondssportevents")
        const response = await fetchApi(url, "POST", data);
        if (response.success) {
            let timer = response.config_variable.configvariable;
            timer_partners.value = timer;
            this.setState({timeToRefresh: parseInt(timer) * 1000})
            //    this.startTimer();
            this.getMainSponsor();
        }
    }


    getCategories = async () => {
        const url = "get_categories";
        const response = await fetchApi(url, "POST", null);

        if (response.success) {
            this.setState({categories: response.list_of_categories});
        }
    };

    getSearchedEvents = async () => {
        const data = new FormData();
        const url = "filternormalevents";
        data.append("address", this.state.currentAddress);
        data.append(
            "latitude",
            this.state.currentLat != 0 ? this.state.currentLat : ""
        );
        data.append(
            "longitude",
            this.state.currentLng != 0 ? this.state.currentLng : ""
        );
        let searchDate = "";
        let currentDate = new Date(this.state.startDate.year + "-" + this.state.startDate.month + "-" + this.state.startDate.day);
        if (this.state.startDate && this.state.startDate !== "") {
            searchDate = moment(currentDate, "DD-MM-YYYY")
                .startOf("day")
                .format("YYYY-MM-DD");

        }

        data.append("date", searchDate);
        data.append("category", this.state.categoryId);
        // data.append("alias_category_id", this.state.useAliasId ?  this.state.categoryIdMyE : 0);
        data.append("alias_category_id", 0);


        data.append("user_id", 0);
        data.append("searchWord", this.state.freeText);

        const response = await fetchApi(url, "POST", data);
        if (response.success) {
            this.getAllLocations();
            this.setState({searchedEvents: response.events, hasSearched: true, startDate: ""});
            if (response.events === null) {

                this.setState({showAlert: true, showEvents: false});
            }
        }
    };

    getAllLocations = async () => {
        const data = new FormData();
        const url = "getalllocations";
        data.append("category", this.state.categoryId ? this.state.categoryId : "");

        data.append(
            "latitude",
            this.state.currentLat != 0 ? this.state.currentLat : ""
        );
        data.append(
            "longitude",
            this.state.currentLng != 0 ? this.state.currentLng : ""
        );

        const response = await fetchApi(url, "POST", data);
        if (response.success && response.locations && response.locations.length > 0) {
            this.setState({zingMapList: response.locations});
        } else {
            this.setState({zingMapList: []});
        }
    };

    getEvents = async (latitude, longitude) => {
        const data = new FormData();
        const url = "filternormalevents";
        data.append("address", this.state.currentAddress);
        data.append("latitude", latitude);
        data.append("longitude", longitude);
        data.append("date", "");
        data.append("category", 0);
        data.append("user_id", 0);
        data.append("searchWord", "");
        const response = await fetchApi(url, "POST", data);

        if (response.success) {
            this.setState({events: response.events});

        }
    };

    getElementText = (response, elementName) => {
        return response.getElementsByTagName(elementName)[0].innerHTML;
    };

    getContactInfo = async () => {
        //const response1 = await fetchApiLocation("http://ip-api.com/json", "GET");
        const response1 = await fetchApiLocation("GET")
            .then((response) => {
                return response.text();
            })
            .then((xml) => {
                return new window.DOMParser().parseFromString(xml, "text/xml");
            })
            .then(async (xmlDoc) => {
                let country = this.getElementText(xmlDoc, "countryName");
                const urlC = "listofcountries";
                const responseC = await fetchApi(urlC, "POST", null, null);
                if (responseC.success) {
                    if (country !== undefined) {
                        country = responseC.listofcountries.filter(
                            (item) => item.en_name.toLowerCase() === country.toLowerCase()
                        );
                    }
                    if (country !== null) {
                        if (country[0] !== undefined && country[0] !== null) {
                            // const url = "contactus";
                            // let data = new FormData();
                            // data.append("country", country[0].id);
                            // const response = await fetchApi(url, "POST", data, null);
                            // if (response.success) {
                            //   this.setState({
                            //     contactUsList: response.contactus
                            //   });
                            // }

                            countryID = country[0].id;
                            this.getAdvertising();
                            this.position();
                        } else {
                            // const url = "contactus";
                            // let data = new FormData();
                            // data.append("country", 1);
                            // const response = await fetchApi(url, "POST", data, null);
                            // if (response.success) {
                            //   this.setState({
                            //     contactUsList: response.contactus
                            //   });
                            // }
                            this.getAdvertising();
                            this.position();
                        }
                    }
                }
            });
        //https://api.hostip.info/
    };

    componentDidMount() {
        this.getTimerForPartners();


        this.getContactInfo();
        this.getAllLocations();
        this.getHpComponents();
        this.getCategories();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        //this.position();
        //this.getAdvertising();
    }

    resize() {
        this.setState({hideParent: window.innerWidth < 1024});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
        clearInterval(this.intervalID);
    }

    getAdvertising = async () => {
        const url = "banneradvertisinglist";
        const data = new FormData();
        data.append("country_id", countryID);
        const response = await fetchApi(url, "POST", data, null);


        if (response.success && response.banneradvertising_list.length > 0) {
            this.setState({
                showBannerModal: true,
                toggleModalEvent: true,
                bannerAdvertisingList: response.banneradvertising_list[0]
            });
            sliderState.canUseSlider = false;
        }
    };

    onDismiss = () => {
        this.setState({showAlert: false, showEvents: true});
    };

    checkPopUps = () => {

        return sliderState.canUseSlider && sliderState.canUseSliderVideo
    }

    changeText = () => {
        if (this.checkPopUps()) {
            if (this.state.currentItem < this.state.titleTextList.length - 1) {
                this.setState({currentItem: this.state.currentItem + 1});
            } else {
                this.setState({currentItem: 0});
            }

            this.setState({titleText: "", titleShow: false})
            this.setState({
                titleShow: true,
                //  titleText: this.state.titleTextList[this.state.currentItem].zing_hpTextSlider,
                titleText: this.state.titleTextList[this.state.currentItem].description,
                //titleTextLight: this.state.titleTextListLight[this.state.currentItem].zing_s_hpTextSlider
            });
        }

    };

    eventClick = event => {
        this.state.categories.forEach(element => {
            if (element.id === event.category_id) {
                this.setState({selectedEventCategoryName: element.name});
            }
        });
        sliderState.canUseSlider = false;
        this.setState({currentEvent: event, toggleModalEvent: true});

    };

    toggleModalEvent = () => {
        sliderState.canUseSlider = true;
        this.setState({
            toggleModalEvent: !this.state.toggleModalEvent,
            showBannerModal: false
        });
    };

    selectedLocationHandler = (address, lat, lng) => {
        this.setState({
            currentAddress: address,
            currentLat: lat,
            currentLng: lng
        });
    };
    renderEvents = (event, idx) => {
        let date = event.date;
        let newDate;

        if (date) {
            newDate = date.replace(/-/g, '/');

        }
        //event = event.event;

        return (
            <div key={event.id}>
                <div
                    className="box col-12 col-lg-12"
                    onClick={() => this.eventClick(event)}
                >
                    <div className="row">
                        <div className="col-3 col-sm-3 col-lg-3 p-0">
                            <div className="bgbox ">
                                <div className="icon">
                                    <img className="gender-img" src={event.logo}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-9 col-sm-9 col-lg-9 no-padding p-0">
                            <div className="textbox">
                                <div className="title">{event.name}</div>

                                <div className="location">
                                    <FontAwesomeIcon icon={faMapMarkerAlt}/>{" "}
                                    {event.address}
                                </div>
                                <div className="date">
                                    {
                                        moment(new Date(newDate))
                                            .format("DD MMM")
                                            .toUpperCase()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    signUpHandler = () => {
        this.setState({toggleModalEvent: false});
        this.props.signUpHandler();
    };

    // obBlurCategory = (evt) => {
    //
    //     if (!this.state.supressBlur && this.state.categoryId ===null) {
    //         this.setState({categories: this.state.categories});
    //     }
    //     this.setState({supressBlur: false});
    //
    // };

    searchBusinessUser = (e) => {
        e.preventDefault();
        sliderState.canUseSlider = false;
        this.setState({showSearchBusiness: !this.state.showSearchBusiness});
    }
    toggleSearchBusinsess = () => {
        if (this.state.showSearchBusiness) {
            sliderState.canUseSlider = true;
        }
        this.setState({showSearchBusiness: !this.state.showSearchBusiness});
    }

    toggleSlide = (isVisible) => {
        if (isVisible && this.state.sliderStopped) {
            this.startSlider()
        } else {
            this.setState({sliderStopped: true})
            clearInterval(this.intervalID);
        }
    }

    startSlider() {

        this.intervalID = setInterval(
            () => this.changeText(),
            this.state.timeToRefresh
        );
    }


    render() {
        let text = this.state.titleText.split(';')
        return (
            <div className="headerJs">
                <Container fluid={true}>
                    <VisibilitySensor onChange={this.toggleSlide}>
                        <Col xs="8" sm={8} lg={8} className="sliderText">
                            {this.state.titleText !== "" ? (

                                <Slide
                                    in={this.state.titleShow}
                                    timeout={1000}

                                    direction="left"
                                >
                                    <div className="txtParents">


                                        <div className="first">{text[0]}</div>
                                        <div className="firstLight">{text[1]}</div>


                                    </div>
                                </Slide>
                                // <Slide direction="left" in={this.state.titleText !== ""}  >
                                //     <div className="first">{this.state.titleText}</div>
                                // </Slide>

                            ) : null}
                        </Col>
                    </VisibilitySensor>
                    <div className="shape col-lg-12">
                        <div className="firstItem">
                            <Col lg={12} className="parent pl-0">
                                <Row className="justify-content-center">
                                    <Col
                                        xs="12"
                                        sm={12}
                                        lg={4}
                                        className="LeftParent order-2 order-sm-2 order-lg-1"
                                    >
                                        {this.state.hideParent ? (
                                            <BusinessOffers
                                                businessEventList={this.state.businessEventList}
                                                //offertsList={this.state.offertsList}
                                                signUpHandler={this.props.signUpHandler}
                                                frontPage={true}
                                                signUpHandler={this.signUpHandler}
                                                setFalseSignUp={this.props.setFalseSignUp}
                                                showSignUp={this.props.showSignUp}
                                                eventHandler={this.props.eventHandler}
                                                handler={this.props.handleLog}
                                                a51List={this.state.a51List}
                                                a52List={this.state.a52List}
                                            />
                                        ) : null}
                                        {this.state.searchedEvents !== null ? (
                                            !this.state.hasSearched ? (
                                                <FlatList
                                                    list={this.state.events}
                                                    renderItem={this.renderEvents}
                                                />
                                            ) : (
                                                <FlatList
                                                    list={this.state.searchedEvents}
                                                    renderItem={this.renderEvents}
                                                />
                                            )
                                        ) : (
                                            <UncontrolledAlert
                                                isOpen={this.state.showAlert}
                                                toggle={this.onDismiss}
                                            >
                                                No event found
                                            </UncontrolledAlert>
                                        )}
                                        {this.state.showEvents
                                            ? this.state.events.map((item) => (
                                                <div key={item.id}>
                                                    <Event event={item} eventClick={this.eventClick}/>
                                                </div>
                                            ))
                                            : null}
                                    </Col>

                                    <Col
                                        sm={12}
                                        lg={4}
                                        className="center order-1 order-sm-1 order-lg-2"
                                    >
                                        {/*<div className="searchText">*/}
                                        {/*    <div className="btext">Search and find</div>*/}
                                        {/*    <div className="ltext">events nearby.</div>*/}
                                        {/*</div>*/}

                                        <Form className="customForm">

                                            <InputGroup>
                                                <ZingMap
                                                    showSearchTextBox={true}
                                                    showOpenMapButton={true}
                                                    inputClassName="hpSearchLocation"
                                                    buttonClassName="hpBtnLocationOpen"
                                                    enableMarkerAdd={false}
                                                    selectedLocationHandler={this.selectedLocationHandler}
                                                    selectBusinessUser={this.props.selectBusinessUser}
                                                    list={this.state.zingMapList}
                                                />
                                            </InputGroup>

                                            <InputGroup>
                                                <DatePicker

                                                    dateFormat={"YYYY-MM-DD"}
                                                    value={this.state.startDate}
                                                    onChange={this.handleChange}
                                                    inputPlaceholder="When"
                                                    shouldHighlightWeekends
                                                    colorPrimary="#007bff"
                                                    wrapperClassName="calendarHeader"
                                                    inputClassName="calendarHeaderInput"
                                                    style={{zIndex: "9999"}}
                                                />

                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>
                                                        <FontAwesomeIcon icon={faCalendarAlt}/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>

                                            <Col lg="12" className="p-0 m-0">
                                                <Autocomplete
                                                    freeSolo={false}
                                                    id="combo-box-demo2"
                                                    options={this.state.categories}
                                                    getOptionLabel={option => option.name}
                                                    className="text-white categoryInput hpCategInput"
                                                    onChange={(event, value) =>
                                                        this.onChangeCategory(event, value)
                                                    }
                                                    // onBlur={(event, value) => this.obBlurCategory(event)}
                                                    renderInput={params => (
                                                        <TextField
                                                            className="categoryAC placeholder1"
                                                            {...params}
                                                            placeholder="Category..."
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Col>

                                            <Col lg="12" className="p-0 m-0">
                                                <Input
                                                    className="categoryAC placeholder1 marginTop10"
                                                    placeHolder={"tags, categories added"}
                                                    onChange={(event, value) =>
                                                        this.changeTextSearch(event, value)
                                                    }
                                                />
                                            </Col>
                                            <div className="text-center">
                                                <button
                                                    color="primary"
                                                    className="eventBtn"
                                                    onClick={(e) => {
                                                        this.handleRequest(e);
                                                    }}
                                                >
                                                    Search event
                                                </button>
                                            </div>
                                            <div className="text-center">
                                                <button
                                                    color="primary"
                                                    className="eventBtn"
                                                    onClick={(e) => {
                                                        this.searchBusinessUser(e);
                                                    }}
                                                >
                                                    Search business
                                                </button>
                                            </div>
                                        </Form>
                                    </Col>
                                    {!this.state.hideParent ? (
                                        <Col lg={4} sm={12} className="RightParent  order-sm-3">
                                            <BusinessOffers
                                                businessEventList={this.state.businessEventList}
                                                //offertsList={this.state.offertsList}
                                                signUpHandler={this.signUpHandler}
                                                frontPage={true}
                                                eventHandler={this.props.eventHandler}
                                                handler={this.props.handleLog}
                                                setFalseSignUp={this.props.setFalseSignUp}
                                                showSignUp={this.props.showSignUp}
                                                a51List={this.state.a51List}
                                                a52List={this.state.a52List}
                                            />
                                        </Col>
                                    ) : null}
                                </Row>
                            </Col>
                        </div>
                    </div>

                    <Container fluid={true}>
                        <div className="imageHead">
                            {this.state.backgroundImages !== null ?
                                <img src={this.state.backgroundImages}/> :
                                <img src={BackImage}/>
                            }

                        </div>
                    </Container>
                    <Modal
                        isOpen={this.state.toggleModalEvent}
                        // toggle={this.toggleModalEvent}
                        size="lg"
                        className="overFowModal"
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">
                                {this.state.showBannerModal
                                    ? "Advertising popup"
                                    : "Event details"}
                            </ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader className="head-2" toggle={this.toggleModalEvent}/>
                        </div>
                        <ModalBody className="overFowModal">
                            {this.state.showBannerModal ? (
                                <Col className="d-flex justify-content-center my-5 addContainer">
                                    <div className="imgAndVideo">
                                        <div className="adText">{this.state.bannerAdvertisingList[0].description}</div>
                                        <img
                                            className="adImg"
                                            src={this.state.bannerAdvertisingList[0].image}
                                        />
                                        <iframe
                                            onClick={this.props.toggleVideoModal}
                                            aria-disabled
                                            className="VideoBeforePlay"
                                            src={
                                                this.state.bannerAdvertisingList[0].video.substring(0, 23) +
                                                "/" +
                                                "embed/" +
                                                this.state.bannerAdvertisingList[0].video.substring(
                                                    32,
                                                    this.state.bannerAdvertisingList[0].video.length
                                                )
                                            }
                                            frameBorder="0"
                                            allowFullScreen/>

                                    </div>
                                </Col>
                            ) : this.state.currentEvent !== null ? (
                                <BannerDetailsNormal
                                    categoryName={this.state.selectedEventCategoryName}
                                    currencyBannner={this.state.currentEvent.currency}
                                    eventId={this.state.currentEvent.id}
                                    openModal={this.state.openModal}
                                    showSignUp={this.props.showSignUp}
                                    setFalseSignUp={this.props.setFalseSignUp}
                                    eventHandler={this.props.eventHandler}
                                    handler={this.props.handleLog}
                                    name={this.state.currentEvent.name}
                                    gender={this.state.currentEvent.gender}
                                    ageCategory={this.state.currentEvent.age_category}
                                    startDate={this.state.currentEvent.date.substring(0, 11)}
                                    startTime={this.state.currentEvent.start_hour.substring(0, 5)}
                                    endDate={this.state.currentEvent.end_date.substring(0, 11)}
                                    endTime={this.state.currentEvent.end_hour.substring(0, 5)}
                                    latitude={this.state.currentEvent.latitude}
                                    longitude={this.state.currentEvent.longitude}
                                    team_number={this.state.currentEvent.team_number}
                                    max_persons={this.state.currentEvent.max_persons}
                                    persons_left={this.state.currentEvent.persons_left}
                                    description={this.state.currentEvent.description}
                                    //multiprice={this.state.currentMultiprice}
                                    address={this.state.currentEvent.address}
                                    latitude={this.state.currentEvent.latitude}
                                    longitude={this.state.currentEvent.longitude}
                                    userLogo={this.state.eventLogo}
                                    eventLogo={this.state.currentEvent.logo}
                                    signUpHandler={this.signUpHandler}
                                />
                            ) : null}
                        </ModalBody>
                    </Modal>

                    <Modal
                        isOpen={this.state.showSearchBusiness}
                        // toggle={this.toggleSearchBusinsess}
                        size="lg"
                        className="businessSearchModal"
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">
                                Search business
                            </ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={this.toggleSearchBusinsess}
                            />
                        </div>
                        <ModalBody className="">
                            <FindPeople
                                selectedCategory={this.state.categoryId ? this.state.categoryId : 0}
                                selectedAddress={this.state.currentAddress}
                                selectedLat={this.state.currentLat}
                                selectedLng={this.state.currentLng}
                                selectedText={this.state.freeText}
                                businessOnly={true}
                                fromHeader={true}
                                categoryList={this.state.categories}
                            />
                        </ModalBody>
                    </Modal>
                </Container>
            </div>
        );
    }

    onWhereChange = evt => {
        this.setState({
            WhereValue: evt.target.value
        });
    };

    onDateChange = evt => {
        this.setState({
            DateValue: evt.target.value
        });
    };

    handleRequest(e) {
        e.preventDefault();
        this.getSearchedEvents();
        this.getSearchedBussinesAndOffers();
    }
}

class Event extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventInfo: this.props.event
        };
    }

    render() {

        return (
            <div
                className="box col-12 col-lg-12"
                onClick={() => this.props.eventClick(this.props.event)}
            >
                <div className="row">
                    <div className="col-4 col-sm-5 col-lg-5 p-0">
                        <div className="bgbox ">
                            <div className="icon">
                                <FontAwesomeIcon icon={faBiking}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-8 col-sm-6 col-lg-6 no-padding p-0">
                        <div className="textbox">
                            <div className="title">{this.state.eventInfo.name}</div>

                            <div className="location">
                                <FontAwesomeIcon icon={faMapMarkerAlt}/>{" "}
                                {this.state.eventInfo.address}
                            </div>
                            <div className="date">
                                {moment(new Date(this.state.eventInfo.date))
                                    .format("DD MMM")
                                    .toUpperCase()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
