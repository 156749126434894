import React from "react";
import {
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Col,
    Container,
    Label,
    FormGroup,
    FormFeedback,
    Modal,
    ModalBody,
    ModalHeader, Tooltip,
} from "reactstrap";
import {
    faEuroSign,
    faCalendarAlt,
    faMale,
    faFemale,
    faRestroom,
    faUsers,
    faSignature,
    faUserTag,
    faWheelchair, faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./MakeDeal.scss";
import ModernDatepicker from "react-modern-datepicker";
import TimePicker from "react-times";
import "react-times/css/material/default.css";
import moment from "moment";
import {fetchApi} from "../../../fetchApi.js";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import {userCredentials} from "../../../globalVariables";

import Checkbox from "react-simple-checkbox";
import ZingMap from "../../ZingMap/ZingMap";
import swal from "sweetalert";
import MultiplyCalendar from "../../MultiplyCalendar/MultiplyCaledar";
import DatePicker from "react-modern-calendar-datepicker";
import LoadingOverlay from "react-loading-overlay";

let profilePic = require("../../../Assets/img/profilePic.jpg");
let SLogo = require("../../../Assets/img/SLogo.png");

export default class MakeDeal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: "",
            dropdownOpenCategory: false,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            prices: [{id: 0}],
            companyName: "",
            Location: "suceava",
            date: "13-02-2020",
            startTime: "00:00",
            endTime: "00:00",
            description: "",
            latitude: "0",
            longitude: "0",
            nOffers: "2",
            nParticipants: [],
            numberOf: "",
            price: "",
            currentIndex2: 0,
            hasChanged: false,
            hasChanged2: false,
            allTogether: [],
            gender: "0",
            categoryName: "",
            categoryId: "",
            categories: [],
            imagePreviewUrl: "",
            eventName: "",
            alliasId: "",
            // isHandicap: false,
            country: "",
            zingMapList: [],
            location: "",
            isValidEventName: false,
            isValidDate: false,
            multiplyModal: false,
            text: "",
            multiplyArray: [],
            startTrue: false,
            endTrue: false,
            isHandicapTrue: false,
            isHandicapBus: "0",
            priceType: [
                {name: "RON"},
                {name: "USD"},
                {name: "EUR"},
                {name: "GBP"},
            ],
            selectedPriceType: "RON",
            indicativePrice: "",
            show: false,
            tooltipOpenStripe: false,
            isStripeConnected: false,
            currentIndex:"",
            closeDeal:this.props.toggleshowUpdateDeal,
            costumeDateState:'',
            costumeEndDateState:'',
            priceInput:''
        };
    }

  getForbidenWords = async () => {
    let data = new FormData();
    let url = "forbidenwords";
    const response = await fetchApi(url, "GET", data);
    if (response.success) {
      this.setState({ forbidenWords: response.forbiden_words });
    }
  };

  componentDidMount = () => {
    this.getForbidenWords();
    this.getCategories();
    this.getProfile();
    this.getMyLocations();
    if (
      this.props.updateEvent &&
      this.props.eventToUpdate.max_persons ===
        this.props.eventToUpdate.persons_left
    ) {
      let category = this.props.categories.filter(
        (element) => element.id === this.props.eventToUpdate.category_id
      );
      let priceList = [];
      let nOfList = [];
      let indicativePriceList = [];
      let prices = [];
      let k = 0;
      this.props.currentMultiprice.forEach((element) => {
        let price = element.price;
        let nof = element.number_of_tickets;
        let indicativePrice = element.indicative_price;
        priceList.push(price);
        nOfList.push(nof);
        indicativePriceList.push(indicativePrice);
        prices.push({ id: k++ });
      });
      // let costumeDate = this.props.currentEvent.date.split(" ");
      
      this.setState({
        // costumeDateState : costumeDate,
        location: this.props.eventToUpdate.address,
        description: this.props.eventToUpdate.description,
        category: category[0].name,
        categoryId: category[0].id,
        endDate: this.props.eventToUpdate.end_date,
        startDate: this.props.eventToUpdate.date,
        startTime: this.props.eventToUpdate.start_hour,
        endTime: this.props.eventToUpdate.end_hour,
        gender: this.props.eventToUpdate.gender,
        eventName: this.props.eventToUpdate.name,
        // isHandicapBus:this.props.eventToUpdate.handicap_facilities === "1" ? true : false,
        isHandicapBus: this.props.eventToUpdate.handicap_facilities,
        numberOf: nOfList,
        price: priceList,
        text: indicativePriceList,
        prices: prices,
      });
    } else {
    }







    if(this.props.comesFromBanner){
      // let countrynm = ""

      // if(this.props.currentVoucher.address !== "" && this.props.currentVoucher.address !== null){
      //     countrynm = this.props.currentVoucher.address.split(",")
      //     this.setState({countryName:  countrynm[1],
      //         cityLocation: countrynm[0],})
      // }else{
      //     this.setState({countryName:'All countries'})
      // }
      let priceList = [];
      let nOfList = [];
      let indicativePriceList = [];
      let prices = [];
      // let k = 0;
      // this.props.currentMultiprice.forEach((element) => {
      //     let price = element.price;
      //     let nof = element.number_of_tickets;
      //     let indicativePrice = element.indicative_price;
      //     priceList.push(price);
      //     nOfList.push(nof);
      //     indicativePriceList.push(indicativePrice);
      //     prices.push({id: k++});
      // });
      let startYear = moment(new Date(this.props.currentEvent.date)).format(
        "YYYY"
    );
    let startMonth = moment(new Date(this.props.currentEvent.date)).format(
        "MM"
    );
    let startDay = moment(new Date(this.props.currentEvent.date)).format(
        "DD"
    );

    let endYear = moment(new Date(this.props.currentEvent.end_date)).format(
        "YYYY"
    );
    let endMonth = moment(new Date(this.props.currentEvent.end_date)).format(
        "MM"
    );
    let endDay = moment(new Date(this.props.currentEvent.end_date)).format(
        "DD"
    );
      let startDate = {
        day: parseInt(startDay),
        month: parseInt(startMonth),
        year: parseInt(startYear),
    };
    let endDate = {
        day: parseInt(endDay),
        month: parseInt(endMonth),
        year: parseInt(endYear),
    };
    let costumeDate = this.props.currentEvent.date.split(" ");
    let costumeEndDate = this.props.currentEvent.end_date.split(" ");

      
      this.setState({
        // this.props.dealName:
      costumeDateState : costumeDate[0],
      costumeEndDateState : costumeEndDate[0],
        // this.state.eventName
        eventName: this.props.currentEvent.name,
        categoryId: this.props.currentEvent.category_id,
        categoryName: this.state.categories.filter(
          (element) => parseInt(element.id) === parseInt(this.props.currentEvent.category_id)
      ).en_name,
      location:this.props.currentEvent.address,
      gender: this.props.currentEvent.gender,
      endDate: endDate,
      startDate: startDate,
      startTime: this.props.currentEvent.start_hour,
       endTime: this.props.currentEvent.end_hour,
      description: this.props.currentEvent.description,

      numberOf: this.props.currentEvent.persons_left
      // nOfList,
      //           priceU: priceList,
      //           textU: indicativePriceList,
      //           pricesForUpdate: prices,
      // startDate={this.state.startDate}
      //       endDate={this.state.endDate}
      // startDate:  this.props.currentEvent.date,
      // endDate: this.props.currentEvent.end_date,

  //         voucherId:this.props.currentVoucher.id,
  //         titleVoucher:this.props.currentVoucher.title,
          
  //         nOfVouchers:this.props.currentVoucher.left_vouchers,
  //         conditions:this.props.currentVoucher.promo_description,
  //         appImage:this.props.currentVoucher.voucher_image,
  //         videoUrl:this.props.currentVoucher.video,
  //         country:this.props.currentVoucher.country,
  //         finalPriceToPay:this.props.currentVoucher.total_pay_value,

  // longitude: this.props.currentVoucher.longitude,
  // latitude: this.props.currentVoucher.latitude,

});

} else {
// this.setState({country:this.props.currentVoucher.country})
}
    };
    selectHandicapYes = () => {
        // if(this.state.isHandicap){
        this.setState({isHandicapTrue: !this.state.isHandicapTrue});
        this.selectHandicapNo();
    };
    selectHandicapNo = () => {
        if (this.state.isHandicapTrue === false) {
            this.setState({isHandicapBus: "1"});
        } else {
            this.setState({isHandicapBus: "0"});
        }
    };
    toggleMultiply = () => {
        this.setState({multiplyModal: !this.state.multiplyModal});
    };

    // getEventsLocation = async () => {
    //     //console.log("response");
    //     const data = new FormData();
    //     const url = "filternormalevents";
    //     data.append("address", "");
    //     data.append("latitude", "");
    //     data.append("longitude", "");
    //     data.append("date", "");
    //     data.append("category", 0);
    //     data.append("user_id", 0);
    //     const response = await fetchApi(url, "POST", data);
    //     if (response.success) {
    //         let arr = [];
    //         response.events.forEach(element => {
    //             let result = this.checkForDuplicate(arr, element.address);
    //             if (result) {
    //                 arr.push({
    //                     description: element.address,
    //                     lat: element.latitude,
    //                     lng: element.longitude
    //                 });
    //             }
    //         });
    //         this.setState({zingMapList: arr});
    //     }
    // };

    getMyLocations = async () => {
        //console.log("response");
        const data = new FormData();
        const url = "getbusinesslocationperbusiness";
        data.append("business_user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, userCredentials.authKey);
        if (
            response.success &&
            response.locations &&
            response.locations.length > 0
        ) {
            this.setState({zingMapList: response.locations});
        }
    };

    checkForDuplicate = (arr, value) => {
        let res = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].description.toLowerCase() === value.toLowerCase()) {
                res++;
            }
        }
        if (res === 0) {
            return true;
        } else {
            return false;
        }
    };

    getProfile = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "getprofile";
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.setState({
                imagePreviewUrl: response.userprofile[0].logo,
                companyName: response.user_informations.company_name,
                country: response.user_informations.country,
                isStripeConnected: response.is_stripe_connected,
                price: response.is_stripe_connected ? [] : 0
            });
        }
    };

    getCategories = async () => {
        const url = "get_firstlevelcategoriesandalias";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);

    const response = await fetchApi(url, "POST", data, authKey);
    let list = [],
        alias = [],
        categs;
    if (response.success) {
        if (this.props.currentEvent) {
            let categoryName = response.list_of_categories.filter(
                (element) =>
                    parseInt(element.category.id) ===
                    parseInt(this.props.currentEvent.category_id)
            );

            if (categoryName.length !== 0) {
                this.setState({categoryName: categoryName[0].category.name});
            }
        }

        for (let i = 0; i < response.list_of_categories.length; i++) {
            categs = response.list_of_categories[i].category;
            list.push(categs);
            for (let j = 0; j < response.list_of_categories[i].alias.length; j++) {
                alias = response.list_of_categories[i].alias[j];
                if (alias.name_of_subcategory.length !== 0) {
                    list.push(alias);
                }
            }
        }
        this.setState({categories: list});
    }
};
  onChangeCategory = (evt, value) => {
    if (value !== null) {
      if (value.parent_category_id) {
        this.setState({
          categoryId: value.parent_category_id,
          alliasId: value.id,
          category: evt.target.value,
          categoryName:
            value.name !== undefined ? value.name : value.name_of_subcategory,
        });
      } else {
        this.setState({
          category: evt.target.value,
          categoryName:
            value.name !== undefined ? value.name : value.name_of_subcategory,
          categoryId: value.id,
          alliasId: false,
        });
      }
    } else {
      this.setState({ categoryId: "" });
    }
  };

  verifyData = () => {
    if (this.state.categoryId === "") {
      return false;
    } else if (this.state.startTime === "") {
      return false;
    } else if (this.state.endTime === "") {
      return false;
    } else if (this.state.description === "") {
      return false;
    } else if (this.state.location === "") {
      return false;
    } else if (this.state.price === "") {
      return false;
    } else if (this.state.numberOf === "") {
      return false;
    } else {
      return true;
    }
  };

    verifyArrayItems = () => {
        if (this.state.isStripeConnected) {
            if (!this.state.price.length > 0 || this.state.price.includes("")) {
                return false;
            } else if (!this.state.numberOf.length > 0 || this.state.numberOf.includes("")
            ) {
                return false;
            } else if (!this.state.text.length > 0 || this.state.text.includes("")) {
                return false;
            } else {
                return true;
            }
        } else {
            if (!this.state.numberOf.length > 0 || this.state.numberOf.includes("")
            ) {
                return false;
            } else if (!this.state.text.length > 0 || this.state.text.includes("")) {
                return false;
            } else {
                return true;
            }
        }
    };

  verifyDateAndTime = () => {
    let cd = new Date();
    cd.setHours(0, 0, 0, 0);
    let d1, d2;
    let count = 0;
    if (this.state.startDate !== null) {
      d1 = new Date(
        this.state.startDate.year +
          "/" +
          this.state.startDate.month +
          "/" +
          this.state.startDate.day
      );
      d1.setHours(0, 0, 0, 0);
    } else {
      count++;
    }
    if (this.state.endDate !== null) {
      d2 = new Date(
        this.state.endDate.year +
          "/" +
          this.state.endDate.month +
          "/" +
          this.state.endDate.day
      );
      d2.setHours(0, 0, 0, 0);
    } else {
      count++;
    }
    let t1 = this.state.startTime.toString();
    let t2 = this.state.endTime.toString();
    if (d1 >= cd && d2 >= cd && count === 0) {
      if (d1 < d2) {
        return false;
      } else if (d1.getTime() === d2.getTime() && t1 < t2) {
        return false;
      } else if (d1.getTime() === d2.getTime() && t1 >= t2) {
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  verifyForBadWords = (text) => {
    let badWords = this.state.forbidenWords.split(";");
    let count = 0;
    if (text && text.length !== 0) {
      badWords.forEach((element) => {
        if (text.includes(element)) {
          count++;
        }
      });

      if (count === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  makeEventHandler = () => {

    let priceLei = parseFloat(this.props.minPriceLei)
    let priceLire = parseFloat(this.props.minPriceLire)
    let priceEuro = parseFloat(this.props.minPriceEuro)
    let priceDolar = parseFloat(this.props.minPriceDolar)

    if( this.props.minPriceDolar !== undefined && 
        this.props.minPriceDolar !== null && 

        this.props.minPriceEuro !== undefined && 
        this.props.minPriceEuro !== null && 

        this.props.minPriceLire !== undefined && 
        this.props.minPriceLire !== null && 

        this.props.minPriceLei !== undefined && 
        this.props.minPriceLei !== null 
         ){
          
  if(this.state.selectedPriceType === "RON"){

      if(this.state.priceInput >= priceLei){
        this.doneHandler();
      }else{
          swal("Warning!", "Price per ticket cannot be les then " +  priceLei + " RON" , "warning");
      }

  }else if(this.state.selectedPriceType === "USD"){

      if(this.state.priceInput >= priceDolar){
        this.doneHandler();
      }else{
        swal("Warning!", "Price per ticket cannot be les then " +  priceDolar + " Dolar" , "warning");
          
      }

  }else if(this.state.selectedPriceType === "GBP"){
     if(this.state.priceInput >= priceLire){
        this.doneHandler();
      }else{
        swal("Warning!", "Price per ticket cannot be les then " +  priceLire + " Pounds" , "warning");

      }
  }else if(this.state.selectedPriceType === "EUR"){
     if(this.state.priceInput >= priceEuro){
        this.doneHandler();
      }else{
        swal("Warning!", "Price per ticket cannot be les then " +  priceEuro + " Euro" , "warning");

      }
  }
  
}else{
    swal("Warning!", "Something went wrong, please contact administrator", "warning");
}

  };

  doneHandler = async () => {
    let numberOfMultiplies = this.state.multiplyArray.length;
    let authKey = userCredentials.authKey;
    this.setState({
      isValidDate: this.verifyDateAndTime(),
      isValidMultipay: !this.verifyArrayItems(),
    });
    let multiplyArray = [];
    let multiplyJson;

    this.state.multiplyArray.forEach((element) => {
      multiplyArray.push({
        date: element.date,
        comment: ".",
        calendar_max_persons: this.state.numberOf,
        calendar_persons_left: this.state.numberOf,
      });
    });

    if (this.state.multiplyArray.length === 0) {
      numberOfMultiplies = 1;
      multiplyArray.push({
        date: moment(this.state.startDate).format("YYYY-MM-DD HH-MM"),
        comment: ".",
        calendar_max_persons: this.state.numberOf,
        calendar_persons_left: this.state.numberOf,
      });
      multiplyJson = JSON.stringify(multiplyArray);
    } else {
      multiplyJson = JSON.stringify(multiplyArray);
    }
    if (this.state.eventName === "") {
      this.setState({ isValidEventName: true });
    } else {
      this.setState({ isValidEventName: false });
    }
    if (this.state.categoryId === "") {
      this.setState({ isValidCategoryId: true });
    } else {
      this.setState({ isValidCategoryId: false });
    }
    if (this.state.location === "") {
      this.setState({ isValidLocation: true });
    } else {
      this.setState({ isValidLocation: false });
    }
    if (this.state.description === "") {
      this.setState({ isValidDescription: true });
    } else {
      this.setState({ isValidDescription: false });
    }
    if (this.state.location === "") {
      this.setState({ isValidLocation: true });
    } else {
      this.setState({ isValidLocation: false });
    }
    if (
      this.state.description === "" ||
      !this.verifyForBadWords(this.state.description)
    ) {
      this.setState({ isValidDescription: true });
    } else {
      this.setState({ isValidDescription: false });
    }
    if (this.state.price === "") {
      this.setState({ isValidPrice: true });
    } else {
      this.setState({ isValidPrice: false });
    }
    if (this.state.numberOf === "" ) {
      this.setState({ isValidNumberOf: true });
    } else {
      this.setState({ isValidNumberOf: false });
    }
    let data = new FormData();
    if (
      this.verifyData() &&
      !this.verifyDateAndTime() &&
      this.verifyForBadWords(this.state.description) &&
      parseFloat(this.state.price) <= 999999.99
    ) {
      if (this.state.alliasId) {
        data.append("alias_category_id", this.state.alliasId);
    data.append("name_of_subcategory", this.state.categoryName);

      }
      // if (this.state.isHandicapBus) {
      //   data.append("handicap_facilities ", 1);
      // }

      const url = "createdeal";
      data.append("name", this.state.eventName);
      data.append("company_name", this.state.companyName);
      data.append("country", this.state.country);
      data.append("category_id", this.state.categoryId);
      data.append("address", this.state.location);
      data.append("latitude", this.state.latitude);
      data.append("longitude", this.state.longitude);
      data.append("description", this.state.description);
      data.append("user_id", userCredentials.userId);
      data.append("currency", this.state.selectedPriceType);
      data.append("handicap_facilities", this.state.isHandicapBus);
      data.append("team_number", "0");
      data.append(
        "max_persons",
        numberOfMultiplies * parseInt(this.state.numberOf)
      );
      data.append(
        "persons_left",
        numberOfMultiplies * parseInt(this.state.numberOf)
      );
      data.append("gender", this.state.gender);
      data.append("age_category", "0");
      data.append("multiply", multiplyJson);
      data.append("start_hour", this.state.startTime);
      data.append("end_hour", this.state.endTime);
      data.append(
        "date",
        this.state.startDate.year +
          "-" +
          this.state.startDate.month +
          "-" +
          this.state.startDate.day
      );
      data.append(
        "end_date",
        this.state.endDate.year +
          "-" +
          this.state.endDate.month +
          "-" +
          this.state.endDate.day
      );
            data.append("indicativePrice", this.state.indicativePrice);
            data.append("price", this.state.price ? this.state.price : 0);
          
            this.setState({show: true});
      const response = await fetchApi(url, "POST", data, authKey);
     
      if (response.success) {
        swal("Success!", "Event created with success", "success");
        this.props.reloadEventsCallback();
        this.setState({ show: false });
      } else {
        swal("Error!", "Could not create event!", "error");


        this.setState({ show: false });
      }
    } else {
    }
  };



  toggleshowUpdateDeal = () => {
    this.setState({ closeDeal: this.props.toggleshowUpdateDeal })
  }


  
  UpdateDeal = async () => {
    let authKey = userCredentials.authKey;
   
    if (this.state.eventName === "") {
      this.setState({ isValidEventName: true });
    } else {
      this.setState({ isValidEventName: false });
    }
 
    if (this.state.location === "") {
      this.setState({ isValidLocation: true });
    } else {
      this.setState({ isValidLocation: false });
    }
    if (this.state.description === "") {
      this.setState({ isValidDescription: true });
    } else {
      this.setState({ isValidDescription: false });
    }
    if (this.state.location === "") {
      this.setState({ isValidLocation: true });
    } else {
      this.setState({ isValidLocation: false });
    }
    if (
      this.state.description === "" ||
      !this.verifyForBadWords(this.state.description)
    ) {
      this.setState({ isValidDescription: true });
    } else {
      this.setState({ isValidDescription: false });
    }
    if (this.state.price === "") {
      this.setState({ isValidPrice: true });
    } else {
      this.setState({ isValidPrice: false });
    }
    if (this.state.numberOf === "") {
      this.setState({ isValidNumberOf: true });
    } else {
      this.setState({ isValidNumberOf: false });
    }
    let data = new FormData();
    if (

      this.verifyForBadWords(this.state.description) 
    ) {



      const url = "updatedeal";

      if (this.state.alliasId) {
        data.append("alias_category_id", this.state.alliasId);
        data.append("name_of_subcategory", this.state.categoryName);
      }
      data.append("deal_id", this.props.currentEvent.id)
      data.append("name", this.state.eventName);
      data.append("company_name", this.state.companyName);
      data.append("country", this.state.country);
      data.append("category_id", this.state.categoryId);
      data.append("address", this.state.location);
      data.append("latitude", this.state.latitude);
      data.append("longitude", this.state.longitude);
      data.append("description", this.state.description);
      data.append("user_id", userCredentials.userId);

      data.append("gender", this.state.gender);
      data.append("age_category", "0");

            this.setState({show: true});
      const response = await fetchApi(url, "POST", data, authKey);

      if (response.success) {
        // this.toggleshowUpdateDeal()
        this.setState({show: true});
        swal("Success!", "Deal Updated with success", "success");
        this.props.CloseModal();
        this.setState({ show: false });
      } else {
        swal("Error!", "Could not update deal!", "error");

        this.setState({ show: false });
      }
    } else {
    }
  };

  onIndexChange = (id) => {
    this.setState({ currentIndex: id });
  };

  onPriceChange = (evt) => {
    if (parseFloat(evt.target.value) <= 999999.99) {
      this.setState({
        price: evt.target.value,
        priceIsTooBig: false,
      });
    
    } else {
      this.setState({ priceIsTooBig: true });
    }
    this.setState({priceInput: evt.target.value })

  };

  onChangeCurrency = (evt) => {
    this.setState({ selectedPriceType: evt.target.value });
  };

  onTextChange = (evt) => {
    let list = this.state.text;
    list[this.state.currentIndex] = evt.target.value;
    this.setState({
      text: list,
    });
  };

  onIndicativeChange = (evt) => {
    this.setState({ indicativePrice: evt.target.value });
  };

  onNumberOfChange = (evt) => {
    this.setState({
      numberOf: evt.target.value,
    });
  };

  onNParticipantsChange = (evt) => {
    let list = this.state.nParticipants;
    list[this.state.currentIndex] = evt.target.value;
    this.setState({
      nParticipants: list,
    });
  };

  changeDescription = (evt) => {
    this.setState({ description: evt.target.value });
  };

  addPrice = () => {
    let newList = this.state.prices;
    newList.push({ id: this.state.currentIndex2 + 1 });
    this.setState({ prices: newList });
    this.setState({ currentIndex2: this.state.currentIndex2 + 1 });
  };

  onStartTimeChange(options) {
    const { hour, minute, meridiem } = options;
    this.setState({ startTime: options.hour + ":" + options.minute });
  }

  onEndTimeChange(options) {
    const { hour, minute, meridiem } = options;
    this.setState({ endTime: options.hour + ":" + options.minute });
  }

  onFocusChange(focusStatue) {
    // do something
  }

  handleChange(date) {
    this.setState({
      startDate: date,
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
    });
  }

  toggleDropDownCategory = (e) => {
    this.setState({
      dropdownOpenCategory: !this.state.dropdownOpenCategory,
    });
  };
  onDropdownItemCategory_Click = (sender) => {
    var newState = {
      dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue"),
    };
    this.setState({
      category: newState.dropDownValue2,
    });
  };

  onCategoryChange = (evt) => {
    this.setState({
      category: evt.target.value,
    });
  };

  selectMale = () => {
    this.setState({ gender: "1" });
  };

  selectFemale = () => {
    this.setState({ gender: "2" });
  };
  seletUnisex = () => {
    this.setState({ gender: "3" });
  };

  onChangeCompanyName = (evt) => {
    this.setState({ companyName: evt.target.value });
  };

  onChangeLocation = (address, lat, lng) => {
    this.setState({ location: address });
    this.setState({ latitude: lat });
    this.setState({ longitude: lng });
  };
  toggleDropDown = (e) => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  onDropdownItem_Click = (sender) => {
    var newState = {
      dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue"),
    };
    this.setState({
      inputValue: newState.dropDownValue2,
      dropdownOpen: !this.state.dropdownOpen,
    });
    this.state.categoryList.forEach((element) => {
      if (element.name === newState.dropDownValue2) {
        this.setState({ categoryId: element.id });
      }
    });
  };

  onInputChange = (evt) => {
    this.setState({
      inputValue: evt.target.value,
    });
    this.state.categoryList.forEach((element) => {
      if (element.name === evt) {
        this.setState({ categoryId: element.id });
      }
    });
  };

  onChangeEventName = (evt) => {
    this.setState({ eventName: evt.target.value });
  };

  // checkHandler = () => {
  //   this.setState({ isHandicap: !this.state.isHandicap });
  // };

  doneCallback = (
    eventsArray,
    text,
    startDate,
    endDate,
    startTime,
    endTime
  ) => {
    let multiplyArray = [];
    eventsArray.forEach((element) => {
      multiplyArray.push({
        date: moment(new Date(element.start)).format("YYYY-MM-DD HH:mm"),
        comment: element.title.toString(),
      });
    });
    let sorted = multiplyArray.sort(function (a, b) {
      let c = new Date(a.date);
      let d = new Date(b.date);
      return c - d;
    });
    this.setState({
      text: text,
      multiplyArray: sorted,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
    });
  };

    toggleTooltipStripe = () => {
        this.setState({tooltipOpenStripe: !this.state.tooltipOpenStripe});
    };


    renderTooltipStripe() {
        return (
            <div>
                <div className="justify-content-center  align-bottom">
                    <FontAwesomeIcon
                        className="iconTooltipPrice"
                        icon={faExclamationTriangle}
                        id="toolTipStripe"
                        color={"#f5b91c"}
                    />
                </div>
                <Tooltip
                    placement="top"
                    isOpen={this.state.tooltipOpenStripe}
                    target="toolTipStripe"
                    toggle={this.toggleTooltipStripe}
                    hideArrow
                >
                    <div className="toolTipGeneral">
                        <h6>
                            Stripe required
                        </h6>
                        <p className="tooltipC">
                            In order to add price and multiprice to your tickets you need to connect to Stripe
                        </p>

                    </div>
                </Tooltip>
            </div>)
    }

    render() {
        return (
            <LoadingOverlay active={this.state.show} spinner text="Creating deal...">
                <Container className="makeEventsbusiness">
                    <Row>
                        <Col lg="2" className="m-3">
                            <img
                                className="eventProfilePic"
                                src={
                                    this.state.imagePreviewUrl !== null &&
                                    this.state.imagePreviewUrl !== "" &&
                                    this.state.imagePreviewUrl !== undefined
                                        ? this.state.imagePreviewUrl
                                        : profilePic
                                }
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = profilePic;
                                }}
                            />
                        </Col>
                        <Col className="pt-3 eventUsrName">{this.state.companyName}</Col>
                    </Row>
                    <Row>
                        <Col lg="4">
                            <FormGroup>
                                <Label for="eventName" className="">
                                    Deal name*
                                </Label>
                                <Col className="input-group p-0 m-0">
                                    <Input
                                        type="text"
                                        placeholder="Deal Name"
                                        value={this.state.eventName}
                                        onChange={this.onChangeEventName}
                                        id="eventName"
                                        valid={this.state.isValidEventName}
                                        invalid={this.state.isValidEventName}
                                    />
                                    <InputGroupAddon
                                        addonType="append"
                                        className="backgrounButton"
                                    >
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faSignature}/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <FormFeedback>This field is required</FormFeedback>
                                </Col>
                            </FormGroup>
                        </Col>

                        <Col lg="4" className="p-0 m-0 firstInput">
                            <FormGroup>
                                <Label for="combo-box-demo4" className="categ-label-dial">
                                    Category*
                                </Label>
                            </FormGroup>
                            <Col className="input-group  mt-n3 p-0">
                                <Autocomplete
                                    id="combo-box-demo4"
                                    options={this.state.categories}
                                    getOptionLabel={(option) =>
                                        option.name_of_subcategory
                                            ? "\xa0\xa0\xa0\xa0" + option.name_of_subcategory
                                            : option.name
                                    }
                                    style={{}}
                                    className="text-white categoryInputB-deal"
                                    onChange={(event, value) =>
                                        this.onChangeCategory(event, value)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            className="categoryAC placeholder1"
                                            {...params}
                      // placeholder="Category..."
                      placeholder={
                        this.props.comesFromBanner
                            ? this.state.categoryName
                            : "Category..."
                    }
                      variant="outlined"
                    />
                                    )}
                                />
                                <Input
                                    className="invisible"
                                    valid={this.state.isValidCategoryId}
                                    invalid={this.state.isValidCategoryId}
                                />
                                <FormFeedback>This field is required</FormFeedback>
                            </Col>
                        </Col>
                        <Col lg="4" className="">
                            <FormGroup>
                                <Label for="city" className="">
                                    City*
                                </Label>

                                <Col className="p-0 m-0 makeeventlocation">
                                    <ZingMap
                  initialAddress={this.state.location}
                                        id="city"
                                        showSearchTextBox={true}
                                        showOpenMapButton={true}
                                        inputClassName="makeEvents"
                                        buttonClassName=""
                                        enableMarkerAdd={true}
                                        selectedLocationHandler={this.onChangeLocation}
                                        list={this.state.zingMapList}
                                    />
                                </Col>
                                <Input
                                    className="invisible"
                                    valid={this.state.isValidLocation}
                                    invalid={this.state.isValidLocation}
                                />
                                <FormFeedback>This field is required</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className="p-0">
                            <FormGroup className=" px-3 py-0">
                                <Label for="genderSelect">Gender category</Label>
                                <Col className="p-0 m-0 genderButtonGroup">
                                    <Row id="genderSelect" className="m-0">
                                        <div
                                            className={
                                                this.state.gender === "1"
                                                    ? "genderIcon genIconPressed"
                                                    : "genderIcon"
                                            }
                                            onClick={this.selectMale}
                                        >
                                            <FontAwesomeIcon icon={faMale}/>
                                        </div>
                                        <div
                                            className={
                                                this.state.gender === "2"
                                                    ? "genderIcon genIconPressed"
                                                    : "genderIcon"
                                            }
                                            onClick={this.selectFemale}
                                        >
                                            <FontAwesomeIcon icon={faFemale}/>
                                        </div>
                                        <div
                                            className={
                                                this.state.gender === "3"
                                                    ? "genderIcon genIconPressed"
                                                    : "genderIcon"
                                            }
                                            onClick={this.seletUnisex}
                                        >
                                            <FontAwesomeIcon icon={faRestroom}/>
                                        </div>
                                    </Row>
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col xs="6">
                            <Col className="text-bus">Handicap Facilities</Col>
                            <Col>
                                <div
                                    value={this.state.isHandicapBus}
                                    className={
                                        this.state.isHandicapBus === "1"
                                            ? "handicapIcon genIconPressed "
                                            : "handicapIcon "
                                    }
                                    onClick={this.selectHandicapYes}
                                >
                                    <FontAwesomeIcon icon={faWheelchair}/>
                                </div>
                            </Col>
                        </Col>
                    </Row>

          {/* <MultiplyCalendar /> */}
          <MultiplyCalendar
          costumeEndDateState={this.state.costumeEndDateState}
          costumeDateState={this.state.costumeDateState}
          // startDateCostume={this.props.currentEvent.date}
            comesFromBanner={this.props.comesFromBanner}
            dealsDateList={this.props.dealsDateList}
            updateDeal={true}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            doneCallback={this.doneCallback}
          />

          <Col className="ml-3 mt-0 pt-0">
            <Input
              className="invisible"
              valid={this.state.isValidDate}
              invalid={this.state.isValidDate}
            />
            <FormFeedback>
              Start date and time must be smaller than end date and time
            </FormFeedback>
          </Col>
          <Col className="m-3">
            {this.state.text !== "" ? <Label>{this.state.text}</Label> : null}
          </Col>
          <Col className="px-3">
            <FormGroup>
              <Label className="px-3" for="description">
                Description*
              </Label>
              <Col lg="12" className="">
                <Input
                  id="description"
                  type="textarea"
                  placeholder="Deal description..."
                  value={this.state.description}
                  onChange={this.changeDescription}
                  valid={this.state.isValidDescription}
                  invalid={this.state.isValidDescription}
                />
                <FormFeedback>
                                    This field is required and must not contain forbidden words
                </FormFeedback>
              </Col>
            </FormGroup>
          </Col>
          <Row className="p-0 m-0">
            <Col lg="4" className="pl-3">
                            <FormGroup>
                                <Row>
                                    <Label for="price">Price*</Label>
                                    <div className="MakeTooltip">
                                        {!this.state.isStripeConnected ? this.renderTooltipStripe() : null}

                                    </div>
                                </Row>
                                <Col lg="12" className="input-group">
                                  {this.props.comesFromBanner === true ? 
                                  <Input
                                  id="price"
                                  // disabled={this.props.comesFromBanner === true ? true: false}
                                  disabled={true }
                                  value={this.state.price || ""}
                                  onChange={this.onPriceChange}
              type="number"
              invalid={this.state.isValidPrice}
            />
            :
            <Input
            id="price"
            // disabled={this.props.comesFromBanner === true ? true: false}
            disabled={!this.state.isStripeConnected }
            value={this.state.price || ""}
            onChange={this.onPriceChange}
type="number"
invalid={this.state.isValidPrice}
/>  
                                }
                                   
                  <InputGroupAddon
                    addonType="append"
                    className="backgrounButton-price-sign"
                  >
                    <InputGroupText className="price-sign">
                      <InputGroupAddon
                        addonType="append"
                        className="backgrounButton"
                      >
                        <InputGroupText>
                          <Input
                          disabled={this.props.comesFromBanner === true ? true : false }
                            type="select"
                            value={this.state.selectedPriceType}
                            className="inputDrop"
                            onChange={this.onChangeCurrency}
                          >
                            {this.state.priceType &&
                              this.state.priceType.map((option) => (
                                <option key={option.name} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                          </Input>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback>This field is required</FormFeedback>
                </Col>
              </FormGroup>
            </Col>
            <Col lg="4" className="pl-3">
              <FormGroup>
                <Label className="pl-3" for="price">
                  Number of people*
                </Label>
                <Col lg="12" className="input-group">
                  <Input
                    id="price"
                    value={this.state.numberOf || ""}
                    onChange={this.onNumberOfChange}
                    type="number"
                    disabled={this.props.comesFromBanner === true ? true: false}
                    invalid={  this.state.isValidNumberOf}
                  />
                  <InputGroupAddon
                    addonType="append"
                    className="backgrounButton"
                  >
                    <InputGroupText>
                      <FontAwesomeIcon icon={faUsers} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback>This field is required</FormFeedback>
                </Col>
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label for="nOf">Indicative price*</Label>
                <Col lg="12" className="p-0 m-0 input-group w-100">
                  {/* <select
                  value={this.state.selectedPriceType}
                  className="inputDrop"
                  onChange={(e) => {
                    this.setState({ selectedPriceType: e.target.value });
                  }}
                >
                  {this.state.priceType &&
                    this.state.priceType.map((option) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                </select>

                <InputGroupAddon addonType="append" className="backgrounButton">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faUserTag} />
                  </InputGroupText>
                </InputGroupAddon> */}
                  <Input
                  disabled={this.props.comesFromBanner === true ? true : false}
                    value={this.state.indicativePrice}
                    className="inputDrop"
                    onChange={this.onIndicativeChange}
                  ></Input>
                </Col>
              </FormGroup>
            </Col>
           
            {this.state.priceIsTooBig ? (
              <Col>
                <Input
                  className="invisible"
                  invalid={this.state.priceIsTooBig}
                />
                <FormFeedback>
                  Maximum amount payable for one Deal or one Ticket is
                  999,999.99
                </FormFeedback>
              </Col>
            ) : null}
          </Row>

          <Row className="justify-content-end py-4 borderTop">


            <Col lg="6" className="d-flex justify-content-end">
              {this.props.comesFromBanner ? (
                <Button
                  onClick={this.UpdateDeal}
                  className="greenBtnEvt"
                >
                  Update
                </Button>
              ) : (
                <Button onClick={this.makeEventHandler} className="greenBtnEvt">
                  Done
                </Button>
              ) 
              }
            </Col>
          </Row>

        </Container>
      </LoadingOverlay>
    );
  }
}
