import React from "react";
import {
    Button,
    Input,
    Container,
    Col,
    Row,
    PopoverBody,
    FormGroup,
    Label,
    UncontrolledPopover,
    FormFeedback,
    ModalBody,
    Modal,
    ModalHeader,
    InputGroupAddon,
    InputGroupText,
    Tooltip
} from "reactstrap";
import {fetchApi} from "../../../fetchApi";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import ReactFileReader from "react-file-reader";
import "./VoucherForm.scss";
import moment from "moment";
import {userCredentials} from "../../../globalVariables";
import swal from "sweetalert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCreditCard,
    faLock,
    faExclamationTriangle,
    faCheck, faPoundSign, faEuroSign, faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import AddCard from "../../BothUsers/CardsManagement/AddCard";
import ReactDOM from "react-dom";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import SelectLocation from "../../ZingMap/SelectLocation/SelectLocation";

let SLogo = require("../../../Assets/img/SLogo.png");
let profilePic = require("../../../Assets/img/profilePic.jpg");

export default class VoucherForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().format("YYYY-MM-DD"),
            categoryName: "",
            dropdownOpenCategory: false,
            dropdownOpenCountry: false,
            openDialog: false,
            appImage: null,
            serverImage: "",
            companyName: "",
            categoryList: [],
            categoryId: null,
            nOfVouchers: "",
            price: "",
            conditions: "",
            popover: false,
            eventsList: [],
            eventId: "",
            zingMapList: [],
            videoUrl: "",
            titleVoucher: "",
            address: "",
            latitude: "",
            longitude: "",
            showSelectCard: false,
            cardsList: [],
            showAddCard: false,
            userDeactivated: false,
            toolTipVoucher:false,
            isValidTitle:false,
            categories: [],
            cardFinalPrice: "",
            priceType: [
                {name: "RON"},
                {name: "USD"},
                {name: "EUR"},
                {name: "GBP"},
            ],
            selectedPriceType: "RON",
            countryId: 0,
            countryList: [],
            country: "",
            cityLocation: "",
            selectedZone: "",
            commissions: this.getCommissions(),
            finalPrice: 0,
            showTerms: false,
            voucherId:'',
            finalPriceToPay:''
        };
    }

    onChangeIndicativePrice = (evt) => {
        this.setState({selectedPriceType: evt.target.value});
    };

    showSelectCard = () => {
        this.setState({showSelectCard: true});
    };
    toggleSelectCard = () => {
        this.setState({showSelectCard: false});
    };
    showAddCard = () => {
        if (!this.state.userDeactivated) {
            this.setState({showAddCard: true});
        } else {
            swal("Error!", "Invalid operation!", "error");
        }
    };
    toggleAddCard = (needRefresh, cardId) => {
        this.setState({showAddCard: false});
        if (needRefresh === true) {

            this.pay(cardId);

        }
    };

    getUserCards = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofcardsperuser";
        const data = new FormData();
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({cardsList: response.cards});
        } else {
            this.setState({userDeactivated: true});
        }
    };

    onChangeVideoUrl = (evt) => {
        this.setState({videoUrl: evt.target.value});
    };
    onChangeTitle = (evt) => {
        this.setState({titleVoucher: evt.target.value});
    };

    componentDidMount = () => {
        this.getUserCards();
        this.getEvents();
        this.fetchCountry();
        this.getCommissions();






        // this.state.latitude);
        // this.state.longitude);


        if(this.props.currentVoucher && this.props.updateVoucher){
            let countrynm = ""

            if(this.props.currentVoucher.address !== "" && this.props.currentVoucher.address !== null){
                countrynm = this.props.currentVoucher.address.split(",")
                this.setState({countryName:  countrynm[1],
                    cityLocation: countrynm[0],})
            }else{
                this.setState({countryName:'All countries'})
            }

            this.setState({
                voucherId:this.props.currentVoucher.id,
                titleVoucher:this.props.currentVoucher.title,
                
                nOfVouchers:this.props.currentVoucher.left_vouchers,
                conditions:this.props.currentVoucher.promo_description,
                appImage:this.props.currentVoucher.voucher_image,
                videoUrl:this.props.currentVoucher.video,
                country:this.props.currentVoucher.country,
                finalPriceToPay:this.props.currentVoucher.total_pay_value,

        longitude: this.props.currentVoucher.longitude,
        latitude: this.props.currentVoucher.latitude,
      });
    } else {
      // this.setState({country:this.props.currentVoucher.country})
    }
  };

  deleteVoucher = async () => {
    if (
      this.props.currentVoucher.left_vouchers ===
      this.props.currentVoucher.vouchers_number
    ) {
      let authKey = userCredentials.authKey;
      let data = new FormData();
      data.append("voucher_id", this.state.voucherId);
      const url = "deletevoucher";

      const response = await fetchApi(url, "POST", data, authKey);
      console.log(response, this.state.voucherId);

      if (response.success) {
        swal({
          title: "Success",
          text: "Voucher deleted successfully!",
          icon: "success",
          buttons: {
            add: {
              text: "Ok",
              value: 1,
            },
          },
        }).then((val) => {
          if (val === 1) {
            this.props.reloadVouchersCallBack();
          } else {
            this.props.reloadVouchersCallBack();
          }
        });
      } else {
        swal("Error!", "Could not delete voucher!", "error");
      }
    } else {
      swal("Info!", "You can't delete this voucher", "info");
    }
  };

    getCommissions = async () => {
        let data = new FormData();
        let url = "getcommissionslist";
        const response = await fetchApi(url, "GET", data);
        let commisionsToUse = new Object();
        if (response.success) {
            // commisionsToUse.stripe_commission = parseFloat(response.commissionslist.stripe_commission) / 100;
            commisionsToUse.zing_commission = parseFloat(response.commissionslist.zing_commission) / 100;
            commisionsToUse.promo_commission = parseFloat(response.commissionslist.Promotion_zing_commission) / 100;
            commisionsToUse.voucher_deal_commission = parseFloat(response.commissionslist.voucher_deal_commission) / 100
        } else {
            this.setState({commissions: null});
        }
        this.setState({commissions: commisionsToUse});
    }


    fetchCountry = async () => {
        const url = "listofcountries";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            let countries = [...[{id: 0, name: "All", code: ""}], ...response.listofcountries]

            this.setState({
                countryList: countries
            });
        }
    };

    getEvents = async () => {
        const url = "listofeventswithmultiprice";
        const response = await fetchApi(url, "POST", null);
        let array = [];
        if (response.success) {
            response.events.forEach((element) => {
                if (element.multiprice.length !== 0) {
                    array.push(element);
                }
            });
            this.setState({eventsList: array});
        }
    };

    // getCategories = async () => {
    //     const url = "get_firstlevelcategoriesandalias";
    //     let authKey = userCredentials.authKey;
    //     let data = new FormData();
    //     data.append("user_id", userCredentials.userId);
    //
    //     const response = await fetchApi(url, "POST", data, authKey);
    //     let list = [],
    //         alias = [],
    //         categs;
    //     if (response.success) {
    //         if (this.props.updateEvent) {
    //             let categoryName = response.list_of_categories.filter(
    //                 (element) =>
    //                     parseInt(element.category.id) ===
    //                     parseInt(this.props.eventToUpdate.category_id)
    //             );
    //
    //             if (categoryName.length !== 0) {
    //                 this.setState({categoryName: categoryName[0].category.name});
    //             }
    //         }
    //
    //         for (let i = 0; i < response.list_of_categories.length; i++) {
    //             categs = response.list_of_categories[i].category;
    //             list.push(categs);
    //             for (let j = 0; j < response.list_of_categories[i].alias.length; j++) {
    //                 alias = response.list_of_categories[i].alias[j];
    //                 if (alias.name_of_subcategory.length !== 0) {
    //                     list.push(alias);
    //                 }
    //             }
    //         }
    //         this.setState({categories: list});
    //     }
    // };

    // onChangeCategory = (evt, value) => {
    //     if (value !== null) {
    //
    //         this.setState({
    //             categoryName: value.en_name,
    //             categoryId: value.id,
    //         });
    //         // this.getAllLocations(value.id);
    //     }
    // };

    handleFiles = (files) => {
        // console.log(files.fileList[0]);
        this.setState({serverImage: files.fileList[0], appImage: files.base64});
    };

    onChangeNumber = (evt) => {
        this.setState({nOfVouchers: evt.target.value});

    };

    onChangePrice = (evt) => {
        this.setState({price: evt.target.value});

    };

    onChangeConditions = (evt) => {
        this.setState({conditions: evt.target.value});
    };

    handleChange(date) {
        this.setState({
            startDate: date,
        });
    }

    // checkPriceValue=()=>{
    //     let priceLei = parseFloat(this.props.minPriceLei)
    //     let priceLire = parseFloat(this.props.minPriceLire)
    //     let priceEuro = parseFloat(this.props.minPriceEuro)
    //     let priceDolar = parseFloat(this.props.minPriceDolar)
    
    //     if( this.props.minPriceDolar !== undefined && 
    //         this.props.minPriceDolar !== null && 
    
    //         this.props.minPriceEuro !== undefined && 
    //         this.props.minPriceEuro !== null && 
    
    //         this.props.minPriceLire !== undefined && 
    //         this.props.minPriceLire !== null && 
    
    //         this.props.minPriceLei !== undefined && 
    //         this.props.minPriceLei !== null 
    //          ){
              
    //   if(this.state.selectedPriceType === "RON"){
    
    //       if(this.state.price >= priceLei){
    //         this.toggle();
    //       }else{
    //           swal("Warning!", "Price per ticket cannot be les then " +  priceLei + " RON" , "warning");
    //       }
    
    //   }else if(this.state.selectedPriceType === "USD"){
    
    //       if(this.state.price >= priceDolar){
    //         this.toggle();
    //       }else{
    //         swal("Warning!", "Price per ticket cannot be les then " +  priceDolar + " Dolar" , "warning");
              
    //       }
    
    //   }else if(this.state.selectedPriceType === "GBP"){
    //      if(this.state.price >= priceLire){
    //         this.toggle();
    //       }else{
    //         swal("Warning!", "Price per ticket cannot be les then " +  priceLire + " Pounds" , "warning");
    
    //       }
    //   }else if(this.state.selectedPriceType === "EUR"){
    //      if(this.state.price >= priceEuro){
    //         this.toggle();
    //       }else{
    //         swal("Warning!", "Price per ticket cannot be les then " +  priceEuro + " Euro" , "warning");
    
    //       }
    //   }
      
    // }else{
    //     swal("Warning!", "Something went wrong, please contact administrator", "warning");
    // }
    // }

    toggle = () => {
        if (this.state.country === "") {
            this.setState({isValidLocation: true});
        } else {
            this.setState({isValidLocation: false});
        }
        if (this.state.titleVoucher === "") {
            this.setState({isValidTitle: true});
        } else {
            this.setState({isValidTitle: false});
        }

        // if (this.state.categoryName === "") {
        //     this.setState({isValidCategory: true});
        // } else {
        //     this.setState({isValidCategory: false});
        // }
        if (this.state.nOfVouchers === "") {
            this.setState({isValidNof: true});
        } else {
            this.setState({isValidNof: false});
        }
        if (this.state.price === "") {
            this.setState({isValidPrice: true});
        } else {
            this.setState({isValidPrice: false});
        }
        if (
            this.state.nOfVouchers !== "" &&
            this.state.price !== "" &&
            this.state.country !== ""
            // && this.state.categoryName !== ""
        )
            this.setState({popover: !this.state.popover});
    };


    getCardFirst() {
        let card = this.state.cardsList;
        let finalPrice = (this.state.price * this.state.nOfVouchers * this.state.commissions.voucher_deal_commission);

        if (this.state.selectedPriceType === "RON" && finalPrice < 2.5) {

            finalPrice = 2.5;
        } else if (this.state.selectedPriceType === "USD" && finalPrice < 0.5) {
            finalPrice = 0.5;

        }

        this.setState({cardFinalPrice: finalPrice, finalPrice: finalPrice});


        const moneySign = (
            <div>
                {this.state.selectedPriceType.toLowerCase().includes("gbp") ? <FontAwesomeIcon icon={faPoundSign}/> :
                    this.state.selectedPriceType.toLowerCase().includes("lira") ?
                        <FontAwesomeIcon icon={faPoundSign}/> :
                        this.state.selectedPriceType.toLowerCase().includes("eur") ?
                            <FontAwesomeIcon icon={faEuroSign}/> :
                            this.state.selectedPriceType.toLowerCase().includes("usd") ?
                                <FontAwesomeIcon icon={faDollarSign}/> :
                                this.state.selectedPriceType.toLowerCase().includes("ron") ?
                                    <div className="RonSign"> RON </div> : (null)}

            </div>
        );

        let contentObj = (<div className="swalElement">Total amount to pay:&nbsp; {moneySign} {finalPrice}  </div>);
        let wrapper = document.createElement('div');
        ReactDOM.render(contentObj, wrapper);
        let element = wrapper.firstChild;
        this.toggle();
        if (card && card.length > 0) {
            swal({
                title: "Do you want to use an existing card?",
                content: element,
                icon: "warning",
                buttons: {
                    add: {
                        text: "Yes",
                        value: 2
                    },
                    select: {
                        text: "No",
                        value: 1
                    }
                }
            }).then(val => {
                if (val === 1) {
                    this.showAddCard();
                } else if (val === 2) {
                    this.showSelectCard();
                }
            });
        } else {
            this.showAddCard();
        }


    }

    pay = (cardId) => {
        this.addVoucher(cardId);
        this.setState({popover: !this.state.popover});
    };
    // editvoucher



updateVoucher = async (cardId) => {
    let authKey = userCredentials.authKey;

    let finalPrice = (this.state.price * this.state.nOfVouchers * this.state.commissions.voucher_deal_commission);

    if (this.state.selectedPriceType === "RON" && finalPrice < 2.5) {
        finalPrice = 2.5;
    } else if (this.state.selectedPriceType === "USD" && finalPrice < 0.5) {
        finalPrice = 0.5;
    }

    let data = new FormData();
    let card = this.state.cardsList.filter(
        (element) => element.default === true
    );

    // data.append("country", this.state.countryId);

    // if (this.state.countryId === 0) {
    //     data.append("address", "");
    //     data.append("latitude", this.props.currentVoucher.longitude);
    //     data.append("longitude",this.props.currentVoucher.latitude);

    // } else {
    //     data.append("address", this.props.currentVoucher.address);
    //     data.append("latitude",  this.props.currentVoucher.longitude);
    //     data.append("longitude", this.props.currentVoucher.latitude);
    // }

    // data.append("business_id", userCredentials.userId);
    data.append("user_id", userCredentials.userId);
    //data.append("category", this.state.categoryId);
    data.append("title", this.state.titleVoucher);
    data.append("voucher_id",this.state.voucherId )
    data.append("video", this.state.videoUrl);
    data.append("image", this.state.serverImage);
    data.append("promo_description", this.state.conditions);

    // data.append("voucher_value", this.state.price);
    // data.append("total_pay_value", finalPrice);
    // data.append("vouchers_number", this.state.nOfVouchers);
    // data.append("left_vouchers", this.state.nOfVouchers);
    // data.append("event_id", 0);
    // data.append("multiprice_event_id", 0);
    // data.append("deal_id", 0);
    // data.append("deal_calendar_id", 0);
    // data.append("deal_multiprice_id", 0);
    // data.append("tickets_number", 0);
    // data.append("type_transaction", 6);
    // data.append("offert_id", 0);
    // data.append("card_id", 0);
    // data.append("amount", finalPrice);
    // data.append("currency", this.state.selectedPriceType);
    const url = "editvoucher";

    const response = await fetchApi(url, "POST", data, authKey);

    for (let item of data.entries()) {
        console.log(item);
      }
      console.log(response, userCredentials.authKey,userCredentials.userId);
    if (response.success) {
        swal({
            title: "Success",
            text: "Voucher updated successfully!",
            icon: "success",
            buttons: {
                add: {
                    text: "Ok",
                    value: 1
                },
            }
        }).then(val => {
            if (val === 1) {
                this.props.reloadVouchersCallBack();
            } else {
                this.props.reloadVouchersCallBack();
            }
        });


    } else {
        swal("Error!", "Could not update voucher!", "error");
    }
};

    
    addVoucher = async (cardId) => {
        let authKey = userCredentials.authKey;

        let finalPrice = (this.state.price * this.state.nOfVouchers * this.state.commissions.voucher_deal_commission);

        if (this.state.selectedPriceType === "RON" && finalPrice < 2.5) {
            finalPrice = 2.5;
        } else if (this.state.selectedPriceType === "USD" && finalPrice < 0.5) {
            finalPrice = 0.5;
        }

        let data = new FormData();
        let card = this.state.cardsList.filter(
            (element) => element.default === true
        );

        data.append("country", this.state.countryId);

        if (this.state.countryId === 0) {
            data.append("address", "");
            data.append("latitude", "");
            data.append("longitude", "");

        } else {
            data.append("address", this.state.cityLocation ? this.state.cityLocation : this.state.country);
            data.append("latitude", this.state.latitude);
            data.append("longitude", this.state.longitude);
        }

        data.append("business_id", userCredentials.userId);
        data.append("user_id", userCredentials.userId);

        //data.append("category", this.state.categoryId);
        data.append("title", this.state.titleVoucher);
        data.append("voucher_value", this.state.price);
        data.append("total_pay_value", finalPrice);
        data.append("vouchers_number", this.state.nOfVouchers);
        data.append("left_vouchers", this.state.nOfVouchers);
        data.append("image", this.state.serverImage);
        data.append("video", this.state.videoUrl);
        data.append("promo_description", this.state.conditions);
        data.append("event_id", 0);
        data.append("multiprice_event_id", 0);
        data.append("deal_id", 0);
        data.append("deal_calendar_id", 0);
        data.append("deal_multiprice_id", 0);
        data.append("tickets_number", 0);
        data.append("type_transaction", 6);
        data.append("offert_id", 0);
        data.append("card_id", cardId ? cardId : card[0].id);
        data.append("amount", finalPrice);
        data.append("currency", this.state.selectedPriceType);
        const url = "createvoucher";

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            swal({
                title: "Success",
                text: "Voucher created successfully!",
                icon: "success",
                buttons: {
                    add: {
                        text: "Ok",
                        value: 1
                    },
                }
            }).then(val => {
                if (val === 1) {
                    this.props.reloadVouchersCallBack();
                } else {
                    this.props.reloadVouchersCallBack();
                }
            });


        } else {
            swal("Error!", "Could not add voucher!", "error");
        }
    };


    setDefaultCard = async (cardid) => {
        const oldIndex = this.state.cardsList.findIndex((s) => {
            return s.default == true;
        });

        let authKey = userCredentials.authKey;
        const url = "setdefaultcardperuser";
        const data = new FormData();
        data.append("card_id", cardid);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            const newDefaultIndex = this.state.cardsList.findIndex((s) => {
                return s.id === cardid;
            });

            if (oldIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.default == true ? {...el, default: false} : el
                    ),
                }));
            }
            if (newDefaultIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.id == cardid ? {...el, default: true} : el
                    ),
                }));
            }
            swal("Success!", "Card successfully set as default!", "success");
        } else {
            swal("Error!", "Error on set default card!", "error");
        }
    };

    selectCityLocation = (location, lat, lng) => {
        this.setState({cityLocation: location, latitude: lat, longitude: lng});
    };

    showTermsAndCondition = isTermsWindow => {
        const doesShow = this.state.showTerms;
        this.setState({showTerms: !doesShow});
        this.setState({isTerms: isTermsWindow});
    };
    toggleTooltipVoucher = () => {
        this.setState({toolTipVoucher: !this.state.toolTipVoucher})
    }
      renderToolTipVoucher() {
        return (<div>
            <div className="justify-content-center align-bottom">
                <FontAwesomeIcon className="iconTooltipVoucher" icon={faExclamationTriangle} id="toolTipCategory" color={"#f5b91c"}/>
            </div>
            <Tooltip
                placement="top"
                isOpen={this.state.toolTipVoucher}
                target="toolTipCategory"
                toggle={this.toggleTooltipVoucher}
                hideArrow
            >
                <div className="toolTipGeneral">
                    <h6>
                    New Voucher
                    </h6>
                    <p className="tooltipC" >
                    WHEN YOU APPROVE A VOUCHER FOR AN EVENT USER, THE COMPANY LOGO WILL APPEAR ON THE BENNER OF THAT EVENT
                    </p>
                </div>
            </Tooltip>
        </div>)
    }
    render() {
        const searchCity = {
            types: ["(cities)"],
            componentRestrictions: {country: [this.state.selectedZone]},
        };

        let commission=0.04;
        if (this.state.commissions && this.state.commissions.voucher_deal_commission){
            commission=this.state.commissions.voucher_deal_commission;
        }

        let finalPrice = this.state.price * this.state.nOfVouchers * commission;

        if (this.state.selectedPriceType === "RON" && finalPrice < 2.5) {
            finalPrice = 2.5;
        } else if (this.state.selectedPriceType === "USD" && finalPrice < 0.5) {
            finalPrice = 0.5;
        }

        finalPrice = parseFloat(finalPrice).toFixed(2)


        return (
            <Container className="d-flex justify-content-center">
                <Col lg="12" className="voucherBG">
                    <Row>
                        <Col lg="6" className="categoryInputV">


                            <FormGroup>
                                {this.renderToolTipVoucher()}
                                <Label for="video" className="">
                                    Title*
                                </Label>
                                <div className="">
                                    <Input
                                        id="title"
                                        type="text"
                                        placeholder="Add a tittle"
                                        value={this.state.titleVoucher}
                                        onChange={this.onChangeTitle}
                                        //disabled={this.state.serverImage === "" ? false : true}
                                    />
                                </div>
                                    <Input
                                        className="invisible"
                                        invalid={this.state.isValidTitle}
                                    />
                                    <FormFeedback>This field is required</FormFeedback>
                            </FormGroup>


                            {/*<FormGroup>*/}
                            {/*    <Label for="combo-box-demo4" className="">*/}
                            {/*        Category**/}
                            {/*    </Label>*/}

                            {/*    <Autocomplete*/}
                            {/*        id="combo-box-demo4"*/}
                            {/*        options={this.state.categories}*/}
                            {/*        getOptionLabel={(option) =>*/}
                            {/*            option.name_of_subcategory*/}
                            {/*                ? "\xa0\xa0\xa0\xa0" + option.name_of_subcategory*/}
                            {/*                : option.name*/}
                            {/*        }*/}
                            {/*        //style={{ width: 300 }}*/}
                            {/*        className="text-white categoryInputB"*/}
                            {/*        onChange={(event, value) =>*/}
                            {/*            this.onChangeCategory(event, value)*/}
                            {/*        }*/}
                            {/*        renderInput={(params) => (*/}
                            {/*            <TextField*/}
                            {/*                {...params}*/}
                            {/*                placeholder="Categories"*/}
                            {/*                variant="outlined"*/}
                            {/*                className="categoryAC placeholder1"*/}
                            {/*            />*/}
                            {/*        )}*/}
                            {/*    />*/}
                            {/*    <Input*/}
                            {/*        className="invisible"*/}
                            {/*        invalid={this.state.isValidCategory}*/}
                            {/*    />*/}
                            {/*    <FormFeedback>This field is required</FormFeedback>*/}
                            {/*</FormGroup>*/}
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6" className="categoryInputV">
                            <FormGroup>
                                <Label for="city" className="">
                                    Country*
                                </Label>

                                <Autocomplete
                                        disabled={this.props.updateVoucher === true ? true : false}
                                    id="combo-box-demo4"
                                    options={this.state.countryList}
                                    getOptionLabel={(option) => option.name}
                                    className="text-white categoryInputB"
                                    onChange={(e, val) => {
                                        this.setState({
                                            country: val && val.en_name ? val.en_name : val && val.name ? val.name : "",
                                            countryId: val ? val.id : -1,
                                            selectedZone: val ? val.code.toLowerCase() : "",
                                            cityLocation: ""
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            value={this.state.country}
                                            variant="outlined"
                                            className="categoryAC placeholder1"
                                            // placeholder={"Country"}
                                            placeholder={
                                                this.props.updateVoucher === true ?
                                                (this.state.countryName) :( "Country")  }
                                        />
                                    )}
                                />
                                <Input
                                    className="invisible"
                                    invalid={this.state.isValidLocation}
                                />
                                <FormFeedback>This field is required</FormFeedback>

                            </FormGroup>
                        </Col>


                        {/*{this.state.selectedZone !== "" && this.state.country !== "" ?*/}
                        <Col lg="6" className="categoryInputV">
                            <FormGroup>
                                <Label for="city" className="">
                                    City
                                </Label>
                                {this.props.updateVoucher !== true? 
                                    <SelectLocation
                                    key={this.state.selectedZone}
                                    cityType={true}
                                    placeHolder={this.state.selectedZone !== "" && this.state.country !== "" ? "Select City" : this.state.countryId===0 ?"All countries" :"Select country first"}
                                    enabledText={this.state.selectedZone !== "" && this.state.country !== ""}
                                    selectCity={this.selectCityLocation}
                                    selectedZone={this.state.selectedZone}
                                    searchOptions={searchCity}
                                />
                                :
                                <SelectLocation
                                disabled={true}
                                    
                                    key={this.state.selectedZone}
                                    cityType={true}
                                    placeHolder={this.state.cityLocation}
                                    // enabledText={this.state.selectedZone !== "" && this.state.country !== ""}
                                    
                                    selectCity={this.selectCityLocation}
                                    selectedZone={this.state.selectedZone}
                                    searchOptions={searchCity}
                                />
                                }
                                
                            </FormGroup>
                        </Col>
                        {/*: null}*/}
                    </Row>

                    <Row className="">
                        <Col lg="6" className="">
                            <FormGroup>
                                <Label for="city" className="">
                                    {this.props.updateVoucher!== true?
                                    "No. of vouchers*" : "No. of vouchers Left"
                                    }
                                    
                                </Label>
                                <Input
                                disabled={this.props.updateVoucher !== false ? true : false}
                                    type="number"
                                    value={this.state.nOfVouchers}
                                    onChange={this.onChangeNumber}
                                    invalid={this.state.isValidNof}
                                />
                                <FormFeedback>This field is required</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg="6" className="">
                            <FormGroup>
                                <Label for="city" className="">
                                    Price*
                                </Label>
                                {this.state.price !== "" ? (
                                    <Label for="city" className="ml-1">
                                        Pay to zing:{" "}
                                        {this.props.updateVoucher !== true ? finalPrice : this.state.finalPriceToPay}
                                    </Label>
                                ) : null}
                                <Col lg="12" className="p-0 m-0 input-group w-100">
                                    <Input
                                        type="number"
                                        value={ this.props.updateVoucher !== true? this.state.price : this.state.finalPriceToPay}
                                        onChange={this.onChangePrice}
                                        invalid={this.state.isValidPrice}
                                    />
                                    <InputGroupAddon
                                        addonType="append"
                                        className="backgrounButtonVoucher"
                                    >
                                        <InputGroupText>
                                            <Input
                                            disabled={this.props.updateVoucher === true ? true: false}
                                                type="select"
                                                value={this.state.selectedPriceType}
                                                className="inputDrop"
                                                onChange={this.onChangeIndicativePrice}
                                            >
                                                {this.state.priceType &&
                                                this.state.priceType.map((option) => (
                                                    <option key={option.name} value={option.name}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Col className="selectEventPic-parinte">
                        {this.state.appImage !== null ? (
                            <img src={this.state.appImage} className="selectEventPic"/>
                        ) : null}
                    </Col>

                    <Row className="">
                        <Col lg="6" className="PhotoSection">
                            <ReactFileReader
                                fileTypes="image/*"
                                base64
                                handleFiles={this.handleFiles}
                            >
                                <Col>
                                    <Button
                                        className="btnDone"
                                        disabled={this.state.videoUrl === "" ? false : true}
                                    >
                                        Upload Logo
                                    </Button>
                                </Col>
                            </ReactFileReader>
                        </Col>
                        <Col lg="6" className="">
                            <FormGroup>
                                <Label for="video" className="">
                                    Video Url
                                </Label>
                                <div className="">
                                    <Input
                                        id="video"
                                        type="text"
                                        placeholder="video url..."
                                        value={this.state.videoUrl}
                                        onChange={this.onChangeVideoUrl}
                                        disabled={this.state.serverImage === "" ? false : true}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Col>
                        <FormGroup>
                            <Label for="conditions" className="">
                                Conditions
                            </Label>

                            <Col lg="12" className="">
                                <Input
                                    id="conditios"
                                    type="textarea"
                                    value={this.state.conditions}
                                    onChange={this.onChangeConditions}
                                />
                            </Col>
                        </FormGroup>
                    </Col>

          <Col lg="12" className="d-flex justify-content-end">
            {this.props.updateVoucher !== true ? (
              <Button id="Popover1" className="btnDone" onClick={this.toggle}>
                Done
              </Button>
            ) : (
              <div>
                <Button
                  id="Popover1"
                  className="btnDoneDel"
                  onClick={(e) => {
                    swal({
                      title: "Are you sure you wish to delete this item?",
                      icon: "warning",
                      buttons: {
                        Ok: {
                          text: "Ok",
                          value: true,
                        },
                        Cancel: {
                          text: "Cancel",
                          value: false,
                        },
                      },
                    }).then((val) => {
                      if (val) this.deleteVoucher();
                    });
                  }}
                >
                  Delete
                </Button>
                <Button
                  id="Popover1"
                  className="btnDone"
                  onClick={(e) => {
                    swal({
                      title: "Are you sure you wish to update this item?",
                      icon: "warning",
                      buttons: {
                        Ok: {
                          text: "Ok",
                          value: true,
                        },
                        Cancel: {
                          text: "Cancel",
                          value: false,
                        },
                      },
                    }).then((val) => {
                      if (val) this.updateVoucher();
                    });
                  }}
                //   onClick={this.updateVoucher}
                >
                  Update
                </Button>
              </div>
            )}

                        <UncontrolledPopover
                            placement="bottom"
                            isOpen={this.state.popover}
                            target="Popover1"
                            toggle={this.toggle}
                        >
                            <PopoverBody>
                                <div className="col-lg-12">
                                    TotalValue: {this.state.price * this.state.nOfVouchers}
                                </div>

                                <div className="col-lg-12">
                                    Zing {this.state.commissions ? this.state.commissions.voucher_deal_commission * 100 : 0.4}%:{" "}
                                    {finalPrice}
                                </div>

                                <Row>
                                    <Button onClick={() => this.getCardFirst()} className="btnDonePopover mr-2 w-100">
                                        Pay
                                    </Button>
                                </Row>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </Col>

                </Col>
                <Modal
                    isOpen={this.state.showSelectCard}
                    size="lg"
                    // toggle={this.toggleSelectCard}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            Select card
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleSelectCard}/>
                    </div>
                    <ModalBody>
                        <div className="cardDetailsParent">
                            <Row>
                                <Col>
                                    {this.state.cardsList.length > 0 ? (
                                        this.state.cardsList.map((item) => (
                                            <Row
                                                key={item.id}
                                                className="d-flex align-items-center voucherContainer cardsListContainer"
                                                onClick={() => {
                                                    this.setDefaultCard(item.id);
                                                }}
                                            >
                                                <Col xs="6">
                                                    <div>{item.name}</div>
                                                </Col>
                                                <Col xs="2">
                                                    {item.exp_month}/{item.exp_year}
                                                </Col>
                                                <Col xs="2">{item.last4}</Col>
                                                <Col xs="2">
                                                    {item.default ? (
                                                        <FontAwesomeIcon icon={faCheck} className="mr-2"/>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        ))
                                    ) : (
                                        <div>No cards saved</div>
                                    )}
                                </Col>
                            </Row>

                            <Row className="my-5">
                                <Col lg="6" className="leftPart">
                                    <div>
                                        <Button
                                            className="addCardBtn"
                                            onClick={() => {
                                                this.toggleSelectCard();
                                                this.pay();
                                            }}
                                        >
                                            Pay
                                        </Button>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="text">
                                        <span className="cardImg">
                                            <FontAwesomeIcon icon={faLock} className="mr-2"/>
                                        </span>
                                        Safe and in control
                                    </div>
                                    <div className="text">
                                        <span className="cardImg">
                                            <FontAwesomeIcon icon={faCreditCard} className="mr-2"/>
                                        </span>{" "}
                                        Fast and simple
                                    </div>
                                </Col>
                            </Row>
                            <div className="terms text-center">
                                <a>
                                    I accept Zing Sport &nbsp;
                                    <a
                                        href="javascript:void(0);"
                                        onClick={() => this.showTermsAndCondition(true)}
                                    >
                                        Terms and Conditions
                                    </a>
                                    &nbsp; by adding a card
                                </a>
                            </div>
                            <Modal
                                className="costume-modal-prof"
                                isOpen={this.state.showTerms}
                                // toggle={() => this.showTermsAndCondition(false)}
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1"/>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={() => this.showTermsAndCondition(false)}
                                    />
                                </div>
                                <ModalBody>
                                    <TermsAndConditions
                                        isTerms={this.state.isTerms}
                                    ></TermsAndConditions>
                                </ModalBody>
                            </Modal>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.showAddCard}
                    size="lg"
                    // toggle={this.toggleAddCard}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">Add card</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleAddCard}/>
                    </div>
                    <ModalBody>
                        <AddCard handleCancel={this.toggleAddCard}></AddCard>
                    </ModalBody>
                </Modal>
            </Container>
        );
    }
}
