import React, {Component} from 'react'
import {render} from 'react-dom'
import Styles from './Styles'
import {Form, Field} from 'react-final-form'
import Card from './Card'
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate
} from './cardUtils'

import {fetchApi} from "../../../fetchApi.js";
import {userCredentials} from "../../../globalVariables";
import swal from "sweetalert";
import "./AddCard.scss";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


export default class AddCard extends Component {
    constructor(props) {
        super(props);
        this.state = {cardsList: []}
    }

  componentDidMount() {
    this.getUserCards();
  }

  veryfyenteries = (values) => {
    if (values.number && values.expiry && values.cvc) {
      if (values.number.length !== 19) {
        swal("Error!", "Card number must have 16 digits", "error");
        return false;
      } else if (values.expiry.length !== 5) {
        swal("Error!", "Please add a valid expire date", "error");
        return false;
      } else if (values.cvc.length === "") {
        swal("Error!", "Please add a valid cvc", "error");
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  onSubmit = async (values) => {
    await sleep(300);

    if (this.veryfyenteries(values)) {
      let authKey = userCredentials.authKey;
      const url = "createcard";
      const data = new FormData();
      data.append("exp_month", values.expiry.split("/")[0]);
      data.append("exp_year", values.expiry.split("/")[1]);
      data.append("number", values.number);
      data.append("cvc", values.cvc);
      data.append("name", values.name);
      if (this.props.saveCard) {
        data.append("isSaved", 1);
      } else {
        data.append("isSaved", 2);
      }

      const response = await fetchApi(url, "POST", data, authKey);

      if (response.success) {
        if (this.props.saveCard) {
          swal({
            title: "Card successfully saved!",
            icon: "success",
            buttons: {
              Ok: {
                text: "Ok",
                value: 1,
              },
            },
          }).then((val) => {
            if (val === 1) {
              if (this.state.cardsList && this.state.cardsList.length === 0) {
                this.setDefaultCard(response.card.id);
              } else {
                this.props.handleCancel(true);
              }
            }
          });
        } else {
          this.props.handleCancel(true, response.card.id);
        }
      } else if (this.props.saveCard) {
        swal("Error!", "Error on save card!", "error");
        this.props.handleCancel(false);
      } else {
        swal("Error!", "Error on use card!", "error");
      }
    }
  };

    getUserCards = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofcardsperuser";
        const data = new FormData();
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.setState({cardsList: response.cards});
        } else {
            this.setState({userDeactivated: true});
        }
    };

    setDefaultCard = async (cardid) => {
        const oldIndex = this.state.cardsList.findIndex((s) => {
            return s.default == true;
        });



        let authKey = userCredentials.authKey;
        const url = "setdefaultcardperuser";
        const data = new FormData();
        data.append("card_id", cardid);
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            const newDefaultIndex = this.state.cardsList.findIndex((s) => {
                return s.id === cardid;
            });

            if (oldIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.default == true ? {...el, default: false} : el
                    ),
                }));
            }
            if (newDefaultIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.id == cardid ? {...el, default: true} : el
                    ),
                }));
            }
            swal("Success!", "Card successfully set as default!", "success");
            this.props.handleCancel(true);
        } else {
            swal("Error!", "Error on set default card!", "error");
        }
    };

    render() {
        return (
            <Styles>
                <Form
                    onSubmit={this.onSubmit}
                    render={({
                                 handleSubmit,
                                 form,
                                 submitting,
                                 pristine,
                                 values,
                                 active
                             }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Card
                                    number={values.number || ''}
                                    name={values.name || ''}
                                    expiry={values.expiry || ''}
                                    cvc={values.cvc || ''}
                                    focused={active}
                                />
                                <div>
                                    <Field
                                    id={"inputID"}
                                        name="number"
                                        component="input"
                                        type="text"
                                        pattern="[\d| ]{16,22}"
                                        placeholder="Card Number"
                                        format={formatCreditCardNumber}
                                    />
                                </div>
                                <div>
                                    <Field
                                    id={"inputID"}
                                        name="name"
                                        component="input"
                                        type="text"
                                        pattern="[A-Za-z ]{3,}"
                                        placeholder="Name"
                                    />
                                </div>
                                <div>
                                    <Field
                                    id={"inputID"}
                                        name="expiry"
                                        component="input"
                                        type="text"
                                        pattern="\d\d/\d\d"
                                        placeholder="Valid Thru"
                                        format={formatExpirationDate}
                                        className="cardHalfWidth"
                                    />
                                    <Field
                                    id={"inputID"}
                                        name="cvc"
                                        component="input"
                                        type="text"
                                        pattern="\d{3,4}"
                                        placeholder="CVC"
                                        format={formatCVC}
                                        className="cardHalfWidth"
                                    />
                                </div>
                                <div className="buttons">
                                    <button type="submit" disabled={submitting} className="addCardBtn">
                                        Submit
                                    </button>
                                    <button
                                        type="button"
                                        onClick={form.reset}
                                        disabled={submitting || pristine}
                                        className="addCardBtn"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </form>
                        )
                    }}
                />
            </Styles>
        );
    }
}


