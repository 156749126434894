import React from "react";
import {
    Navbar,
    NavbarBrand,
    UncontrolledPopover,
    PopoverBody,
    Modal,
    ModalFooter,
    ModalHeader,
    ModalBody,
    Col,
    Media,
} from "reactstrap";
import style from "./NavBarNormal.scss";
import AccountSettings from "../../BothUsers/AccountSettings/AccountSettings";
import MyProfile from "../../BothUsers/MyProfile/MyProfile";
import AddCategory from "../../BothUsers/AddCategory/AddCategory";
import Messages from "../Messages/Messages";
import EventNotification from "../../BothUsers/EventNotifications/EventNotification";
import {slide as Menu} from "react-burger-menu";
import {userCredentials} from "../../../globalVariables";
import {notificationInterval} from "../../../globalVariables";
import AboutUs from "../../FirstPage/AboutUs/AboutUs";
import HowItWorks from "../../FirstPage/HowItWorks/HowItWorks";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent} from "@material-ui/core";
import {fetchApi} from "../../../fetchApi";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import swal from "sweetalert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Route, BrowserRouter as Router, Switch, Link} from 'react-router-dom'
import google from '../../../Assets/img/google.png';
import apple from '../../../Assets/img/appStoreNew.png';
import {
    faPoundSign, faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import ContactUS from "../../FirstPage/Footer/ContactUS";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import queryString from "query-string";

let SLogo = require("../../../Assets/img/SLogo.png");
let logo = require("../../../Assets/img/logo_zing_mic_login.png");
let notification1 = require("../../../Assets/img/notification1.png");
let message1 = require("../../../Assets/img/message1.png");
let manager3 = require("../../../Assets/img/manager3.png");
let download4 = require("../../../Assets/img/download4.png");
let profilePic = require("../../../Assets/img/profilePic.jpg");

export default class NavBarNormal extends React.Component {
    documentStyle = document.documentElement.style;
    initalNavbarBackgroundColor = "rgba(95, 108, 255, 0)";
    scrolledNavbarBackgroundColor = "#ffffff";

    handleScroll = () => {
        if (window.scrollY === 0) {
            this.documentStyle.setProperty(
                "--navbar-background-color",
                this.initalNavbarBackgroundColor
            );
        } else {
            this.documentStyle.setProperty(
                "--navbar-background-color",
                this.scrolledNavbarBackgroundColor
            );
        }
    };

    componentDidMount() {
        this.fromWrapper();
        this.getStoreLinks();
        this.getMainSponsor();
        window.addEventListener("scroll", this.handleScroll);
        document.addEventListener("mousedown", this.handleClickOutside);
        this.getProfile();
        this.getNotificationsList();
        this.getNumberOfMessages();
        this.intervalID = setInterval(() => {
            this.getNewNotificationsNo();
            this.getNumberOfMessages();
        }, notificationInterval.value);
    }

    refreshNumberMessages() {

        this.getNumberOfMessages();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.notificationsNewNo !== this.state.notificationsNewNo) {
            this.getNotificationsList();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    constructor(props) {
        super(props);

        this.dropdown = React.createRef();

        this.toggle = this.toggle.bind(this);
        this.openProfile = this.openProfile.bind(this);
        this.openManager = this.openManager.bind(this);
        this.openCategory = this.openCategory.bind(this);
        this.refreshNumberMessages = this.refreshNumberMessages.bind(this);
        //this.openAccountSettings = this.openAccountSettings.bind(this);
        // this.handleClick = this.handleClick.bind(this);

        this.container = React.createRef();
        this.state = {
            modalMakeEvents: false,
            dropdownOpen: false,
            getLoggedIn: false,
            isToggleOn: false,
            setProfile: false,
            setManager: false,
            userType: "normal",
            setCategory: false,
            setAccountSettings: false,
            popoverNotifications: false,
            eventNotificationList: [],
            popoverMessages: false,
            messagesFromCreatedEvents: 4,
            messagesFromFriends: 3,
            messagesFromOngoingEvents: 1,
            openModal: false,
            messagesF: false,
            messagesO: false,
            messagesC: false,
            messagesFromFriendsList: [
                {id: 0, from: "Rebeca", message: "hello...", date: "2020-02-12"},
                {id: 1, from: "Ana", message: "buna..", date: "2020-02-12"},
                {id: 2, from: "Ion", message: "ok...", date: "2020-02-12"}
            ],
            messagesFromOngoingList: [
                {id: 0, from: "Ruben", message: "where...", date: "2020-02-12"},
                {id: 1, from: "Vlad", message: "how..", date: "2020-02-12"}
            ],
            messagesFromCreatedList: [
                {id: 0, from: "Jhon", message: "ok..", date: "2020-02-12"},
                {id: 1, from: "Tim", message: "idk..", date: "2020-02-12"},
                {id: 2, from: "Steve", message: "something...", date: "2020-02-12"}
            ],
            mainSponsor: "",
            openMenu: false,
            modal: false,
            contentModal: "profile",
            modalTitle: "",
            menuOpen: false,
            isOpen: false,
            modalMessages: false,
            notificationsNewNo: 0,
            numberOfMessages: 0,
            userDeactivated: false,
            modalContactUs: false,
            appStoreLink: "",
            googlePlayLink: "",
            fromWrapper:false,
            refreshChat:false,
            appsAndroidLink:"",
            appsIosLink:"",
        };
    }

    getStoreLinks = async () => {
        const url = "get_hpcomponents";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            this.setState({
                appsAndroidLink: response.googlePlayLink && response.googlePlayLink.length > 0 ? response.googlePlayLink[0].googlePlayLink : "",
                appsIosLink:response.appStoreLink && response.appStoreLink.length > 0 ? response.appStoreLink[0].appStoreLink : "",

                googlePlayLink: response.googlePlayLinkScanner && response.googlePlayLinkScanner.length > 0 ? response.googlePlayLinkScanner[0].googlePlayLinkScanner : "",
                appStoreLink: response.appStoreLinkScanner && response.appStoreLinkScanner.length > 0 ? response.appStoreLinkScanner[0].appStoreLinkScanner : ""
            });
        }
    };

    fromWrapper() {
        const link = queryString.parse(window.location.search);

        if (link) {
            const fromWrapper = link.from;
            if (fromWrapper) {
                this.setState({fromWrapper: false})
            } else {
                this.setState({fromWrapper: true})
            }
        }
    }


    getNotificationsList = async () => {
        const data = new FormData();
        let authKey = userCredentials.authKey;
        const url = "getusernotifications";

        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({eventNotificationList: response.notifications});
        }
    };

    getNewNotificationsNo = async () => {
        const data = new FormData();
        let authKey = userCredentials.authKey;
        const url = "getnewnotificationsnumber";
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({notificationsNewNo: response.number_of_notifications});
        }
    };

    getNumberOfMessages = async () => {
        const data = new FormData();
        const url = "numberofnewmessages";

        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, userCredentials.authKey);

        if (response.success) {
            let friends = response.friends_messages;
            let myEvents = response.events_messages;
            let myOngoings = response.ongoing_messages;
            let total = friends + myEvents + myOngoings;
            this.setState({numberOfMessages: total});


            if (total > 0) {
                this.setState({refreshChat: true})
            } else {
                this.setState({refreshChat: false})
            }
        }
    };


    handleStateChange(state) {
        this.setState({openMenu: state.isOpen});
        if (!state.isOpen) {
            this.openCreateEvents(false)
        }
    }

    handleClickOutside = event => {
        if (
            this.container.current &&
            !this.container.current.contains(event.target)
        ) {
            this.setState({openMenu: false});

        }
    };

    toggleMenu = () => {
        this.setState({openMenu: !this.state.openMenu});
    };

    removeFromFList = id => {
        let newList = this.state.messagesFromFriendsList.filter(
            item => item.id !== id
        );
        this.setState({messagesFromFriendsList: newList});
    };
    removeFromOList = id => {
        let newList = this.state.messagesFromOngoingList.filter(
            item => item.id !== id
        );
        this.setState({messagesFromOngoingList: newList});
    };
    removeFromCList = id => {
        let newList = this.state.messagesFromCreatedList.filter(
            item => item.id !== id
        );
        this.setState({messagesFromCreatedList: newList});
    };

    toggleModalM = () => {
        this.setState({
            openModal: !this.state.openModal
        });
    };

    toggleModalF = () => {
        this.setState({
            openModal: !this.state.openModal,
            messagesF: true,
            messagesO: false,
            messagesC: false
        });
    };

    toggleModalO = () => {
        this.setState({
            openModal: !this.state.openModal,
            messagesF: false,
            messagesO: true,
            messagesC: false
        });
    };

    toggleModalC = () => {
        this.setState({
            openModal: !this.state.openModal,
            messagesF: false,
            messagesO: false,
            messagesC: true
        });
    };

    togglePopoverMessages = () => {
        this.setState({
            popoverMessages: !this.state.popoverMessages
        });
    };

    togglePopoverNotifications = async () => {
        this.setState({
            popoverNotifications: !this.state.popoverNotifications
        });

        const data = new FormData();
        const url = "checknotification";
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, userCredentials.authKey);
        if (response.success) {
            this.setState({notificationsNewNo: 0});
        }

        this.getNotificationsList();
    };

    getMainSponsor = async () => {
        const url = "get_officialpartners";
        const response = await fetchApi(url, "POST", null);

        if (response.success) {
            this.setState({
                mainSponsor: response.list_of_partners[0].logo,
                mainSponsorLink: response.list_of_partners[0].video,
            });
        }
    };

    openCategory(value) {
        this.setState({setCategory: value});
    }

    openManager(value) {
        this.setState({setManager: value});
    }

    openProfile = () => {
        this.setState({
            modal: !this.state.modal,
            contentModal: "profile",
            modalTitle: "My profile"
        });
    };

    openAccountSettings = () => {
        if (!this.state.userDeactivated) {
            this.setState({
                modal: !this.state.modal,
                contentModal: "account",
                modalTitle: "My account"
            });
        } else {
            swal("Error", "Invalid request!", "error");
        }
    };

    toggle(e) {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    showAll = () => {
        this.setState({
            modal: !this.state.modal
        });
        this.props.showFriendsPage();
    };

    closeModal = () => {
        this.setState({
            modal: false
        });
        setTimeout(() => {
            this.getProfile();
        }, 1500);
    };

    getProfile = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "getprofile";
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);


        if (response.success && response.userprofile != null) {

            if (response.userprofile[0]) {
                userCredentials.userLogo = response.userprofile[0].logo;
                this.setState({
                    imagePreviewUrl: response.userprofile[0].logo
                });
            }
        } else {
            this.setState({userDeactivated: true});
        }
    };

    openEvents() {
        this.props.changeTagsEvent();
    }

    openMyEvents() {
        this.props.changeMyEvents();
    }

    openFriends() {
        this.props.changeFriends();
    }

    // openFind() {
    //     this.props.changeFind();
    // }

    openMyTickets() {
        this.props.changeMyTickets();
    }

    openCreateEvents(open) {
        this.props.changeTagCreateEvents(open);
    }

    openHowItWorks() {
        this.toggleHowItWorks();
    }

    openAboutUs() {
        this.toggleAboutUs();
    }

    openContactUs() {
        this.toggleContactUs()
    }

    toggleHowItWorks = () => {
        this.setState({
            modalHowItWorks: !this.state.modalHowItWorks
        });
    };
    toggleContactUs = () => {
        this.setState({
            modalContactUs: !this.state.modalContactUs
        });
    };
    toggleAboutUs = () => {
        this.setState({
            modalAboutUs: !this.state.modalAboutUs
        });
    };
    toggleMessages = () => {

        this.setState({modalMessages: !this.state.modalMessages})
    };

    duplicateUser = async () => {
        let authKey = userCredentials.authKey;
        let url = "duplicateuser";
        const response = await fetchApi(url, "POST", null, authKey);

        if (response.success) {
            if (response.is_activ === true) {
                swal(
                    "Info!",
                    "You already have a business account for this email, we will restore it",
                    "info"
                );
            }
            if (localStorage.getItem("react-cookie-consent-notification")) {
                localStorage.clear();
                localStorage.setItem("react-cookie-consent-notification", true);
            } else {
                localStorage.clear();
            }
            this.props.handleLog(false, null);

            let userInfo = {
                auth_key: response.auth_key,
                user_id: response.user_id,
                user_type: parseInt(response.user_type),
            };

            localStorage.setItem("userIsLogged", "true");
            localStorage.setItem("userCredentials", JSON.stringify(userInfo));
            // userCredentials.authKey = userInfo.auth_key;
            // userCredentials.userId = userInfo.user_id;
            // userCredentials.userType = userInfo.user_type;

            this.props.handleLog("buss", true);
        }
    };

    updateNotificationItem = (notificationId) => {
        const notificationIndex = this.state.eventNotificationList.findIndex(s => {
            return s.notification_id === notificationId;
        });
        const notifications = [...this.state.eventNotificationList];
        notifications[notificationIndex].is_checked = 1;
        let elem = notifications[notificationIndex];
        if (elem) {
            let notType = parseInt(elem.notification_type);
            let entityId = -1;
            switch (notType) {
                case 1:
                    entityId = notifications[notificationIndex].event_id;//event created
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                    break;
                case 2:
                    entityId = notifications[notificationIndex].deal_user_id; //deal created
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 3:
                    entityId = notifications[notificationIndex].event_id;//apply to free event - wait for approve/reject
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                case 4:
                    entityId = notifications[notificationIndex].event_id; //assigned to event with pay
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                case 5:
                    entityId = notifications[notificationIndex].event_id; //approve la case 3
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                case 6:
                    entityId = notifications[notificationIndex].event_id; //reject la case 3
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                case 7:
                    entityId = notifications[notificationIndex].deal_user_id; //apply free deal
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 8:
                    entityId = notifications[notificationIndex].deal_user_id; //apply deal with pay
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 9:
                    entityId = notifications[notificationIndex].deal_user_id; //approve reject deal
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 10:
                    entityId = notifications[notificationIndex].deal_user_id; //approve reject deal
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 11:
                    entityId = notifications[notificationIndex].fub_user_id; //follow user
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openUserById(entityId);
                    break;
                case 12:
                    entityId = notifications[notificationIndex].event_id;//create voucher
                    swal("Info", "You can apply to vouchers from your event.", "info");
                    break;
                case 13:
                    entityId = notifications[notificationIndex].event_id;//approve voucher la case 12
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                    break;
                case 14:
                    entityId = notifications[notificationIndex].event_id;//reject voucher la case 12
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                    break;
                case 15:
                    entityId = notifications[notificationIndex].event_id;//apply voucher
                    this.setState({eventNotificationList: notifications});
                    this.togglePopoverNotifications();
                    this.props.openEventById(entityId);
                    break;
            }
            //let eventId = notifications[notificationIndex].event_id;

        }


    }
    resetPage = () => {
        window.location.reload();
        this.openEvents()
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div ref={this.container}>
                <Dialog
                    open={this.state.modalHowItWorks ? this.state.modalHowItWorks : false}
                    fullWidth
                    maxWidth={"xl"}
                    // maxWidth={}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            {this.state.modalTitle}
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleHowItWorks}/>
                    </div>
                    <DialogContent>
                        <div className="modal_how_it_works">
                            <ErrorBoundary>
                                <HowItWorks/>
                            </ErrorBoundary>
                        </div>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.modalAboutUs ? this.state.modalAboutUs : false}
                    fullWidth
                    maxWidth={"xl"}

                    // maxWidth={}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            {this.state.modalTitle}
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleAboutUs}/>
                    </div>
                    <DialogContent className="aboutusClass">
                        <div className="modal_AboutUs">
                            <ErrorBoundary>
                                <AboutUs/>
                            </ErrorBoundary>
                        </div>
                    </DialogContent>
                </Dialog>


                <Modal
                    isOpen={this.state.modalContactUs}
                    size="lg"
                    // toggle={()=>this.toggleContactUs()}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1 text-white">
                            Contact us
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.toggleContactUs()}
                        />
                    </div>
                    <ModalBody className="modalFooter">
                        <ContactUS/>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.modal}
                    // toggle={this.toggleModal}
                    size="lg"
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            {this.state.modalTitle}
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleModal}/>
                    </div>
                    <ModalBody>
                        <ErrorBoundary>
                            {this.state.contentModal === "profile" ? (
                                <MyProfile
                                    userId={userCredentials.userId}
                                    closeModal={this.closeModal}
                                    showModificationsOptions={true}
                                    showAll={this.showAll}
                                />
                            ) : (
                                <AccountSettings
                                    handleInput={this.props.handleInput}
                                    handleLogOut={this.props.handleLog}
                                    // console.log(this.props.handleInput,"heoolondkjhsags")
                                />
                            )}
                            {/* <MyProfile closeModal={this.toggleModalMyProfile} /> */}
                        </ErrorBoundary>
                    </ModalBody>
                </Modal>
                {/* <Navbar className="Navbar navbar-dark navbar-custom fixed-top"> */}
                <Navbar className="Navbar-normal navbar-dark navbar-custom fixed-top">
                    <NavbarBrand className="logo-flex">
                        <img className="logo loginLogo" src={logo} alt="logo" onClick={() => this.resetPage()}/>
                    </NavbarBrand>
                    <Col className="d-flex justify-content-true sponsorTop">
                        {this.state.mainSponsorLink ? (
                            <a href={this.state.mainSponsorLink} target="_blank">
                                <img src={this.state.mainSponsor}/>
                            </a>
                        ) : (
                            <img src={this.state.mainSponsor}/>
                        )}
                    </Col>
                    {/* <div>
            <img src={this.state.mainSponsor} className="sponsor" alt="img" />
          </div> */}

                    <div className="d-flex d-flex-costume-norm mobileMen">
                        <div href="#" className="navbar-div logo-image">
                            <NavbarBrand onClick={() => this.openCategory(true)}>
                                {" "}
                                <img className="nav-icons" src={download4} alt="logo"/>
                            </NavbarBrand>
                            <Modal
                                className="costume-modal-prof"
                                isOpen={this.state.setCategory}
                                // toggle={() => this.openCategory(false)}
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1 text-white">
                                        Add category
                                    </ModalHeader>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={() => this.openCategory(false)}
                                    />
                                </div>
                                <ErrorBoundary>
                                    <AddCategory/>
                                </ErrorBoundary>
                                <ModalFooter className=" categ-save-btn "></ModalFooter>
                            </Modal>
                            <NavbarBrand
                                onClick={() => {
                                    if (this.state.userType === "buss") {
                                        this.openManager(true);
                                    }
                                }}
                                id={this.state.userType === "normal" ? "PopoverLegacy" : ""}
                            >
                                {" "}
                                <img className="nav-icons" src={manager3} alt="logo"/>{" "}
                            </NavbarBrand>
                            {this.state.userType === "normal" ? (
                                <UncontrolledPopover
                                    trigger="legacy"
                                    placement="bottom"
                                    target="PopoverLegacy"
                                    className="ManagerErrParent"
                                >
                                    <PopoverBody className="ManagerErr">
                                        My Manager is available just for <b>Business Account</b>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            ) : (
                                <Modal
                                    className="costume-modal-prof"
                                    isOpen={this.state.setManager}
                                >
                                    <div className="saveClose-Parent">
                                        <a
                                            className="saveClose-cld"
                                            autoFocus
                                            color="primary"
                                            onClick={() => this.openManager(false)}
                                        >
                                            {" "}
                                            X{" "}
                                        </a>
                                    </div>
                                </Modal>
                            )}
                            <NavbarBrand>
                                <div className="notificationsContainer">
                                    {" "}
                                    <img
                                        className="nav-icons"
                                        src={message1}
                                        alt="logo"
                                        id="messages"
                                        onClick={this.toggleMessages}
                                    />
                                    {this.state.numberOfMessages > 0 ? (
                                        <div className="numberCircle">
                                            <div className="onlyNumber">
                                                {this.state.numberOfMessages}
                                            </div>
                                        </div>
                                    ) : null}{" "}
                                </div>
                            </NavbarBrand>
                            <Modal
                                isOpen={this.state.modalMessages}
                                // toggle={this.toggleMessages}
                                size="lg"
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1  text-white">
                                        Inbox
                                    </ModalHeader>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={this.toggleMessages}
                                    />
                                </div>
                                <ModalBody>
                                    <ErrorBoundary>
                                        <Messages refreshNumber={this.refreshNumberMessages} needToRefreshChat={this.state.refreshChat}/>
                                    </ErrorBoundary>
                                </ModalBody>
                            </Modal>

                            {/*<UncontrolledPopover*/}
                            {/*  trigger="legacy"*/}
                            {/*  placement="bottom"*/}
                            {/*  target="messages"*/}
                            {/*  isOpen={this.state.popoverMessages}*/}
                            {/*  className="popoverNotifications"*/}
                            {/*  toggle={this.togglePopoverMessages}*/}
                            {/*  //onClick={this.togglePopoverMessages}*/}
                            {/*>*/}
                            {/*  <PopoverBody>*/}
                            {/*    <MessagesNotifications />*/}
                            {/*  </PopoverBody>*/}
                            {/*</UncontrolledPopover>*/}
                            <Modal
                                isOpen={this.state.openModal}
                                // toggle={this.toggleModalM}
                                centered={true}
                                size="lg"
                            >
                                <ModalHeader toggle={this.toggleModalM}>Messages</ModalHeader>
                                <ModalBody>
                                    {/*{this.state.messagesF ? (*/}
                                    {/*  <div>*/}
                                    {/*    <Messages*/}
                                    {/*      // messageList={this.state.messagesFromFriendsList}*/}
                                    {/*      // removeFromList={this.removeFromFList}*/}
                                    {/*    />*/}
                                    {/*  </div>*/}
                                    {/*) : null}*/}
                                    {/*{this.state.messagesO ? (*/}
                                    {/*  <div>*/}
                                    {/*    <Messages*/}
                                    {/*      // messageList={this.state.messagesFromOngoingList}*/}
                                    {/*      // removeFromList={this.removeFromOList}*/}
                                    {/*    />*/}
                                    {/*  </div>*/}
                                    {/*) : null}*/}
                                    {/*{this.state.messagesC ? (*/}
                                    {/*  <div>*/}
                                    {/*    <Messages*/}
                                    {/*      // messageList={this.state.messagesFromCreatedList}*/}
                                    {/*      // removeFromList={this.removeFromCList}*/}
                                    {/*    />*/}
                                    {/*  </div>*/}
                                    {/*) : null}*/}
                                </ModalBody>
                            </Modal>
                            <NavbarBrand onClick={this.togglePopoverNotifications}>
                                {" "}
                                <div className="notificationsContainer">
                                    <img
                                        className="nav-icons"
                                        src={notification1}
                                        alt="logo"
                                        id="notification"
                                    />
                                    {this.state.notificationsNewNo > 0 ? (
                                        <div className="numberCircle">
                                            <div className="onlyNumber">
                                                {this.state.notificationsNewNo}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                {" "}
                            </NavbarBrand>
                            <UncontrolledPopover
                                trigger="legacy"
                                placement="bottom"
                                target="notification"
                                isOpen={this.state.popoverNotifications}
                                className="popoverNotifications"
                                toggle={this.togglePopoverNotifications}
                            >
                                <PopoverBody>
                                    <ErrorBoundary>


                                        {this.state.eventNotificationList.length > 0 ?
                                            <div>
                                                {this.state.eventNotificationList.map((item) => (
                                                    <EventNotification
                                                        name={item.name}
                                                        category={item.category_name}
                                                        key={item.notification_id}
                                                        is_checked={item.is_checked}
                                                        logo={item.logo}
                                                        notificationId={item.notification_id}
                                                        updateNotificationState={this.updateNotificationItem}
                                                        readit={item.is_checked == "1"}
                                                        description={item.description}
                                                    />
                                                ))}  </div> : <div>No Notification</div>
                                        }

                                    </ErrorBoundary>
                                </PopoverBody>
                            </UncontrolledPopover>
                            <NavbarBrand onClick={this.openProfile}>
                                {/* <img className="nav-icons" src={icons8} alt="logo" />{" "} */}

                                <div className="profile-img-navbar">
                                    <img
                                        className="image-nav"
                                        src={
                                            this.state.imagePreviewUrl !== undefined &&
                                            this.state.imagePreviewUrl !== null &&
                                            this.state.imagePreviewUrl !== ""
                                                ? this.state.imagePreviewUrl
                                                : profilePic
                                        }
                                        onError={e => {
                                            e.target.onerror = null;
                                            e.target.src = profilePic;
                                        }}
                                    ></img>
                                </div>
                            </NavbarBrand>
                            <NavbarBrand onClick={this.duplicateUser}>
                                <FontAwesomeIcon icon={faExchangeAlt} className="switchAccount" title={"Swap account"}/>
                            </NavbarBrand>
                        </div>
                        {/* <div ref={this.dropdown}>
             <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                  tag="button"
                  type="button"
                  className="navbar-toggler"
                  caret
                >
                  <img src={rightMenu} width="35" alt="menu" />
                </DropdownToggle>
                <DropdownMenu className="dropdownmenuCustom">
                  <DropdownItem
                    onClick={() => this.openAccountSettings(true)}
                    className="itemCustom"
                  >
                    {" "}
                    Account Settings{" "}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      localStorage.clear();
                      this.props.handleLog(false, null);
                    }}
                    className="itemCustom"
                  >
                    Log Out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>*/}
                    </div>
                    <div ref={this.dropdown} className="">
                        <Menu
                            right
                            className="rightMen fixMenu"
                            noOverlay
                            isOpen={this.state.openMenu}
                            onStateChange={state => this.handleStateChange(state)}
                        >
                            <a className="menu-item" onClick={this.duplicateUser}>
                                Swap account
                            </a>
                            <a className="menu-item" onClick={() => this.toggleModal(true)}>
                                My Profile
                            </a>
                            <a className="menu-item" onClick={this.openAccountSettings}>
                                {" "}
                                Settings
                            </a>
                            <a className="menu-item" onClick={() => this.openEvents()}>
                                Events
                            </a>
                            <a className="menu-item" onClick={() => this.openMyEvents()}>
                                {" "}
                                My Events{" "}
                            </a>
                            <a className="menu-item" onClick={() => this.openFriends()}>
                                {" "}
                                Friends{" "}
                            </a>
                            {/* <a className="menu-item" onClick={() => this.openFind()}>
                                {" "}
                Find Business{" "}
                            </a> */}
                            <a className="menu-item" onClick={() => this.openMyTickets()}>
                                {" "}
                                My Tickets{" "}
                            </a>
                            <a className="menu-item" onClick={() => this.openHowItWorks()}>

                                {/* <a href="/howitworks" className="menu-item " onClick={() => null}> */}
                                How it Works{" "}
                            </a>
                            <a
                                className="menu-item"
                                onClick={() => this.openCreateEvents(true)}
                            >
                                Create Events
                            </a>
                            <a className="menu-item" onClick={() => this.openAboutUs()}>
                                About Us
                            </a>

                            <a className="menu-item" onClick={() => this.openContactUs()}>
                                {/* <a href="/contact"  className="menu-item" onClick={() => null}> */}

                                Contact Us
                            </a>

                            <a
                                className="menu-item"
                                onClick={() => {
                                    if (localStorage.getItem("react-cookie-consent-notification")) {
                                        localStorage.clear();
                                        localStorage.setItem("react-cookie-consent-notification", true);
                                    } else {
                                        localStorage.clear();
                                    }
                                    this.props.handleLog(false, null);
                                }}
                            >
                                Logout
                            </a>
                            {this.state.fromWrapper ?
                                <a href={this.state.appsIosLink} target="_blank" className="menu-item">
                                <Media
                                    className="appBtnScannerMenu"
                                    object
                                    src={apple}
                                    alt="image"
                                />
                            </a>:null}
                            {this.state.fromWrapper ?
                                <a href={this.state.appsAndroidLink} target="_blank" className="menu-item">
                                <Media className="appBtnScannerMenu" object src={google} alt="image"/>
                            </a> : null}
                        </Menu>
                    </div>
                </Navbar>
            </div>
        );
    }
}
