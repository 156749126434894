import React, {Component} from 'react';
import NavBar from "./NavBar/NavBar";
import Header from "./Header/Header";
import HowItWorks from "./HowItWorks/HowItWorks";
import AboutUs from "./AboutUs/AboutUs";
import OurPartners from "./OurPartners/OurPartners";
import PromoBar_v1 from "./PromoBar/PromoBar_v1";
import PromoBar_v2 from "./PromoBar/PromoBar_v2";
import Footer from './Footer/Footer';
import ScrollableAnchor from 'react-scrollable-anchor';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from 'react-loading-overlay';
import './FirstPage.scss';
import CookiesAgreement from '../CookiesAgreement/CookiesAgreement.js'
import {
    Modal,
    Button,
    ModalBody,
    ModalHeader
} from "reactstrap";
import {businessUserId} from "../../globalVariables";
import CookieConsent from 'react-cookie-consent-notification';

let SLogo = require("../../Assets/img/SLogo.png");
export default class FirstPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSignUp: false,
            cookiesModal: false,
            cookieReadMore: false,
            showLogIn: true,
            businessUserId: null,
            isLogged: false

        };
    }

    signUpHandler = () => {

        this.setState({showSignUp: true});
    };

    selectBusinessUser = (userId) => {
        this.setState({showSignUp: true});
        businessUserId.value = userId;

    };

    setFalseSignUp = () => {
        this.setState({showSignUp: false});
    };

    CookieModal() {
        this.setState({
            cookieReadMore: !this.state.cookieReadMore,

        });
    }

    saveCookies = () => {
        this.setState({
            cookiesModal: false,
            cookieReadMore: false
        });
        localStorage.setItem("react-cookie-consent-notification", true);
    };

    componentDidMount = () => {

        var orig = document.body.className;
        setTimeout(() => {
            this.setState({showLogIn: false});
            document.body.className = orig + (orig ? " " : "") + "noOverflow";
        }, 2000);
        
    }


    checkStatus = (status) => {

        if (status) {
            this.saveCookies();
        }
    };

    toogleCookieModal = () => {
        this.setState({cookieReadMore: !this.state.cookieReadMore})
    }

    render() {

        return (
            <LoadingOverlay
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(9, 38, 67)'
                    })
                }}
                active={this.state.showLogIn}
            >
                <div className="noScroll" >
                    <CookieConsent
                        background={'#000'}
                        bottomPosition={true}
                        buttonText={'I agree'}
                        buttonBackground={'#fff'}
                        buttonColor={'#000'}
                        buttonFontSize={16}
                        color={'#fff'}
                        consentFunction={this.checkStatus}
                        padding={20}
                    >
                        This website uses cookies to improve service and for analytical purposes.
                        Please read our <a onClick={this.toogleCookieModal}
                                           style={{color: '#13e567', cursor: 'pointer'}}>Cookie Policy</a>.
                        Confirm your consent to the use of cookies.
                    </CookieConsent>

                    <Modal
                        isOpen={this.state.cookieReadMore}
                        size="lg"
                        className=" modal-body "
                        // toggle={this.toogleCookieModal}
                    >

                        <div className="header-p">
                            <ModalHeader className="head-1"/>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={() => this.toogleCookieModal()}
                            />
                        </div>
                        <ModalBody>
                            <div className=" modal-body-child ">
                                <CookiesAgreement/>
                            </div>
                        </ModalBody>
                    </Modal>

                    <NavBar
                        handleLog={this.props.handler}
                        showSignUp={this.state.showSignUp}
                        setFalseSignUp={this.setFalseSignUp}
                        signUpHandler={this.signUpHandler}

                    />
                    <ScrollableAnchor id={"Search"}>
                        <LoadingOverlay
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: 'rgb(9, 38, 67)'
                                })
                            }}
                            className="MyLoader_wrapper"
                            active={this.state.showLogIn}
                            spinner
                            text="Loading..."
                        >
                            <Header
                                eventHandler={this.props.eventHandler}
                                signUpHandler={this.signUpHandler}
                                handleLog={this.props.handler}
                                showSignUp={this.state.showSignUp}
                                setFalseSignUp={this.setFalseSignUp}
                                selectBusinessUser={this.selectBusinessUser}

                            />
                        </LoadingOverlay>

                    </ScrollableAnchor>

                    <ScrollableAnchor id={"HowItWorks"}>
                        <HowItWorks isLogged={this.state.isLogged}/>
                    </ScrollableAnchor>
                    <ScrollableAnchor id={"CreateEvent"}>
                        <PromoBar_v1

                            eventHandler={this.props.eventHandler}
                            handleLog={this.props.handler}/>
                    </ScrollableAnchor>
                    <ScrollableAnchor id={"AboutUs"}>
                        <AboutUs isLogged={this.state.isLogged}/>
                    </ScrollableAnchor>
                    <ScrollableAnchor id={"OurPartners"}>
                        <OurPartners/>
                    </ScrollableAnchor>
                    <ScrollableAnchor id={"CreateAccount"}>
                        <PromoBar_v2 handleLog={this.props.handler}/>
                    </ScrollableAnchor>
                    <Footer classNamee="FirstPage-Footer"/>
                    <ArrowTop/>
                </div>
            </LoadingOverlay>
        );
    }
}

class ArrowTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth

        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
    };

    render() {
        const {width} = this.state;
        const isMobile = width <= 500;

        return (
            <a href='#Search'>
                <div className={isMobile ? "arrowParentMobile" : "arrowParent"}>
                    <div className="Arr">
                        <FontAwesomeIcon icon={faArrowUp}/>
                    </div>
                </div>
            </a>
        );
    }
}
