import React from "react";
import NavBarBusiness from "./NavBarBusiness/NavBarBusiness";
import HomeScreenBusiness from "./HomeScreenBusiness/HomeScreenBusiness";
import Footer from "../FirstPage/Footer/Footer";
import LoadingOverlay from 'react-loading-overlay';
import "./HPbusiness.scss"
import queryString from "query-string";

export default class HPbusiness extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: null,
            modalMakeEvents: false,
            showEventInfoId: null,
            showDealInfoId: null,
            showUserInfoId: null,
            eventsNumberBus: 0,
            showLogIn: true,
            fromWrapper:false
        };
        this.changeTagEventUser = this.changeTagEventUser.bind(this)
        this.changeTagEvent = this.changeTagEvent.bind(this);
        this.changeTagDeals = this.changeTagDeals.bind(this);
        this.changeTagBookKeeper = this.changeTagBookKeeper.bind(this)
        this.changeTagVouchers = this.changeTagVouchers.bind(this)
        this.handleInputValueBus = this.handleInputValueBus.bind(this)


    }
    fromWrapper() {
        const link = queryString.parse(window.location.search);

        if (link) {
            const fromWrapper = link.from;
            if (fromWrapper) {
                this.setState({fromWrapper: false})
            } else {
                this.setState({fromWrapper: true})
            }
        }
    }
    changeTagEventUser() {
        this.setState({open: 1});
    }

    changeTagEvent() {
        this.setState({open: 2});

    }

    changeTagDeals() {
        this.setState({open: 3});

    }

    changeTagBookKeeper() {
        this.setState({open: 4})

    }

    changeTagVouchers() {
        this.setState({open: 5})
    }

    // changeTagFindPeople = () => {
    //     this.setState({open: 6});

    // };

    changeMyTickets = () => {
        this.setState({open: 7});
    };

    changeTagPromoEvent() {
        this.setState({modalPromoEvent: true});
    }

    componentDidMount() {
        this.fromWrapper();
      

        var orig = document.body.className;
        setTimeout(() => {
            this.setState({showLogIn: false});
            document.body.className = orig + (orig ? " " : "") + "noOverflow";
        }, 2000);

        let getPromo = this.props.promo;
        if (getPromo) {
            this.changeTagPromoEvent();
        }
    }

    openEventById = (eventId) => {
        this.setState({showEventInfoId: eventId});
    };
    openDealById = (dealId) => {
        this.setState({showDealInfoId: dealId});
    };

    openUserById = (userId) => {
        this.setState({showUserInfoId: userId});
    };
    resetShowEventInfoId = () => {
        this.setState({showEventInfoId: null});
    };

    resetShowDealInfoId = () => {
        this.setState({showDealInfoId: null});
    };

    resetShowUserInfoId = () => {
        this.setState({showUserInfoId: null});
    };

    handleInputValueBus(val) {
        this.setState({eventsNumberBus: val});
        // console.log("yess")

    }
    refreshPage() {
        window.location.reload(false);
      }
    render() {
        const style = {
            height: "100%"
        };
        return (
            <div style={style}>
                <LoadingOverlay
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "rgb(9, 38, 67)",
                        }),
                    }}
                    className="MyLoader_wrapper"
                    active={this.state.showLogIn}
                    spinner
                    text="Loading..."
                >
                    <NavBarBusiness
                    fromWrapperProp={this.state.fromWrapper}
                    
                    refreshPage={this.refreshPage}
                        openEventById={this.openEventById}
                        openDealById={this.openDealById}
                        openUserById={this.openUserById}
                        handleLog={this.props.handler}
                        changeTagsEventUser={this.changeTagEventUser}
                        changeTagsEvent={this.changeTagEvent}
                        changeDeals={this.changeTagDeals}
                        changeBookKeeper={this.changeTagBookKeeper}
                        changeVouchers={this.changeTagVouchers}
                        // changeFindPeople={this.changeTagFindPeople}
                        changeMyTickets={this.changeMyTickets}
                        handleInputBus={this.state.eventsNumberBus}
                    />

                    <HomeScreenBusiness
                        selectedEventId={this.props.selectedEventId}
                        handleInputBus={this.handleInputValueBus}
                        openTag={this.state.open}


                        changeTagsEventUser={this.changeTagEventUser}
                        changeTagsEvent={this.changeTagEvent}
                        changeDeals={this.changeTagDeals}
                        changeBookKeeper={this.changeTagBookKeeper}
                        changeVouchers={this.changeTagVouchers}
                        // changeFindPeople={this.changeTagFindPeople}
                        changeMyTickets={this.changeMyTickets}


                        openEventFromPromo={this.state.modalPromoEvent}
                        showEventInfoId={this.state.showEventInfoId}
                        showDealInfoId={this.state.showDealInfoId}
                        showUserInfoId={this.state.showUserInfoId}
                        resetShowEventInfoId={this.resetShowEventInfoId}
                        resetShowDealInfoId={this.resetShowDealInfoId}
                        resetShowUserInfoId={this.resetShowUserInfoId}
                        selectedBusinessId={this.props.userBusinessId}
                    />
                    <Footer
                        classNamee="HPBusiness-Footer"
                    />
                </LoadingOverlay>
            </div>
        );
    }
}
