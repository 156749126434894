import React, {Component} from "react";
import {
    Media,
    Modal,
    Button,
    ModalHeader,
    ModalBody,
    Container,
    Col,
    Row,
    Alert,
    UncontrolledAlert,
    Jumbotron,
    Label,
    FormGroup,
    Input, FormFeedback,
} from "reactstrap";
import FlatList from "flatlist-react";
import "./HomeScreenNormal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
    faCreditCard,
    faLock,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";
import BusinessOffers from "../../FirstPage/Header/BusinessOffers";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions.js";
import Friends from "../../BothUsers/Friends/Friends";
import MakeEvents from "../MakeEvents/MakeEvents";
import ContentEvents from "../../BothUsers/Events/ContentEvents";
import SearchBar from "../../BothUsers/SearchBar/SearchBar.js";
import {fetchApi} from "../../../fetchApi.js";
import {userCredentials} from "../../../globalVariables";

import BannerDetailsNormal from "../BannerDetails/BannerDetailsNormal";
import FindPeople from "../../BothUsers/FindPeople/FindPeople";
import Voucher from "../../BusinessUser/Voucher/Voucher";
import swal from "sweetalert";
import moment from "moment";
import AddCard from "../../BothUsers/CardsManagement/AddCard";
import MyTickets from "../MyTickets/MyTickets.js";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import UserProfile from "../../BothUsers/UserProfile/UserProfile";
import queryString from "query-string";
import {fetchApiLocation} from "../../../fetchApiLocation";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import SelectLocation from "../../ZingMap/SelectLocation/SelectLocation";

let SponsorImage = require("../../../Assets/img/sponsor.jpg");
let backgroundBig = require("../../../Assets/img/image-01.png");
let SLogo = require("../../../Assets/img/SLogo.png");

let countryID = 0;

export default class HomeScreenNormal extends Component {
    constructor(props) {
        super(props);
        this.searchbarRef = React.createRef();
        this.state = {
            items: [],
            actions: [],
            inputValue: "",
            inputValueMyE: "",
            DateValue: "",
            dropDownValue2: "Select action",
            dropdownOpen: false,
            dropdownOpenMyE: false,
            showContent: 1,
            friendsList: [],
            totalFriendsNo: 8,
            filteredList: [],
            filterVal: "",
            eventsList: [],
            modalMakeEvents: false,
            eventsFromUserList: [],
            categories: [],
            startDate: "",
            where: "",
            categoryId: "",
            searchedEvents: [],
            hasSearched: false,
            startDateMyE: "",
            whereMyE: "",
            categoryIdMyE: "",
            searchedEventsMyE: [],
            hasSearchedMyE: false,
            modalShowDetails: false,
            currrentEvent: null,
            currentMultiprice: [],
            eventToUpdate: null,
            updateEvent: false,
            ageCategoryList: [],
            showAlert: false,
            showAlertDelete: false,
            showEvents: false,
            showEventsMyE: false,
            showAlertMyE: false,
            categoryName: "",
            profileImg: null,
            name: "",
            eventLogo: null,
            businessEventList: [],
            offertsList: [],
            showPaymentInfo: false,
            selectedTab: 1,
            allFriends: [],
            firstTime: true,
            showVoucers: false,
            voucherList: [],
            categoryText: "",
            categoryTextMyE: "",
            updateEvents: false,
            updateMyEvents: false,
            openFirstEventId: false,
            userTypeMyE: [],
            showAddCard: false,
            cardsList: [],

      page: 0,
      pageUser: 0,
      hasEvents: false,
      hasEventsUser: false,
      pagedEvents: [],
      pagedEventsUser: [],
      userDeactivated: false,
      handicapBanner: null,
      userProfileModal: false,
      userProfileId: "",
      businessUserId: null,
      openBusinessModal: false,
      searchCheck: false,
      updatePeopleEvent: false,
      countryList: [],
      country: "",
      countryId: "",
      showTerms: false,
      selectedZone: "",
      cityLocation: "",
      a51List: [],
      a52List: [],
      currentEvent: "",
      myBirthday:''
    };
  }

    showTermsAndCondition = isTermsWindow => {
        const doesShow = this.state.showTerms;
        this.setState({showTerms: !doesShow});
        this.setState({isTerms: isTermsWindow});
    };
    getDealById = async (event_id) => {

        const url = "getdealbyid";
        const data = new FormData();
        data.append("deal_id", event_id);
        const response = await fetchApi(url, "POST", data);

        if (response.success) {
            if (response.deal_details && response.deal_details[0]) {
                let event = response.deal_details[0].deal[0];
                this.setState({
                    //profileModal: !this.state.profileModal,
                    currentEvent: event,
                    modalTitle: "Deal details",
                    //currentMultiprice: multiprice,
                    currentCalendar: response.deal_details[0].deal_calendar,
                    curentDealCalendarId: 0,
                    eventLogo: response.deal_details[0].deal[0].logo,
                    modalShowDetails: true,
                });
            }
        }
    };

    toggleUserProfileModal = (userId) => {
        this.setState({
            userProfileModal: !this.state.userProfileModal,
            userProfileId: userId,
        });
        this.props.resetShowUserInfoId();

    };

    changeTag = () => {
        this.props.changeTag();
    };
    changeTagEvent = () => {
        this.props.changeTagEvent();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.businessUserId !== this.props.selectedBusinessId) {
            if (this.props.selectedBusinessId === prevProps.selectedBusinessId) {
                this.setState({
                    businessUserId: this.props.selectedBusinessId,
                    openBusinessModal: true,
                });
            }
        }
        if (prevState.showContent !== this.props.openTag) {
            if (this.props.openTag !== prevProps.openTag) {
                if (this.props.openTag === 1) {

                    this.getEvents();
                    this.setState({showContent: this.props.openTag});
                } else if (this.props.openTag === 2) {
                    this.getMyEvents();
                    this.setState({showContent: this.props.openTag});
                }else if (this.props.openTag === 3) {
                    this.setState({showContent: this.props.openTag});
                }else if (this.props.openTag === 4) {
                    this.setState({showContent: this.props.openTag});
                }else if (this.props.openTag === 5) {
                    this.setState({showContent: this.props.openTag});
                }
            }
        }
        if (prevState.modalMakeEvents !== this.props.openEvent) {
            if (this.props.openEvent !== prevProps.openEvent) {
                this.setState({modalMakeEvents: true});
            }
        }

        if (prevState.modalMakeEvents !== this.props.openEventFromPromo) {
            if (this.props.openEventFromPromo !== prevProps.openEventFromPromo) {
                this.setState({modalMakeEvents: true});
            }
        }


        if (!this.state.openFirstEvent) {
            this.setState({openFirstEvent: true})
            this.openFirstEvent()
        }


        if (prevProps.showEventInfoId !== this.props.showEventInfoId) {
            if (this.props.showEventInfoId) {

                this.getEvents().then(() => {
                    this.getMyEvents().then(() => {
                        let allEvents = this.state.eventsList.concat(
                            this.state.eventsFromUserList
                        );
                        const evtIndex = allEvents.findIndex(s => {
                            return s.event.id === this.props.showEventInfoId;
                        });
                        if (evtIndex !== -1) {
                            this.setState({
                                currentEvent: allEvents[evtIndex].event,
                                currentMultiprice: allEvents[evtIndex].multiprice,
                                eventLogo: allEvents[evtIndex].user_logo
                            });
                            this.setState({modalShowDetails: true});
                            this.props.resetShowEventInfoId();
                        }
                    });
                });
            }
        }

        // if (prevState.updateEvents !== this.state.updateEvents && this.state.updateEvents) {
        //
        //     this.getMyEvents();
        //     this.setState({updateEvents: false});
        // }

        // if (prevState.updateMyEvents !== this.state.updateMyEvents && this.state.updateMyEvents) {
        //     this.getEvents();
        //     this.setState({updateMyEvents: false});
        // }

        if (prevProps.showDealInfoId !== this.props.showDealInfoId) {
            if (this.props.showDealInfoId) {
                this.getDealById(this.props.showDealInfoId);
            }
        }

        if (prevProps.showUserInfoId !== this.props.showUserInfoId) {
            if (this.props.showUserInfoId) {
                this.toggleUserProfileModal(this.props.showUserInfoId);
            }
        }
    }

    getElementText = (response, elementName) => {
        return response.getElementsByTagName(elementName)[0].innerHTML;
    };

  getContactInfo = async () => {
    //const response1 = await fetchApiLocation("http://ip-api.com/json", "GET");
    const response1 = await fetchApiLocation("GET")
      .then((response) => {
        return response.text();
      })
      .then((xml) => {
        return new window.DOMParser().parseFromString(xml, "text/xml");
      })
      .then(async (xmlDoc) => {
        let country = this.getElementText(xmlDoc, "countryName");
        const urlC = "listofcountries";
        const responseC = await fetchApi(urlC, "POST", null, null);
        if (responseC.success) {
          let countries = [
            ...[{ id: 0, name: "All", code: "" }],
            ...responseC.listofcountries,
          ];
          this.setState({
            countryList: countries,
          });

          if (country !== undefined) {
            country = responseC.listofcountries.filter(
              (item) => item.en_name.toLowerCase() === country.toLowerCase()
            );
          }
          if (country !== null) {
            if (country[0] !== undefined && country[0] !== null) {
              // const url = "contactus";
              // let data = new FormData();
              // data.append("country", country[0].id);
              // const response = await fetchApi(url, "POST", data, null);
              // if (response.success) {
              //   this.setState({
              //     contactUsList: response.contactus
              //   });
              // }

              countryID = country[0].id;
              this.position();
            } else {
              // const url = "contactus";
              // let data = new FormData();
              // data.append("country", 1);
              // const response = await fetchApi(url, "POST", data, null);
              // if (response.success) {
              //   this.setState({
              //     contactUsList: response.contactus
              //   });
              // }
              this.position();
            }
          }
        }
      });
    //https://api.hostip.info/
  };

  position = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) =>
        this.setState(
          {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
          () => {
            this.getBusinessEventsAndOffers(
              this.state.latitude,
              this.state.longitude
            );
          }
        ),
      (error) => {
        this.getBusinessEventsAndOffers("", "");
      }
    );
  };

  getBusinessEventsAndOffers = async (latitude, longitude) => {
    const url = "filterbusinessevents";
    const data = new FormData();
    data.append("address", "");
    data.append("latitude", latitude);
    data.append("longitude", longitude);
    data.append("country_id", this.state.longitude !== "" ? countryID : 0);
    let searchDate = "";
    let currentDate = new Date(
      this.state.startDate.year +
        "-" +
        this.state.startDate.month +
        "-" +
        this.state.startDate.day
    );
    if (this.state.startDate && this.state.startDate != "") {
      searchDate = moment(currentDate, "DD-MM-YYYY").startOf("day").format();
    }
    data.append("date", "");
    data.append(
      "category",
      this.state.categoryId ? this.state.categoryId : "0"
    );
    data.append("user_id", 0);
    data.append("searchWord", this.state.categoryText);
    const response = await fetchApi(url, "POST", data);

    if (response.success) {
      this.setState({
        offertsList: response.offerts,
        businessEventList: response.events,
        a51List: response.a51,
        a52List: response.a52,
      });
    }
  };

    updateEvent = (event) => {
        if (
            event.persons_left === event.max_persons &&
            parseInt(event.user_id) === userCredentials.userId
        ) {
            this.setState({
                eventToUpdate: event,
                modalMakeEvents: true,
                updateEvent: true,
                updatePeopleEvent: false,
            });
        } else if (
            event.persons_left !== event.max_persons &&
            parseInt(event.user_id) === userCredentials.userId
        ) {
            // //this.setState({ showAlert: true });
            // swal("Info!", "This event can't be updated", "info");
            this.setState({
                updatePeopleEvent: true,
                eventToUpdate: event,
                updateEvent: true,
                modalMakeEvents: true,
            });
        }
    };

    onDismiss = () => {
        this.setState({showAlert: false, showEvents: false});
    };

    onDismissMyE = () => {
        this.setState({showAlertMyE: false, showEventsMyE: false});
    };

    handleNoDelete = () => {
        this.setState({showAlertDelete: false});
    };

    handleDeleteClick = () => {
        this.setState({showAlertDelete: true});
    };

    handleChange = (date) => {
        this.setState({
            startDate: date,
        });
    };

    deleteEvent = async (eventId, userId, maxPersons, personsLeft) => {
        let authKey = userCredentials.authKey;
        const url = "deleteevent";
        let data = new FormData();
        data.append("event_id", eventId);
        if (
            maxPersons === personsLeft &&
            parseInt(userId) === userCredentials.userId
        ) {
            const response = await fetchApi(url, "POST", data, authKey);
            if (response.success) {
                this.getSearchedEventsFromUser();
            }
        } else {
            swal("Error!", "This event can't be deleted", "error");
        }
    };

    handleChangeMyE = (date) => {
        this.setState({
            startDateMyE: date,
        });
    };

    reloadEventsCallback = () => {
        this.getEvents();
        this.getMyEvents();
        this.setState({modalMakeEvents: false});
    };

    toggleModalmakeEvent = () => {
        this.setState({
            modalMakeEvents: !this.state.modalMakeEvents,
            updatePeopleEvent: false,
        });
        if (this.state.updateEvent) {
            this.setState({updateEvent: false});
        }
    };

    getEvents = async () => {
        const url = "listofeventsfromlocation";

        const data = new FormData();

        data.append("location", "");
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("date", "");
        data.append("category", "");
        data.append("alias_category_id", "");
        data.append("user_id", userCredentials.userId);
        data.append("searchWord", "");

        const response = await fetchApi(url, "POST", data);

        if (response.success) {
            let allEvents = response.events.reverse();
            let promotedEvents = [];
            let normalEvents = [];
            let closedEvents = [];
            let combinedEvents = [];
            let repeatNr = 1;
            let jumpNr = 5;
            let stackedPromoted = [];

            for (let i = 0; i < allEvents.length; i++) {
                let promoted = moment().isSameOrBefore(allEvents[i].event.end_promoted_date);
                if (allEvents[i].event.is_promoted === "1" && promoted) {
                    promotedEvents.push(allEvents[i]);
                } else if (allEvents[i].is_closed) {
                    closedEvents.push(allEvents[i]);
                } else {
                    normalEvents.push(allEvents[i]);
                }
            }


            normalEvents = [...normalEvents, ...closedEvents];

            let numberPromoted = promotedEvents.length;
            let numberNormal = normalEvents.length;
            if (numberPromoted !== 0) {
                if (numberPromoted * 5 * 4 < numberNormal) {
                    repeatNr = 4;
                    jumpNr = 5;
                } else if (numberPromoted * 5 * 3 < numberNormal) {
                    repeatNr = 3;
                    jumpNr = 5;
                } else if (numberPromoted * 4 * 3 < numberNormal) {
                    repeatNr = 3;
                    jumpNr = 4;
                } else if (numberPromoted * 5 * 2 < numberNormal) {
                    repeatNr = 2;
                    jumpNr = 5;
                } else if (numberPromoted * 4 * 2 < numberNormal) {
                    repeatNr = 2;
                    jumpNr = 4;
                } else if (numberPromoted * 4 < numberNormal) {
                    repeatNr = 1;
                    jumpNr = 4;
                } else {
                    repeatNr = 1;
                    jumpNr = 3;
                }

                for (let i = 0; i < repeatNr; i++) {
                    stackedPromoted = stackedPromoted.concat(promotedEvents);
                }

                const merge = (normal, promoted, jump = 0, position) =>
                    normal.splice(jump, 0, promoted[position]) && normal;

                let newEvents = [];

                for (let i = 0; i < stackedPromoted.length; i++) {
                    newEvents = merge(normalEvents, stackedPromoted, i * jumpNr, i);
                }

                combinedEvents = newEvents;

                for (let i = 0; i < combinedEvents.length; i++) {
                    combinedEvents[i].event = {
                        ...combinedEvents[i].event,
                        ...{
                            event_type:
                                combinedEvents[i].event.user_id.toString() ===
                                userCredentials.userId.toString()
                                    ? 2
                                    : 3,
                            joinedEvent: false,
                        },
                    };
                }

                this.setState({eventsList: combinedEvents});
            } else {
                for (let i = 0; i < normalEvents.length; i++) {
                    normalEvents[i].event = {
                        ...normalEvents[i].event,
                        ...{
                            event_type:
                                normalEvents[i].event.user_id.toString() ===
                                userCredentials.userId.toString()
                                    ? 2
                                    : 3,
                            joinedEvent: false,
                        },
                    };
                }
                this.setState({eventsList: normalEvents});
            }
            if (this.state.eventsList.length > 10) {
                this.addEvents(0);
            } else {
                this.setState({pagedEvents: this.state.eventsList, hasEvents: false});
            }

            const link = queryString.parse(window.location.search);
            if (link) {
                const mySubString = link.snid;
                if (
                    mySubString &&
                    TryParseInt(mySubString, null) !== null &&
                    this.state.firstTime
                ) {
                    let event = response.events.filter(
                        (element) => parseInt(element.event.id) === parseInt(mySubString)
                    );

                    setTimeout(() => {
                        this.toggleModalShowDetails(
                            event[0].event,
                            event[0].multiprice,
                            event[0].user_logo
                        );
                    }, 3500);


                    this.setState({firstTime: false});
                }
            }
        }
        if (this.state.eventsList && this.state.eventsList.length == 0) {
            this.setState({showAlert: true});
        } else {
            this.setState({showAlert: false});
        }
    };

    getMyEvents = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofeventsperuser";
        const data = new FormData();
        data.append("location", "");
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("date", "");
        data.append("category", "");
        data.append("alias_category_id", "");
        data.append("user_id", userCredentials.userId);
        data.append("searchWord", "");
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {

            let allEvents = response.events.reverse();

            let normalEvents = [];
            let closedEvents = [];
            for (let i = 0; i < allEvents.length; i++) {

                if (allEvents[i].is_closed) {
                    closedEvents.push(allEvents[i]);
                } else {
                    normalEvents.push(allEvents[i]);
                }
            }

            normalEvents = [...normalEvents, ...closedEvents];

            this.setState({
                eventsFromUserList: normalEvents,
            });
        }
        if (
            this.state.eventsFromUserList &&
            this.state.eventsFromUserList.length === 0
        ) {
            this.setState({showAlertMyE: true});
            this.clickedDeleteF(0);
        } else {
            this.setState({showAlertMyE: false});
            this.clickedDeleteF(this.state.eventsFromUserList.length);
        }
        if (this.state.eventsFromUserList.length > 5) {
            this.addEventsUser(0);
        } else {
            this.setState({
                pagedEventsUser: this.state.eventsFromUserList,
                hasEventsUser: false,
            });
        }
    };

    clickedDeleteF(numberEventsNormal) {
        this.props.handleInput(numberEventsNormal);
    }

    // getCategories = async () => {
    //     // const url = "get_firstlevelcategories";
    //     const url = "get_firstlevelcategoriesandalias";
    //     let authKey = userCredentials.authKey;
    //     let data = new FormData();
    //     data.append("user_id", userCredentials.userId);
    //     const response = await fetchApi(url, "POST", data, authKey);
    //     if (response.success) {
    //         this.setState({ categories: response.list_of_categories });
    //     }
    // };

    getCategories = async () => {
        const url = "get_firstlevelcategoriesandalias";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);
        let list = [],
            alias = [],
            categs;
        if (response.success) {
            for (let i = 0; i < response.list_of_categories.length; i++) {
                categs = response.list_of_categories[i].category;
                list.push(categs);
                for (let j = 0; j < response.list_of_categories[i].alias.length; j++) {
                    alias = response.list_of_categories[i].alias[j];
                    if (alias.name_of_subcategory.length !== 0) {
                        list.push(alias);
                    }
                }
            }
            this.setState({categories: list});
        }
    };

    getAgeCategory = async () => {
        const url = "agecategorylist";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            this.setState({ageCategoryList: response.age_category});
        }
    };

    getSearchedEvents = async () => {
        const url = "listofeventsfromlocation";
        const data = new FormData();
        data.append("location", this.state.where);
        data.append("latitude", this.state.latitude);
        data.append("longitude", this.state.longitude);
        data.append(
            "date",
            this.state.startDate !== ""
                ? moment(
                new Date(
                    this.state.startDate.year +
                    "-" +
                    this.state.startDate.month +
                    "-" +
                    this.state.startDate.day
                )
                ).format("YYYY-MM-DD")
                : ""
        );
        data.append("category", this.state.useAliasId ? 0:  this.state.categoryId );
        // data.append("category", this.state.useAliasId ? this.state.categAliasId : this.state.categoryId);
        data.append("alias_category_id", this.state.useAliasId ?  this.state.categoryId : 0);

        data.append("user_id", userCredentials.userId);
        data.append("searchWord", this.state.categoryText);

        const response = await fetchApi(url, "POST", data);

        if (response.success) {
            if (response.events.length === 0) {
                this.setState({showAlert: true, showEvents: false});
            } else {
                let allEvents = response.events.reverse();
                let promotedEvents = [];
                let normalEvents = [];
                let combinedEvents = [];
                let closedEvents = [];
                let repeatNr = 1;
                let jumpNr = 5;
                let stackedPromoted = [];

                for (let i = 0; i < allEvents.length; i++) {
                    let promoted = moment().isSameOrBefore(allEvents[i].event.end_promoted_date);
                    if (allEvents[i].event.is_promoted === "1" && promoted) {
                        promotedEvents.push(allEvents[i]);
                    } else if (allEvents[i].is_closed) {
                        closedEvents.push(allEvents[i]);
                    } else {
                        normalEvents.push(allEvents[i]);
                    }
                }


                normalEvents = [...normalEvents, ...closedEvents];

                let numberPromoted = promotedEvents.length;
                let numberNormal = normalEvents.length;

                if (numberPromoted !== 0) {
                    if (numberPromoted * 5 * 4 < numberNormal) {
                        repeatNr = 4;
                        jumpNr = 5;
                    } else if (numberPromoted * 5 * 3 < numberNormal) {
                        repeatNr = 3;
                        jumpNr = 5;
                    } else if (numberPromoted * 4 * 3 < numberNormal) {
                        repeatNr = 3;
                        jumpNr = 4;
                    } else if (numberPromoted * 5 * 2 < numberNormal) {
                        repeatNr = 2;
                        jumpNr = 5;
                    } else if (numberPromoted * 4 * 2 < numberNormal) {
                        repeatNr = 2;
                        jumpNr = 4;
                    } else if (numberPromoted * 4 < numberNormal) {
                        repeatNr = 1;
                        jumpNr = 4;
                    } else {
                        repeatNr = 1;
                        jumpNr = 3;
                    }

                    for (let i = 0; i < repeatNr; i++) {
                        stackedPromoted = stackedPromoted.concat(promotedEvents);
                    }

                    const merge = (normal, promoted, jump = 0, position) =>
                        normal.splice(jump, 0, promoted[position]) && normal;

                    let newEvents = [];

                    for (let i = 0; i < stackedPromoted.length; i++) {
                        newEvents = merge(normalEvents, stackedPromoted, i * jumpNr, i);
                    }

                    combinedEvents = newEvents;

                    for (let i = 0; i < combinedEvents.length; i++) {
                        combinedEvents[i].event = {
                            ...combinedEvents[i].event,
                            ...{
                                event_type:
                                    combinedEvents[i].event.user_id.toString() ===
                                    userCredentials.userId.toString()
                                        ? 2
                                        : 3,
                                joinedEvent: false,
                            },
                        };
                    }

                    this.setState({eventsList: combinedEvents, hasSearched: true});
                } else {
                    for (let i = 0; i < normalEvents.length; i++) {
                        normalEvents[i].event = {
                            ...normalEvents[i].event,
                            ...{
                                event_type:
                                    normalEvents[i].event.user_id.toString() ===
                                    userCredentials.userId.toString()
                                        ? 2
                                        : 3,
                                joinedEvent: false,
                            },
                        };
                    }
                    this.setState({eventsList: normalEvents});
                }
            }
            if (this.state.eventsList.length > 10) {
                this.addEvents(0);
            } else {
                this.setState({pagedEvents: this.state.eventsList, hasEvents: false});
            }
        }
    };

    addEvents = (page) => {
        const newEvents = [];
        for (
            let i = page * 10, il = i + 10;
            i < il && i < this.state.eventsList.length;
            i++
        ) {
            newEvents.push(this.state.eventsList[i]);
        }

        this.setState({
            pagedEvents: [...this.state.pagedEvents, ...newEvents],
        });

        if (this.state.eventsList.length > this.state.pagedEvents.length) {
            this.setState({hasEvents: true});
        } else {
            this.setState({hasEvents: false});
        }
    };

    addEventsUser = (page) => {
        const newEvents = [];
        for (
            let i = page * 5, il = i + 5;
            i < il && i < this.state.eventsFromUserList.length;
            i++
        ) {
            newEvents.push(this.state.eventsFromUserList[i]);
        }

        this.setState({
            pagedEventsUser: [...this.state.pagedEventsUser, ...newEvents],
        });

        if (
            this.state.eventsFromUserList.length > this.state.pagedEventsUser.length
        ) {
            this.setState({hasEventsUser: true});
        } else {
            this.setState({hasEventsUser: false});
        }
    };

    onScrollHandler = () => {
        this.setState(
            {
                page: this.state.page + 1,
            },
            () => {
                this.addEvents(this.state.page);
            }
        );
    };

    onScrollHandlerUser = () => {
        this.setState(
            {
                pageUser: this.state.pageUser + 1,
            },
            () => {
                this.addEventsUser(this.state.pageUser);
            }
        );
    };

    getSearchedEventsFromUser = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofeventsperuser";
        const data = new FormData();
        data.append("location", this.state.whereMyE);
        data.append("latitude", this.state.latitude ? this.state.latitude : "");
        data.append("longitude", this.state.longitude ? this.state.longitude : "");
        data.append(
            "date",
            this.state.startDateMyE !== ""
                ? moment(
                new Date(
                    this.state.startDateMyE.year +
                    "-" +
                    this.state.startDateMyE.month +
                    "-" +
                    this.state.startDateMyE.day
                )
                ).format("YYYY-MM-DD")
                : ""
        );
        data.append("category", this.state.useAliasId ? 0    : this.state.categoryIdMyE);

        data.append("alias_category_id", this.state.useAliasId ?  this.state.categoryIdMyE : 0);

        data.append("user_id", userCredentials.userId);
        data.append("searchWord", this.state.categoryTextMyE);

        const response = await fetchApi(url, "POST", data, authKey);


        if (response.success) {
            let allEvents = response.events.reverse();

            let normalEvents = [];
            let closedEvents = [];

            for (let i = 0; i < allEvents.length; i++) {
                if (allEvents[i].is_closed) {
                    closedEvents.push(allEvents[i]);
                } else {
                    normalEvents.push(allEvents[i]);
                }
            }


            normalEvents = [...normalEvents, ...closedEvents];


            this.setState({
                searchedEventsMyE: normalEvents.reverse(),
                hasSearchedMyE: true,
                latitude: "",
                longitude: "",
            });
            if (response.events.length === 0) {
                this.setState({showAlertMyE: true, showEventsMyE: false});
            } else {
                this.setState({showAlertMyE: false, showEventsMyE: true});
            }

            if (this.state.searchedEventsMyE.length > 5) {
                this.addEventsUser(0);
            } else {
                this.setState({
                    pagedEventsUser: this.state.searchedEventsMyE,
                    hasEventsUser: false,
                });
            }
        }
    };

    componentDidMount = () => {
        this.getUserCards();
        this.getEvents();
        //this.getMyEvents();
        this.getCategories();
        this.getAgeCategory();
        this.getProfile();
        this.getaccountinformations();
        //this.getBusinessEventsAndOffers();
        this.listoffriends();
        // this.listofblockedusers();
        this.getContactInfo();
    };

    openFirstEvent() {
        if (this.props.selectedEventId) {
            this.getEvents().then(() => {
                this.getMyEvents().then(() => {
                    let allEvents = this.state.eventsList.concat(
                        this.state.eventsFromUserList
                    );
                    const evtIndex = allEvents.findIndex((s) => {
                        return s.event.id === this.props.selectedEventId;
                    });

                    if (evtIndex !== -1) {
                        this.setState({
                            currentEvent: allEvents[evtIndex].event,
                            currentMultiprice: allEvents[evtIndex].multiprice,
                            eventLogo: allEvents[evtIndex].user_logo,
                        });
                        this.setState({modalShowDetails: true});
                    }
                });
            });
        }
    }

    getaccountinformations = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        const url = "getaccountinformations";
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            let info = response.account_info[0];
            this.setState({
                name: info.first_name + " " + info.last_name,
            });
        }
    };

    getProfile = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let url = "getprofile";
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success && response.userprofile != null) {
            if (response.userprofile[0]) {
                this.setState({
                    profileImg: response.userprofile[0].logo,
                    myBirthday: response.user_informations.birthday
                });
            }
        }
    };

    // getOtherProfile = async id => {
    //     let authKey = userCredentials.authKey;
    //     let data = new FormData();
    //     let url = "getprofile";
    //     data.append("user_id", id);
    //     const response = await fetchApi(url, "POST", data, authKey);
    //     if (response.success) {
    //         return "Asd";
    //     }
    //     return null;
    // };

    handleEventClick = () => {
        this.setState({
            pagedEvents: [],
            pagedEventsUser: [],
            page: 0,
            pageUser: 0,
        });
        this.props.changeTagsEvent();
        this.setState({
            // showContent: 1
        });
        this.getEvents();
        if (this.searchbarRef.current)
            this.searchbarRef.current.resetSearchValues();
    };

    handleMyEventsClick = () => {
        this.props.changeMyEvents();
        this.setState({
            pagedEvents: [],
            pagedEventsUser: [],
            page: 0,
            pageUser: 0,
        });
        // this.setState({showContent: 2});
        this.getMyEvents();

        if (this.searchbarRef.current)
            this.searchbarRef.current.resetSearchValues();
    };

    handleFriendsClick = () => {
        this.props.changeFriends();
        this.listoffriends();
        this.setState({
            // showContent: 3,
             updateMyEvents: true,
             updateEvents: true});
    };

    handleFindClick = () => {
        // this.props.changeFind();
        this.setState({

            // showContent: 4,
            updateMyEvents: true,
             updateEvents: true});
    };

    handleBusinessClick = () => {
        this.props.changeMyTickets();
        this.setState({
            // showContent: 5,
            updateMyEvents: true,
            updateEvents: true});
    };

    onWhereChange = (address, lat, lng) => {
        this.setState({where: address});
    };

    onSearchClick = () => {
        this.setState({
            showAlert: false,
            pagedEvents: [],
            pagedEventsUser: [],
            page: 0,
            searchCheck: false,
        });
        this.getSearchedEvents();
    };

    onWhereChangeMyE = (address, lat, lng) => {
        this.setState({whereMyE: address, latitude: lat, longitude: lng});
    };

    onSearchClickMyE = () => {
        this.setState({
            showAlertMyE: false,
            pagedEvents: [],
            pagedEventsUser: [],
            pageUser: 0,
        });
        this.getSearchedEventsFromUser();
    };

    toggleModalShowDetails = (item, multiprice, logo) => {
        this.setState({
            currentEvent: item,
            currentMultiprice: multiprice,
            eventLogo: logo,
            modalShowDetails: !this.state.modalShowDetails,
        });

        //this.setState({ modalShowDetails: !this.state.modalShowDetails });
        if (this.state.showPaymentInfo) {
            this.setState({showPaymentInfo: false});
        }
        if (this.state.showVoucers) {
            this.setState({showVoucers: false});
        }
    };

    toggleModalGetVouchers = (item, multiprice, logo) => {
        this.setState({
            showVoucers: true,
            currentEvent: item,
            // currentMultiprice: multiprice,
            // eventLogo: logo,
            modalShowDetails: !this.state.modalShowDetails,
        });
        this.getVouchers(item);

    };

    getVouchers = async (event) => {
        let authKey = userCredentials.authKey;
        const url = "listofvouchersperlocation";
        const data = new FormData();
        data.append("latitude", event.latitude);
        data.append("longitude", event.longitude);
        data.append("country", event.country);
        data.append("address", "");
        //  data.append("category_id", event.category_id);

        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {

            if (response.vouchers && response.vouchers.length !== 0) {
                this.setState({

                    voucherList: (response.vouchers).reverse(),
                });
            } else {
                this.getVouchersAgain(event)
            }
        }
    };

    getVouchersAgain = async (event) => {
        let authKey = userCredentials.authKey;
        const url = "listofvouchersperlocation";
        const data = new FormData();

        data.append("latitude", "");
        data.append("longitude", "");
        data.append("address", event.address);

        data.append("country", event.country);
        // data.append("category_id", event.category_id);

        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({
                voucherList: (response.vouchers).reverse(),
            });

        }
    }


    onSearchVoucherClick = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofvouchersperlocation";
        const data = new FormData();


        // data.append("category_id", this.state.currentEvent.category_id);
        data.append("country", this.state.countryId);

        if (this.state.countryId === 0) {
            data.append("latitude", "");
            data.append("longitude", "");
            data.append("address", "");
        } else {
            data.append("latitude",this.state.latitude ? this.state.latitude : "");
            data.append("longitude", this.state.longitude? this.state.longitude :"");
            data.append("address","");
        }

        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.setState({
                voucherList: (response.vouchers).reverse(),
            });
        }
    }

    getAllFriendsEventHandler = (event) => {
        this.setState({filterVal: ""});
        let friendsList = this.state.friendsList;
        if (this.state.friendsList.length === 5) {
            let currentDisplayedFriends = this.state.friendsList;
            if (!currentDisplayedFriends.some((friend) => friend.key === "5"))
                this.setState({friendsList: currentDisplayedFriends});

            this.listoffriends();
        } else {
            this.setState({friendsList: friendsList.slice(0, 5)});
            this.setState({filteredList: friendsList.slice(0, 5)});
        }
    };

    searchFriends = (event) => {
        let friend = this.state.allFriends;
        this.setState({filterVal: event.target.value.substr(0, 10)});
        if (event.target.value !== "") {
            const filteredList = friend.filter(
                (friendsList) =>
                    friendsList[0].name
                        .toLowerCase()
                        .indexOf(event.target.value.toLowerCase()) !== -1
            );
            this.setState({friendsList: filteredList});
        } else {
            // this.listoffriends();

            this.setState({friendsList: friend});
        }
    };

    listoffriends = async () => {
        const url = "listoffriends";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {
            this.setState({
                friendsList: response.list_of_friends,
                allFriends: response.list_of_friends,
            });
            this.changeTag();
        }
    };

    showVoucer = () => {
        this.setState({showPaymentInfo: true, modalShowDetails: true});
    };

    resetEventsParams = () => {
        this.setState({
            where: "",
            startDate: "",
            latitude: "",
            longitude: "",
            categoryId: "",
            searchWord: "",
            categoryText: "",
            updateEvents: true,
            eventsList: [],
            searchedEvents: [],
            showAlert: false,
        });
    };
    resetMyEventsParams = () => {
        this.setState({
            whereMyE: "",
            startDateMyE: "",
            latitude: "",
            longitude: "",
            categoryIdMyEd: "",
            searchWord: "",
            updateMyEvents: true,
            eventsFromUserList: [],
            searchedEventsMyE: [],
            categoryTextMyE: "",
            showAlertMyE: false,
        });
    };

  renderEvents(eventInfo, idx) {
    let promoted = moment().isSameOrBefore(eventInfo.event.end_promoted_date);
    return (
      <div
        className="my-1 "
        key={idx}
        style={
          this.state.searchCheck && eventInfo.user_type === 3
            ? { display: "none" }
            : {}
        }
      >
        <ErrorBoundary>
          <ContentEvents
            categName={eventInfo.event.name_of_subcategory}
            aliasName={eventInfo.event.name_of_aliascategory}

            isClosed={!eventInfo.is_closed}
            currencyOcta={eventInfo.event.currency}
            eventEndDate={eventInfo.event.end_date}
            isPromoted={promoted ? eventInfo.event.is_promoted : "2"}
            startDatePromotion={eventInfo.event.start_promoted_date}
            endDatePromotion={eventInfo.event.end_promoted_date}
            promotionType={eventInfo.event.promoted_type}
            promotionDescription={eventInfo.event.promoted_comment}
            numberOfMsg={eventInfo.number_of_messages}
            eventType={3}
            eventId={eventInfo.event.id}
            userIdE={eventInfo.event.user_id}
            name={eventInfo.event.name}
            category={eventInfo.event.category_id}
            text={eventInfo.event.description}
            date={
              eventInfo.event.date ? eventInfo.event.date.substring(0, 11) : ""
            }
            area={eventInfo.event.address}
            time={
              eventInfo.event.start_hour
                ? eventInfo.event.start_hour.substring(0, 5)
                : ""
            }
            teams={eventInfo.event.team_number}
            participants={
              eventInfo.event.max_persons - eventInfo.event.persons_left
            }
            state="closed"
            price={
              eventInfo.multiprice.length === 0
                ? "FREE"
                : eventInfo.multiprice.length === 1
                ? eventInfo.multiprice[0].price === "0"
                  ? "FREE"
                  : "PAY"
                : "PAY"
            }
            multiprice={eventInfo.multiprice}
            gender={eventInfo.event.gender}
            ageCategory={eventInfo.event.age_category}
            latitude={eventInfo.event.latitude}
            longitude={eventInfo.event.longitude}
            team_number={eventInfo.event.team_number}
            max_persons={eventInfo.event.max_persons}
            persons_left={eventInfo.event.persons_left}
            type="free"
            toogleModalShowDetails={() =>
              this.toggleModalShowDetails(
                eventInfo.event,
                eventInfo.multiprice,
                eventInfo.user_logo
              )
            }
            reloadEventsCallback={this.reloadEventsCallback}
            categories={this.state.categories}
            userLogo={eventInfo.user_logo}
            userTypeStars={eventInfo.user_type}
            showVoucer={this.showVoucer}
            handicap_facilities={eventInfo.handicap_facilities}
            description={eventInfo.event.name}
            voucherLogo={eventInfo.voucher_logo}
            voucherVideo={eventInfo.voucher_video}
          />
        </ErrorBoundary>
      </div>
    );
  }

  renderEventsUser(eventInfo, idx) {
    let promoted = moment().isSameOrBefore(eventInfo.event.end_promoted_date);
    return (
      <div className="my-1 " key={idx}>
        <ErrorBoundary>
          <ContentEvents
            aliasName={eventInfo.event.name_of_aliascategory}

            categName={eventInfo.event.name_of_subcategory}
            isClosed={!eventInfo.is_closed}
            currencyOcta={eventInfo.event.currency}
            eventEndDate={eventInfo.event.end_date}
            isPromoted={promoted ? eventInfo.event.is_promoted : "2"}
            startDatePromotion={eventInfo.event.start_promoted_date}
            endDatePromotion={eventInfo.event.end_promoted_date}
            promotionType={eventInfo.event.promoted_type}
            promotionDescription={eventInfo.event.promoted_comment}
            eventType={2}
            numberOfMsg={eventInfo.number_of_messages}
            eventId={eventInfo.event.id}
            userIdE={eventInfo.event.user_id}
            name={eventInfo.event.name}
            category={eventInfo.event.category_id}
            text={eventInfo.event.description}
            date={eventInfo.event.date.substring(0, 11)}
            area={eventInfo.event.address}
            time={eventInfo.event.start_hour.substring(0, 5)}
            teams={eventInfo.event.team_number}
            participants={
              eventInfo.event.max_persons - eventInfo.event.persons_left
            }
            state="closed"
            price={
              eventInfo.multiprice.length === 0
                ? "FREE"
                : eventInfo.multiprice.length === 1
                ? eventInfo.multiprice[0].price === "0"
                  ? "FREE"
                  : "PAY"
                : "PAY"
            }
            gender={eventInfo.event.gender}
            ageCategory={eventInfo.event.age_category}
            latitude={eventInfo.event.latitude}
            longitude={eventInfo.event.longitude}
            team_number={eventInfo.event.team_number}
            max_persons={eventInfo.event.max_persons}
            persons_left={eventInfo.event.persons_left}
            type="free"
            toogleModalShowDetails={() =>
              this.toggleModalShowDetails(
                eventInfo.event,
                eventInfo.multiprice,
                eventInfo.user_logo
              )
            }
            toggleModalGetVouchers={() =>
              this.toggleModalGetVouchers(eventInfo.event)
            }
            reloadEventsCallback={this.reloadEventsCallback}
            categories={this.state.categories}
            multiprice={eventInfo.multiprice}
            userLogo={eventInfo.user_logo}
            showButtons={
              parseInt(eventInfo.event.user_id) === userCredentials.userId
            }
            userTypeStars={eventInfo.user_type}
            showJoin={true}
            joinedEvent={true}
            updateEvent={() => this.updateEvent(eventInfo.event)}
            showVoucer={this.showVoucer}
            handicapBanner={eventInfo.event.handicap_facilities}
            description={eventInfo.event.name}
            voucherLogo={eventInfo.voucher_logo}
            voucherVideo={eventInfo.voucher_video}
          />
        </ErrorBoundary>
      </div>
    );
  }

    renderMakeEvent = () => {
        return (
            <div>
                <Row className="justify-content-end makeEventRow">
                    <Col
                        lg="3"
                        className="d-flex justify-content-center text-white px-3 py-2 makeEventBtn mt-3 align-items-center"
                        onClick={this.toggleModalmakeEvent}
                    >
                        New Event
                    </Col>
                </Row>
                <Modal
                    isOpen={this.state.modalMakeEvents}
                    size="lg"
                    // toggle={this.toggleModalmakeEvent}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            New/Update event
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={this.toggleModalmakeEvent}
                        />
                    </div>
                    <ModalBody>
                        <ErrorBoundary>
                            <MakeEvents
                                reloadEventsCallback={this.reloadEventsCallback}
                                eventToUpdate={this.state.eventToUpdate}
                                updateEvent={this.state.updateEvent}
                                categories={this.state.categories}
                                ageCategoryList={this.state.ageCategoryList}
                                profileImg={this.state.profileImg}
                                name={this.state.name}
                                updatePeopleEvent={this.state.updatePeopleEvent}
                            />
                        </ErrorBoundary>
                    </ModalBody>
                </Modal>
            </div>
        );
    };

    onBusinessFilter = () => {

        this.setState({searchCheck: !this.state.searchCheck});
    };

    renderSwitch = (param) => {
        let list = this.state.pagedEvents;
        let listUser = this.state.pagedEventsUser;

        switch (param) {
            case 1:
                return (
                    <div>
                        {this.renderMakeEvent()}
                    <Container className="d-flex justify-content-center">
                        <Modal
                        isOpen={this.state.showSearchBusiness}
                        // toggle={this.toggleSearchBusinsess}
                        size="lg"
                        className="businessSearchModal"
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">
                                Search business
                            </ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={this.toggleSearchBusinsess}
                            />
                        </div>
                        <ModalBody className="">
                            <FindPeople
                                selectedCategory={this.state.categoryId ? this.state.categoryId : 0}
                                // selectedAddress={this.state.currentAddress}
                                selectedLat={this.state.currentLat}
                                selectedLng={this.state.currentLng}
                                selectedAddress={this.state.where}
                                selectedText={this.state.categoryText}
                                businessOnly={true}
                                fromHeader={true}
                                categoryList={this.state.categories}
                            />
                        </ModalBody>
                    </Modal>
                    <Col lg="13">


                        <SearchBar
                        toggleSearchBusinsess={this.toggleSearchBusinsess}
                                isLoggedSearch={this.state.isLoggedinn}

                            ref={this.searchbarRef}
                            inputValue={this.state.inputValue}
                            onInputChange={this.onInputChange}
                            onInputChangeWord={this.onInputChangeWord}
                            dropdownOpen={this.state.dropdownOpen}
                            toggleDropDown={this.toggleDropDown}
                            onDropdownItem_Click={this.onDropdownItem_Click}
                            categories={this.state.categories}
                            startDate={this.state.startDate}
                            handleChange={this.handleChange}
                            where={this.state.where}
                            onWhereChange={this.onWhereChange}
                            onSearchClick={this.onSearchClick}
                            resetFunction={this.resetEventsParams}
              searchWord={this.state.categoryText}
                        />

                        <Col className="showEvtBus" >

                            <FormGroup check className="">
                                <Label check className="businessOnly">
                                    <Input
                                        type="checkbox"
                                        onChange={this.onBusinessFilter}
                                        checked={this.searchCheck}
                                    />
                                    Show business events only
                                </Label>
                            </FormGroup>
                        </Col>

                        <div className="eventsArea">
                            <ErrorBoundary>
                                {this.state.pagedEvents !== null && !this.state.showAlert ? (
                                    <FlatList
                                        list={list}
                                        renderItem={(item, index) => this.renderEvents(item, index)}
                                        hasMoreItems={this.state.hasEvents}
                                        loadMoreItems={this.onScrollHandler}
                                    />
                                ) : (
                                    <UncontrolledAlert
                                        isOpen={this.state.showAlert}
                                        toggle={this.onDismiss}
                                    >
                                        No event found
                                    </UncontrolledAlert>
                                )}
                            </ErrorBoundary>
                        </div>
                        </Col>
                    </Container>
                    </div>
                );
            case 2:
                return (
                    <Container className="events-cont">
                        <Modal
                        isOpen={this.state.showSearchBusiness}
                        // toggle={this.toggleSearchBusinsess}
                        size="lg"
                        className="businessSearchModal"
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">
                                Search business
                            </ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={this.toggleSearchBusinsessMyE}
                            />
                        </div>
                        <ModalBody className="">
                            <FindPeople
                                selectedCategory={this.state.categoryIdMyE ? this.state.categoryIdMyE : 0}
                                // selectedAddress={this.state.currentAddress}
                                selectedLat={this.state.currentLat}
                                selectedLng={this.state.currentLng}
                                selectedAddress={this.state.whereMyE}
                                selectedText={this.state.categoryTextMyE}
                                businessOnly={true}
                                fromHeader={true}
                                categoryList={this.state.categories}
                            />
                        </ModalBody>
                    </Modal>
                        {this.renderMakeEvent()}
                        <SearchBar
                        toggleSearchBusinsess={this.toggleSearchBusinsessMyE}
                        isLoggedSearch={this.state.isLoggedinn}
                            ref={this.searchbarRef}
                            inputValue={this.state.inputValueMyE}
                            onInputChange={this.onInputChangeMyE}
                            onInputChangeWord={this.onInputChangeWordMyE}
                            dropdownOpen={this.state.dropdownOpenMyE}
                            toggleDropDown={this.toggleDropDownMyE}
                            onDropdownItem_Click={this.onDropdownItem_ClickMyE}
                            categories={this.state.categories}
                            startDate={this.state.startDateMyE}
                            handleChange={this.handleChangeMyE}
                            where={this.state.whereMyE}
                            onWhereChange={this.onWhereChangeMyE}
                            onSearchClick={this.onSearchClickMyE}
                            resetFunction={this.resetMyEventsParams}
              searchWord={this.state.categoryTextMyE}
                        />

                        <div className=" topMrg eventsArea">
                            <ErrorBoundary>
                                {this.state.pagedEventsUser !== null &&
                                !this.state.showAlertMyE ? (
                                    <FlatList
                                        list={listUser}
                                        renderItem={(item, index) =>
                                            this.renderEventsUser(item, index)
                                        }
                                        hasMoreItems={this.state.hasEventsUser}
                                        loadMoreItems={this.onScrollHandlerUser}
                                    />
                                ) : (
                                    <UncontrolledAlert
                                        isOpen={this.state.showAlertMyE}
                                        toggle={this.onDismiss}
                                    >
                                        No event found
                                    </UncontrolledAlert>
                                )}
                            </ErrorBoundary>
                        </div>
                    </Container>
                );
            case 3:
                return (
                    <div>
                        {this.renderMakeEvent()}
                        <ErrorBoundary>
                            <Friends
                                friendsList={this.state.friendsList}
                                filterVal={this.state.filterVal}
                                totalFriendsNo={this.state.totalFriendsNo}
                                refreshAllFriends={this.listoffriends}
                                getAllFriendsEventHandler={this.getAllFriendsEventHandler}
                                searchEventHandler={this.searchFriends}
                            />
                        </ErrorBoundary>
                    </div>
                );
            case 4:
                return (
                    <div>
                        {this.renderMakeEvent()}
                        <ErrorBoundary>
                            <FindPeople
                                // refreshAllFriends={this.listoffriends}
                                // friendsList={this.state.friendsList}
                                // filterVal={this.state.filterVal}
                                // totalFriendsNo={this.state.totalFriendsNo}
                                // getAllFriendsEventHandler={this.getAllFriendsEventHandler}
                                // searchEventHandler={this.searchFriends}
                            />
                        </ErrorBoundary>
                    </div>
                );
            case 5:
                return (
                    <div>
                        {this.renderMakeEvent()}
                        {/* <Button> Hello</Button> */}
                        <div>
                            <ErrorBoundary>
                                <Jumbotron className="jumbo-containerr">
                                    <MyTickets
                                        myBirthday={this.state.myBirthday}
                                     categoriesList={this.state.categories}></MyTickets>
                                </Jumbotron>
                            </ErrorBoundary>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };
    toggleSearchBusinsess = () => {

        this.setState({showSearchBusiness: !this.state.showSearchBusiness});

    }
    toggleSearchBusinsessMyE = () => {

        this.setState({showSearchBusiness: !this.state.showSearchBusiness});

    }
    setDefaultCard = async (cardid) => {
        const oldIndex = this.state.cardsList.findIndex((s) => {
            return s.default == true;
        });

        let authKey = userCredentials.authKey;
        const url = "setdefaultcardperuser";
        const data = new FormData();
        data.append("card_id", cardid);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            const newDefaultIndex = this.state.cardsList.findIndex((s) => {
                return s.id === cardid;
            });

            if (oldIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.default == true ? {...el, default: false} : el
                    ),
                }));
            }
            if (newDefaultIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.id == cardid ? {...el, default: true} : el
                    ),
                }));
            }
            swal("Success!", "Card successfully set as default!", "success");
        } else {
            swal("Error!", "Error on set default card!", "error");
        }
    };

    showAddCard = () => {
        if (!this.state.userDeactivated) {
            this.setState({showAddCard: true});
        } else {
            swal("Error!", "Invalid operation!", "error");
        }
    };
    toggleAddCard = (needRefresh) => {
        this.setState({showAddCard: false});
        if (needRefresh === true) {
            this.getUserCards();
        }
    };

    getUserCards = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofcardsperuser";
        const data = new FormData();
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({cardsList: response.cards});
            this.setState({userDeactivated: false});
        } else {
            this.setState({userDeactivated: true});
        }
    };

    closeModal = () => {
        this.setState({openBusinessModal: false});
    };

    selectCityLocation = (location, lat, lng) => {
        this.setState({cityLocation: location, latitude: lat, longitude: lng});
    };
    position = async () => {
        await navigator.geolocation.getCurrentPosition(
          (position) =>
            this.setState(
              {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
              () => {
                this.getBusinessEventsAndOffers(
                  this.state.latitude,
                  this.state.longitude
                );
              }
            ),
          (error) => {
            this.getBusinessEventsAndOffers("", "");
          }
        );
      };
    getBusinessEventsAndOffers = async (latitude, longitude) => {
        const url = "filterbusinessevents";
        const data = new FormData();
        data.append("address", "");
        data.append("latitude", latitude);
        data.append("longitude", longitude);
    data.append("country_id", this.state.longitude !== "" ? countryID : 0);
        let searchDate = "";
        let currentDate = new Date(
          this.state.startDate.year +
            "-" +
            this.state.startDate.month +
            "-" +
            this.state.startDate.day
        );
        if (this.state.startDate && this.state.startDate != "") {
          searchDate = moment(currentDate, "DD-MM-YYYY").startOf("day").format();
        }
        data.append("date", "");
        data.append(
          "category",
          this.state.categoryId ? this.state.categoryId : "0"
        );
        data.append("user_id", 0);
        data.append("searchWord", this.state.categoryText);
        const response = await fetchApi(url, "POST", data);

        if (response.success) {
          this.setState({
            offertsList: response.offerts,
            businessEventList: response.events,
            a51List: response.a51,
            a52List: response.a52,
          });
        }
      };
    render() {
        const style = {
            height: "100%",
        };

        const searchCity = {
            types: ["(cities)"],
            componentRestrictions: {country: [this.state.selectedZone]},
        };

        return (
            // <div style={style}>
                <Container>
                    <Modal
                        className="costume-modal-prof"
                        isOpen={this.state.openBusinessModal}
                        // toggle={() => this.closeModal()}
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1"/>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={() => this.closeModal()}
                            />
                        </div>

            <UserProfile
            businessLogo={this.props.userLogo}
              userId={this.state.businessUserId}
              closeHandler={() => this.closeModal()}
            />
          </Modal>
          <div className="bg" />
          <Col className="downContent">
            <Row className="pt-3">
              {

            (this.state.businessEventList !== null &&
                this.state.businessEventList.length > 0 )|| (
                this.state.a51List !== null &&
                this.state.a51List.length > 0) || (

                this.state.a52List !== null &&
                this.state.a52List.length > 0 )
               ? (
                <Col
                  xs="12"
                  sm={12}
                  sm={8}
                  md={12}
                  lg={8}
                  className="mt-1 pl-0 pr-0"
                >
                  <MenuBar
                    eventClick={() => {
                      this.handleEventClick();
                    }}
                    myEventsClick={() => {
                      this.handleMyEventsClick();
                    }}
                    friendsClick={this.handleFriendsClick}
                    businessClick={this.handleBusinessClick}
                    findClick={this.handleFindClick}
                    selectedTab={this.state.showContent}
                  />

                  <div className="p-1" />
                  <div className="">
                    {this.renderSwitch(this.state.showContent)}
                  </div>
                </Col>
              ) : (
                <Col
                  xs="12"
                  sm={12}
                  sm={8}
                  md={12}
                  lg={12}
                  className="mt-1 pl-0 pr-0"
                >
                  <MenuBar
                    eventClick={() => {
                      this.handleEventClick();
                    }}
                    myEventsClick={() => {
                      this.handleMyEventsClick();
                    }}
                    friendsClick={this.handleFriendsClick}
                    businessClick={this.handleBusinessClick}
                    findClick={this.handleFindClick}
                    selectedTab={this.state.showContent}
                  />

                  <div className="p-1" />
                  <div className="">
                    {this.renderSwitch(this.state.showContent)}
                  </div>
                </Col>
              )}

              {
              (this.state.businessEventList !== null &&
                this.state.businessEventList.length > 0 )|| (
                this.state.a51List !== null &&
                this.state.a51List.length > 0) || (

                this.state.a52List !== null &&
                this.state.a52List.length > 0 ) ? (
                <Col sm={12} md={12} lg={4} className="">
                  <div className="RightParentBg">
                    {this.state.businessEventList !== null ||
                    this.state.offertsList !== null ? (
                      <Col lg="4" md="4" className="mt-2 RightParent">
                        {/* <Sponsor /> */}
                        <ErrorBoundary>
                          <BusinessOffers
                                reloadEventsCallback={this.reloadEventsCallback}
                            businessEventList={this.state.businessEventList}
                            //offertsList={this.state.offertsList}
                            isLogged={true}
                            handler={this.props.handler}
                            a51List={this.state.a51List}
                            a52List={this.state.a52List}
                          />
                        </ErrorBoundary>
                      </Col>
                    ) : null}
                  </div>
                </Col>
              ) : null}
            </Row>
          </Col>
                    <Modal
                        isOpen={this.state.modalShowDetails}
                        size="lg"
                        // toggle={this.toggleModalShowDetails}
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1 text-white">
                                {this.state.showPaymentInfo
                                    ? "Cards list"
                                    : this.state.showVoucher
                                        ? "Get Voucher"
                                        : "Event details"}
                            </ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={this.toggleModalShowDetails}
                            />
                        </div>

                        <ModalBody>
                            <ErrorBoundary>
                                {this.state.showPaymentInfo ? (
                                    <div className="cardDetailsParent">
                                        <Row>
                                            <Col>
                                                {this.state.cardsList.length > 0 ? (
                                                    this.state.cardsList.map((item) => (
                                                        <Row
                                                            key={item.id}
                                                            className="d-flex align-items-center voucherContainer cardsListContainer"
                                                            onClick={() => {
                                                                this.setDefaultCard(item.id);
                                                            }}
                                                        >
                                                            <Col xs="6">
                                                                <div>{item.name}</div>
                                                            </Col>
                                                            <Col xs="2">
                                                                {item.exp_month}/{item.exp_year}
                                                            </Col>
                                                            <Col xs="2">{item.last4}</Col>
                                                            <Col xs="2">
                                                                {item.default ? (
                                                                    <FontAwesomeIcon
                                                                        icon={faCheck}
                                                                        className="mr-2"
                                                                    />
                                                                ) : null}
                                                            </Col>
                                                        </Row>
                                                    ))
                                                ) : (
                                                    <div>No cards saved</div>
                                                )}
                                            </Col>
                                        </Row>

                                        <Row className="my-5">
                                            <Col lg="6" className="leftPart">
                                                <div>
                                                    <Button
                                                        className="addCardBtn"
                                                        onClick={() => {
                                                            this.showAddCard();
                                                        }}
                                                    >
                                                        + Add card
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div className="text">
                                                    <span className="cardImg">
                                                        <FontAwesomeIcon icon={faLock} className="mr-2"/>
                                                    </span>
                                                    Safe and in control
                                                </div>
                                                <div className="text">
                                                    <span className="cardImg">
                                                        <FontAwesomeIcon
                                                            icon={faCreditCard}
                                                            className="mr-2"
                                                        />
                                                    </span>{" "}
                                                    Fast and simple
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="terms text-center">
                                            <a>
                                                I accept Zing Sport &nbsp;
                                                <a
                                                    href="javascript:void(0);"
                                                    onClick={() => this.showTermsAndCondition(true)}
                                                >
                                                    Terms and Conditions
                                                </a>
                                                &nbsp; by adding a card
                                            </a>
                                        </div>
                                        <Modal
                                            className="costume-modal-prof"
                                            isOpen={this.state.showTerms}
                                            // toggle={() => this.showTermsAndCondition(false)}
                                        >
                                            <div className="header-p">
                                                <ModalHeader className="head-1"/>
                                                <div className="logo_image">
                                                    <img className="my_logo" src={SLogo} alt="my_logo"/>
                                                </div>
                                                <ModalHeader
                                                    className="head-2"
                                                    toggle={() => this.showTermsAndCondition(false)}
                                                />
                                            </div>

                                            <ModalBody>
                                                <ErrorBoundary>
                                                    <TermsAndConditions
                                                        isTerms={this.state.isTerms}
                                                    ></TermsAndConditions>
                                                </ErrorBoundary>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                    
                                ) : this.state.currentEvent !== undefined &&
                                this.state.currentMultiprice !== undefined &&
                                !this.state.showVoucers &&
                                this.state.currentMultiprice !== null &&
                                this.state.currentEvent.date !== undefined ? (
                           
                                    <BannerDetailsNormal
                                        isClosed={this.state.currentEvent.is_closed}
                                            currencyBannner={this.state.currentEvent.currency}
                                            userLogged={this.props.userLogged}
                                            handler={this.props.handler}
                                            price={
                                                this.state.currentMultiprice.length === 0
                                                    ? "FREE"
                                                    : this.state.currentMultiprice.length === 1
                                                    ? this.state.currentMultiprice[0].price === "0"
                                                        ? "FREE"
                                                        : "PAY"
                                                    : "PAY"
                                            }
                                            id={this.state.currentEvent.id}
                                            userIdE={this.state.currentEvent.user_id}
                                            name={this.state.currentEvent.name}
                                            gender={this.state.currentEvent.gender}
                                            ageCategory={this.state.currentEvent.age_category}
                                            startDate={this.state.currentEvent.date.substring(0, 11)}
                                            // endDate={
                                            //     this.state.currentEvent.end_date !== null
                                            //         ? !this.state.currentEvent.end_date.substring(0, 11)
                                            //         : this.state.currentEvent.date.substring(0, 11)
                                            // }
                                            endTime={this.state.currentEvent.end_hour.substring(0, 5)}

                                            endDate={this.state.currentEvent.end_date.substring(0, 11)}
                                            startTime={this.state.currentEvent.start_hour.substring(
                                                0,
                                                5
                                            )}
                                            categoryName={this.state.currentEvent.name_of_subcategory}
                                            latitude={this.state.currentEvent.latitude}
                                            longitude={this.state.currentEvent.longitude}
                                            team_number={this.state.currentEvent.team_number}
                                            max_persons={this.state.currentEvent.max_persons}
                                            persons_left={this.state.currentEvent.persons_left}
                                            description={this.state.currentEvent.description}
                                            multiprice={this.state.currentMultiprice}
                                            address={this.state.currentEvent.address}
                                            latitude={this.state.currentEvent.latitude}
                                            longitude={this.state.currentEvent.longitude}
                                            userLogo={this.state.eventLogo}
                                            eventLogo={this.state.currentEvent.category_logo}
                                            category={this.state.currentEvent.category_id}
                                            closeModal={this.toggleModalShowDetails}
                                            reloadEventsCallback={this.reloadEventsCallback}
                                            isLogged={true}
                                            handicap_facilities={
                                                this.state.currentEvent.handicap_facilities
                                            }
                                            endDateEvent={this.state.currentEvent.end_date}
                                            inHoliday={false}
                                        />
                                        
                                    ) : // <BannerDetailsBusiness name={this.state.currentEvent.name} />
                                    this.state.showVoucers ? (
                                        <div className="eventsArea">
                                            <Row className="voucherSearch">
                                                <Col lg="5" className="categoryInputV">
                                                    <Autocomplete
                                                        id="combo-box-demo4"
                                                        freeSolo={false}
                                                        options={this.state.countryList}
                                                        getOptionLabel={(option) => option.name}
                                                        className="text-white categoryInputB"
                                                        onChange={(e, val) => {
                                                            this.setState({
                                                                country: val && val.en_name ? val.en_name : val && val.name ? val.name : "",
                                                                countryId: val ? val.id : -1,
                                                                selectedZone: val ? val.code.toLowerCase() : "",
                                                                cityLocation: "",
                                                                latitude:"",
                                                                longitude:""
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                value={this.state.country}
                                                                variant="outlined"
                                                                className="categoryAC placeholder1"
                                                                placeholder={"Search Country"}
                                                            />
                                                        )}
                                                    />

                                                </Col>
                                                <Col lg="5" className="categoryInputV">
                                                    <SelectLocation
                                                        key={this.state.selectedZone}
                                                        cityType={true}
                                                        placeHolder={this.state.selectedZone !== "" && this.state.country !== "" ? "Select City" : this.state.countryId === 0 ? "All countries" : "Select country first"}
                                                        enabledText={this.state.selectedZone !== "" && this.state.country !== ""}

                                                        selectCity={this.selectCityLocation}
                                                        selectedZone={this.state.selectedZone}
                                                        searchOptions={searchCity}
                                                    />
                                                </Col>

                                                <Col lg="2"
                                                     md="1"
                                                     sm="1"
                                                     className="rounded-circle searchContainer d-flex justify-content-center align-items-center mr-3 div-search"
                                                     onClick={() => {
                                                         if (this.state.country) {
                                                             this.onSearchVoucherClick()
                                                         } else {
                                                             this.getVouchers(this.state.currentEvent)
                                                         }
                                                     }}
                                                >
                                                    <img
                                                        src={require("../../../Assets/img/search.png")}
                                                        className="searchImg"
                                                    />
                                                </Col>
                                            </Row>

                                            {this.state.voucherList.length !== 0 ? (
                                                this.state.voucherList.map((item) => (
                                                    <div key={item.voucher.id} className="pl-3 px-1">
                                                        <Voucher
                                                            logo={item.voucher.logo}
                                                            titleVoucher={item.voucher.title}
                                                            category={item.voucher.category}
                                                            location={item.voucher.address}
                                                            nOf={item.voucher.vouchers_number}
                                                            price={item.voucher.voucher_value}
                                                            reserved={
                                                                item.voucher.vouchers_number -
                                                                item.voucher.left_vouchers
                                                            }
                                                            userId={userCredentials.userId}
                                                            voucherId={item.voucher.id}
                                                            eventId={this.state.currentEvent.id}
                                                            requests={item.request_to_assign.length}
                                                            conditions={item.voucher.promo_description}
                                                            currency={item.voucher.currency}
                                                        />
                                                    </div>
                                                ))
                                            ) : (

                                                <div className="locationNoVoucher">This location has no vouchers</div>
                                            )}
                                        </div>
                                    ) : null}
                            </ErrorBoundary>
                        </ModalBody>
                    </Modal>
                    <Modal
                        isOpen={this.state.showAddCard}
                        size="lg"
                        // toggle={this.toggleAddCard}
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1  text-white">Add card</ModalHeader>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader className="head-2" toggle={this.toggleAddCard}/>
                        </div>
                        <ModalBody>
                            <ErrorBoundary>
                                <AddCard handleCancel={this.toggleAddCard}></AddCard>
                            </ErrorBoundary>
                        </ModalBody>
                    </Modal>

                    <Modal
                        className="costume-modal-prof"
                        isOpen={this.state.userProfileModal}
                        // toggle={this.toggleUserProfileModal}
                    >
                        <div className="header-p">
                            <ModalHeader className="head-1"/>
                            <div className="logo_image">
                                <img className="my_logo" src={SLogo} alt="my_logo"/>
                            </div>
                            <ModalHeader
                                className="head-2"
                                toggle={this.toggleUserProfileModal}
                            />
                        </div>

                        <UserProfile userId={this.state.userProfileId}/>
                    </Modal>
                </Container>
            // </div>
        );
    }

    toggleDropDown = (e) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    };
    onDropdownItem_Click = (sender) => {
        var newState = {
            dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue"),
        };
        this.setState({
            inputValue: newState.dropDownValue2,
            dropdownOpen: !this.state.dropdownOpen,
        });
        this.state.categories.forEach((element) => {
            if (element.name === newState.dropDownValue2) {
                this.setState({categoryId: element.id});
            }
        });
    };

    onInputChange = (value, searchWord) => {
        if (searchWord.id) {
            this.setState({
                categoryId: searchWord.id,
                // categoryText: "",
                useAliasId: searchWord.parent_category_id ? true :false,
                categAliasId:searchWord.parent_category_id ,
            });
        } else {
            this.setState({
                categoryId: 0,
                // categoryText: searchWord,
            });
        }
    };
    onInputChangeWord = (value, searchWord) => {
        this.setState({
            categoryText: value
        });
};
    toggleDropDownMyE = (e) => {
        this.setState({
            dropdownOpenMyE: !this.state.dropdownOpenMyE,
        });
    };
    onDropdownItem_ClickMyE = (sender) => {
        var newState = {
            dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue"),
        };
        this.setState({
            inputValueMyE: newState.dropDownValue2,
            dropdownOpenMyE: !this.state.dropdownOpen,
        });
        this.state.categories.forEach((element) => {
            if (element.name === newState.dropDownValue2) {
                this.setState({categoryIdMyE: element.id});
            }
        });
    };

    onInputChangeMyE = (value, searchWord) => {
        if (searchWord.id) {
            this.setState({
                categoryIdMyE: searchWord.id,
                // categoryTextMyE: "",
                useAliasId: searchWord.parent_category_id ? true : false
            });
        } else {
            this.setState({
                categoryIdMyE: 0,
                // categoryTextMyE: searchWord,
            });
        }
    };
    onInputChangeWordMyE = (value, searchWord) => {
        this.setState({
            categoryTextMyE: value
        });
};
    onDateChange = (evt) => {
        this.setState({
            DateValue: evt.target.value,
        });
    };

    handleRequest(e) {
        e.preventDefault();
        //do something...
    }
}

class MenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            showEvents: true,
            showMyEvents: false,
            showFriends: false,
            showFind: false,
            showBusiness: false,
        };
    }

    eventsClick = () => {
        this.setState({
            showEvents: true,
            showMyEvents: false,
            showFriends: false,
            showFind: false,
            showBusiness: false,
        });
    };

    myEventsClick = () => {
        this.setState({
            showEvents: false,
            showMyEvents: true,
            showFriends: false,
            showFind: false,
            showBusiness: false,
        });
    };

    friendsClick = () => {
        this.setState({
            showEvents: false,
            showMyEvents: false,
            showFriends: true,
            showFind: false,
            showBusiness: false,
        });
    };

    findClick = () => {
        this.setState({
            showEvents: false,
            showMyEvents: false,
            showFriends: false,
            showFind: true,
            showBusiness: false,
        });
    };

    businessClick = () => {
        this.setState({
            showEvents: false,
            showMyEvents: false,
            showFriends: false,
            showFind: false,
            showBusiness: true,
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedTab !== this.props.selectedTab) {
            if (this.props.selectedTab === 1) {
                this.eventsClick();
            } else if (this.props.selectedTab === 2) {
                this.myEventsClick();
            } else if (this.props.selectedTab === 3) {
                this.friendsClick();
            } else if (this.props.selectedTab === 5) {
                this.businessClick();
            } else if (this.props.selectedTab === 4) {
                this.findClick();
            }
        }
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
      }

      handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
      };
    render() {

        const {width} = this.state;
        const isMobile = width <= 500;
        if (isMobile) {
        return (
        <div></div>
        )
    }else{
        return(

        <Container className="container-height">
                <Row className="menuBarN py-3 mx-0">
                    <div onClick={this.eventsClick}>
                        <div
                            className={
                                this.state.showEvents ? "permanentGreen" : "permanentWhite"
                            }
                            onClick={
                                this.state.showEvents === false ? this.props.eventClick : null
                            }
                        >
                            Events
                        </div>
                    </div>
                    <div onClick={this.myEventsClick}>
                        <div
                            className={
                                this.state.showMyEvents ? "permanentGreen" : "permanentWhite"
                            }
                            onClick={
                                this.state.showMyEvents === false
                                    ? this.props.myEventsClick
                                    : null
                            }
                        >
                            My events
                        </div>
                    </div>
                    <div onClick={this.friendsClick}>
                        <div
                            className={
                                this.state.showFriends ? "permanentGreen" : "permanentWhite"
                            }
                            onClick={this.props.friendsClick}
                        >
                            Friends
                        </div>
                    </div>

                    {/* <div onClick={this.findClick}>
                        <div
                            className={
                                this.state.showFind ? "permanentGreen" : "permanentWhite"
                            }
                            onClick={this.props.findClick}
                        >
                Find Business
                        </div>
                    </div> */}

                    <div onClick={this.businessClick}>
                        <div
                            className={
                                this.state.showBusiness ? "permanentGreen" : "permanentWhite"
                            }
                            onClick={this.props.businessClick}
                        >
                            My Tickets
                        </div>
                    </div>
                </Row>
            </Container>
        )
    }
    }
}

class Sponsor extends Component {
    render() {
        return (
            <div className="box">
                <div className="row">
                    <div className="col-12">
                        <div className="Imgbox">
                            <div className="position-absolute">
                                <Media
                                    className="sponsorImg"
                                    object
                                    src={SponsorImage}
                                    alt="sponsorImg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function TryParseInt(str, defaultValue) {
    var retValue = defaultValue;
    if (str !== null) {
        if (str.length > 0) {
            if (!isNaN(str)) {
                retValue = parseInt(str);
            }
        }
    }
    return retValue;
}

