import React, {Component, Children} from "react";
import {fetchApi} from "../../../fetchApi.js";
import {userCredentials} from "../../../globalVariables";
import {
    Col,
    Row,
    ModalBody,
    Modal,
    ModalHeader,
    Container
} from "reactstrap";
import FlatList from "flatlist-react";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import Friends from "../../BothUsers/Friends/Friends.js";
import UserProfile from "../../BothUsers/UserProfile/UserProfile.js";
import ContentEvents from "../../BothUsers/Events/ContentEvents.js";
import BannerDetailsNormal from "../../NormalUser/BannerDetails/BannerDetailsNormal";
import "./MyTickets.scss";
import Barcode from 'react-barcode';

const localizer = momentLocalizer(moment);
let profilePic = require("../../../Assets/img/profilePic.jpg");
let SLogo = require("../../../Assets/img/SLogo.png");

export default class MyTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketsList: [],
            showProfile: false,
            userIdforModal: 0,
            profileModal: false,
            showUsersCreatedEvents: false,
            showUserRegisteredEvents: false,
            showUserFriends: false,
            showUserDeals: false,
            modalTitle: null,
            createdEventsList: [],
            registeredEventsList: [],
            dealsDateList: [],
            modalShowDetails: false,
            friendsList: [],
            friendsList2: [],
            currentLogo: "",
            holidayList: [],
            nameC: '',
            ticket: false,
            ticketDetails: [],
            locationTicket: "",
            eventName: '',
            dealName: '',
            indicativePrice: ''
        };
    }

    toggleModalShowDetails = (item, multiprice, logo) => {
        this.setState({
            currentEvent: item,
            currentMultiprice: multiprice,
            eventLogo: logo,
            modalShowDetails: !this.state.modalShowDetails,
            profileModal: true,
            modalTitle: "Event details",
        });

        //this.setState({ modalShowDetails: !this.state.modalShowDetails });
        if (this.state.showVoucherInput) {
            this.setState({showVoucherInput: false});
        }
        if (this.state.showVoucers) {
            this.setState({showVoucers: false});
        }
    };
    toggleProfileModal = () => {
        this.setState({profileModal: !this.state.profileModal});
        if (this.state.showUsersCreatedEvents) {
            this.setState({showUsersCreatedEvents: false});
        }
        if (this.state.showUserRegisteredEvents) {
            this.setState({showUserRegisteredEvents: false});
        }
        if (this.state.showUserFriends) {
            this.setState({showUserFriends: false});
        }
        if (this.state.showUserDeals) {
            this.setState({showUserDeals: false});
        }
    };

    componentDidMount = () => {
        this.getMyTickets();
    };

    getMyTickets = async () => {
        const url = "listofticketsperuser";
        const data = new FormData();
        data.append("user_id", userCredentials.userId);
        const response = await fetchApi(url, "POST", data, userCredentials.authKey);
        if (response.success) {

            this.setState({ticketsList: response.list_of_tickets});
        }
    };

    showEventDetails = async (event_id, multiprice, logo) => {
        const url = "geteventbyid";
        const data = new FormData();
        data.append("event_id", event_id);
        const response = await fetchApi(url, "POST", data);
        if (response.success) {
            if (response.event && response.event.length > 0) {
                let event = response.event[0];
                this.setState({
                    profileModal: !this.state.profileModal,
                    currentEvent: event,
                    modalTitle: "Event details",
                    currentMultiprice: multiprice,
                    currentLogo: logo,
                });
            }
        }
    };

    showDealDetails = async (event_id, multiprice, logo) => {
        const url = "getdealbyid";
        const data = new FormData();
        data.append("deal_id", event_id);
        const response = await fetchApi(url, "POST", data);
        if (response.success) {
            if (response.deal_details && response.deal_details[0].deal.length > 0) {
                let event = response.deal_details[0].deal[0];
                this.setState({
                    profileModal: !this.state.profileModal,
                    currentEvent: event,
                    modalTitle: "Deal details",
                    currentMultiprice: multiprice,
                    currentCalendar: response.deal_details[0].deal_calendar,
                    currentLogo: logo,
                });
            }
        }
    };

    showUserDetails = (user_id) => {
        this.setState({
            showProfile: !this.state.showProfile,
            userIdforModal: user_id,
        });
    };

    openFriends = (name) => {
        this.listoffriends();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " friends",
            showUserFriends: true,
        });
    };

    listoffriends = async () => {
        const url = "listoffriends";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", this.state.userIdforModal);
        const response = await fetchApi(url, "POST", data, authKey);
        // console.log(response);
        if (response.success) {
            // console.log( response, "has enter refresh")
            this.setState({friendsList2: response.list_of_friends});
        }
    };

    getMyEvents = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofeventsperuser";
        const data = new FormData();
        data.append("location", "");
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("date", "");
        data.append("category", "");
        data.append("user_id", this.state.userIdforModal);
        data.append("searchWord", "");
        const response = await fetchApi(url, "POST", data, authKey);
        // console.log(response);
        if (response.success) {
            let createdEvents = [];
            createdEvents = response.events.filter(
                (element) =>
                    parseInt(element.event.user_id) ===
                    parseInt(this.state.userIdforModal)
            );
            // console.log(createdEvents);
            let registeredEvents = [];
            registeredEvents = response.events.filter(
                (element) =>
                    parseInt(element.event.user_id) !==
                    parseInt(this.state.userIdforModal)
            );
            // console.log(registeredEvents);
            this.setState({
                createdEventsList: createdEvents.reverse(),
                registeredEventsList: registeredEvents.reverse(),
            });
        }
        if (
            this.state.eventsFromUserList &&
            this.state.eventsFromUserList.length == 0
        ) {
            this.setState({showAlertMyE: true});
        } else {
            this.setState({showAlertMyE: false});
        }
    };

    getMyDeals = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofdealsperbusiness";
        const data = new FormData();
        data.append("business_user_id", this.state.userIdforModal);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            let arr = [];
            response.deals.forEach((felement) => {
                felement.deals_calendar.forEach((element) => {
                    let endDate =
                        element.date.substring(0, 10) + " " + felement.deals.end_hour;
                    arr.push({
                        start: moment(element.date).toDate(),
                        end: moment(endDate).toDate(),
                        title: felement.deals.name,
                        resource: felement,
                    });
                });
            });
            this.setState({dealsDateList: arr, holidayList: response.holidays});
        }
    };

    eventStyleGetter = (event, start, end, isSelected) => {
        var style = {};
        let cd = new Date();
        cd.setHours(0, 0, 0, 0);
        let date = new Date(event.resource.deals.date);
        let day = new Date(start);
        event.resource.deals_calendar.forEach((element) => {
            let eDay = new Date(element.date);
            if (+day === +eDay) {
                if (parseInt(element.persons_left) === 0 || date < cd) {
                    style = {
                        backgroundColor: "red",
                    };
                }
                this.state.holidayList.forEach((holiday) => {
                    let stdh = new Date(holiday.from_date);
                    let edh = new Date(holiday.to_date);
                    if (+edh >= +stdh && +stdh <= +eDay && +eDay <= +edh) {
                        style = {
                            backgroundColor: "orange",
                        };
                    }
                });
            }
        });
        return {
            style: style,
        };
    };

    openEventsCreated = (name) => {
        this.getMyEvents();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " created events",
            showUsersCreatedEvents: true,
        });
    };

    openEventsRegistered = (name) => {
        this.getMyEvents();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " registered events",
            showUserRegisteredEvents: true,
        });
    };

    openDealsCreated = (name) => {
        this.getMyDeals();
        this.closeModal();
        this.toggleProfileModal();
        this.setState({
            modalTitle: name + " created deals",
            showUserDeals: true,
        });
    };
    closeModal = () => {
        this.setState({modal: false});
    };
    toogleModalShowDetails = () => {
        this.setState({modalShowDetails: !this.state.modalShowDetails});
    };

    reloadEventsCallback = () => {
        this.getMyTickets();
        this.setState({profileModal: false});
    };

    renderEvents = (eventInfo, idx) => {
        return (
            <div className="my-1 " key={eventInfo.event.id}>
                <ContentEvents
                    categName={eventInfo.event.name_of_subcategory}
                    aliasName={eventInfo.event.name_of_aliascategory}

                    isClosed={!eventInfo.is_closed}
                    currencyOcta={eventInfo.event.currency}
                    numberOfMsg={eventInfo.number_of_messages}
                    eventType={3}
                    eventId={eventInfo.event.id}
                    userIdE={eventInfo.event.user_id}
                    name={eventInfo.event.name}
                    category={eventInfo.event.category_id}
                    text={eventInfo.event.description}
                    date={
                        eventInfo.event.date ? eventInfo.event.date.substring(0, 11) : ""
                    }
                    area={eventInfo.event.address}
                    time={
                        eventInfo.event.start_hour
                            ? eventInfo.event.start_hour.substring(0, 5)
                            : ""
                    }
                    teams={eventInfo.event.team_number}
                    participants={
                        eventInfo.event.max_persons - eventInfo.event.persons_left
                    }
                    state="closed"
                    price={
                        eventInfo.multiprice.length === 0
                            ? "FREE"
                            : eventInfo.multiprice.length === 1
                            ? eventInfo.multiprice[0].price === "0"
                                ? "FREE"
                                : "PAY"
                            : "PAY"
                    }
                    gender={eventInfo.event.gender}
                    ageCategory={eventInfo.event.age_category}
                    latitude={eventInfo.event.latitude}
                    longitude={eventInfo.event.longitude}
                    team_number={eventInfo.event.team_number}
                    max_persons={eventInfo.event.max_persons}
                    persons_left={eventInfo.event.persons_left}
                    type="free"
                    toogleModalShowDetails={() =>
                        this.toggleModalShowDetails(
                            eventInfo.event,
                            eventInfo.multiprice,
                            eventInfo.user_logo
                        )
                    }
                    reloadEventsCallback={this.reloadEventsCallback}
                    categories={this.state.categories}
                    userLogo={eventInfo.user_logo}
                    showVoucer={this.showVoucer}
                    description={eventInfo.event.name}
                />
            </div>
        );
    };
    selectEvent = (event) => {
        this.setState({profileModal: !this.state.profileModal});
        let dealCalendar = event.resource.deals_calendar.filter(
            (item) =>
                new Date(item.date).getTime() === new Date(event.start).getTime()
        );
        this.setState({
            profileModal: !this.state.profileModal,
            curentDealCalendarId: dealCalendar[0].id,
            curentDealCalendar: dealCalendar[0],
        });
        this.toggleModalShowDetailsD(
            event.resource.deals,
            event.resource.deals_calendar
        );
    };

    toggleModalShowDetailsD = (item, calendar) => {
        this.setState({
            currentEvent: item,
            currentMultiprice: [
                {
                    id: 1,
                    price: calendar.length > 0 ? calendar[0].price : 0,
                    nOf: item.max_persons,
                    indicativePrice: item.currency,
                },
            ],
            eventLogo: item.logo,
            currentCalendar: calendar,
        });
        //console.log(item);
        this.setState({modalShowDetails: !this.state.modalShowDetails});
    };

    findCategoryName = (id) => {
        if (this.props.categoriesList !== undefined) {
            let catName = this.props.categoriesList.filter(
                (element) => parseInt(element.id) === parseInt(id)
            );
            if (catName[0] !== undefined) {
                return catName[0].en_name;
            }
        }
    };
    toggleTicket = (val, val1, val2, val3, val4) => {
        this.setState({
            ticket: !this.state.ticket,
            ticketDetails: val,
            locationTicket: val1,
            eventName: val2,
            dealName: val3,
            indicativePrice: val4

        });
    };

    render() {
        const ColoredDateCellWrapper = ({children, value}) => {
            let evtPresent = this.state.dealsDateList.filter((x) => {
                let curDate = new Date(value);
                curDate.setHours(0, 0, 0, 0);
                let dealDateStart = new Date(x.start);
                dealDateStart.setHours(0, 0, 0, 0);
                let dealDateEnd = moment(x.end);
                if (+curDate === +dealDateStart) {
                    return x;
                }
            });
            return React.cloneElement(Children.only(children), {
                style: {
                    ...children.style,
                    backgroundColor: evtPresent.length > 0 ? "lightgreen" : "lightyellow",
                },
            });
        };
        return (
            <div className="myTicketsContainer">
                <Modal className="costume-modal-prof" toggle={this.toggleTicket} isOpen={this.state.ticket}>
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">Inbox</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleTicket}/>
                    </div>
                    <ModalBody className=" modalBodyBarcode ">

                        <Row className="noMarginRow">

                            {this.state.ticketDetails && this.state.ticketDetails.length > 0 ? (this.state.ticketDetails.map((item, index) => (
                                <Container key={item.id} xs="1" sm="2" md="4" lg={12} className="d-flex ticketsArea">


                                    <Row className="parrentBarcode">
                                        <Col className="noMrgColName" lg="5"
                                             xs="14">{this.state.eventName || this.state.dealName}</Col>
                                        <Row className="parentColsEvt" lg={"auto"}>
                                            <Col className="noMrgCol" lg={6} xs="14"><h6>Username:</h6> <h6
                                                className="secH">{item.username}</h6></Col>
                                            <Col className="noMrgCol" lg={6} xs="14"><h6>Birthday:</h6> <h6
                                                className="secH">{this.props.myBirthday}</h6></Col>
                                            <Col className="noMrgCol" lg={6} xs="14"><h6>Event location:</h6><h6
                                                className="secH">{this.state.locationTicket}</h6></Col>
                                            <Col className="noMrgCol" lg={6} xs="14"><h6>Event start:</h6><h6
                                                className="secH">{item.created_at}</h6></Col>
                                            <Col className="noMrgCol" lg={6} xs="14"><h6>Ticket price:</h6> <h6
                                                className="secH">{item.price}</h6></Col>
                                            <Col className="noMrgCol" lg={6} xs="14"><h6>Indicative price:</h6> <h6
                                                className="secH">{this.state.indicativePrice} </h6></Col>

                                        </Row>
                                        <Col className="positionBarcode" lg="3" xs="2"><Barcode
                                            style={{font: "1px", background: "#838ae5", fontSize: 10,}} fontSize={13}
                                            width={1} height={50} value={item.qr_code}/></Col>

                                    </Row>
                                </Container>
                            ))) : (null)}


                        </Row>

                    </ModalBody>
                </Modal>
                {this.state.ticketsList && this.state.ticketsList.length > 0 ? (
                    this.state.ticketsList.map((item, index) => (
                        <Row className="rowShadow" key={index} lg="12">

                            <Col lg="2" className="profilePictureTickets">
                                <img
                                    src={
                                        item.user_logo !== null &&
                                        item.user_logo !== undefined &&
                                        item.user_logo !== ""
                                            ? item.user_logo
                                            : profilePic
                                    }
                                    onError={e => {
                                        e.target.onerror = null;
                                        e.target.src = profilePic;
                                    }}
                                    className="eventProfilePicTickets"
                                    alt="profilePic"
                                    onClick={() => {
                                        this.showUserDetails(item.user_id);
                                    }}
                                />
                            </Col>
                            <Col lg="10">
                                <Col
                                    lg="4"
                                    className="priceDetailsText"
                                    onClick={() => {
                                        item.event_id
                                            ? this.showEventDetails(
                                            item.event_id,
                                            item.multiprice,
                                            item.user_logo
                                            )
                                            : this.showDealDetails(
                                            item.deal_id,
                                            item.multiprice,
                                            item.user_logo
                                            );
                                    }}
                                >
                                    Details...
                                </Col>
                                <Row>
                                    <Col lg="8" className="priceTitleText">
                                        {item.event_name || item.deal_name} -{" "}
                                        {this.findCategoryName(item.event_category) ||
                                        this.findCategoryName(item.deal_category)}{" "}
                                        {item.event_location || item.deal_location}
                                    </Col>

                                </Row>
                                {item.multiprice && item.multiprice.length > 0 ? (
                                        item.multiprice.map((price, index) => (
                                            <Row key={index}>
                                                <Col xs="6" sm="6" lg="6">
                                                    <div onClick={() => this.toggleTicket(
                                                        price.tickets,
                                                        item.event_location,
                                                        item.event_name,
                                                        item.deal_name,
                                                        price.indicative_price
                                                    )}
                                                         className="details ticketsNrBtn">Tickets
                                                        number: {price.number_of_tickets}</div>
                                                </Col>
                                                <Col xs="6" sm="6" lg="6">
                                                    <div className="details">Tickets price: {price.price}</div>
                                                </Col>
                                            </Row>)))
                                    : null}
                            </Col>
                        </Row>
                    ))
                ) : (
                    <div>No tickets purchased</div>
                )}

                <Modal
                    className="costume-modal-prof"
                    isOpen={this.state.showProfile}
                    // toggle={() => this.showUserDetails(this.state.userIdforModal)}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1"/>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.showUserDetails(0)}
                        />
                    </div>
                    <UserProfile
                        userId={this.state.userIdforModal}
                        openEventsCreated={this.openEventsCreated}
                        openEventsRegistered={this.openEventsRegistered}
                        openFriends={this.openFriends}
                        openDealsCreated={this.openDealsCreated}
                        closeHandler={() => this.showUserDetails(0)}
                    />

                </Modal>

                <Modal
                    isOpen={this.state.profileModal}
                    // toggle={this.toggleProfileModal}
                    size="lg"
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            {this.state.modalTitle}
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleProfileModal}/>
                    </div>
                    <ModalBody>
                        {this.state.showUsersCreatedEvents ? (
                            <div className="eventsArea">
                                <FlatList
                                    list={this.state.createdEventsList}
                                    renderItem={this.renderEvents}
                                ></FlatList>
                            </div>
                        ) : this.state.showUserRegisteredEvents ? (
                            <div className="eventsArea">
                                <FlatList
                                    list={this.state.registeredEventsList}
                                    renderItem={this.renderEvents}
                                ></FlatList>
                            </div>
                        ) : this.state.showUserDeals ? (
                            <Col className="d-flex justify-content-center">
                                <Calendar
                                    messages={{
                                        showMore: total => (
                                            <div
                                                style={{cursor: 'pointer'}}
                                                onMouseOver={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                            >SEE all deals
                                            </div>
                                        ),
                                    }}
                                    localizer={localizer}
                                    defaultDate={new Date()}
                                    defaultView="month"
                                    events={this.state.dealsDateList}
                                    style={{
                                        fontSize: "12px",
                                        height: "700px",
                                        width: "700px",
                                        backgroundColor: "#fff",
                                    }}
                                    selectable={true}
                                    //onSelectSlot={this.selectSlot}
                                    onSelectEvent={(event) => this.selectEvent(event)}
                                    components={{
                                        dateCellWrapper: ColoredDateCellWrapper,
                                    }}
                                    eventPropGetter={this.eventStyleGetter}
                                    views={{month: true}}
                                />
                            </Col>
                        ) : this.state.showUserFriends ? (
                            <div>

                                <Friends
                                    friendsList={this.state.friendsList2}
                                    filterVal={this.state.filterVal}
                                    totalFriendsNo={this.state.totalFriendsNo}
                                    refreshAllFriends={this.listoffriends}
                                    getAllFriendsEventHandler={this.getAllFriendsEventHandler}
                                    searchEventHandler={this.searchFriends}
                                    showBlockList={false}
                                />
                            </div>
                        ) : this.state.currentEvent !== undefined &&
                        !this.state.showVoucers &&
                        this.state.currentMultiprice !== null &&
                        this.state.currentEvent.date !== undefined ? (
                            <BannerDetailsNormal
                                currencyBannner={this.state.currentEvent.currency}
                                userLogged={true}
                                categoryName={this.state.currentEvent.name_of_subcategory}
                                price={this.props.priceTicket}
                                name={this.props.nameTicket}

                                id={this.state.currentEvent.id}
                                userIdE={this.state.currentEvent.user_id}
                                name={this.state.currentEvent.name}
                                gender={this.state.currentEvent.gender}
                                ageCategory={this.state.currentEvent.age_category}
                                startDate={this.state.currentEvent.date.substring(0, 11)}
                                endDate={this.state.currentEvent.end_date.substring(0, 11)}
                                startTime={this.state.currentEvent.start_hour.substring(0, 5)}
                                endTime={this.state.currentEvent.end_hour.substring(0, 5)}
                                latitude={this.state.currentEvent.latitude}
                                longitude={this.state.currentEvent.longitude}
                                team_number={this.state.currentEvent.team_number}
                                max_persons={this.state.currentEvent.max_persons}
                                persons_left={this.state.currentEvent.persons_left}
                                description={this.state.currentEvent.description}
                                multiprice={this.state.currentMultiprice}
                                address={this.state.currentEvent.address}
                                latitude={this.state.currentEvent.latitude}
                                longitude={this.state.currentEvent.longitude}
                                userLogo={this.state.currentLogo}
                                eventLogo={this.state.currentEvent.logo ? this.state.currentEvent.logo : this.state.currentEvent.category_logo}
                                category={this.state.currentEvent.category_id}
                                closeModal={this.toggleModalShowDetails}
                                reloadEventsCallback={this.reloadEventsCallback}
                                calendar={this.state.currentCalendar}
                                isLogged={true}
                            />
                        ) : null}
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.modalShowDetails}
                    // toggle={this.toogleModalShowDetails}
                    size="lg"
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            Event details
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={this.toogleModalShowDetails}
                        />
                    </div>

                    <ModalBody>
                        {this.state.currentEvent !== undefined &&
                        this.state.currentEvent.date !== undefined ? (
                            <BannerDetailsNormal
                                isLogged={true}
                                categoryName={this.state.currentEvent.name_of_subcategory}
                                userLogged={true}
                                id={this.state.currentEvent.id}
                                userIdE={this.state.currentEvent.user_id}
                                name={this.props.nameTicket}
                                gender={this.state.currentEvent.gender}
                                ageCategory={this.state.currentEvent.age_category}
                                startDate={this.state.currentEvent.date.substring(0, 11)}
                                endDate={
                                    this.state.currentEvent.end_date !== null
                                        ? this.state.currentEvent.end_date.substring(0, 11)
                                        : ""
                                }
                                // price={
                                //   !this.state.currentMultiprice ||
                                //     this.state.currentMultiprice.length === 0
                                //     ? "FREE"
                                //     : this.state.currentMultiprice.length === 1
                                //       ? this.state.currentMultiprice[0].price === "0"
                                //         ? "FREE"
                                //         : "PAY"
                                //       : "PAY"
                                // }
                                price={this.props.priceTicket}

                                startTime={this.state.currentEvent.start_hour.substring(0, 5)}
                                endTime={this.state.currentEvent.end_hour.substring(0, 5)}
                                latitude={this.state.currentEvent.latitude}
                                longitude={this.state.currentEvent.longitude}
                                team_number={this.state.currentEvent.team_number}
                                max_persons={this.state.currentEvent.max_persons}
                                persons_left={this.state.currentEvent.persons_left}
                                description={this.state.currentEvent.description}
                                multiprice={this.state.currentMultiprice}
                                address={this.state.currentEvent.address}
                                latitude={this.state.currentEvent.latitude}
                                longitude={this.state.currentEvent.longitude}
                                userLogo={this.state.eventLogo}
                                eventLogo={this.state.currentEvent.logo ? this.state.currentEvent.logo : this.state.currentEvent.category_logo}
                                category={this.state.currentEvent.category_id}
                                closeModal={this.toggleModalShowDetails}
                                reloadEventsCallback={this.reloadEventsCallback}
                                calendar={this.state.currentCalendar}
                                curentDealCalendarId={this.state.curentDealCalendarId}
                                isDeal={this.state.currentCalendar !== undefined ? true : false}
                            />
                        ) : null}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
