import React, { Component } from "react";
import {
  Input,
  Container,
  Col,
  Row,
  DropdownItem,
  InputGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Collapse,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import "./SearchBar.scss";
import ModernDatepicker from "react-modern-datepicker";
import moment from "moment";
import { fetchApi } from "../../../fetchApi.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ZingMap from '../../ZingMap/ZingMap.js'
import DatePicker from "react-modern-calendar-datepicker";

export default class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.ZingMapRef = React.createRef();
    this.state = {
      modalMakeEvents: false,
      categoryName: "",
      categoryId: "",
      width: window.innerWidth,
      isOpenColapse: false,
      supressBlur: false,
      compKey: -1

    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  toggle = () => {
    this.setState({ isOpenColapse: !this.state.isOpenColapse });

  }

    onChangeCategory = (evt, value) => {
        if (value != null) {
            this.props.onInputChange(value.name, value);
            this.setState({supressBlur: true});
        } else {
            this.setState({supressBlur: false});
        }

  };
  onChangeWord = (e) => {
      this.props.onInputChangeWord(e.target.value);

  };


  obBlurCategory = (evt) => {
    if (!this.state.supressBlur) {
      this.props.onInputChange(0, evt.target.value);
    }
    this.setState({ supressBlur: false });
  };

  resetSearchValues = () => {
    var rand = 1 + (Math.random() * 999);
    this.setState({ categoryName: "", categoryId: "", compKey: rand });
    this.ZingMapRef.current.resetLocation();
    this.props.resetFunction();
  }
  render() {

    const { width } = this.state;
    const isMobile = width <= 500;

    if (isMobile) {
      return (
        <div>
          <div className="mobileSearch" onClick={() => this.toggle()}>Search event <FontAwesomeIcon 
          icon={faSearch} className="ml-2" /></div>
          <Collapse isOpen={this.state.isOpenColapse}>
                        <div className="bg-white search-bar-div mobSearchBar ">
              <div className="row mrg-lft">
                                <Col className="d-flex align-items-center margin-left_divv py-2 searchBarParent">
                                    <Col xs="14" lg="3" md="3" sm="3"
                                         className="search-event-border divv-flex-mob bRadius pl-0">
                    <Autocomplete
                      key={this.state.compKey}
                      freeSolo={false}
                      id="combo-box-demo"
                      options={this.props.categories}
                      // getOptionLabel={option => option.name}
                      getOptionLabel={(option) =>
                        option.name_of_subcategory
                            ? "\xa0\xa0\xa0\xa0" + option.name_of_subcategory
                            : option.name
                    }
                                            className="drop-down-stl text-white autoSearch"
                      // style={{ width: 300, border:"none" }}
                      onChange={(event, value) => this.onChangeCategory(event, value)}
                      onBlur={(event, value) => this.obBlurCategory(event)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Category/Free text..."
                        />
                      )}
                    />
                    <InputGroupButtonDropdown
                      className="dropdown-stl"
                      addonType="append"
                      isOpen={this.props.dropdownOpen}
                      toggle={this.props.toggleDropDown}
                    >
                      <DropdownMenu persist>
                        {this.props.categories &&
                          this.props.categories.map((item) => (
                            <DropdownItem
                              key={item.id}
                              toggle={false}
                              onClick={this.props.onDropdownItem_Click}
                              dropdownvalue={item.name}
                            >
                              {item.name}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </InputGroupButtonDropdown>
                    <Col
                      lg="1"
                      md="1"
                      sm="1"
                      className="ml-2 rounded-circle searchContainer d-flex justify-content-center align-items-center mr-3 div-search-mob"
                      onClick={this.props.onSearchClick}
                    >
                      <img
                        src={require("../../../Assets/img/search.png")}
                        className="searchImg"
                      />
                    </Col>
                    <Col
                lg="1"
                md="1"
                sm="1"
                className="rounded-circle searchContainerBus d-flex justify-content-center align-items-center mr-3 div-search-mob"
                onClick={this.props.toggleSearchBusinsess}
              >
                <img
                  src={require("../../../Assets/img/search.png")}
                  className="searchImg"
                />
              </Col>
                  </Col>
                  <Col xs="12" lg="3" md="4" sm="3" className="pl-0 searchEventMobile">

                    <ZingMap

                    placeHolder="Search Places"
                      ref={this.ZingMapRef}
                      showSearchTextBox={true}
                      showOpenMapButton={false}
                                            inputClassName="borderSearchBar text-white"
                      buttonClassName=""
                      enableMarkerAdd={false}
                      selectedLocationHandler={(address, lat, lng) => { 
                      this.props.onWhereChange(address, lat, lng) 
                      }} />
                  </Col>

                                    <Col xs="12" lg="3" md="4" sm="3" className="datePicker pl-0">
                    <DatePicker
                      dateFormat={"YYYY-MM-DD"}
                      value={this.props.startDate}
                      onChange={date => this.props.handleChange(date)}
                      inputPlaceholder="When"
                      shouldHighlightWeekends
                      colorPrimary="#007bff"
                                            wrapperClassName="calendar"
                                            inputClassName="text-white"
                      style={{
                                                zIndex: "9999",
                      }}
                    />

                  </Col>
                  <Col xs="12" lg="3" md="4" sm="3" className="   pl-0">
                    <Input
                      onChange={(e) => {
                        this.onChangeWord(e);
                      }}
                      className="SearchWordInput-small"
                      placeholder="tags, categories added"
                      value={this.props.searchWord}
                    ></Input>
                  </Col>
                </Col>
              </div>
            </div>
          </Collapse>
        </div>
      );
    } else {

      return (
                <div className="bg-white search-bar-div  ">
          <div className="row noMargin">
            <Col className="d-flex align-items-center margin-left_divv py-2 searchBarParent">

                            <Col xs="12" lg="2" md="2" sm="2"
                                 className=" datePickerSearch-other search-event-border divv-flex bRadius p-0">
              
                <Autocomplete
                  key={this.state.compKey}
                  freeSolo={false}
                  id="combo-box-demo"
                  options={this.props.categories}
                  getOptionLabel={(option) =>
                    option.name_of_subcategory
                        ? "\xa0\xa0\xa0\xa0" + option.name_of_subcategory
                        : option.name
                }
                  // getOptionLabel={option => option.name}
                                    className="drop-down-stl text-white autoSearch"
                  // style={{ width: 300, border:"none" }}
                  onChange={(event, value) => this.onChangeCategory(event, value)}
                  onBlur={(event, value) => this.obBlurCategory(event)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      // placeholder="Category/Free text..."
                      placeholder="Category/Free text..."
                    />
                  )}
                />
                <InputGroupButtonDropdown
                  className="dropdown-stl"
                  addonType="append"
                  isOpen={this.props.dropdownOpen}
                  toggle={this.props.toggleDropDown}
                >
                  <DropdownMenu persist>
                    {this.props.categories && this.props.categories.map(item => (
                      <DropdownItem
                        key={item.id}
                        toggle={false}
                        onClick={this.props.onDropdownItem_Click}
                        dropdownvalue={item.name}
                      >
                        {item.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </InputGroupButtonDropdown>
              </Col>
              <Col xs="12" lg="2" md="2" sm="2" className="datePickerSearch-other p-0">

                <ZingMap
                  ref={this.ZingMapRef}
                  showSearchTextBox={true}
                  showOpenMapButton={false}
                  inputClassName="borderSearchBar "
                  buttonClassName=""
                  enableMarkerAdd={false}
                  selectedLocationHandler={(address, lat, lng) => { 
                  this.props.onWhereChange(address, lat, lng) 
                  }}></ZingMap>

              </Col>

              <Col
                xs="12"
                lg="2"
                md="2"
                sm="2"
                className="datePickerSearch pl-0 pr-0 d-flex justify-content-center"
              >
                <DatePicker
                  dateFormat={"YYYY-MM-DD"}
                  value={this.props.startDate}
                  onChange={(date) => this.props.handleChange(date)}
                  inputPlaceholder="When..."
                  shouldHighlightWeekends
                  colorPrimary="#007bff"
                  inputClassName="calendar "
                  style={{
                    zIndex: "9999",

                  }}

                />
              </Col>
              <Col
                xs="12"
                lg="2"
                md="2"
                sm="2"
                className="datePickerSearch pl-0 pr-0 d-flex justify-content-center"
                            >
                <Input
                  onChange={(e) => {
                    this.onChangeWord(e);
                  }}
                  // onChange={(event, value) => this.onChangeWord(event, value)}
                  id="inptID"
                  className="SearchWordInput"
                  placeholder="tags, categories added"
                  value={this.props.searchWord}
                ></Input>
              </Col>

              <Col
                lg="1"
                md="1"
                sm="1"
                className="rounded-circle searchContainer d-flex justify-content-center align-items-center mr-3 div-search"
                onClick={this.props.onSearchClick}
              >
                <img
                  src={require("../../../Assets/img/search.png")}
                  className="searchImg"
                />
              </Col>
              <Col
                lg="1"
                md="1"
                sm="1"
                className="rounded-circle searchContainerBus d-flex justify-content-center align-items-center mr-3 div-search"
                onClick={this.props.toggleSearchBusinsess}
              >
                <img
                  src={require("../../../Assets/img/search.png")}
                  className="searchImg"
                />
              </Col>
              {/* toggleSearchBusinsess */}
            </Col>
          </div>
        </div>
      );

    }
  }
}
