import React, {Component} from "react";
import {
    Col,
    Row,
    Button,
    ModalBody,
    Modal,
} from "reactstrap";
import GoogleMapReact from "google-map-react";
import ZingMapMarker from "./ZingMapMarker/ZingMapMarker.js";
import "./ZingMap.scss";
import Dialog from "@material-ui/core/Dialog";
import {InputGroupAddon, InputGroupText, ModalHeader} from "reactstrap";
import LocationSearchInput from "./LocationSearchInput/LocationSearchInput.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faMapMarkerAlt
    , faCheck, faLock, faCreditCard, faEuroSign, faDollarSign
} from "@fortawesome/free-solid-svg-icons";
import {DialogContent} from "@material-ui/core";
import LocationSave from "./LocationSave/LocationSave.js";
import ReactTooltip from "react-tooltip";
import {userCredentials,sliderState} from "../../globalVariables.js";
import moment from "moment"
import {fetchApi} from "../../fetchApi.js";
import swal from "sweetalert";
import UserProfile from "../BothUsers/UserProfile/UserProfile";

import AddCard from "../BothUsers/CardsManagement/AddCard";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import ReactDOM from "react-dom";

let SLogo = require("../../Assets/img/SLogo.png");
let isMarkerClick = false;
export default class ZingMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearchTextBox: this.props.showSearchTextBox,
            showOpenMapButton: this.props.showOpenMapButton,
            showZingMap: this.props.openBackModal,
            showLocationSave: false,
            locationsList: [],
            currentpointlat: 0,
            currentpointlng: 0,
            currentaddress: "",
            enableMarkerAdd: this.props.enableMarkerAdd,
            disableSelect: this.props.disableSelect,
            myModal: false,
            openDialog: true,
            businessUserId: null,
            openBusinessModal: false,
            openDeals: false,
            cardsList: [],
            locValue: "",
            monthsNo: "",
            cost: "",
            showAddCard: false
        };

        this.locationSearchInputHref = React.createRef();
    }

    toggleMyModal = () => {
        //if (this.state.myModal) {
        this.setState({
            showZingMap: false,
            showSaveLocation: false,
        });
        if (this.props.toggleMyModal) {
            this.props.toggleMyModal(true);
        }

        // } else {
        //   this.setState({
        //     myModal: !this.state.myModal,
        //     showZingMap: true,
        //     showSaveLocation: true,
        //   });
        // }
    };

    getUserCards = async () => {
        let authKey = userCredentials.authKey;
        const url = "listofcardsperuser";
        const data = new FormData();
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            this.setState({cardsList: response.cards});
        }
    };

    componentDidMount = () => {
        this.getUserCards();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.list !== prevProps.list) {
            this.setState({locationsList: this.props.list});
        }
    };

    showZingMap = () => {

        sliderState.canUseSlider=false;
        this.setState({showZingMap: true});
    };

    closeZingMap = () => {
        sliderState.canUseSlider=true;
        this.setState({showZingMap: false});
        if (this.props.toggleMyModal) {
            this.props.toggleMyModal(false);
        }

    };

    closeZingFromMarker = async (userId) => {
        let userIsLogged = await localStorage.getItem("userIsLogged");
        if (userIsLogged !== "true") {
            if (userId) {
                this.setState({showZingMap: false});
                if (this.props.selectBusinessUser) {
                    this.props.selectBusinessUser(userId);
                }
            }
        } else if (userId) {
            this.setState({
                showZingMap: false,
                businessUserId: userId,
                openBusinessModal: true,
            });
        }
    };

    closeModalUser = () => {
        this.setState({openBusinessModal: false, openDeals: false});
    };

    zingMapClick = ({lat, lng, event}) => {

        if (!isMarkerClick && this.state.enableMarkerAdd && event.target.className !== "closeLocation") {
            this.setState({showLocationSave: true});
        }

        isMarkerClick = false;
        this.setState({currentpointlat: lat});
        this.setState({currentpointlng: lng});
        ReactTooltip.hide();


    };

    getCardFirst = (locValue, monthsNo, cost) => {

        this.setState({locValue: locValue, monthsNo: monthsNo, cost: cost});

        const moneySign = (
            <div>
                <FontAwesomeIcon icon={faEuroSign}/>
            </div>
        );

        let contentObj = (<div className="swalElement">Total amount to pay:&nbsp; {moneySign} {cost}  </div>);
        let card = this.state.cardsList;
        let wrapper = document.createElement('div');
        ReactDOM.render(contentObj, wrapper);
        let element = wrapper.firstChild;


        if (card && card.length > 0) {
            swal({
                title: "Do you want to use an existing card?",
                content: element,
                icon: "warning",
                buttons: {
                    add: {
                        text: "Yes",
                        value: 2
                    },
                    select: {
                        text: "No",
                        value: 1
                    }
                }
            }).then(val => {
                if (val === 1) {
                    this.closeZingMap();
                    this.showAddCard();
                } else if (val === 2) {
                    this.closeZingMap();
                    this.showSelectCard();
                }
            });
        } else {
            this.closeZingMap();
            this.showAddCard();
        }

    };

    showAddCard = () => {

        if (!this.state.userDeactivated) {
            this.setState({showAddCard: true});
        } else {
            swal("Error!", "Invalid operation!", "error");
        }
    };

    toggleAddCard = (needRefresh, cardId) => {
        this.setState({showAddCard: false});
        if (needRefresh === true) {
            this.saveLoacionAndPay(this.state.locValue, this.state.monthsNo, this.state.cost, cardId);
        }
    };
    showSelectCard = () => {
        this.setState({showSelectCard: true});
    };
    toggleSelectCard = () => {
        this.setState({showSelectCard: false});
    };

    showTermsAndCondition = (isTermsWindow) => {
        const doesShow = this.state.showTerms;
        this.setState({showTerms: !doesShow});
        this.setState({isTerms: isTermsWindow});
    };

    setDefaultCard = async (cardid) => {
        const oldIndex = this.state.cardsList.findIndex((s) => {
            return s.default == true;
        });

        let authKey = userCredentials.authKey;
        const url = "setdefaultcardperuser";
        const data = new FormData();
        data.append("card_id", cardid);
        const response = await fetchApi(url, "POST", data, authKey);
        if (response.success) {
            const newDefaultIndex = this.state.cardsList.findIndex((s) => {
                return s.id === cardid;
            });

            if (oldIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.default == true ? {...el, default: false} : el
                    ),
                }));
            }
            if (newDefaultIndex !== -1) {
                this.setState((prevState) => ({
                    cardsList: prevState.cardsList.map((el) =>
                        el.id == cardid ? {...el, default: true} : el
                    ),
                }));
            }
            swal({
                title: "Card successfully set as default!",
                icon: "success",
                buttons: {
                    Ok: {
                        text: "Ok",
                        value: 1
                    },
                }
            }).then(val => {
                if (val === 1) {
                    //  this.toggleSelectCard();
                    //  this.purchaseTicket(this.state.cardMultiPrice, this.state.cardPrice);
                }
            });

        } else {
            swal("Error!", "Error on set default card!", "error");
        }
    };

    saveLoacionAndPay = async (locValue, monthsNo, cost, cardId) => {
        let card = this.state.cardsList.filter(
            (element) => element.default === true
        );


        let authKey = userCredentials.authKey;
        const url = "addbusinesslocation";
        let data = new FormData();

        data.append("card_id", cardId ? cardId : card[0].id);
        data.append("amount", cost);
        data.append("currency", "EUR");
        data.append("user_id", userCredentials.userId);
        data.append("business_id", 1);
        data.append("offert_id", 0);
        data.append("event_id", 0);
        data.append("multiprice_event_id", 0);
        data.append("deal_id", 0);
        data.append("deal_calendar_id", 0);
        data.append("deal_multiprice_id", 0);
        data.append("tickets_number", 0);
        data.append("type_transaction", 4);

        // data.append("business_user_id", userCredentials.userId);
        data.append("address", locValue);
        data.append("latitude", this.state.currentpointlat);
        data.append("longitude", this.state.currentpointlng);
        data.append("start_date", moment().format("YYYY-MM-DD"));
        data.append("end_date", moment().add(1, 'M').format("YYYY-MM-DD"));
        // data.append("amount_value", cost);


        const response = await fetchApi(url, "POST", data, authKey);

        if (response.success) {

            swal("Success!", "Location saved!", "success");
            this.saveLocationHandler(locValue, monthsNo, cost);

        } else {
            swal("Error!", "Something went wrong. Please try again!", "error");
        }

    };

    saveLocationHandler = (locValue, monthsNo, cost) => {

        if (!locValue || locValue.trim() == "") {
            this.setState({showLocationSave: false});
            return;
        }
        if (this.state.currentpointlat !== 0 && this.state.currentpointlng !== 0) {
            this.setState({showLocationSave: false});
            let newlist = this.state.locationsList;
            newlist = newlist.concat({
                address: locValue,
                latitude: this.state.currentpointlat,
                longitude: this.state.currentpointlng,
            });

            this.setState({locationsList: newlist});
            this.setState({showLocationSave: false});
            this.setState({currentpointlat: 0,currentpointlng: 0,currentaddress:""});

        }

    };

    // saveBULocation = async (locValue, monthsNo, cost) => {
    //     let response = null;
    //
    //     const data = new FormData();
    //     const url = "addbusinesslocation";
    //     // data.append("business_user_id", userCredentials.userId);
    //     // data.append("address", locValue);
    //     // data.append("latitude", this.state.currentpointlat);
    //     // data.append("longitude", this.state.currentpointlng);
    //     // data.append("start_date", moment().format("YYYY-MM-DD"));
    //     // data.append("end_date", moment().add(1, 'M').format("YYYY-MM-DD"));
    //     // data.append("amount_value", cost);
    //
    //     response = await fetchApi(url, "POST", data, userCredentials.authKey);
    //
    //     if ((response && response.success)) {
    //         swal("Success!", "Location saved!", "success");
    //     } else {
    //         swal("Error!", "Error on save location!", "error");
    //     }
    // };

    cancelLocationHandler = () => {
        this.setState({showLocationSave: false, currentaddress: "", currentpointlag :0, currentpointlng:0});
    };

    markerClickHandler = (address, lat, lng) => {
        isMarkerClick = true;
        this.setState({currentaddress: address});
        if (this.props.showSearchTextBox)
            this.locationSearchInputHref.current.handleSelect(address);
        this.props.selectedLocationHandler(address, lat, lng);
        this.closeZingMap();
    };

    changeLocationHandler = (address, lat, lng) => {
        isMarkerClick = true;
        this.setState({currentaddress: address});
        this.props.selectedLocationHandler(address, lat, lng);
        this.closeZingMap();
    };

    selectPlace = (address, lat, lng) => {
        isMarkerClick = false;
        this.setState({currentaddress: address});
        if ((lat && lat !== 0) && (lng && lng !== 0)) {
            if (!isMarkerClick && this.state.enableMarkerAdd) {
                this.setState({showLocationSave: true});
            }
            isMarkerClick = false;
            this.setState({currentpointlat: lat});
            this.setState({currentpointlng: lng});
            ReactTooltip.hide();
        }


    };

    resetLocation = () => {
        this.setState({
            currentpointlat: 0,
            currentpointlng: 0,
            currentaddress: ""
        });

        this.locationSearchInputHref.current.resetLocation();
    };

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.openBackModal) {
            this.setState({showZingMap: this.props.openBackModal});
        }
    };

    openUserDeals = (id) => {
        this.setState({
            openBusinessModal: true,
            businessUserId: id,
            openDeals: true,
        });
    };


    render() {
        const locationsListMarkers = this.state.locationsList.map((item, index) => {
            return (
                <ZingMapMarker
                    key={index}
                    lat={item.latitude}
                    lng={item.longitude}
                    MarkerText={item.address}
                    markerKey={item.id}
                    markerClickHandler={this.markerClickHandler}
                    closeMap={this.closeZingFromMarker}
                    disableSelect={this.props.disableSelect}
                    locItem={item}
                    markerLat={this.props.markerLat === item.latitude}
                    markerLng={this.props.markerLng === item.longitude}
                    openUserDeals={this.openUserDeals}
                    showDeals={this.props.showDeals}
                />
            );
        });

        const gMapsElement = (
            <div className="zingMapParent">
                <div
                    className="zingMapContainer"
                    style={{height: "100vh", width: "100%"}}
                >
                    {this.state.showLocationSave ? (
                        <LocationSave
                            currentSelectedAddress={this.state.currentaddress}
                            showSaveLocation={this.state.showLocationSave}
                            saveLocationHandler={this.getCardFirst}
                            cancelLocationHandler={this.cancelLocationHandler}
                            openModal={this.toggleMyModal}
                        />
                    ) : null}
                    <GoogleMapReact
                        onClick={this.zingMapClick}
                        yesIWantToUseGoogleMapApiInternals
                        bootstrapURLKeys={{key: ""}}
                        defaultCenter={{
                            lat: this.props.centerLat
                                ? parseFloat(this.props.centerLat)
                                : 47.64,
                            lng: this.props.centerLng
                                ? parseFloat(this.props.centerLng)
                                : 26.23,
                        }}
                        defaultZoom={14}
                    >
                        {locationsListMarkers}
                    </GoogleMapReact>
                    <ul className="legendContainer">
                        <li>
                            Click on the{" "}
                            <span className="zingMapMarkerLegend">
                                <FontAwesomeIcon icon={faMapMarkerAlt} size="2x"/>
                            </span>{" "}
                            to select location.
                        </li>

                        {this.props.enableMarkerAdd ? (
                            <div>
                                <li>Click on the map to add location.</li>
                                <div className="selectPlaceMap location-search-input">
                                    <LocationSearchInput
                                        placeHolder={"Select location"}
                                        ref={this.locationSearchInputHref}
                                        address={this.state.currentaddress}
                                        onChangeLocation={this.selectPlace}
                                    />
                                </div>

                            </div>

                        ) : null}
                    </ul>
                </div>
            </div>
        );
        return (
            <div className="zingMapComponent">
                <Modal
                    isOpen={this.state.showAddCard}
                    size="lg"
                    // toggle={this.toggleAddCard}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">Add card</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleAddCard}/>
                    </div>
                    <ModalBody>
                        <AddCard handleCancel={this.toggleAddCard}/>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.showSelectCard}
                    size="lg"
                    // toggle={this.toggleSelectCard}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">
                            Select card
                        </ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleSelectCard}/>
                    </div>
                    <ModalBody>
                        <div className="cardDetailsParent">
                            <Row>
                                <Col>
                                    {this.state.cardsList.length > 0 ? (
                                        this.state.cardsList.map((item) => (
                                            <Row
                                                key={item.id}
                                                className="d-flex align-items-center voucherContainer cardsListContainer"
                                                onClick={() => {
                                                    this.setDefaultCard(item.id);
                                                }}
                                            >
                                                <Col xs="6">
                                                    <div>{item.name}</div>
                                                </Col>
                                                <Col xs="2">
                                                    {item.exp_month}/{item.exp_year}
                                                </Col>
                                                <Col xs="2">{item.last4}</Col>
                                                <Col xs="2">
                                                    {item.default ? (
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className="mr-2"
                                                        />
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        ))
                                    ) : (
                                        <div>No cards saved</div>
                                    )}
                                </Col>
                            </Row>

                            <Row className="my-5">
                                <Col lg="6" className="leftPart">
                                    <div>
                                        <Button
                                            className="addCardBtn"
                                            onClick={() => {
                                                this.toggleSelectCard();
                                                this.saveLoacionAndPay(this.state.locValue, this.state.monthsNo, this.state.cost);
                                            }}
                                        >
                                            Pay
                                        </Button>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="text">
                                        <span className="cardImg">
                                            <FontAwesomeIcon icon={faLock} className="mr-2"/>
                                        </span>
                                        Safe and in control
                                    </div>
                                    <div className="text">
                                        <span className="cardImg">
                                            <FontAwesomeIcon icon={faCreditCard} className="mr-2"/>
                                        </span>{" "}
                                        Fast and simple
                                    </div>
                                </Col>
                            </Row>
                            <div className="terms text-center">
                                <a>
                                    I accept Zing Sport &nbsp;
                                    <a
                                        href="javascript:void(0);"
                                        onClick={() => this.showTermsAndCondition(true)}
                                    >
                                        Terms and Conditions
                                    </a>
                                    &nbsp; by adding a card
                                </a>
                            </div>
                            <Modal
                                className="costume-modal-prof"
                                isOpen={this.state.showTerms}
                                // toggle={() => this.showTermsAndCondition(false)}
                            >
                                <div className="header-p">
                                    <ModalHeader className="head-1"/>
                                    <div className="logo_image">
                                        <img className="my_logo" src={SLogo} alt="my_logo"/>
                                    </div>
                                    <ModalHeader
                                        className="head-2"
                                        toggle={() => this.showTermsAndCondition(false)}
                                    />
                                </div>
                                <ModalBody>
                                    <TermsAndConditions
                                        isTerms={this.state.isTerms}></TermsAndConditions>
                                </ModalBody>
                            </Modal>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    className="costume-modal-prof"
                    isOpen={this.state.openBusinessModal}
                    // toggle={() => this.closeModalUser()}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1"/>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader
                            className="head-2"
                            toggle={() => this.closeModalUser()}
                        />
                    </div>

                    <UserProfile
                        userId={this.state.businessUserId}
                        closeHandler={() => this.closeModalUser()}
                        openDeals={this.state.openDeals}
                    />
                </Modal>
                {this.state.showSearchTextBox ? (
                    <div className={this.props.inputClassName}>
                        <LocationSearchInput
                            placeHolder={this.props.placeHolder}
                            ref={this.locationSearchInputHref}
                            address={
                                this.props.initialAddress
                                    ? this.props.initialAddress
                                    : this.state.currentaddress
                            }
                            onChangeLocation={this.changeLocationHandler}
                        />
                        {this.state.showOpenMapButton ? (
                            <InputGroupAddon
                                addonType="append"
                                onClick={this.showZingMap}
                                className={this.props.buttonClassName}
                            >
                                <InputGroupText>
                                    <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                </InputGroupText>
                            </InputGroupAddon>
                        ) : null}
                    </div>
                ) : null}
                {!this.state.showSearchTextBox && this.state.showOpenMapButton ? (
                    <Button
                        onClick={this.showZingMap}
                        className={this.props.buttonClassName}
                    >
                        {this.props.buttonText}
                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                    </Button>
                ) : null}
                <Dialog
                    className="locationDialog"
                    open={this.state.showZingMap ? this.state.showZingMap : false}
                    maxWidth="lg"
                    fullWidth
                    onBackdropClick={this.closeZingMap}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white"></ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.closeZingMap}/>
                    </div>
                    <DialogContent>{gMapsElement}</DialogContent>
                </Dialog>
            </div>
        );
    }
}
