import React, {Component} from "react";

import {
    Input,
    Modal,
    Button,
    ButtonGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    ModalBody,
    Container,
    Row,
    Col,
    ModalHeader,
    FormFeedback,
    FormGroup,
    Label,
} from "reactstrap";
import "./MakeEvents.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModernDatepicker from "react-modern-datepicker";

import {
    faCalendarAlt,
    faMapMarkerAlt,
    faWheelchair,
    faMale,
    faFemale,
    faRestroom,
} from "@fortawesome/free-solid-svg-icons";

import Script from "react-load-script";
import TimePicker from "react-times";
import "react-times/css/material/default.css";
import {fetchApi} from "../../../fetchApi";
import moment from "moment";

import "./MakeEvents.scss";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import {userCredentials} from "../../../globalVariables";
import ZingMap from "../../ZingMap/ZingMap.js";
import swal from "sweetalert";
import DatePicker from "react-modern-calendar-datepicker";
import UserProfile from "../../BothUsers/UserProfile/UserProfile";
import {configOptions} from "final-form";

let profilePic = require("../../../Assets/img/profilePic.jpg");
let SLogo = require("../../../Assets/img/SLogo.png");

export default class MakeEvents extends Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.state = {
            name: "",
            dropdownCategoriiOpen: false,
            categorii: "",
            dropdownCategoryOpen: false,
            category: "",
            dropdownTeamOpen: false,
            team: "",
            dropdownNumberOpen: false,
            number: "",
            dropdownAgeOpen: false,
            age: this.props.ageCategoryList[0],
            dropdownHourOpen: false,
            hour: "",
            dropdownMinuteOpen: false,
            minute: "",
            startDate: null,
            endDate: null,
            startTime: "00:00",
            endTime: "00:00",
            modal: false,
            latitude: "0",
            longitude: "0",
            locationList: [
                {lat: 47.640007, lng: 26.242621},
                {lat: 47.637751, lng: 26.246569},
            ],
            location: "",
            gender: "1",
            description: "",
            price: 0,
            genderList: [],
            ageCategoryId: "1",
            categories: [],
            categoryId: null,
            alliasId: "",
            isHandicap: "1",
            isHandicapTrue: false,
            userId: 70,
            showFirstGroup: true,
            showSecondGroup: false,
            showThirdGroup: false,
            showFourthGroup: false,
            showAlertDate: false,
            showAlertDescription: false,
            showAlertGeneral: false,
            categoryName: "",
            // categories: "",
            eventName: "",
            zingMapList: [],
            startTrue: false,
            zingMapList2: [],
        };
    }

    toggleSeeOnApp = () => {
        this.setState({seeOnAppModal: !this.state.seeOnAppModal});
    };

    openFullscreen = () => {
        if (this.mapRef.current.requestFullscreen) {
            this.mapRef.current.requestFullscreen();
        } else if (this.mapRef.current.mozRequestFullScreen) {
            /* Firefox */
            this.mapRef.current.mozRequestFullScreen();
        } else if (this.mapRef.current.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            this.mapRef.current.webkitRequestFullscreen();
        } else if (this.mapRef.current.msRequestFullscreen) {
            /* IE/Edge */
            this.mapRef.current = window.top.document.body; //To break out of frame in IE
            this.mapRef.current.msRequestFullscreen();
        }
    };

    selectMale = () => {
        this.setState({gender: "1"});
    };

    selectFemale = () => {
        this.setState({gender: "2"});
    };
    seletUnisex = () => {
        this.setState({gender: "3"});
    };

    selectHandicapYes = () => {
        // if(this.state.isHandicap){
        this.setState({isHandicapTrue: !this.state.isHandicapTrue});
        this.selectHandicapNo();
    };

    selectHandicapNo = () => {
        if (this.state.isHandicapTrue === true) {
            this.setState({isHandicap: "1"});
        } else {
            this.setState({isHandicap: "0"});
        }
    };

    //   selectHandicapNo = () => {
    //     this.setState({isHandicap: "2"});
    // };

    onChangeWhereLocation = (address, lat, lng) => {
        this.setState({centerLat: lat, centerLng: lng});
    };

    onChangeLocation = (address, lat, lng) => {
        this.setState({location: address});
        this.setState({latitude: lat});
        this.setState({longitude: lng});
        this.getAllLocations(this.state.categoryId);
    };
    onChangeDescription = (evt) => {
        this.setState({description: evt.target.value});
    };

  handleFirstGroup = () => {
    if (this.state.eventName === "") {
      this.setState({ isValidEventName: true });
    }
    if (this.state.location === "") {
      this.setState({ isValidLocation: true });
    }
    if (this.state.category === "") {
      this.setState({ isValidCategory: true });
    }
    if (this.state.team === "" || this.state.team <= 0) {
      this.setState({ isValidTeam: true });
    }
    if (
      this.state.number === "" ||
      this.state.number <= 0 ||
      this.state.number === "0"
    ) {
      this.setState({ isValidNumber: true });
    }
    if (
      this.state.location !== "" &&
      this.state.category !== "" &&
      this.state.team !== "" &&
      this.state.team >= 0 &&
      this.state.number !== "" &&
      this.state.number >= 0 &&
      this.state.number !== "0" &&
      this.state.eventName !== ""
    ) {
      this.setState({
        showFirstGroup: false,
        showSecondGroup: true,
        groupOneIsValid: true,
      });
    } else {
      this.setState({ showAlertGeneral: true });
    }
  };
  handleSecondGroup = () => {
    if (this.state.age !== "") {
      this.setState({
        showSecondGroup: false,
        showThirdGroup: true,
        groupTwoIsValid: true,
      });
    }
  };
  handleThirdGroup = () => {
    //  let cd = new Date(moment().format("YYYY-MM-DD"));

        let curentDate = moment();

        let d1, d2;
        if (this.state.startDate !== null && this.state.startTime !== null) {
            d1 = new Date(
                this.state.startDate.year +
                "-" +
                this.state.startDate.month +
                "-" +
                this.state.startDate.day +
                " " +
                this.state.startTime
            );
        } else {
            this.setState({showAlertDate: true, isValidDate: true});
        }

        if (this.state.endDate !== null && this.state.endTime !== null) {
            d2 = new Date(
                this.state.endDate.year +
                "-" +
                this.state.endDate.month +
                "-" +
                this.state.endDate.day +
                " " +
                this.state.endTime
            );
        } else {
            this.setState({showAlertDate: true, isValidDate: true});
        }

        let firstDate = moment(d1);
        let lastDate = moment(d2);

        // let t1 = this.state.startTime.toString();
        // let t2 = this.state.endTime.toString();

        if (curentDate.isBefore(firstDate) && firstDate.isBefore(lastDate)) {
            this.setState({
                showThirdGroup: false,
                showFourthGroup: true,
                groupThreeIsValid: true,
            });
        } else {
            this.setState({showAlertDate: true, isValidDate: true});
        }
        // if (d1 >= cd && d2 >= cd) {
        //     if (d1 < d2) {
        //
        //     } else if (d1.getTime() === d2.getTime() && t1 < t2) {
        //         this.setState({
        //             showThirdGroup: false,
        //             showFourthGroup: true,
        //             groupThreeIsValid: true,
        //         });
        //     } else if (d1.getTime() === d2.getTime() && t1 >= t2) {
        //
        //     } else {
        //         this.setState({showAlertDate: true, isValidDate: true});
        //     }
        // } else {
        //     this.setState({showAlertDate: true, isValidDate: true});
        // }
    };

    getCategories = async () => {
        const url = "get_firstlevelcategoriesandalias";
        let authKey = userCredentials.authKey;
        let data = new FormData();
        data.append("user_id", userCredentials.userId);

        const response = await fetchApi(url, "POST", data, authKey);
        //console.log(response);
        let list = [],
            alias = [],
            categs;
        if (response.success) {
            for (let i = 0; i < response.list_of_categories.length; i++) {
                categs = response.list_of_categories[i].category;
                list.push(categs);
                for (let j = 0; j < response.list_of_categories[i].alias.length; j++) {
                    alias = response.list_of_categories[i].alias[j];
                    if (alias.name_of_subcategory.length !== 0) {
                        list.push(alias);
                    }
                }
            }
            this.setState({categories: list});
        }
    };

    verifyEnteries = () => {
        if (this.state.name === "") {
            return false;
        } else if (this.state.startDate === "") {
            return false;
        } else if (this.state.description === "") {
            return false;
        } else if (this.state.category === "") {
            return false;
        } else if (this.state.team === "") {
            return false;
        } else if (this.state.number === "") {
            return false;
        } else if (this.state.ageCategoryId === "") {
            return false;
        } else if (this.state.categoryId === "") {
            return false;
        } else {
            return true;
        }
    };

    position = async () => {
        await navigator.geolocation.getCurrentPosition((position) =>
            this.setState(
                {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                }
                //newState => console.log(newState)
            )
        );
    };

    verifyForBadWords = (text) => {
        let badWords = this.state.forbidenWords.split(";");
        let count = 0;
        if (text && text.length !== 0) {
            badWords.forEach((element) => {
                if (text.includes(element)) {
                    count++;
                }
            });

            if (count === 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    doneHandler = () => {
        this.makeEventHandler();
    };

    updateHandler = () => {
        this.updateEventHandler();
    };

    makeEventHandler = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let object = [
            {
                price: 0,
                nOf: this.state.number,
                indicativePrice: "normal",
            },
        ];
        let newData = JSON.stringify(object);
        if (
            this.state.description === "" ||
            !this.verifyForBadWords(this.state.description)
        ) {
            this.setState({isValidDescription: true});
        } else {
            this.setState({isValidDescription: false});
        }
        if (
            this.state.description !== "" &&
            this.verifyForBadWords(this.state.description)
        ) {
            if (this.state.alliasId) {
                data.append("alias_category_id", this.state.alliasId);
            }
            // if (this.state.isHandicap) {
            //   data.append("handicap_facilities ", 1);
            // }
            data.append("category_id", this.state.categoryId);
            data.append("name", this.state.eventName);
            data.append("country", 1);
            data.append("address", this.state.location);
            data.append("latitude", this.state.latitude);
            data.append("longitude", this.state.longitude);
            data.append(
                "date",
                this.state.startDate.year +
                "-" +
                this.state.startDate.month +
                "-" +
                this.state.startDate.day +
                " " +
                this.state.startTime
            );
            data.append(
                "end_date",
                this.state.endDate.year +
                "-" +
                this.state.endDate.month +
                "-" +
                this.state.endDate.day +
                " " +
                this.state.endTime
            );
            data.append("start_hour", this.state.startTime);
            data.append("end_hour", this.state.endTime);
            data.append("description", this.state.description);
            data.append("user_id", userCredentials.userId);
            data.append("currency", "euro");
            data.append("team_number", this.state.team);
            data.append("max_persons", this.state.number);
            data.append("persons_left", this.state.number);
            data.append("gender", this.state.gender);
            data.append("age_category", this.state.ageCategoryId);
            if (this.state.alliasId) {
                data.append("alias_category_id", this.state.alliasId);
            data.append("name_of_subcategory", this.state.categoryName);
            }
            data.append("multiprice", newData);
            data.append("handicap_facilities", this.state.isHandicap);

            const url = "addevent";
            const response = await fetchApi(url, "POST", data, authKey);

            if (!response.success) {
                swal("Error!", "Could not create event!", "error");
            } else {
                swal("Success!", response.message, "success");
                this.props.reloadEventsCallback();
            }
        } else {
            this.setState({showAlertDescription: true});
        }
    };

    updateEventHandler = async () => {
        let authKey = userCredentials.authKey;
        let data = new FormData();
        let object = {
            price: 0,
            nOf: 0,
            indicativePrice: "price for normal event",
        };
        if (
            this.state.description === "" ||
            !this.verifyForBadWords(this.state.description)
        ) {
            this.setState({isValidDescription: true});
        } else {
            this.setState({isValidDescription: false});
        }
        let newData = JSON.stringify(object);
        if (
            this.state.description !== "" &&
            this.verifyForBadWords(this.state.description)
        ) {
            data.append("name", this.state.eventName);
            data.append("country", 1);
            data.append("category_id", this.state.categoryId);
            data.append("address", this.state.location);
            data.append("latitude", this.state.latitude);
            data.append("longitude", this.state.longitude);
            data.append(
                "date",
                this.state.startDate.year +
                "-" +
                this.state.startDate.month +
                "-" +
                this.state.startDate.day +
                " " +
                this.state.startTime
            );
            data.append(
                "end_date",
                this.state.endDate.year +
                "-" +
                this.state.endDate.month +
                "-" +
                this.state.endDate.day +
                " " +
                this.state.endTime
            );
            data.append("start_hour", this.state.startTime);
            data.append("end_hour", this.state.endTime);
            data.append("description", this.state.description);
            data.append("user_id", userCredentials.userId);
            data.append("currency", "euro");
            data.append("team_number", this.state.team);
            data.append("max_persons", this.state.number);
            data.append("persons_left", this.props.eventToUpdate.persons_left);
            data.append("gender", this.state.gender);
            data.append("age_category", this.state.ageCategoryId);
            data.append("event_id", this.props.eventToUpdate.id);
            data.append("multiprice", newData);
            data.append("handicap_facilities", this.state.isHandicap);
            const url = "updateevent";
            const response = await fetchApi(url, "POST", data, authKey);
            if (response.success) {
                this.props.reloadEventsCallback();
                swal("Success!", "The event has been updated", "success");
            } else {
                swal("Error!", "Could not update event!", "error");
            }
        } else {
            this.setState({showAlertDescription: true});
        }
    };

    getGender = async () => {
        const url = "genderlist";
        const response = await fetchApi(url, "POST", null);
        if (response.success) {
            this.setState({genderList: response.gender});
        }
    };

    toggleAlertDate = () => {
        this.setState({showAlertDate: !this.state.showAlertDate});
    };

    toggleAlertDescription = () => {
        this.setState({showAlertDescription: !this.state.showAlertDescription});
    };
    toggleAlertGeneral = () => {
        this.setState({showAlertGeneral: !this.state.showAlertGeneral});
    };

    onChangePrice = (evt) => {
        this.setState({price: evt.target.value});
    };

    onChangeEventName = (evt) => {
        this.setState({eventName: evt.target.value});
    };

    getEventsLocation = async () => {
        const data = new FormData();
        const url = "filternormalevents";
        data.append("address", "");
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("date", "");
        data.append("category", 0);
        data.append("user_id", 0);
        const response = await fetchApi(url, "POST", data);

        if (response.success) {
            let arr = [];
            response.events.forEach((element) => {
                let result = this.checkForDuplicate(arr, element.address);
                if (result) {
                    arr.push({
                        description: element.address,
                        lat: element.latitude,
                        lng: element.longitude,
                    });
                }
            });
            this.setState({zingMapList: arr});
        }
    };

    getAllLocations2 = async (category) => {
        //console.log("response");
        const data = new FormData();
        const url = "getalllocations";
        data.append("latitude", "");
        data.append("longitude", "");
        data.append("category", category ? category : "");
        const response = await fetchApi(url, "POST", data);

        if (
            response.success &&
            response.locations &&
            response.locations.length > 0
        ) {
            this.setState({zingMapList2: response.locations});
        }
    };

    checkForDuplicate = (arr, value) => {
        let res = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].description.toLowerCase() === value.toLowerCase()) {
                res++;
            }
        }
        if (res === 0) {
            return true;
        } else {
            return false;
        }
    };

    getAllLocations = async (categId) => {
        //console.log("response");
        const data = new FormData();
        const url = "getalllocations";

        data.append("category", categId);

        data.append(
            "latitude",
            this.state.latitude != 0 ? this.state.latitude : ""
        );
        data.append(
            "longitude",
            this.state.longitude != 0 ? this.state.longitude : ""
        );
        //console.log(categId, this.state.latitude, this.state.longitude);
        const response = await fetchApi(url, "POST", data);

        if (
            response.success &&
            response.locations &&
            response.locations.length > 0
        ) {
            this.setState({zingMapList: response.locations});
        } else {
            this.setState({zingMapList: []});
        }
    };

    openUserDeals = (id) => {
        this.setState({userDeals: !this.state.userDeals, businessUserId: id});
    };

    render() {
        return (
            <Container className="event popupEventForm">
                <Col>
                    <Row className="mb-2">
                        <Col xs="2" lg="2" className="">
                            <img
                                src={
                                    this.props.profileImg !== null &&
                                    this.props.profileImg !== undefined &&
                                    this.props.profileImg !== ""
                                        ? this.props.profileImg
                                        : profilePic
                                }
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = profilePic;
                                }}
                                className="eventProfilePic-MakeEvents"
                                alt="profilePic"
                            />
                        </Col>
                        <Col className="pt-3 eventUsrName">{this.props.name}</Col>
                    </Row>

                    {this.state.showFirstGroup ? (
                        <div className="eventGeneral">
                            <Col className="eventRowTitle">General Informations:</Col>

                            <Row className="mt-1">
                                <Col lg="4" className="pl-0 mt-1">
                                    <Input
                                        placeholder="Event name..."
                                        value={this.state.eventName}
                                        onChange={this.onChangeEventName}
                                        invalid={this.state.isValidEventName}
                                    />
                                    <FormFeedback>This field is required</FormFeedback>
                                </Col>
                                <Col lg="4" className="pl-0 mt-1 makeeventlocation">
                                    {/* <Input
                    id="autocomplete"
                    placeholder="where..."
                    // value={this.state.query}
                    value={this.state.location || ""}
                    onChange={this.onChangeLocation}
                    className=""
                  /> */}
                                    {this.props.updatePeopleEvent ? (
                                        <Input value={this.state.location} readOnly={true}/>
                                    ) : (
                                        <ZingMap
                                            placeHolder={this.state.location}
                                            showSearchTextBox={true}
                                            showOpenMapButton={true}
                                            inputClassName="makeEvents text-white"
                                            buttonClassName=""
                                            enableMarkerAdd={false}
                                            selectedLocationHandler={this.onChangeLocation}
                                            list={this.state.zingMapList2}
                                            initialAddress={this.state.location}
                                        ></ZingMap>
                                    )}
                                    <Input
                                        className="invisible"
                                        invalid={this.state.isValidLocation}
                                    />
                                    <FormFeedback>This field is required</FormFeedback>
                                </Col>

                                <Col lg="2" className="pl-0 mt-1 mkComplete ">
                                    {this.props.updateEvent &&
                                    this.props.eventToUpdate.max_persons ===
                                    this.props.eventToUpdate.persons_left ? (
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.state.categories}
                                            getOptionLabel={(option) =>
                                                option.name_of_subcategory
                                                    ? "\xa0\xa0\xa0\xa0" + option.name_of_subcategory
                                                    : option.name
                                            }
                                            style={{width: 205}}
                                            onChange={(event, value) =>
                                                this.onChangeCategory(event, value)
                                            }
                                            renderInput={(params) => (
                                                <div>
                                                    <TextField
                                                        className="categoryAC"
                                                        {...params}
                                                        placeholder={this.state.category}
                                                        variant="outlined"
                                                    />
                                                </div>
                                            )}
                                        />
                                    ) : (
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.state.categories}
                                            getOptionLabel={(option) =>
                                                option.name_of_subcategory
                                                    ? "\xa0\xa0\xa0\xa0" + option.name_of_subcategory
                                                    : option.name
                                            }
                                            style={{width: 205}}
                                            onChange={(event, value) =>
                                                this.onChangeCategory(event, value)
                                            }
                                            renderInput={(params) => (
                                                <div>
                                                    <TextField
                                                        className="categoryAC"
                                                        {...params}
                                                        placeholder={
                                                            this.props.updateEvent
                                                                ? this.state.category
                                                                : "Category..."
                                                        }
                                                        variant="outlined"
                                                    />
                                                </div>
                                            )}
                                        />
                                    )}

                                    <Input
                                        className="invisible"
                                        invalid={this.state.isValidCategory}
                                    />
                                    <FormFeedback>This field is required</FormFeedback>
                                </Col>
                            </Row>

                            <Row className="">
                                <Col lg="4" className="pl-0 mt-1">
                                    <Input
                                        placeholder="Number of teams..."
                                        value={this.state.team}
                                        onChange={this.onChangeTeam}
                                        invalid={this.state.isValidTeam}
                                        type="number"
                                        readOnly={this.props.updatePeopleEvent}
                                    />
                                    <FormFeedback>This field is required</FormFeedback>
                                </Col>
                                <Col lg="4" className="pl-0 mt-1">
                                    <Input
                                        placeholder="No of participants..."
                                        value={this.state.number}
                                        onChange={this.onChangeNumber}
                                        invalid={this.state.isValidNumber}
                                        type="number"
                                        readOnly={this.props.updatePeopleEvent}
                                    />
                                    <FormFeedback>This field is required</FormFeedback>
                                </Col>
                            </Row>

                            <Row className="mt-1">
                                <Button
                                    onClick={this.handleFirstGroup}
                                    className="nrmgreenBtnEvt"
                                >
                                    Next
                                </Button>
                            </Row>
                        </div>
                    ) : null}
                    {this.state.showSecondGroup ? (
                        <div className="mkEvtSelAge">
                            <Row>
                                <Col className="eventRowTitle col-lg-12">
                                    Who is this event for?
                                </Col>
                            </Row>
                            <Row className="mt-1 pl-0 ml-0">
                                <Col className="col-lg-4 pl-0">
                                    {/* <Row className="mt-1 pl-0"> */}
                                    <Col className="col-lg-12 mt-1 labelSubtitleMargins pl-0">
                                        Select age category:
                                    </Col>
                                    {/* <Col className="col-lg-12 mt-1 labelSubtitleMargins pl-0"> */}
                                    <Input
                                        className="selectInput"
                                        placeholder="Age..."
                                        value={this.state.age}
                                        onChange={this.onChangeAge}
                                        type="select"
                                        readOnly={this.props.updatePeopleEvent}
                                        disabled={this.props.updatePeopleEvent}
                                    >
                                        {this.props.ageCategoryList.map((item) => (
                                            <option key={item.id}>{item.name}</option>
                                        ))}
                                    </Input>
                                    {/* </Col> */}
                                    {/* </Row> */}
                                </Col>
                                <Col className="col-lg-4 pl-0">
                                    <Row className="">
                                        <Col className="col-lg-12 pl-0 ">
                                            Select gender category:
                                        </Col>
                                    </Row>
                                    <Row className="mt-1 pl-0">
                                        <Col className="col-lg-12 pl-0">
                                            <ButtonGroup>
                                                <Row id="genderSelect" className="m-0">
                                                    <Col className="p-0 m-0 d-flex genderButtonGroup">
                                                        <div
                                                            className={
                                                                this.state.gender === "1"
                                                                    ? "genderIcon genIconPressed"
                                                                    : "genderIcon"
                                                            }
                                                            onClick={
                                                                this.props.updatePeopleEvent
                                                                    ? null
                                                                    : this.selectMale
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faMale}/>
                                                        </div>
                                                        <div
                                                            className={
                                                                this.state.gender === "2"
                                                                    ? "genderIcon genIconPressed"
                                                                    : "genderIcon"
                                                            }
                                                            onClick={
                                                                this.props.updatePeopleEvent
                                                                    ? null
                                                                    : this.selectFemale
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faFemale}/>
                                                        </div>
                                                        <div
                                                            className={
                                                                this.state.gender === "3"
                                                                    ? "genderIcon genIconPressed"
                                                                    : "genderIcon"
                                                            }
                                                            onClick={
                                                                this.props.updatePeopleEvent
                                                                    ? null
                                                                    : this.seletUnisex
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faRestroom}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-lg-4 pl-0">
                                    <Row className="mt-1 pl-0">
                                        <Col className="col-lg-10 pl-0 mb-1">
                                            Handicap Facilities:
                                        </Col>
                                    </Row>
                                    <Row className="mt-1 pl-0">
                                        <ButtonGroup>
                                            <Col className="genderButtonGroup">
                                                <Row id="genderSelect" className="m-0">
                                                    <div
                                                        value={this.state.isHandicap}
                                                        className={
                                                            this.state.isHandicap === "1"
                                                                ? "handicapIcon "
                                                                : "handicapIcon genIconPressed"
                                                        }
                                                        onClick={
                                                            this.props.updatePeopleEvent
                                                                ? null
                                                                : this.selectHandicapYes
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faWheelchair}/>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </ButtonGroup>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Button
                                    onClick={this.handleSecondGroup}
                                    className="nrmgreenBtnEvt"
                                >
                                    Next
                                </Button>
                            </Row>
                        </div>
                    ) : null}
                    {this.state.showThirdGroup ? (
                        <div className="ml-0 pl-0">
                            <Col className="eventRowTitle">Event time informations</Col>

                            <Row className="mt-1 ml-0 pl-0"></Row>
                            <Row className="mt-1 ml-0 pl-0">
                                <Col lg="6" className="mt-0 px-3 py-0">
                                    <FormGroup className="px-0 py-0">
                                        <Label for="calendar">Start date</Label>
                                        <Col lg="12" className="m-0 p-0">
                                            <InputGroup className="">
                                                <div className={"datePickermakeEventB index_big"}>
                                                    {this.props.updatePeopleEvent ? (
                                                        <Input
                                                            value={
                                                                this.state.startDate !== null
                                                                    ? this.state.startDate.year +
                                                                    "-" +
                                                                    this.state.startDate.month +
                                                                    "-" +
                                                                    this.state.startDate.day
                                                                    : ""
                                                            }
                                                            readOnly={true}
                                                        />
                                                    ) : (
                                                        <DatePicker
                                                            dateFormat={"YYYY-MM-DD"}
                                                            value={this.state.startDate}
                                                            onChange={(date) => this.handleChangeS(date)}
                                                            inputPlaceholder="Select a date"
                                                            shouldHighlightWeekends
                                                            colorPrimary="#007bff"
                                                            // wrapperClassName="datePicker"
                                                            inputClassName="text-white"
                                                            style={{
                                                                zIndex: "9999",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <InputGroupAddon
                                                    addonType="append"
                                                    className="backgrounButton-normal"
                                                >
                                                    <InputGroupText>
                                                        <FontAwesomeIcon icon={faCalendarAlt}/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>

                                <Col lg="6" className="mt-0 px-3 py-0">
                                    <FormGroup className="px-0 py-0">
                                        <Label for="calendar">End date</Label>
                                        <Col lg="12" className="m-0 p-0">
                                            <InputGroup className="">
                                                <div className={"datePickermakeEventB index_small"}>
                                                    {this.props.updatePeopleEvent ? (
                                                        <Input
                                                            value={
                                                                this.state.startDate !== null
                                                                    ? this.state.endDate.year +
                                                                    "-" +
                                                                    this.state.endDate.month +
                                                                    "-" +
                                                                    this.state.endDate.day
                                                                    : ""
                                                            }
                                                            readOnly={true}
                                                        />
                                                    ) : (
                                                        <DatePicker
                                                            dateFormat={"YYYY-MM-DD"}
                                                            value={this.state.endDate}
                                                            onChange={(date) => this.handleChangeE(date)}
                                                            inputPlaceholder="Select a date"
                                                            shouldHighlightWeekends
                                                            colorPrimary="#007bff"
                                                            inputClassName="text-white"
                                                            style={{
                                                                zIndex: "9999",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <InputGroupAddon
                                                    addonType="append"
                                                    className="backgrounButton-normal"
                                                >
                                                    <InputGroupText>
                                                        <FontAwesomeIcon icon={faCalendarAlt}/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs="1" sm="1" md="6" className="mt-1">
                                <Col lg="6" sm="6" md="6" className="pl-0 mt-1">
                                    <TimePicker
                                        onFocusChange={this.onFocusChange.bind(this)}
                                        onTimeChange={this.onStartTimeChange.bind(this)}
                                        time={this.state.startTime}
                                        disabled={this.props.updatePeopleEvent}
                                    />
                                </Col>

                                <Col lg="6" sm="6" md="6" className="pl-0 mt-1">
                                    <TimePicker
                                        onFocusChange={this.onFocusChange.bind(this)}
                                        onTimeChange={this.onEndTimeChange.bind(this)}
                                        time={this.state.endTime}
                                        disabled={this.props.updatePeopleEvent}
                                    />
                                </Col>
                            </Row>
                            <Row className="ml-3 pl-0 mt-3 row">
                                <Input
                                    className="invisible"
                                    valid={this.state.isValidDate}
                                    invalid={this.state.isValidDate}
                                />
                                <FormFeedback>
                                    Start date must be smaller than end date and current date
                                </FormFeedback>
                            </Row>
                            <Row className="ml-0 pl-3 mt-3">
                                <Button
                                    onClick={this.handleThirdGroup}
                                    className="nrmgreenBtnEvt"
                                >
                                    Next
                                </Button>
                            </Row>
                        </div>
                    ) : null}
                    {this.state.showFourthGroup ? (
                        <div>
                            <Col>Description:</Col>
                            <Col lg="12" className="mt-1">
                                <Input
                                    title="Event description..."
                                    placeholder="Event description..."
                                    type="textarea"
                                    value={this.state.description}
                                    onChange={this.onChangeDescription}
                                    className="evtDescArea"
                                    invalid={this.state.isValidDescription}
                                />
                                <FormFeedback>
                                    This field is required and must not contain forbidden words
                                </FormFeedback>
                            </Col>
                            {/* <Alert
                isOpen={this.state.showAlertDescription}
                toggle={this.toggleAlertDescription}
              >
                Please describe the event
              </Alert> */}

                            <Col lg="4" className="mt-3">
                                {this.props.updateEvent ? (
                                    <Button
                                        onClick={this.updateHandler}
                                        className="nrmgreenBtnEvt"
                                    >
                                        Update
                                    </Button>
                                ) : (
                                    <Button onClick={this.doneHandler} className="nrmgreenBtnEvt">
                                        Done
                                    </Button>
                                )}
                            </Col>
                        </div>
                    ) : null}
                    {!this.props.updateEvent ? (
                        <div className="eventsbottom">
                            <Row className="mt-1">
                                <Col className="pl-0 eventRowTitle evtBotAreaSpace" lg="12">
                                    FIND PLACE for your EVENT(you can BUY/ RENT/ RESERVE directly
                                    from our app)
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col lg="12" className=" p-0">
                                    <Row className="d-flex align-items-center">
                                        <Col xs="12" lg="6" className=" marginTop pl-0 ">
                                            {/* <InputGroup className="mkeventsWhere">

                        <Input
                          title="where"
                          id="autocomplete"
                          placeholder="where"
                          value={this.state.query}
                          className="noBorder"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            {" "}
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup> */}
                                            <ZingMap
                                                placeHolder="Where..."
                                                showSearchTextBox={true}
                                                showOpenMapButton={false}
                                                inputClassName="makeEvents text-white"
                                                buttonClassName=""
                                                enableMarkerAdd={false}
                                                selectedLocationHandler={this.onChangeWhereLocation}
                                                //list={this.state.zingMapList}
                                            ></ZingMap>
                                        </Col>
                                        <Col
                                            xs="12"
                                            lg="6"
                                            className="eventGeneral m-0 mb-3 pl-0 mkCompleteBottom"
                                        >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.state.categories}
                                                // getOptionLabel={(option) => option.name}
                                                getOptionLabel={(option) =>
                                                    option.name_of_subcategory
                                                        ? "\xa0\xa0\xa0\xa0" + option.name_of_subcategory
                                                        : option.name
                                                }
                                                // style={{ width: 400 }}
                                                onChange={(event, value) =>
                                                    this.onChangeCategory2(event, value)
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        title="Category..."
                                                        className="categoryAC"
                                                        {...params}
                                                        placeholder="Category..."
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="12" className="p-0">
                                    <Row className="text-right borderTop">
                                        <Col className="mt-3 mb-3 p-0 d-flex justify-content-center mkevent">
                                            {/* <Button
                        onClick={this.openFullscreen}
                        className="nrmgreenBtnEvt mr-2"
                      >
                        See on Z-map
                      </Button> */}
                                            <ZingMap
                                                showSearchTextBox={false}
                                                showOpenMapButton={true}
                                                inputClassName=""
                                                buttonClassName="nrmgreenBtnEvt-evt iconBtnSpace"
                                                buttonText="See on Z-map"
                                                enableMarkerAdd={false}
                                                selectedLocationHandler={(address, lat, lng) => {
                                                    this.onChangeLocation(address, lat, lng);
                                                }}
                                                list={this.state.zingMapList2}
                                                centerLat={this.state.centerLat}
                                                centerLng={this.state.centerLng}
                                                showDeals={true}
                                            ></ZingMap>

                                            <Button
                                                onClick={this.toggleSeeOnApp}
                                                className="nrmgreenBtnEvt-evt "
                                            >
                                                See on APP
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                    {/* <MapContainer /> */}
                </Col>
                <Modal
                    isOpen={this.state.seeOnAppModal}
                    // toggle={this.toggleSeeOnApp}
                    size="lg"
                >
                    <div className="header-p">
                        <ModalHeader className="head-1  text-white">See on app</ModalHeader>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.toggleSeeOnApp}/>
                    </div>
                    <ModalBody>
                        <div className="eventsArea">
                            {this.state.zingMapList2.map((item) => (
                                <div
                                    key={item.id}
                                    className="bannerBusinessContainer"
                                    onClick={() => this.openUserDeals(item.business_user_id)}
                                >
                                    <Row>
                                        <Col lg="2">
                                            <img
                                                className="profileImgBanner"
                                                src={item.logo}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = profilePic;
                                                }}
                                            />
                                        </Col>
                                        <Col lg="10" className="center">
                                            <div className="companyName">{item.company_name}</div>
                                            <div className="address">{item.address}</div>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    className="costume-modal-prof"
                    isOpen={this.state.userDeals}
                    // toggle={this.openUserDeals}
                >
                    <div className="header-p">
                        <ModalHeader className="head-1"/>
                        <div className="logo_image">
                            <img className="my_logo" src={SLogo} alt="my_logo"/>
                        </div>
                        <ModalHeader className="head-2" toggle={this.openUserDeals}/>
                    </div>

                    <UserProfile
                        userId={this.state.businessUserId}
                        closeHandler={() => this.openUserDeals}
                        openDeals={true}
                    />
                </Modal>
            </Container>
        );
    }

    position = async () => {
        await navigator.geolocation.getCurrentPosition((position) =>
            this.setState(
                {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                }
                //newState => console.log(newState)
            )
        );
    };

    getForbidenWords = async () => {
        let data = new FormData();
        let url = "forbidenwords";
        const response = await fetchApi(url, "GET", data);
        // console.log(response);
        if (response.success) {
            this.setState({forbidenWords: response.forbiden_words});
        }
    };

    componentDidMount = () => {
        this.getAllLocations2();
        this.getForbidenWords();
        this.position();
        //this.getAgeCategory();
        this.getGender();
        this.getCategories();
        if (this.props.updateEvent) {
            let startYear = moment(new Date(this.props.eventToUpdate.date)).format(
                "YYYY"
            );
            let startMonth = moment(new Date(this.props.eventToUpdate.date)).format(
                "MM"
            );
            let startDay = moment(new Date(this.props.eventToUpdate.date)).format(
                "DD"
            );

            let endYear = moment(new Date(this.props.eventToUpdate.end_date)).format(
                "YYYY"
            );
            let endMonth = moment(new Date(this.props.eventToUpdate.end_date)).format(
                "MM"
            );
            let endDay = moment(new Date(this.props.eventToUpdate.end_date)).format(
                "DD"
            );

            let startDate = {
                day: parseInt(startDay),
                month: parseInt(startMonth),
                year: parseInt(startYear),
            };
            let endDate = {
                day: parseInt(endDay),
                month: parseInt(endMonth),
                year: parseInt(endYear),
            };

            let category = this.props.categories.filter(
                (element) => element.id === this.props.eventToUpdate.category_id
            );
            let age = this.props.ageCategoryList.filter(
                (element) => element.id === this.props.eventToUpdate.age_category
            );
            // console.log(category, "asd");
            this.setState({
                // category: thiiis.props.eventToUpdate
                eventName: this.props.eventToUpdate.name,
                isHandicap: this.props.eventToUpdate.handicap_facilities,
                location: this.props.eventToUpdate.address,
                description: this.props.eventToUpdate.description,
                category: category[0].name,
                categoryId: category[0].id,
                age: age[0].name,
                team: this.props.eventToUpdate.team_number,
                number: this.props.eventToUpdate.max_persons,
                startDate: startDate,
                endDate: endDate,
                startTime: this.props.eventToUpdate.start_hour,
                endTime: this.props.eventToUpdate.end_hour,
                gender: this.props.eventToUpdate.gender,
            });
        } else {
            //console.log("altfel");
        }
        //this.getEventsLocation();
        this.getAllLocations(this.state.categoryId);
    };

    toogle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    onStartTimeChange(options) {
        const {hour, minute, meridiem} = options;
        this.setState({startTime: options.hour + ":" + options.minute});
    }

    onEndTimeChange(options) {
        const {hour, minute, meridiem} = options;
        this.setState({endTime: options.hour + ":" + options.minute});
    }

    onFocusChange(focusStatue) {
        // do something
    }

    toggleDropDownCategory = (e) => {
        this.setState({
            dropdownCategoryOpen: !this.state.dropdownCategoryOpen,
        });
    };

    // onDropdownItem_ClickCategory = sender => {
    //     var newState = {
    //         dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue")
    //     };
    //     this.setState({
    //         category: newState.dropDownValue2,
    //         dropdownCategoryOpen: !this.state.dropdownCategoryOpen
    //     });
    //     this.props.categories.forEach(element => {
    //         if (element.name === newState.dropDownValue2) {
    //             this.setState({categoryId: element.id});
    //         }
    //     });
    // };

    onChangeCategory2 = (evt, value) => {
        if (value !== null) {
            if (value.id) {
                this.setState({
                    categoryIdZmap: value.id,
                });
                this.getAllLocations2(value.id);
            }
        }
    };

    onChangeCategory = (evt, value) => {
        if (value !== null) {
            if (value.parent_category_id) {
                this.setState({
                    categoryId: value.parent_category_id,
                    alliasId: value.id,
                    category: evt.target.value,
                    categoryName:
                        value.name !== undefined ? value.name : value.name_of_subcategory,
                });
                this.getAllLocations(value.parent_category_id);
            } else {
                this.setState({
                    category: evt.target.value,
                    categoryName:
                        value.name !== undefined ? value.name : value.name_of_subcategory,
                    categoryId: value.id,
                    alliasId: false,
                });
                this.getAllLocations(value.id);
            }
        } else {
            this.setState({categoryId: null});
            this.getAllLocations(null);
        }
    };

    toggleDropDownTeam = (e) => {
        this.setState({
            dropdownTeamOpen: !this.state.dropdownTeamOpen,
        });
    };

    onDropdownItem_ClickTeam = (sender) => {
        var newState = {
            dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue"),
        };
        this.setState({
            team: newState.dropDownValue2,
            dropdownTeamOpen: !this.state.dropdownTeamOpen,
        });
    };

    onChangeTeam = (evt) => {
        this.setState({
            team: evt.target.value,
        });
    };

    toggleDropDownNumber = (e) => {
        this.setState({
            dropdownNumberOpen: !this.state.dropdowNumberOpen,
        });
    };

    onDropdownItem_ClickNumber = (sender) => {
        var newState = {
            dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue"),
        };
        this.setState({
            number: newState.dropDownValue2,
            dropdownNumberOpen: !this.state.dropdownNumberOpen,
        });
    };

    onChangeNumber = (evt) => {
        this.setState({
            number: evt.target.value,
        });
    };

    toggleDropDownAge = (e) => {
        this.setState({
            dropdownAgeOpen: !this.state.dropdowAgeOpen,
        });
    };

    onDropdownItem_ClickAge = (sender) => {
        var newState = {
            dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue"),
        };
        this.setState({
            age: newState.dropDownValue2,
            dropdownAgeOpen: !this.state.dropdownAgeOpen,
        });
    };

    onChangeAge = (evt) => {
        this.setState({
            age: evt.target.value,
        });

        let list = this.props.ageCategoryList.filter(
            (item) => item.name === evt.target.value
        );
        //console.log(list[0].id);
        this.setState({ageCategoryId: list[0].id});
    };

    toggleDropDownCategorii = (e) => {
        this.setState({
            dropdownCategoriiOpen: !this.state.dropdownCategoriiOpen,
        });
    };

    onDropdownItem_ClickCategorii = (sender) => {
        var newState = {
            dropDownValue2: sender.currentTarget.getAttribute("dropdownvalue"),
        };
        this.setState({
            categorii: newState.dropDownValue2,
            dropdownCategoriiOpen: !this.state.dropdownCategoriiOpen,
        });
    };

    onChangeCategorrii = (evt) => {
        this.setState({
            categorii: evt.target.value,
        });
    };

    handleChangeS = (date) => {
        this.setState({
            startDate: date,
        });
    };

    handleChangeE = (date) => {
        this.setState({
            endDate: date,
        });
    };

    handleScriptLoad = () => {
        // Declare Options For Autocomplete
        const options = {
            types: ["(cities)"],
        }; // To disable any eslint 'google not defined' errors

        // Initialize Google Autocomplete
        /*global google*/
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            options
        );

        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components and formatted
        // address.
        this.autocomplete.setFields(["address_components", "formatted_address"]);

        // Fire Event when a suggested name is selected
        this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    };

    handlePlaceSelect = () => {
        // Extract City From Address Object
        const addressObject = this.autocomplete.getPlace();
        const address = addressObject.address_components;

        // Check if address is valid
        if (address) {
            // Set State
            this.setState({
                city: address[0].long_name,
                query: addressObject.formatted_address,
            });
        }
    };
}

function TryParseInt(str, defaultValue) {
    var retValue = defaultValue;
    if (str !== null) {
        if (str.length > 0) {
            if (!isNaN(str)) {
                retValue = parseInt(str);
            }
        }
    }
    return retValue;
}
