import React, { Children } from "react";
import {
  Button,
  Label,
  Input,
  Container,
  Col,
  Row,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import "./UserProfile.scss";
import { fetchApi } from "../../../fetchApi";
import { userCredentials } from "../../../globalVariables";
import {
  faShare,
  faUserAlt,
  faUserTag,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faFlag,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";
import { Animated } from "react-animated-css";
import "react-image-crop/dist/ReactCrop.css";
import swal from "sweetalert";
import ModalVideo from "react-modal-video";
import FlatList from "flatlist-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import BannerDetailsNormal from "../../NormalUser/BannerDetails/BannerDetailsNormal";
import Friends from "../../BothUsers/Friends/Friends";
import ContentEvents from "../../BothUsers/Events/ContentEvents";

const localizer = momentLocalizer(moment);
let profilePic = require("../../../Assets/img/profilePic.jpg");
let SLogo = require("../../../Assets/img/SLogo.png");

export default class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: "",
      friendsList: [],
      selected: "",
      selectedFile: null,
      modal: false,
      images: [],
      seeFriend: false,
      showImagesFromServer: false,
      modalProfileImg: false,
      src: null,
      croppedImageUrl: null,
      profilePic: null,
      fileToUpload: null,
      imagesFromServer: [],
      showFilesToUpload: false,
      city: "",
      cities: "",
      selectedCities: [],
      logoName: "",
      showEdit: false,
      address: "",
      language: "",
      socialNetworkInput: "",
      showSocialNetworkInput: false,
      currentSocialNetwork: 0,
      userSnList: [],
      socialNetworkList: [],
      otherUserLogo: "",
      firstName: "",
      tara: "",
      description: "",
      friends: "",
      createdEventsNumber: "",
      nrEventsAssigned: "",
      follow: true,
      userIdOtherUserId: null,
      reportUser: "",
      showMesageReport: false,
      mesageUserReported: "",
      isActive: false,
      blockUser: false,
      openBlockUser: true,
      UnBlockUser: false,
      usrCategories: [],
      videoModal: false,
      videoUrl: "",
      messageText: "",
      createdEventsList: [],
      registeredEventsList: [],
      curentDealCalendarId: 0,
      currentDealCalendar: [],
      holidayList: [],
      dealsDateList: [],
      modalShowDetailsD: false,
      imageModal: false,
      email: "",
      inHoliday: false,
      companyName: "",
      filterVal: "",
      currentMonthList: [],
      drillView: "month",
      calendarDate: new Date(),
    };
  }

  onDrillDown = (view) => {
    this.setState({ drillView: view });
  };

  selectSlot = (slot) => {
    this.setState({ drillView: "day", calendarDate: new Date(slot.start) });
  };

  navigateThroughCalendar = (date) => {
    let currentMonthList = [];

    this.setState({ calendarDate: new Date(date) });
    for (let el of this.state.dealsDateList) {
      let d1 = new Date(el.start);
      let d2 = new Date(date);
      d2.setHours(0, 0, 0, 0);
      d1.setHours(0, 0, 0, 0);
      d2.setDate(0);
      let nd1 = new Date(d2);
      d2.setDate(d2.getDate() + 1);
      d2.setMonth(d2.getMonth() + 1);

      let d3 = new Date(d2);
      d3.setDate(d3.getDate() + 1);
      let d4 = new Date(d3);
      d4.setDate(d4.getDate() + 1);
      let d5 = new Date(d4);
      d5.setDate(d5.getDate() + 1);
      let d6 = new Date(d5);
      d6.setDate(d6.getDate() + 1);
      let d7 = new Date(d6);
      d7.setDate(d7.getDate() + 1);

      let nd2 = new Date(nd1);
      nd2.setDate(nd2.getDate() - 1);
      let nd3 = new Date(nd2);
      nd3.setDate(nd3.getDate() - 1);
      let nd4 = new Date(nd3);
      nd4.setDate(nd4.getDate() - 1);
      let nd5 = new Date(nd4);
      nd5.setDate(nd5.getDate() - 1);
      let nd6 = new Date(nd5);
      nd6.setDate(nd6.getDate() - 1);

      if (
        (new Date(el.start).getMonth() === new Date(date).getMonth() &&
          new Date(el.start).getFullYear() === new Date(date).getFullYear()) ||
        +d1 === +d2 ||
        +d1 === +d3 ||
        +d1 === +d4 ||
        +d1 === +d5 ||
        +d1 === +d6 ||
        +d1 === +d7 ||
        +d1 === +nd1 ||
        +d1 === +nd2 ||
        +d1 === +nd3 ||
        +d1 === +nd4 ||
        +d1 === +nd5 ||
        +d1 === +nd6
      ) {
        currentMonthList.push(el);
        // console.log(
        //   new Date(el.start).getFullYear(),
        //   new Date(date).getFullYear(),
        //   yearIncrement
        // );
      }
    }

    this.setState({
      currentMonthList: currentMonthList,
    });
  };

  openImageModal = (img) => {
    this.setState({ imageModal: !this.state.imageModal, currentImg: img });
  };

  selectEvent = (event) => {
    let inHoiday = false;
    let cd = new Date();
    cd.setHours(0, 0, 0, 0);
    let date = new Date(event.resource.deals.date);
    let day = new Date(event.start);
    event.resource.deals_calendar.forEach((element) => {
      let eDay = new Date(element.date);
      if (+day === +eDay) {
        this.state.holidayList.forEach((holiday) => {
          let stdh = new Date(holiday.from_date);
          let edh = new Date(holiday.to_date);
          if (+edh >= +stdh && +stdh <= +eDay && +eDay <= +edh) {
            // console.log("vacanta");
            inHoiday = true;
          }
        });
      }
    });

    let dealCalendar = event.resource.deals_calendar.filter(
      (item) => 
      new Date(item.date.replace(/-/g, "/")).getTime() === new Date(event.start).getTime()
        
    );
    if(dealCalendar[0] !== undefined) {

      this.setState({
        profileModal: !this.state.profileModal,
        curentDealCalendarId: dealCalendar[0].id,
        currentDealCalendar: dealCalendar[0],
        inHoliday: inHoiday,
      });
    }else{

      this.setState({
        profileModal: !this.state.profileModal,
        curentDealCalendarId: event.resource.deals_calendar[0].id,
        currentDealCalendar: event.resource.deals_calendar[0],
        inHoliday: inHoiday,
      });
    }

    this.toggleModalShowDetailsD(
      event.resource.deals,
      event.resource.deals_calendar
    );
  };

  toggleModalShowDetailsD = (item, calendar) => {
    if (calendar !== undefined) {
      this.setState({
        currentEvent: item,
        currentMultiprice: [
          {
            id: 1,
            price: calendar.length > 0 ? calendar[0].price : 0,
            nOf: item.max_persons,
            indicativePrice: calendar[0].indicative_price,
          },
        ],
        eventLogo: item.logo,
        currentCalendar: calendar,
      });
    }
    //console.log(item);
    this.setState({ modalShowDetailsD: !this.state.modalShowDetailsD });
  };

  listoffriends = async () => {
    const url = "listoffriends";
    let authKey = userCredentials.authKey;
    let data = new FormData();
    data.append("user_id", this.props.userId);
    const response = await fetchApi(url, "POST", data, authKey);
    // console.log(response);
    if (response.success) {
      this.setState({
        friendsList: response.list_of_friends,
        allFriends: response.list_of_friends,
      });
    }
  };

  openFriends = (name) => {
    this.listoffriends();
    this.closeModal();
    this.toggleProfileModal();
    this.setState({
      modalTitle: name + " friends",
      showUserFriends: true,
    });
  };

  openEventsCreated = (name) => {
    this.getMyEvents();
    this.closeModal();
    this.toggleProfileModal();
    this.setState({
      modalTitle: name + " created events",
      showUsersCreatedEvents: true,
    });
  };

  openEventsRegistered = (name) => {
    this.getMyEvents();
    this.closeModal();
    this.toggleProfileModal();
    this.setState({
      modalTitle: name + " registered events",
      showUserRegisteredEvents: true,
    });
  };

  openDealsCreated = (name) => {
    this.getMyDeals();
    this.closeModal();
    this.toggleProfileModal();
    this.setState({
      modalTitle: name + " created deals",
      showUserDeals: true,
    });
  };

  closeModal = () => {
    this.setState({ modalProfileFriends: false });
  };

  getMyEvents = async () => {
    let authKey = userCredentials.authKey;
    const url = "listofeventsperuser";
    const data = new FormData();
    data.append("location", "");
    data.append("latitude", "");
    data.append("longitude", "");
    data.append("date", "");
    data.append("category", "");
    data.append("user_id", this.props.userId);
    data.append("searchWord", "");
    data.append("alias_category_id", 0);
    const response = await fetchApi(url, "POST", data, authKey);
    // console.log(response);
    if (response.success) {
      let createdEvents = [];
      createdEvents = response.events.filter(
        (element) =>
          parseInt(element.event.user_id) === parseInt(this.props.userId)
      );
      let registeredEvents = [];
      registeredEvents = response.events.filter(
        (element) =>
          parseInt(element.event.user_id) !== parseInt(this.props.userId)
      );
      this.setState({
        createdEventsList: createdEvents.reverse(),
        registeredEventsList: registeredEvents.reverse(),
      });
    }
    if (
      this.state.eventsFromUserList &&
      this.state.eventsFromUserList.length == 0
    ) {
      this.setState({ showAlertMyE: true });
    } else {
      this.setState({ showAlertMyE: false });
    }
  };
  EventWrapper = (event, dealName, dealDate) => {
    return (
      <span>
        {dealDate + " " + dealName}
        <FontAwesomeIcon
          color={"#f5b91c"}
          icon={faExclamationTriangle}
          className="approveRejectDeals"
          title={"You have pendings users to this deal!"}
        />
      </span>
    );
  };
  getMyDeals = async () => {
    let authKey = userCredentials.authKey;
    const url = "listofdealsperbusiness";
    const data = new FormData();
    data.append("business_user_id", this.props.userId);
    const response = await fetchApi(url, "POST", data, authKey);

    if (response.success) {
      let arr = [];
      response.deals.forEach((felement) => {
        felement.deals_calendar.forEach((element) => {
          let endDate =
            element.date.substring(0, 10) + " " + felement.deals.end_hour;
          arr.push({
            start: moment(element.date).toDate(),
            end: moment(endDate).toDate(),
            title:
              element.needtoaccept > 0
                ? this.EventWrapper(
                    element,
                    felement.deals.name,
                    felement.deals.start_hour
                  )
                : felement.deals.start_hour.substring(0, 5) +
                  " " +
                  felement.deals.name,
            resource: felement,
          });
        });
      });
      this.setState({ dealsDateList: arr, holidayList: response.holidays });

      //filter
      let currentMonthList = [];

      for (let el of arr) {
        let d1 = new Date(el.start);
        let d2 = new Date();
        d2.setHours(0, 0, 0, 0);
        d1.setHours(0, 0, 0, 0);
        d2.setDate(0);
        let nd1 = new Date(d2);
        d2.setDate(d2.getDate() + 1);
        d2.setMonth(d2.getMonth() + 1);

        let d3 = new Date(d2);
        d3.setDate(d3.getDate() + 1);
        let d4 = new Date(d3);
        d4.setDate(d4.getDate() + 1);
        let d5 = new Date(d4);
        d5.setDate(d5.getDate() + 1);
        let d6 = new Date(d5);
        d6.setDate(d6.getDate() + 1);
        let d7 = new Date(d6);
        d7.setDate(d7.getDate() + 1);

        let nd2 = new Date(nd1);
        nd2.setDate(nd2.getDate() - 1);
        let nd3 = new Date(nd2);
        nd3.setDate(nd3.getDate() - 1);
        let nd4 = new Date(nd3);
        nd4.setDate(nd4.getDate() - 1);
        let nd5 = new Date(nd4);
        nd5.setDate(nd5.getDate() - 1);
        let nd6 = new Date(nd5);
        nd6.setDate(nd6.getDate() - 1);

        if (
          (new Date(el.start).getMonth() === new Date().getMonth() &&
            new Date(el.start).getFullYear() === new Date().getFullYear()) ||
          +d1 === +d2 ||
          +d1 === +d3 ||
          +d1 === +d4 ||
          +d1 === +d5 ||
          +d1 === +d6 ||
          +d1 === +d7 ||
          +d1 === +nd1 ||
          +d1 === +nd2 ||
          +d1 === +nd3 ||
          +d1 === +nd4 ||
          +d1 === +nd5 ||
          +d1 === +nd6
        ) {
          currentMonthList.push(el);
          // console.log(
          //   new Date(el.start).getFullYear(),
          //   new Date(date).getFullYear(),
          //   yearIncrement
          // );
        }
      }
      this.setState({
        currentMonthList: currentMonthList,
      });
    }
  };

  eventStyleGetter = (event, start, end, isSelected) => {
    var style = {};
    let cd = new Date();
    cd.setHours(0, 0, 0, 0);
    let date = new Date(event.resource.deals.date);
    let day = new Date(start);
    event.resource.deals_calendar.forEach((element) => {
      let eDay = new Date(element.date.replace(/-/g, "/"));
      if (+day === +eDay) {
        if (parseInt(element.persons_left) === 0 || eDay < cd) {
          style = {
            backgroundColor: "red",
          };
        }
        this.state.holidayList.forEach((holiday) => {
          let stdh = new Date(holiday.from_date);
          let edh = new Date(holiday.to_date);
          if (+edh >= +stdh && +stdh <= +eDay && +eDay <= +edh) {
            style = {
              backgroundColor: "orange",
            };
          }
        });
      }
    });
    return {
      style: style,
    };
  };

  toggleProfileModal = () => {
    this.setState({ profileModal: !this.state.profileModal });
    if (this.state.showUsersCreatedEvents) {
      this.setState({ showUsersCreatedEvents: false });
    }
    if (this.state.showUserRegisteredEvents) {
      this.setState({ showUserRegisteredEvents: false });
    }
    if (this.state.showUserFriends) {
      this.setState({ showUserFriends: false });
    }
    if (this.state.showUserDeals) {
      this.setState({ showUserDeals: false });
    }
  };

  toggleVideoModal = () => {
    if (this.state.videoUrl !== null && this.state.videoUrl !== "") {
      this.setState({ videoModal: !this.state.videoModal });
    } else {
      swal("Info!", "this user doesn't have a video", "info");
    }
  };

  onChangeText = (e) => {
    this.setState({ mesageUserReported: e.target.value });
  };

  handleShow = () => {
    this.setState({ isActive: true });
  };

  handleHide = () => {
    this.setState({ isActive: false });
  };

  getProfile = async () => {
    let authKey = userCredentials.authKey;
    let data = new FormData();
    let url = "getprofile";
    data.append("user_id", this.props.userId);
    const response = await fetchApi(url, "POST", data, authKey);
    if (
      response.success &&
      response.userprofile != null &&
      response.userprofile[0] !== undefined
    ) {
      this.setState({
        imagePreviewUrl: response.userprofile[0].img_makeitheader === "1" ? response.userprofile[0].image : response.userprofile[0].logo,
        imagesFromServer: response.userprofile_images,
        showCropImage: false,
        userSnList: response.userprofile_socialnetwork,
        userType: response.userprofile[0].user_type,
        phoneNumber: response.userprofile[0].phone_number,
        websiteUrl: response.userprofile[0].website_url,
        videoUrl: response.userprofile[0].video_url,
      });
      if (response.userprofile_images.length !== 0) {
        this.setState({ showImagesFromServer: true });
      }
    } else {
      this.setState({ images: response.message });
    }
  };

  componentDidMount = () => {
    this.getProfile();
    this.getSocialNetworks();

    this.getCategories();
    this.getselecteduserprofile();
    this.getSelectedCategories();
    if (this.props.openDeals) {
      this.openDealsCreated("User");
    }
  };

  getSocialNetworks = async () => {
    let url = "socialnetworks";
    const response = await fetchApi(url, "POST", null, null);
    if (response.success) {
      this.setState({ socialNetworkList: response.list_of_social_networks });
    }
  };

  formatUrl = (id) => {
    let userSocialNetwork = this.state.userSnList;
    let currentSocialNetwork = userSocialNetwork.filter(element => parseInt(element.social_id) === parseInt(id))
    let url = null
    if (currentSocialNetwork.length > 0) {
      url = currentSocialNetwork[0].link
    }

    return url;
  };

  openFollow() {
    this.setState({
      follow: !this.state.follow,
    });
  }

  openBlock() {
    this.setState({
      openBlockUser: !this.state.openBlockUser,
    });
  }

  fromCroppedToProfile = () => {
    if (this.state.croppedImageUrl != null) {
      this.setState({ imagePreviewUrl: this.state.croppedImageUrl });
    }
    this.toggleProfileModal();
  };

  getCategories = async () => {
    const url = "get_categories";
    const response = await fetchApi(url, "POST", null);
    if (response.success) {
      this.setState({ citySelectItems: response.list_of_categories });
    }
  };

  follow = async () => {
    let authKey = userCredentials.authKey;
    const url = "follow";
    let data = new FormData();
    data.append("user_id", userCredentials.userId);
    data.append("fub_user_id", this.props.userId);
    const response = await fetchApi(url, "POST", data, authKey);
    if (response.success) {
      this.getselecteduserprofile();
    }
  };
  unfollow = async () => {
    let authKey = userCredentials.authKey;
    const url = "unfollow";
    let data = new FormData();
    data.append("user_id", userCredentials.userId);
    data.append("fub_user_id", this.props.userId);
    const response = await fetchApi(url, "POST", data, authKey);
    if (this.props.refreshFriends) {
      this.props.refreshFriends();
    }
    if (response.success) {
      this.getselecteduserprofile();
    }
  };

  sendreport = async () => {
    const url = "sendreport";
    let authKey = userCredentials.authKey;
    let data = new FormData();
    data.append("user_id", userCredentials.userId);
    data.append("user_reported", this.props.userId);
    data.append("messages", this.state.mesageUserReported);
    const response = await fetchApi(url, "POST", data, authKey);
    if (response.success) {
      this.setState({ reportUser: response.user_reported });
      swal("Success", "User reported!", "success");
      this.setState({ isActive: false });
    } else {
      swal("Error", "Error to report user!", "error");
      this.setState({ isActive: true });
    }
  };

  getselecteduserprofile = async () => {
    let authKey = userCredentials.authKey;
    const url = "getselecteduserprofile";
    let data = new FormData();
    data.append("user_id", userCredentials.userId);
    data.append("fub_user_id", this.props.userId);
    const response = await fetchApi(url, "POST", data, authKey);
    let userIsLogged = await localStorage.getItem("userIsLogged");
  
    if (response.success && userIsLogged) {
      this.setState({
        // imagePreviewUrl: response.logo,

        firstName: response.name,
        tara: response.country,
        friends: response.number_of_friends,
        createdEventsNumber: response.number_of_created_events,
        nrEventsAssigned: response.number_of_assigned_events,
        follow: response.isFollow,
        language: response.language,
        address: response.address,
        openBlockUser: response.isBlock,
        nrOfDeals: response.number_of_deals,
        email: response.profile_email,
        companyName: response.company_name
      });
      if (response.description !== "null") {
        this.setState({ description: response.description });
      }
      if (response.isFollow.length !== 0) {
        this.setState({ follow: !this.state.follow });
      } else {
      }
      if (response.isBlock.length !== 0) {
        this.setState({ openBlockUser: !this.state.openBlockUser });
      } else {
      }
    } else {
      swal(
        "Info",
        "You're not allowed to see this profile. Please log in.",
        "info"
      );
      if (this.props.closeHandler) this.props.closeHandler();
    }
  };

  async sendMessage() {
    const url = "sendmessages";
    let authKey = userCredentials.authKey;
    let data = new FormData();
    data.append("user_id", userCredentials.userId);
    data.append("message_type", 1);
    data.append("friend_id", this.props.userId);
    data.append("event_id", 0);
    data.append("message", this.state.messageText);
    const response = await fetchApi(url, "POST", data, authKey);
    if (response.success) {
      this.setState({ messageText: "" });
      swal("Success!", "Message sent!", "success");
    } else {
      swal(
        "Error!",
        "Message was not able to be sent! Please try again later...",
        "error"
      );
    }
  }

  block = async () => {
    let authKey = userCredentials.authKey;
    const url = "block";
    let data = new FormData();
    data.append("user_id", userCredentials.userId);
    data.append("fub_user_id", this.props.userId);
    const response = await fetchApi(url, "POST", data, authKey);
    if (this.props.refreshFriends) {
      this.props.refreshFriends();
    }
    if (this.props.refreshBlockedFriends) {
      this.props.refreshBlockedFriends();
    }
    if (response.success) {
      this.getselecteduserprofile();
    }
  };

  removeblock = async () => {
    let authKey = userCredentials.authKey;
    const url = "removeblock";
    let data = new FormData();
    data.append("user_id", userCredentials.userId);
    data.append("fub_user_id", this.props.userId);
    const response = await fetchApi(url, "POST", data, authKey);
    if (this.props.refreshFriends) {
      this.props.refreshFriends();
    }
    if (this.props.refreshBlockedFriends) {
      this.props.refreshBlockedFriends();
    }

    if (response.success) {
      this.getselecteduserprofile();
    }
  };

  reloadEventsCallback = () => {
    this.getMyDeals();
    this.getMyEvents();
  };

  async getSelectedCategories() {
    this.setState({ selectedCategories: [] });
    let authKey = userCredentials.authKey;
    let newCateg = [];
    let data = new FormData();
    let url = "get_aliasandcategoriesperuser";
    data.append("user_id", this.props.userId);
    const response = await fetchApi(url, "POST", data, authKey);
    if (response.success) {
      let categ1,
        categ2,
        categ3 = [];
      categ1 = response.list_of_categories;
      categ2 = response.list_of_categories_with_alias;
      categ3 = response.list_of_categories_other_with_alias;
      let allCategs = [...categ1, ...categ2, ...categ3];

      let categoryGroups = {};
      const items = allCategs;
      for (let i = 0; i < items.length; i++) {
        let categoryName = items[i].category_name;
        if (categoryName && categoryName != "") {
          if (!categoryGroups[categoryName]) {
            categoryGroups[categoryName] = [];
          }
          categoryGroups[categoryName].push({
            id: items[i].id,
            user_id: items[i].user_id,
            parent_category_id: items[i].parent_category_id,
            name_of_subcategory: items[i].name_of_subcategory,
            type: items[i].type,
            category_name: items[i].category_name,
          });
        }
      }
      for (let groupNames in categoryGroups) {
        newCateg.push({
          category_name: groupNames,
          data: categoryGroups[groupNames],
        });
      }
      this.setState({ usrCategories: newCateg });
    }
  }

  toggleModalShowDetails = (item, multiprice, logo) => {
    this.toggleProfileModal();
    this.setState({
      currentEvent: item,
      currentMultiprice: multiprice,
      eventLogo: logo,
      modalShowDetails: !this.state.modalShowDetails,
      profileModal: true,
      modalTitle: "Event details",
    });

    //this.setState({ modalShowDetails: !this.state.modalShowDetails });
    if (this.state.showVoucherInput) {
      this.setState({ showVoucherInput: false });
    }
    if (this.state.showVoucers) {
      this.setState({ showVoucers: false });
    }
  };

  renderEvents = (eventInfo, idx) => {
    
    return (
      <div className="my-1 " key={eventInfo.event.id}>
        <ContentEvents
            aliasName={eventInfo.event.name_of_aliascategory}
        categName={eventInfo.event.name_of_subcategory}
        
          isClosed={!eventInfo.is_closed}
          currencyOcta={eventInfo.event.currency}
          eventEndDate={eventInfo.event.end_date}
          isPromoted={eventInfo.event.is_promoted}
          startDatePromotion={eventInfo.event.start_promoted_date}
          endDatePromotion={eventInfo.event.end_promoted_date}
          promotionType={eventInfo.event.promoted_type}
          promotionDescription={eventInfo.event.promoted_comment}
          numberOfMsg={eventInfo.number_of_messages}
          eventType={eventInfo.event.event_type}
          joinedEvent={eventInfo.event.joinedEvent}
          eventId={eventInfo.event.id}
          userIdE={eventInfo.event.user_id}
          name={eventInfo.event.name}
          category={eventInfo.event.category_id}
          text={eventInfo.event.description}
          date={
            eventInfo.event.date ? eventInfo.event.date.substring(0, 11) : ""
          }
          area={eventInfo.event.address}
          time={
            eventInfo.event.start_hour
              ? eventInfo.event.start_hour.substring(0, 5)
              : ""
          }
          teams={eventInfo.event.team_number}
          participants={
            eventInfo.event.max_persons - eventInfo.event.persons_left
          }
          state="closed"
          price={
            eventInfo.multiprice.length === 0
              ? "FREE"
              : eventInfo.multiprice.length === 1
                ? eventInfo.multiprice[0].price === "0"
                  ? "FREE"
                  : "PAY"
                : "PAY"
          }
          multiprice={eventInfo.multiprice}
          gender={eventInfo.event.gender}
          ageCategory={eventInfo.event.age_category}
          latitude={eventInfo.event.latitude}
          longitude={eventInfo.event.longitude}
          team_number={eventInfo.event.team_number}
          max_persons={eventInfo.event.max_persons}
          persons_left={eventInfo.event.persons_left}
          type="free"
          toogleModalShowDetails={() =>
            this.toggleModalShowDetails(
              eventInfo.event,
              eventInfo.multiprice,
              eventInfo.user_logo
            )
          }
          reloadEventsCallback={this.reloadEventsCallback}
          categories={this.state.categories}
          userLogo={eventInfo.user_logo}
          showVoucer={this.showVoucer}
          userTypeBusiness={eventInfo.user_type}
          showButtons={
            parseInt(eventInfo.event.user_id) ===
              parseInt(userCredentials.userId)
              ? true
              : false
          }
          description={eventInfo.event.name}
          voucherLogo={eventInfo.voucher_logo}
          voucherVideo={eventInfo.voucher_video}
        />
      </div>
    );
  };
  getAllFriendsEventHandler = (event) => {
    this.setState({ filterVal: "" });

    if (this.state.filteredList.length <= 5) {
        let currentDisplayedFriends = this.state.friendsList;
        let friend = this.state.friendsList;
        if (!currentDisplayedFriends.some((friend) => friend.key === "5"))
            // currentDisplayedFriends = [
            //     ...currentDisplayedFriends,
            //     {key: "5", name: "Alina"}
            // ];
            // friend = {key: "6", name: "Cornel"};
            // if (!currentDisplayedFriends.some(friend => friend.key === "6"))
            //   currentDisplayedFriends = [...currentDisplayedFriends, friend];
            // friend = { key: "7", name: "Adrian", modal: false };
            // if (!currentDisplayedFriends.some(friend => friend.key === "7"))
            //   currentDisplayedFriends = [...currentDisplayedFriends, friend];

            this.setState({ friendsList: currentDisplayedFriends });
        this.setState({ filteredList: currentDisplayedFriends });
    } else {
        this.setState({ filteredList: this.state.friendsList.slice(0, 5) });
        this.setState({ friendsList: this.state.friendsList.slice(0, 5) });
    }
};
  searchFriends = (event) => {
    let friend = this.state.allFriends;
    this.setState({ filterVal: event.target.value.substr(0, 10) });
    if (event.target.value !== "") {
        const filteredList = friend.filter(
            (friendsList) =>
                friendsList[0].name
                    .toLowerCase()
                    .indexOf(event.target.value.toLowerCase()) !== -1
        );
        this.setState({ friendsList: filteredList });
    } else {
        // this.listoffriends();

        this.setState({ friendsList: friend });
    }
};

  render() {
    const ColoredDateCellWrapper = ({ children, value }) => {
      let evtPresent = this.state.currentMonthList.filter((x) => {
        let curDate = new Date(value);
        curDate.setHours(0, 0, 0, 0);
        let dealDateStart = new Date(x.start);
        dealDateStart.setHours(0, 0, 0, 0);
        let dealDateEnd = moment(x.end);
        if (+curDate === +dealDateStart) {
          return x;
        }
      });
      return React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
          backgroundColor: evtPresent.length > 0 ? "lightgreen" : "lightyellow",
        },
      });
    };

    return (
      <Container className="trans-ition-user-profile usrProfileModal">
        <Animated
          animationIn="bounceInUp"
          animationOut="slideOutUp"
          isVisible={true}
        >
          <Col className=" no-margin-row-parent">
            <Row className="topParent">
              <Col xs="2" md="2" sm="2" lg="2" className="pl-0">
                <img
                  className="userLogo"
                  src={
                    this.state.imagePreviewUrl !== null &&
                      this.state.imagePreviewUrl !== "" &&
                      this.state.imagePreviewUrl !== undefined
                      ? (this.state.imagePreviewUrl) :
                      this.props.businessLogo !== undefined ? 
                       (this.props.businessLogo)
                      : (profilePic) 

                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = profilePic;
                  }}
                />
              </Col>
              <Col xs="10" sm="8" md="8" lg="8" className="pl-0 oHidden">
                <div className="d-flex">
                  <div className="profileDataLabel">
                    <FontAwesomeIcon className="mr-2" icon={faUserAlt} />
                    Name:
                  </div>
                {this.state.userType === "2" ? 
                (<Label>
                  {
                  this.state.companyName === "" ?
                  this.state.firstName
                  : 
                  this.state.companyName
                  }
                  </Label>) 
                :
                (<Label>{this.state.firstName}</Label>)
                }

                </div>
                <div className="d-flex">
                  <div className="profileDataLabel">
                    <FontAwesomeIcon icon={faFlag} className="mr-2" />
                    Language:
                  </div>
                  <Label> {this.state.language}</Label>
                </div>
                <div className="d-flex">
                  <div className="profileDataLabel d-flex">
                    <FontAwesomeIcon icon={faMapMarkedAlt} className="mr-2" />{" "}
                    Address:
                  </div>
                  <Label> {this.state.address}</Label>
                </div>
                <div className="dFlexUser">
                  <div className="profileDataLabel">
                    <FontAwesomeIcon icon={faUserTag} className="mr-2" />{" "}
                    Categories:
                  </div>
                  <div className="categoriesBox">
                    {this.state.usrCategories &&
                      this.state.usrCategories.length > 0 ? (
                        this.state.usrCategories.map((item) =>
                          item.category_name ? (
                            <Button
                              key={item.category_name}
                              className="disableHover"
                            >
                              {" "}
                              {item.category_name}{" "}
                            </Button>
                          ) : null
                        )
                      ) : (
                        <Button className="disableHover">
                          {" "}
                        No categories selected{" "}
                        </Button>
                      )}
                  </div>
                </div>
                <div className=" d-flex">
                  <div className="profileDataLabel">
                    <FontAwesomeIcon icon={faAddressCard} className="mr-2" />{" "}
                    Description:
                  </div>
                  <Label className="descriptionBox">
                    {this.state.description}
                  </Label>
                </div>
                {this.state.userType === "2" ? (
                  <div>
                    <div className="d-flex">
                      <div className="profileDataLabel d-flex">
                        <FontAwesomeIcon
                          icon={faMapMarkedAlt}
                          className="mr-2"
                        />{" "}
                        Phone number:
                      </div>
                      <Label> {this.state.phoneNumber}</Label>
                    </div>
                    {this.state.userType === "2" ? (
                      <div className="d-flex">
                        <div className="profileDataLabel d-flex">
                          <FontAwesomeIcon
                            icon={faMapMarkedAlt}
                            className="mr-2"
                          />{" "}
                          Email:
                        </div>
                        <Label> {this.state.email}</Label>
                      </div>
                    ) : null}
                    <div className="d-flex">
                      <div className="profileDataLabel d-flex">
                        <FontAwesomeIcon
                          icon={faMapMarkedAlt}
                          className="mr-2"
                        />{" "}
                        Website url:
                      </div>
                      <Label> {this.state.websiteUrl}</Label>
                    </div>
                    <Button
                      onClick={this.toggleVideoModal}
                      className="seeAllBtn"
                    >
                      Watch Video
                    </Button>
                  </div>
                ) : null}
              </Col>
              <Col xs="12" sm="2" md="2" lg="2" className="pl-0">
                {this.props.userId.toString() !==
                  userCredentials.userId.toString() ? (
                    <div className="profileRightBtns">
                      {this.state.follow ? (
                        <Button
                          className="btnProfile Follow mb-2"
                          onClick={() => this.follow() && this.openFollow()}
                        >
                          Follow
                        </Button>
                      ) : null}
                      {!this.state.follow ? (
                        <Button
                          className="btnProfile Unfollow mb-2"
                          onClick={() => this.unfollow() && this.openFollow()}
                        >
                          {" "}
                        Unfollow
                        </Button>
                      ) : null}
                      {this.state.openBlockUser ? (
                        <div>
                          <Button
                            className="btnProfile Block mb-2"
                            id="PopoverLegacy"
                            type="button"
                          >
                            Block
                        </Button>
                          <UncontrolledPopover
                            className="pop-over-block"
                            trigger="legacy"
                            placement="bottom"
                            target="PopoverLegacy"
                          >
                            <PopoverHeader>Are you sure?</PopoverHeader>
                            <div className="popmsg">
                              If you block <b>{this.state.firstName}</b>, he/she
                            will no longer be in your list of friends.
                          </div>
                            <PopoverBody>
                              <Button
                                onClick={() =>
                                  this.unfollow() &&
                                  this.block() &&
                                  this.openBlock()
                                }
                                className="confirm-block-btn"
                              >
                                Confirm
                            </Button>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </div>
                      ) : null}
                      {!this.state.openBlockUser ? (
                        <div>
                          <Button
                            className="btnProfile Block mb-2"
                            id="PopoverLegacy"
                            type="button"
                          >
                            Unblock
                        </Button>
                          <UncontrolledPopover
                            className="pop-over-block"
                            trigger="legacy"
                            placement="bottom"
                            target="PopoverLegacy"
                          >
                            <PopoverHeader>
                              Are you sure you want to unblock{" "}
                              {this.state.firstName} ?
                          </PopoverHeader>
                            <PopoverBody>
                              <Button
                                onClick={() =>
                                  this.removeblock() && this.openBlock()
                                }
                                className="confirm-block-btn"
                              >
                                Confirm
                            </Button>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </div>
                      ) : null}
                      {this.state.isActive ? (
                        <Button
                          className="btnProfile Report"
                          onClick={this.handleHide}
                        >
                          {" "}
                        Report
                        </Button>
                      ) : (
                          <Button
                            className="btnProfile Report"
                            onClick={this.handleShow}
                          >
                            {" "}
                        Report
                          </Button>
                        )}
                    </div>
                  ) : null}
              </Col>
              {this.state.isActive && (
                <div className="report-user-contain">
                  <Animated
                    animationIn="zoomIn"
                    animationOut="zoomOut"
                    isVisible={true}
                  >
                    <Label className="center-label-report">
                      Why do you want to report this user?
                    </Label>
                    <Row className=" justify-content-center d-flex parent-input-btns">
                      <Col>
                        <Input
                          className="text-white"
                          type="textarea"
                          onChange={(e) => {
                            this.onChangeText(e);
                          }}
                          value={this.state.mesageUserReported}
                          placeholder="Start typing..."
                        />
                      </Col>
                      <Col xs="2">
                        <Button
                          onClick={() => this.sendreport()}
                          className="MessageBtnUserProfile"
                        >
                          <FontAwesomeIcon icon={faShare} />
                        </Button>
                      </Col>
                    </Row>
                  </Animated>
                </div>
              )}
            </Row>
            {this.state.userType === "2" ? (
              <div className="profileEventsInfo">
                <Row>
                  <div className="col-sm-4 col-lg-4 col-md-4">
                    <div className="eventOpenClosedUserProfile">
                      Events created:
                    </div>
                    <div>
                      <Button
                        className="noOfEvt"
                        onClick={() =>
                          this.openEventsCreated(this.state.firstName)
                        }
                      >
                        {this.state.createdEventsNumber}
                      </Button>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-4 col-md-4">
                    <div className="eventOpenClosedUserProfile ">
                      {" "}
                      Events registered:
                    </div>
                    <div>
                      <Button
                        className="noOfEvt"
                        onClick={() =>
                          this.openEventsRegistered(this.state.firstName)
                        }
                      >
                        {this.state.nrEventsAssigned}
                      </Button>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-4 col-md-4">
                    <div className="eventOpenClosedUserProfile ">
                      {" "}
                      Deals created:
                    </div>
                    <div>
                      <Button
                        className="noOfEvt"
                        onClick={() =>
                          this.openDealsCreated(this.state.firstName)
                        }
                      >
                        {this.state.nrOfDeals}
                      </Button>
                    </div>
                  </div>
                  {/* <div className="col-sm-3 col-lg-3 col-md-3">
                    <div className="eventOpenClosedUserProfile ">Friends:</div>
                    <div>
                      <Button
                        className="noOfEvt"
                        onClick={() => this.openFriends(this.state.firstName)}
                      >
                        {this.state.friends}
                      </Button>
                    </div>
                  </div> */}
                </Row>
              </div>
            ) : (
                <div className="profileEventsInfo">
                  <Row>
                    <div className="col-sm-4 col-lg-4 col-md-4">
                      <div className="eventOpenClosedUserProfile">
                        Events created:
                    </div>
                      <div>
                        <Button
                          className="noOfEvt"
                          onClick={() =>
                            this.openEventsCreated(this.state.firstName)
                          }
                        >
                          {this.state.createdEventsNumber}
                        </Button>
                      </div>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-md-4">
                      <div className="eventOpenClosedUserProfile ">
                        {" "}
                      Events registered:
                    </div>
                      <div>
                        <Button
                          className="noOfEvt"
                          onClick={() =>
                            this.openEventsRegistered(this.state.firstName)
                          }
                        >
                          {this.state.nrEventsAssigned}
                        </Button>
                      </div>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-md-4">
                      <div className="eventOpenClosedUserProfile ">Friends:</div>
                      <div>
                        <Button
                          className="noOfEvt"
                          onClick={() => this.openFriends(this.state.firstName)}
                        >
                          {this.state.friends}
                        </Button>
                      </div>
                    </div>
                  </Row>
                </div>
              )}
            <div className="profileEventsInfo">
              <Row className="justify-content-between">
                {this.state.imagesFromServer &&
                  this.state.imagesFromServer.length > 0
                  ? this.state.imagesFromServer.map((item) => (
                    <div
                      key={item.image}
                      className="d-flex justify-content-center imageBoxMyProfile  col"
                    >
                      <img
                        className="d-flex justify-content-center imageBoxMyProfile"
                        src={item.image}
                        onError={(e) => {
                          e.target.onerror = null;
                        }}
                        onClick={() => this.openImageModal(item.image)}
                      />
                    </div>
                  ))
                  : null}
              </Row>
            </div>
            <div className="socialNetworksUP row">
              {this.state.socialNetworkList.map((item) => (
                <a
                  key={item.id}
                  href={this.formatUrl(item.id)}
                  target="_blank"
                >
                  <img
                    src={item.logo}
                    className="socialNetworkImg"
                  />
                </a>
              ))}
            </div>
            <div className="eventContainerBottomUserProfile no-margin-row ">
              <div className="margin-r-l row mt-3">
                <Row className=" justify-content-center d-flex parent-input-btns">
                  <Col>
                    <Input
                      placeholder="Start typing..."
                      className="messageInputUserProfile"
                      value={this.state.messageText}
                      onChange={(val) =>
                        this.setState({ messageText: val.target.value })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (this.state.messageText.length !== 0) {
                            this.sendMessage();
                          } else {
                            swal(
                              "Info!",
                              "Can't send an empty message",
                              "info"
                            );
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col xs="2">
                    <Button
                      className="MessageBtnUserProfile"
                      onClick={() => {
                        if (this.state.messageText.length !== 0) {
                          this.sendMessage();
                        } else {
                          swal("Info!", "Can't send an empty message", "info");
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faShare} />
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Animated>
        <Modal
          isOpen={this.state.videoModal}
          // toggle={this.toggleVideoModal}
          fade={false}
        >
          <ModalVideo
            channel="youtube"
            youtube={{ autoplay: 1, mute: 1 }}
            isOpen={this.state.videoModal}
            videoId={
              this.state.videoUrl !== null
                ? this.state.videoUrl.split("v=")[1]
                : ""
            }
            onClose={() => this.setState({ videoModal: false })}
          />
        </Modal>

        <Modal
          isOpen={this.state.profileModal}
          // toggle={this.toggleProfileModal}
          size="lg"
        >
          <div className="header-p">
            <ModalHeader className="head-1  text-white">
              {this.state.modalTitle}
            </ModalHeader>
            <div className="logo_image">
              <img className="my_logo" src={SLogo} alt="my_logo" />
            </div>
            <ModalHeader className="head-2" toggle={this.toggleProfileModal} />
          </div>
          <ModalBody>
            {this.state.showUsersCreatedEvents ? (
              <div className="eventsArea">
                <FlatList
                  list={this.state.createdEventsList}
                  renderItem={this.renderEvents}
                ></FlatList>
              </div>
            ) : this.state.showUserRegisteredEvents ? (
              <div className="eventsArea">
                <FlatList
                  list={this.state.registeredEventsList}
                  renderItem={this.renderEvents}
                ></FlatList>
              </div>
            ) : this.state.showUserDeals ? (
              <Col className="d-flex justify-content-center">
                <Calendar
                messages={{
                  showMore: total => (
                    <div
                      style={{ cursor: 'pointer' }}
                      onMouseOver={e => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >SEE all deals
                    </div>
                  ),
                }}
                  localizer={localizer}
                  //defaultDate={new Date()}
                  date={this.state.calendarDate}
                  defaultView="month"
                  events={this.state.currentMonthList}
                  style={{
                    fontSize:"12px",
                    height: "700px",
                    width: "700px",
                    backgroundColor: "#fff",
                  }}
                  selectable={true}
                  onSelectSlot={this.selectSlot}
                  onSelectEvent={(event) => this.selectEvent(event)}
                  components={{
                    dateCellWrapper: ColoredDateCellWrapper,
                  }}
                  eventPropGetter={this.eventStyleGetter}
                  onNavigate={this.navigateThroughCalendar}
                  view={this.state.drillView}
                  onView={this.onDrillDown}
                />
              </Col>
            ) : this.state.showUserFriends ? (
              <div>
                <Friends
                userId={this.props.userId}
                  openEventsRegistered={this.openEventsRegistered}
                  openEventsCreated={this.openEventsCreated}
                  // openDealsCreated={this.openDealsCreated}
                  friendsList={this.state.friendsList}
                  filterVal={this.state.filterVal}
                  totalFriendsNo={this.state.totalFriendsNo}
                  refreshAllFriends={this.listoffriends}
                  getAllFriendsEventHandler={this.getAllFriendsEventHandler}
                  searchEventHandler={this.searchFriends}
                />
              </div>
            ) : this.state.currentEvent !== undefined &&
              !this.state.showVoucers &&
              this.state.currentMultiprice !== null &&
              this.state.currentEvent.date !== undefined ? (
                        <BannerDetailsNormal
                        categoryName={this.state.currentEvent.name_of_subcategory}
                          currencyBannner={this.state.currentEvent.currency}
                          userLogged={true}
                          handler={this.props.handler}
                          // handleLog={this.props.handler}

                          price={
                            !this.state.currentMultiprice ||
                              this.state.currentMultiprice.length === 0
                              ? "FREE"
                              : this.state.currentMultiprice.length === 1
                                ? this.state.currentMultiprice[0].price === "0"
                                  ? "FREE"
                                  : "PAY"
                                : "PAY"
                          }
                          id={this.state.currentEvent.id}
                          userIdE={this.state.currentEvent.user_id}
                          name={this.state.currentEvent.name}
                          gender={this.state.currentEvent.gender}
                          ageCategory={this.state.currentEvent.age_category}
                          startDate={this.state.currentEvent.date.substring(0, 11)}
                          endDate={this.state.currentEvent.end_date.substring(0, 11)}
                          startTime={this.state.currentEvent.start_hour.substring(0, 5)}
                          endTime={this.state.currentEvent.end_hour.substring(0, 5)}

                          latitude={this.state.currentEvent.latitude}
                          longitude={this.state.currentEvent.longitude}
                          team_number={this.state.currentEvent.team_number}
                          max_persons={this.state.currentEvent.max_persons}
                          persons_left={this.state.currentEvent.persons_left}
                          description={this.state.currentEvent.description}
                          multiprice={this.state.currentMultiprice}
                          address={this.state.currentEvent.address}
                          latitude={this.state.currentEvent.latitude}
                          longitude={this.state.currentEvent.longitude}
                          userLogo={this.state.eventLogo}
                          eventLogo={this.state.currentEvent.category_logo}
                          category={this.state.currentEvent.category_id}
                          closeModal={this.toggleModalShowDetails}
                          reloadEventsCallback={this.reloadEventsCallback}
                          isDeal={this.state.currentCalendar !== undefined ? true : false}
                          handicap_facilities={
                            this.state.currentEvent.handicap_facilities
                          }
                          endDateEvent={this.state.currentEvent.end_date}
                          inHoliday={this.state.inHoliday}
                        />
                      ) : null}
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalShowDetailsD}
          // toggle={this.toggleModalShowDetailsD}
          size="lg"
        >
          <div className="header-p">
            <ModalHeader className="head-1  text-white">
              Event details
            </ModalHeader>
            <div className="logo_image">
              <img className="my_logo" src={SLogo} alt="my_logo" />
            </div>
            <ModalHeader
              className="head-2"
              toggle={this.toggleModalShowDetailsD}
            />
          </div>

          <ModalBody>
            {this.state.currentEvent !== undefined &&
              this.state.currentEvent.date !== undefined ? (
                <BannerDetailsNormal
                categoryName={this.state.currentEvent.name_of_subcategory}
                  currencyBannner={this.state.currentEvent.currency}
                  userLogged={true}
                  id={this.state.currentEvent.id}
                  userIdE={this.state.currentEvent.user_id}
                  name={this.state.currentEvent.name}
                  gender={this.state.currentEvent.gender}
                  ageCategory={this.state.currentEvent.age_category}
                  startDate={this.state.currentEvent.date.substring(0, 11)}
                  endDate={
                    this.state.currentEvent.end_date !== null
                      ? this.state.currentEvent.end_date.substring(0, 11)
                      : ""
                  }
                  price={
                    !this.state.currentMultiprice ||
                      this.state.currentMultiprice.length === 0
                      ? "FREE"
                      : this.state.currentMultiprice.length === 1
                        ? this.state.currentMultiprice[0].price === "0"
                          ? "FREE"
                          : "PAY"
                        : "PAY"
                  }
                  startTime={this.state.currentEvent.start_hour.substring(0, 5)}
                  endTime={this.state.currentEvent.end_hour.substring(0, 5)}

                  latitude={this.state.currentEvent.latitude}
                  longitude={this.state.currentEvent.longitude}
                  team_number={this.state.currentEvent.team_number}
                  max_persons={this.state.currentDealCalendar.number_of_persons}
                  persons_left={this.state.currentDealCalendar.left_persons}
                  description={this.state.currentEvent.description}
                  multiprice={this.state.currentMultiprice}
                  address={this.state.currentEvent.address}
                  latitude={this.state.currentEvent.latitude}
                  longitude={this.state.currentEvent.longitude}
                  userLogo={this.state.eventLogo}
                  eventLogo={this.state.currentEvent.category_logo}
                  category={this.state.currentEvent.category_id}
                  closeModal={this.toggleModalShowDetailsD}
                  reloadEventsCallback={this.reloadEventsCallback}
                  calendar={this.state.currentCalendar}
                  curentDealCalendarId={this.state.curentDealCalendarId}
                  isDeal={this.state.currentCalendar !== undefined ? true : false}
                  endDateEvent={this.state.currentEvent.end_date}
                  inHoliday={this.state.inHoliday}
                />
              ) : null}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.imageModal}
          // toggle={this.openImageModal}
          size="lg"
        >
          <div className="header-p">
            <ModalHeader className="head-1  text-white">Image</ModalHeader>
            <div className="logo_image">
              <img className="my_logo" src={SLogo} alt="my_logo" />
            </div>
            <ModalHeader className="head-2" toggle={this.openImageModal} />
          </div>

          <ModalBody>
            <img className="d-flex w-100 p-0 m-0" src={this.state.currentImg} />
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}
